<template>
  <div
    id="notifications"
    class="w-full px-4 mx-auto max-w-7xl sm:px-8 md:px-8 md:mb-8"
    role="form"
    aria-label="Notification Settings"
  >
    <div class="flex items-center justify-between my-6">
      <h1 id="id-notifications-label" class="mr-4 text-2xl font-semibold">
        Notification Settings
      </h1>
    </div>
    <div class="grid gap-8" aria-labelledby="id-notifications-label">
      <template v-for="item in switches" :key="item.input">
        <div v-if="item.show" class="flex xs:items-center">
          <div
            class="switch xs:mt-0"
            role="checkbox"
            tabindex="0"
            :class="[
              item.selected
                ? 'bg-blue-700 justify-end'
                : 'bg-gray-300 justify-start',
            ]"
            :aria-expanded="item.selected.toString()"
            @click="toggle(item)"
            @keyup.enter="toggle(item)"
          >
            <span
              class="flex-none block w-6 h-6 mx-1 bg-white rounded-full shadow"
            ></span>
          </div>
          <span class="ml-4"> {{ item.label }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationSettings",
  data() {
    return {
      switches: [
        {
          label: "Notify me when a student started an Application",
          input: "start",
          selected: false,
          show: true,
        },
        {
          label: "Notify me when a student submits an Application",
          input: "submit",
          selected: false,
          show: true,
        },
        {
          label: "Notify me when a student gets accepted into a program",
          input: "accept",
          selected: false,
          show: false,
        },
        {
          label: "Notify me if a student changes their program",
          input: "change",
          selected: false,
          show: false,
        },
        {
          label: "Notify me if a student withdraws",
          input: "withdrawal",
          selected: false,
          show: true,
        },
        {
          label: "Notify me weekly or bi-weekly Prospect list",
          input: "prospect",
          selected: false,
          show: false,
        },
      ],
      enrollmentURL: this.trailingSlash(
        process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
      ),
    };
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    async getNotifications() {
      let response = await fetch(this.enrollmentURL + "advisor/notifications", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-jwt-token": this.$store.state.currentUser.advisorToken,
        },
      });

      let status = await response.status;
      if (status !== 200) {
        return false;
      }

      let data = await response.json();

      Object.entries(data).forEach(([key, value]) => {
        this.switches.find((switche) => {
          if (switche.input === key) {
            switche.selected = Boolean(value);
          }
        });
      });
    },
    toggle(item) {
      item.selected = !item.selected;
      this.updateNotifications(item);
    },
    async updateNotifications(item) {
      const data = { email: this.$store.state.university.userDetails.email };

      this.switches.forEach((switche) => {
        data[switche.input] = switche.selected;
      });

      let response = await fetch(this.enrollmentURL + "advisor/update", {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json",
          "x-jwt-token": this.$store.state.currentUser.advisorToken,
        },
      });

      let status = await response.status;

      if (status !== 200) {
        item.selected = !item.selected;
      }
    },
  },
};
</script>

<style scoped>
.switch {
  @apply block w-12 h-8 mt-1 flex flex-none;
  @apply items-center rounded-full shadow-inner cursor-pointer;
  @apply transition-all duration-200 ease-in-out;
}
</style>

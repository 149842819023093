<template>
  <label class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5" :for="id">
    <VSelect
      :id="id"
      v-model="filters"
      class="filters"
      multiple
      :placeholder="placeholder"
      :value="filters"
      :options="sortedItems"
      :reduce="(item) => item.value"
      :close-on-select="false"
      :aria-label="placeholder"
      @option:selecting="remove"
      @option:selected="sort"
      @option:deselected="deselected"
    >
      <template #option="option">
        <span class="inline-flex">
          <input
            class="text-blue-700 mt-3.2 form-checkbox"
            type="checkbox"
            :checked="option.selected"
          />
          <span class="ml-2">{{ option.label }}</span>
        </span>
      </template>
    </VSelect>
  </label>
</template>
<script>
export default {
  name: "Multiselect",
  props: {
    selectData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    id: {
      type: String,
      default: "Multiselect",
    },
    placeholder: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeClear", "input"],
  data() {
    return {
      sortedItems: [],
      filters: [],
    };
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) this.sort(this.filters);
      },
      deep: true,
    },
    clear: {
      handler(newVal) {
        if (newVal) {
          this.$emit("changeClear");
          this.filters = this.value.slice();
          this.sort(this.filters);
        }
      },
    },
  },
  created() {
    this.filters = this.value.slice();
    this.sort(this.filters);
  },
  methods: {
    sort(selectedItems) {
      let array = this.data;
      if (array) {
        array.forEach((item) => {
          item.selected = false;
        });

        for (let item of selectedItems) {
          const itemIndex = array.findIndex((obj) => {
            let value = item.value ? item.value : item;
            return obj.value === value;
          });

          if (itemIndex !== -1) {
            array[itemIndex].selected = true;
          }
        }

        array.sort(
          (a, b) =>
            (a.selected === b.selected ? 0 : a.selected ? -1 : 1) ||
            a.label.localeCompare(b.label)
        );
      }
      this.$emit("input", this.filters);
      this.sortedItems = array;
    },
    deselected() {
      this.sort(this.filters);
    },
    remove(item) {
      if (item.selected) {
        const itemIndex = this.filters.findIndex((obj) => obj === item.value);

        this.filters.splice(itemIndex, 1);
        this.sort(this.filters);
      }
    },
  },
};
</script>

<template>
  <div class="w-full mx-auto max-w-7xl md:px-8 md:mb-8">
    <div class="m-4">
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 bg-white border-t border-gray-200 dashboard sm:grid sm:grid-cols-12 sm:gap-6 md:gap-12 sm:px-8 md:p-12 md:border md:rounded "
    >
      <div class="col-span-12">
        <template v-if="loading">
          <spinner>
            <template #spinnercontent>
              <p class="text-gray-600">
                {{ message }}
              </p>
              <p>
                Something not working as expected?
              </p>
              <router-link
                :to="{
                  name: 'applications'
                }"
                class="api-link underline hover:no-underline"
              >
                Click here to go back.
              </router-link>
            </template>
          </spinner>
        </template>
        <template v-else>
          <PageSection :id="'etranscript'" :title="'E-Transcript'">
            <template #content>
              <EtranscriptForm />
            </template>
          </PageSection>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PageSection from "../SharedComponents/Layout/PageSection.vue";
import EtranscriptForm from "../E-Transcript/etranscript-form.vue";
import formsMix from "../../../mixins/forms";
import Spinner from "../../helpers/Spinner.vue";

export default {
  name: "ETranscript",
  components: {
    PageSection,
    EtranscriptForm,
    Spinner,
  },
  mixins: [formsMix],
  data() {
    return {
      loading: true,
      message: "Loading E-Transcript form, please wait.",
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async created() {
    let canView = await this.isValidApplication(
      this.$route.params.applicationId,
      "E-Transcript"
    );
    if (!canView) return this.setCountdown(5000);
    this.loading = false;
  },
  methods: {
    setCountdown(countdown) {
      let counter = countdown / 1000;
      let redirectCountdown = setInterval(() => {
        if (counter === 0) {
          clearInterval(redirectCountdown);
          this.$router.push({ name: "applications" });
        }
        counter--;
        this.message =
          "You don't have access to this form, redirecting back to main page in " +
          counter +
          " seconds.";
      }, 1000);
    },
  },
};
</script>

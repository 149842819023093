<template>
  <div>
    <p v-if="validToken && !remainingBalance" class="error text-error-900">
      {{ studentFullName }} has a balance of 0 so no payment is needed at this
      time. If this message doesn't seem right, please have
      {{ studentFullName }} check their APIConnect account and send a new
      payment request.
    </p>
    <p v-if="!validToken" class="error text-error-900">
      We could not find the payment request information, please have the student
      check their APIConnect account and send a new payment request.
    </p>
    <FormPanel
      :id="'account-information'"
      :title="'Account Information'"
      class="mt-6"
      panel-content-class="text-gray-600 border-gray-200"
    >
      <template #content>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6">
          <label class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>First Name</span>
            </span>
            <p
              data-cy="first-name-text"
              class="block w-full min-h-10 text-base"
            >
              {{ paymentRequest.requirer_first_name }}
            </p>
          </label>

          <label class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Last Name</span>
            </span>
            <p data-cy="last-name-text" class="block w-full min-h-10 text-base">
              {{ paymentRequest.requirer_last_name }}
            </p>
          </label>

          <label class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Account Number</span>
            </span>
            <p
              data-cy="account-number-text"
              class="block w-full min-h-10 text-base"
            >
              {{ paymentRequest.account_number }}
            </p>
          </label>
        </div>
      </template>
    </FormPanel>

    <FormPanel
      :id="'payment-details'"
      :title="'Payment Details'"
      class="mt-6"
      panel-content-class="text-gray-600 border-gray-200"
    >
      <template #content>
        <PaymentDetails
          :items="items"
          :items-total="itemsTotal"
          :convenience-fee="convenienceFee"
          :ach-fee="achFee"
        />
      </template>
    </FormPanel>

    <PaymentType
      ref="paymentType"
      :selected-type="paymentType"
      @turnFeeOff="turnFeeOff"
      @update:payment-type="paymentType = $event"
      @updateState="updateState"
    />

    <div class="mt-12">
      <ButtonWithSpinner
        ref="summarySubmit"
        data-cy="summary-submit-button"
        type="submit"
        variant="primary"
        variant-type="block"
        :disabled="v$.$invalid || !validToken || !remainingBalance || hasErrors"
        @click="submit"
      >
        <span> Continue </span>
      </ButtonWithSpinner>
    </div>
    <p v-if="errorText" class="error text-error-900 mt-2">
      {{ errorText }}
    </p>

    <p class="text-sm text-gray-650 mt-4">
      * Selecting continue will take you to a secure payment page to enter your
      payment information.
    </p>
  </div>
</template>

<script>
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import PaymentType from "@/components/PaymentRequest/PaymentType.vue";
import PaymentDetails from "@/components/PaymentRequest/PaymentDetails.vue";
import FormValidation from "@/mixins/formValidation";
import axios from "axios";
import { requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { dollarsToCents } from "@/mixins/helpers";

export default {
  name: "Summary",
  components: {
    FormPanel,
    ButtonWithSpinner,
    PaymentType,
    PaymentDetails,
  },
  mixins: [FormValidation],
  props: {
    validToken: {
      type: Boolean,
      default: true,
    },
    paymentRequest: {
      type: Object,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
    token: {
      type: String,
      default: "",
    },
    metadata: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      byPassFee: false,
      state: null,
      sectionsToValidate: ["paymentType"],
      paymentType: {},
      errorText: "",
    };
  },
  computed: {
    itemsTotal() {
      return this.items.reduce(
        (accumulator, current) => accumulator + current.balance,
        0
      );
    },
    convenienceFee() {
      const factor = this.paymentType.paymentType?.feeFactor ?? 0;
      if (this.byPassFee) return 0;
      let fee = 0;

      if (factor) fee = Math.round(this.itemsTotal * factor);
      return fee;
    },
    achFee() {
      return this.paymentType.paymentType?.achFee ?? 0;
    },
    studentFullName() {
      return `${this.paymentRequest.requirer_first_name} ${this.paymentRequest.requirer_last_name}`;
    },
    remainingBalance() {
      return !!this.items.length;
    },
  },
  watch: {
    paymentType: {
      handler: async function () {
        let reset = this.paymentType.paymentType?.value !== "usCreditCard";
        if (reset) {
          this.turnFeeOff(reset);
          // Check if ref exists before tryin method
          if (typeof this.$refs.paymentType !== "undefined") {
            this.$refs.paymentType.cleanDropDown();
          }
        }
      },
      deep: true,
    },
  },
  validations: {
    state: {
      required: requiredIf(function () {
        return this.paymentType.paymentType?.value === "usCreditCard";
      }),
    },
  },
  methods: {
    updateState(state) {
      this.state = state;
    },
    turnFeeOff(turnOff) {
      this.byPassFee =
        this.paymentType.paymentType?.value === "usCreditCard" && turnOff;
    },
    async submit() {
      this.errorText = "";
      this.$refs.summarySubmit.startLoading();

      try {
        await this.validate();

        if (!this.hasErrors) {
          let url = await this.getCheckoutSession();
          this.$refs.summarySubmit.stopLoading();

          if (url) window.location.href = url;
        }
      } catch {
        this.$refs.summarySubmit.stopLoading();
      }
    },
    getSubmissionObject() {
      const cancelURL = window.location.href;
      const successURL =
        `${location.protocol}//${location.hostname}` +
        "/payment-success/" +
        this.token +
        "?paymentType=" +
        this.paymentType.paymentType.value +
        "&applicationId=" +
        this.paymentRequest.application_id;

      let items = this.items.map((item) => {
        return {
          name: item.name,
          amount: item.balance, //Amount should come in cents
          sku: item.sku ?? "",
          invoice_id: item.invoice ?? "",
          line_item_id: item.line_item_id ?? "",
        };
      });

      if (this.convenienceFee) {
        const convenienceFeeObj = {
          name: "Convenience fee",
          amount: this.convenienceFee,
          sku: this.paymentType.paymentType.sku,
          invoice_id: "",
          line_item_id: "",
        };

        items.push(convenienceFeeObj);
        this.saveFeeOnStorage(convenienceFeeObj);
      }

      if (this.achFee) {
        const achFeeObj = {
          name: "ACH fee",
          amount: this.achFee,
          sku: this.paymentType.paymentType.sku,
          invoice_id: "",
          line_item_id: "",
        };

        items.push(achFeeObj);
        this.saveFeeOnStorage(achFeeObj);
      }

      return {
        line_items: items,
        application_id: this.paymentRequest.application_id,
        legacy_participant_account_id: this.paymentRequest.account_number ?? "",
        payment_method: this.paymentType.paymentType.stripe,
        success_url: successURL,
        cancel_url: cancelURL,
        customer_email: this.paymentRequest.email,
        ...this.metadata,
        program_session_finance_code:
          this.metadata?.program_session_finance_code ?? null,
        program_type: this.metadata.program_type,
        program_name: this.program?.program?.name,
        session: this.program?.session,
        session_year: this.sessionYear,
        session_country: this.program?.program?.site?.country,
        program_session_id: this.program?.salesforce_id,
        first_name: this.currentUser?.firstName,
        last_name: this.currentUser?.lastName,
        email: this.currentUser?.email,
        phone: this.userData?.data?.phone,
        participant_type: this.userData?.data?.student_type,
        birth_date: this.userData?.data?.birthday,
        home_university_name: this.userData?.data?.schoolname?.label,
        program_city: this.program?.program?.site?.city,
        program_country: this.program?.program?.site?.country,
        start_date: this.program?.start_date,
        end_date: this.program?.end_date,
        end_date_is_final: this.program?.end_date_is_final,
        start_date_is_final: this.program?.start_date_is_final,
        host_institution: this.program?.program?.university?.name,
        contract_number: this.appliedAgreement?.contract_number ?? null,
        billing_contract_id: this.appliedAgreement?.contract_number ?? null,
        product_name: this.program?.program?.name,
        price: dollarsToCents(this.program?.price),
      };
    },
    async getCheckoutSession() {
      try {
        const response = await axios.post(
          "/payment-session",
          this.getSubmissionObject()
        );
        return response.data.url ? response.data.url : "";
      } catch (e) {
        if (Object.hasOwnProperty.call(e.response.data, "error")) {
          Object.values(e.response.data.error).forEach((field) => {
            if (typeof field === "object") {
              field.forEach((error) => {
                this.errorText += error + "\n";
              });
            } else {
              this.errorText += field + "\n";
            }
          });
        } else if (Object.hasOwnProperty.call(e.response.data, "message")) {
          this.errorText += e.response.data.message + "\n";
        }
        return "";
      }
    },
    saveFeeOnStorage(object) {
      localStorage.setItem("fee-details", JSON.stringify(object));
    },
  },
};
</script>
<style scoped>
.w-fit {
  width: fit-content;
}
</style>

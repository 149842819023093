<template>
  <div id="student-info">
    <h2 class="px-4 text-xl font-semibold sm:px-0 hidden">Student Info</h2>
    <div v-if="!loading" class="table-container sm:border sm:rounded">
      <table class="w-full">
        <tbody>
          <tr>
            <th scope="row">
              Name
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ fullName }}
              </span>
            </td>
          </tr>
          <tr>
            <th scope="row">
              Email
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ studentData.email }}
              </span>
            </td>
          </tr>
          <tr v-if="studentData.major">
            <th scope="row">
              Major
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ studentData.major }}
              </span>
            </td>
          </tr>
          <tr v-if="formioData.major">
            <th scope="row">
              Major
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ formioData.major }}
              </span>
            </td>
          </tr>
          <tr v-if="formioData.minor">
            <th scope="row">
              Minor
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ formioData.minor }}
              </span>
            </td>
          </tr>
          <tr v-if="confirmed.includes(tab)">
            <th scope="row">
              Confirmed
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ tabApplication.confirmed }}
              </span>
            </td>
          </tr>
          <tr v-if="programType.includes(tab) && tabApplication.programType">
            <th scope="row">
              Program Type Interested In
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ tabApplication.programType }}
              </span>
            </td>
          </tr>

          <tr v-if="formioData.gpa">
            <th scope="row">
              GPA
            </th>
            <td>
              <span v-if="loading"> Loading...</span>
              <span v-else>
                {{ formioData.gpa }}
              </span>
            </td>
          </tr>

          <tr v-if="tab !== 'application' && tabApplication.financialAid">
            <th scope="row">
              Plans to Use Financial Aid
            </th>
            <td>
              <span v-if="loading">Loading...</span>
              <span v-if="tabApplication.financialAid === 1">
                Yes
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import formService from "@/services/form";

export default {
  name: "StudentInfo",
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    studentData: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    fullName: {
      type: String,
      default: "",
    },
    tab: {
      type: String,
      default: "",
      required: true,
    },
    tabApplication: {
      type: Object,
      default() {
        return {
          programSessionId: "",
          applicationId: "",
          confirmed: "",
          financialAid: "",
          programType: "",
        };
      },
    },
  },
  data() {
    return {
      formioData: {
        gpa: "",
        minor: "",
        major: "",
      },
      confirmed: ["accepted", "all"],
      programType: ["application", "all"],
    };
  },
  mounted() {
    this.getFormioData();
  },
  methods: {
    getFormioData() {
      formService
        .listSubmissions("studentapplicationstage2step1", {
          "data.application_id": this.tabApplication.applicationId,
        })
        .then((submissions) => {
          if (submissions.length > 0) {
            const submission = submissions[0]?.data;
            this.formioData = {
              gpa: submission?.gpa,
              minor: submission?.minor?.name,
              major: submission?.secondMajor?.name,
            };
          };
        });
    },
  },
};
</script>

<style scoped>
.table-container {
  @apply px-3 mt-3;
  @apply bg-white border-t border-b border-gray-300;
}
th {
  @apply py-3;
  @apply text-xs tracking-widest text-left text-gray-500 uppercase;
}
td {
  @apply py-3;
  @apply text-right;
}
</style>

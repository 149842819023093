<template>
  <div
    v-if="featureFlagOn"
    :class="[
      isActive
        ? 'bg-white text-[#1f264c] font-bold'
        : 'hover:bg-[#494e6d] text-white font-medium',
    ]"
  >
    <img :src="icon" class="w-6 h-6 object-contain mb-1.5" />
    <img
      v-show="nestedItems.length > 0 && showChevron"
      :src="dropdownIcon"
      class="mb-1 absolute top-[20%] right-[4px]"
      @mouseover="toggleSubNavigationList"
    />
    <div
      v-if="isSubNavigationToggled"
      class="right-[-200px] top-0 bg-[#1f264c] rounded absolute text-white"
      @mouseleave="toggleSubNavigationList"
      @click.stop
    >
      <div
        v-for="nestedItem in nestedItems"
        :key="nestedItem.id"
        class="py-[8px] hover:bg-[#494e6d] rounded text-left min-w-[200px] min-h-[40px] font-normal"
      >
        <span class="pl-3 text-sm leading-normal">{{ nestedItem.name }}</span>
      </div>
    </div>
    <div :class="[!isDesktop ? 'ml-4 text-lg' : 'px-1']">
      <span>{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    name: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
    nestedItems: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    showChevron: {
      type: Boolean,
      default: true,
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
    featureFlagOn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubNavigationToggled: false,
    };
  },
  computed: {
    icon() {
      return this.isActive && this.isDesktop
        ? this.icons.active
        : this.icons.default;
    },
    dropdownIcon() {
      return this.isActive
        ? "/images/icon-dropdown-active.svg"
        : "/images/icon-dropdown-default.svg";
    },
  },
  methods: {
    toggleSubNavigationList() {
      this.isSubNavigationToggled = !this.isSubNavigationToggled;
    },
  },
};
</script>

<template>
  <div>
    <Teleport v-if="parentMounted" to="#portal-title">
      <h2
        id="formtitle"
        class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
      >
        Reset your password
      </h2>
    </Teleport>
    <div v-if="validToken" class="ml-auto text-sm">
      Passwords must be 12 characters long and contain at least one number, one
      lower case letter, and one upper case letter.
    </div>
    <p v-if="weakPassword" class="text-center mb-6">
      To keep your account secure, we’re going to need you to update your
      password.
    </p>
    <form
      v-if="validToken"
      id="change-password"
      class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6 md:place-items-center"
      action=""
      aria-label="Change Password"
      @submit.prevent="submitForm()"
    >
      <label for="password" class="block text-sm md:col-span-2 md:w-382px">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Password</span>
        </span>
        <input
          id="password"
          v-model.trim="v$.password.$model"
          data-cy="password"
          type="password"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.password.$error }"
        />
        <span
          v-if="v$.password.required.$invalid && v$.password.$error"
          class="block error text-error-900"
        >
          Field is required
        </span>
        <span
          v-if="v$.password.minLength.$invalid && v$.password.$error"
          class="block error text-error-900"
        >
          Password must have at least
          {{ v$.password.minLength.$params.min }} characters.
        </span>
        <span
          v-if="v$.password.upperLetterRegex.$invalid && v$.password.$error"
          class="block error text-error-900"
        >
          Password must have at least one uppercase letter.
        </span>
        <span
          v-if="v$.password.lowerLetterRegex.$invalid && v$.password.$error"
          class="block error text-error-900"
        >
          Password must have at least one lowercase letter.
        </span>
        <span
          v-if="v$.password.numberRegex.$invalid && v$.password.$error"
          class="block error text-error-900"
        >
          Password must have at least one number.
        </span>
        <span
          v-if="
            v$.password.validateUserOnPassword.$invalid && v$.password.$error
          "
          class="block error text-error-900"
        >
          Password should not contain parts of your email.
        </span>
      </label>
      <label
        for="passwordConfirmation"
        class="block text-sm md:col-span-2 md:w-382px"
      >
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Password Confirmation</span>
        </span>
        <input
          id="passwordConfirmation"
          v-model.trim="v$.passwordConfirmation.$model"
          data-cy="password-confirmation"
          type="password"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.passwordConfirmation.$error }"
        />
        <span
          v-if="
            v$.passwordConfirmation.required.$invalid &&
            v$.passwordConfirmation.$error
          "
          class="block error text-error-900"
        >
          Field is required
        </span>
        <span
          v-if="
            v$.passwordConfirmation.sameAsPassword.$invalid &&
            v$.passwordConfirmation.$error
          "
          class="block error text-error-900"
        >
          Passwords must match
        </span>
      </label>
      <div class="mt-6 md:col-span-2 text-center">
        <button
          id="submit"
          type="submit"
          :disabled="v$.$invalid || disabledButton"
          class="m-auto block w-full text-center rounded font-semibold border-2 border-transparent px-4 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900 md:w-186px min-h-50px"
        >
          <span>Submit</span>
        </button>
        <div
          v-if="validToken && sendSubmissionError"
          class="error text-error-900 mt-2"
        >
          {{ sendSubmissionError }}
        </div>
      </div>
    </form>
    <div
      v-if="!validToken && sendSubmissionError"
      class="error text-error-900 mt-2"
    >
      {{ sendSubmissionError }}
    </div>
    <div v-if="!weakPassword" class="grid grid-cols-1 gap-4 sm:gap-6">
      <div class="mt-6 text-center text-gray-600">
        <AlreadyHaveAccount />
      </div>
    </div>
  </div>
</template>

<script>
import { CONSTANT_KEYS } from "../../constants";
import AlreadyHaveAccount from "./AlreadyHaveAccount";

import axios from "axios";
import { helpers, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const upperLetterRegex = helpers.regex(/[A-Z]/);
const lowerLetterRegex = helpers.regex(/[a-z]/);
const numberRegex = helpers.regex(/\d/);
const validateUserOnPassword = (value, vm) => {
  const containsEmail = vm.validEmailParts.some((word) => value.includes(word));
  return !containsEmail;
};
const validatePasswordSameAs = (value, vm) => {
  return vm.password === value;
};

export default {
  name: "ChangePassword",
  components: {
    AlreadyHaveAccount,
  },

  props: {
    token: {
      type: String,
      default: "",
    },
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeComponent", "logout", "setPassword"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      validToken: true,
      password: "",
      email: "",
      disabledButton: false,
      passwordConfirmation: "",
      sendSubmissionError: "",
      currentToken: "",
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(12),
      upperLetterRegex,
      lowerLetterRegex,
      numberRegex,
      validateUserOnPassword,
    },
    passwordConfirmation: {
      required,
      sameAsPassword: validatePasswordSameAs,
    },
  },
  computed: {
    validEmailParts() {
      let parts = [];
      let emailParts = this.email.split("@");

      for (const part of emailParts) {
        let splitByDot = part.split(".");
        let filter = splitByDot.filter((word) => word.length > 3);
        parts = parts.concat(filter);
      }
      return parts;
    },
    weakPassword() {
      return this.$route.query.weakPassword;
    },
  },
  async mounted() {
    this.setToken();
    await axios
      .post("/api/token-validation", {
        email: this.email.toLowerCase(),
        token: this.currentToken,
      })
      .then((response) => {
        if (response.status === 200) {
          this.validToken = true;
        }
      })
      .catch((e) => {
        if (e.response.status === 500) {
          this.sendSubmissionError =
            "There was an error setting your password. Please try again." +
            "\n";
        }
        this.validToken = false;
        this.sendSubmissionError =
          "Your password set link has expired. We have sent you a new link. Please check your email." +
          "\n \n";
        if (
          !this.sendSubmissionError.includes(
            "You have requested password reset recently"
          )
        ) {
          this.sendSubmissionResetPass();
        }
      });
  },
  methods: {
    setToken() {
      if (this.token) {
        // Token assigned by param
        const urlParams = new URLSearchParams(window.location.search);
        this.currentToken = this.token;
        this.email = urlParams.get("email");
      } else if (localStorage.getItem(CONSTANT_KEYS.RESET_TOKEN_STORAGE_KEY)) {
        // Token assigned by local storage
        this.currentToken = localStorage.getItem(
          CONSTANT_KEYS.RESET_TOKEN_STORAGE_KEY
        );
        this.email = localStorage.getItem(CONSTANT_KEYS.RESET_EMAIL);
      }
    },
    async submitForm() {
      this.sendSubmissionError = "";
      this.v$.$touch();
      this.disabledButton = true;
      await this.sendSubmission();
    },
    async sendSubmission() {
      try {
        await axios.post("/reset-password", {
          token: this.currentToken,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        });

        this.$emit("setPassword", true);
        this.$emit("changeComponent", "reset-confirmation");
      } catch (e) {
        this.disabledButton = false;
        if (e.response.status === 500) {
          this.sendSubmissionError =
            "There was an error setting your password. Please try again.";
        } else {
          Object.values(e.response.data.errors).forEach((field) => {
            field.forEach((error) => {
              this.sendSubmissionError += error + "\n";
            });
          });
        }
      }
    },

    changePasswordCancel() {
      this.$emit("logout");
      window.location.replace("/sign-in");
    },
    async sendSubmissionResetPass() {
      try {
        await axios.post("/forgot-password", {
          email: this.email.toLowerCase(),
        });
      } catch (e) {
        this.disabledButton = false;
        if (e.response.status === 500) {
          this.emailError = true;
        } else {
          Object.values(e.response.data.errors).forEach((field) => {
            field.forEach((error) => {
              this.sendSubmissionError += error + "\n";
            });
          });
        }
      }
    },
  },
};
</script>

<template>
  <section>
    <header>
      <p
        v-if="showInstructions"
        class="flex items-center mt-2 mb-8 text-gray-600"
      >
        Most API students are required to pay a $150 application fee to complete
        and submit their application; however, based on either the school you
        currently attend or the program you have selected, you are not required
        to pay the $150 application deposit. In order to move forward with your
        application, please provide us with both your current and permanent
        addresses, as we require this information for our records.
      </p>
    </header>

    <CurrentAddress
      ref="currentAddress"
      :current-address="currentAddress"
      :is-stand-alone-component="true"
      @update:current-address="currentAddress = $event"
    />

    <PermanentAddress
      ref="permanentAddress"
      :permanent-address="permanentAddress"
      :current-address="currentAddressToPermanentAddress"
      @update:permanent-address="permanentAddress = $event"
    />

    <CancellationPolicyCheckbox
      ref="cancellationPolicyCheckbox"
      :cancellation-policy="cancellationPolicyCheckbox"
      @update:cancellation-policy="cancellationPolicyCheckbox = $event"
    />
    <div class="mt-10 block">
      <ButtonWithSpinner
        ref="paymentSubmitButton"
        variant="primary"
        variant-type="block"
        @click.prevent="submit()"
      >
        <span>Continue Application</span>
      </ButtonWithSpinner>
      <p v-if="hasErrors" class="text-error-900">
        Please complete the required fields correctly.
      </p>
    </div>
    <div v-if="formioErrors" class="text-error-900">
      <p v-for="error in formioErrors" :key="error">
        {{ error }}
      </p>
    </div>
    <div v-if="missingAppId" class="text-error-900">
      <p>
        No Application ID, please contact your API program manager to help you
        with your application.
      </p>
    </div>
  </section>
</template>

<script>
import FormValidation from "../../../mixins/formValidation";
import formValidation from "../../../mixins/formValidation";
import { mapState } from "vuex";
import formIoApi from "../../../mixins/formIoApi";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner";
import CancellationPolicyCheckbox from "./CancellationPolicyCheckbox";
import forms from "../../../mixins/forms";
import { eventBus } from "../../../app";
import PermanentAddress from "./PermanentAddress";
import CurrentAddress from "./CurrentAddress";
import AddressComponents from "../../../mixins/AddressComponents";
import { dollarsToCents } from "../../../mixins/helpers";
import { format } from "date-fns";
import axios from "axios";
import { uuid } from "vue-uuid";
import apiClient from "@/services/API";
import AdvisorNotifications from "@/mixins/AdvisorNotifications.js";

export default {
  components: {
    ButtonWithSpinner,
    CancellationPolicyCheckbox,
    PermanentAddress,
    CurrentAddress,
  },
  mixins: [
    FormValidation,
    formIoApi,
    formValidation,
    forms,
    AddressComponents,
    AdvisorNotifications,
  ],
  props: {
    showInstructions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formIoForm: "institutionpayment",
      sectionsToValidate: [
        "permanentAddress",
        "currentAddress",
        "cancellationPolicyCheckbox",
      ],
      cancellationPolicyCheckbox: {},
      ipedsId: null,
      submissionId: "",
    };
  },
  computed: {
    ...mapState([
      "currentApplicationId",
      "userData",
      "program",
      "enrollmentApplications",
      "appliedAgreement",
      "studentApplications",
      "featureFlags",
      "customer_id",
    ]),
    stripePayDepositFormEnabled() {
      return this.featureFlags["stripe-application-deposit"];
    },
    programSessionFinanceCode() {
      let application = this.studentApplications.filter(
        (app) => app.id === this.setApplicationId
      );
      return application.length > 0
        ? application[0].programSession.program_session_finance_code
        : null;
    },
    programType() {
      let application = this.enrollmentApplications.filter(
        (app) => app.application_id === this.setApplicationId
      );
      return application?.length > 0 ? application[0].program_type : null;
    },
    year() {
      return this.program?.start_date.split("-")[0];
    },
    setApplicationId() {
      if (this.currentApplicationId) {
        return this.currentApplicationId;
      } else {
        return this.$route.params.applicationId;
      }
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          currentAddress: this.currentAddress.address,
          currentCity: this.currentAddress.city,
          currentState: this.currentAddress.state,
          currentCountry: this.currentAddress.country,
          currentPostCode: this.currentAddress.postCode,
          permanentAddress: this.permanentAddress.address,
          permanentCity: this.permanentAddress.city,
          permanentState: this.permanentAddress.state,
          permanentCountry: this.permanentAddress.country,
          permanentPostCode: this.permanentAddress.postCode,
          cancellationPolicy: this.cancellationPolicyCheckbox.policy,
          application_id: this.setApplicationId,
        },
      };
      return body;
    },
  },
  watch: {
    currentAddress: {
      handler() {
        if (this.permanentAddress.sameAsCurrentAddress) {
          this.currentAddressToPermanentAddress = {
            address: this.currentAddress.address,
            city: this.currentAddress.city,
            state: this.currentAddress.state,
            country: this.currentAddress.country,
            postCode: this.currentAddress.postCode,
          };
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.$store.dispatch("getCustomerId");
    eventBus.$on("passCurrentAddressToPermanentAddress", (value) => {
      this.setPermanentAddressAsCurrentAddress(value);
    });
    this.ipedsId = await this.setIpedsId();
    eventBus.$emit("showSpinner", false);
  },
  methods: {
    async submitToPaymentService() {
      axios
        .post("/api/submit-to-paymentservice", this.getDataForPaymentService())
        .catch((error) => {
          console.log(error);
        });
    },
    getDataForPaymentService() {
      return {
        event_id: "evt_" + uuid.v4(),
        program_session_finance_code: this.programSessionFinanceCode,
        legacy_participant_account_id: this.customer_id,
        program_type: this.programType,
        program_name: this.program?.program?.name,
        program_session: this.program?.session,
        session_country: this.program?.site?.country,
        session_year: this.year,
        source_type: "connect",
        amount_in_cents: dollarsToCents(this.program?.application_fee),
        application_id: this.setApplicationId,
        resource_id: this.userData._id,
        event_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        event_data: {
          first_name: this.userData.data.firstname,
          last_name: this.userData.data.lastname,
          email: this.userData.data.email,
          phone: this.userData.data.phone,
          participant_type: this.userData.data.student_type,
          birth_date: this.userData.data.birthday,
          home_university_name: this.userData.data.schoolname.label,
          home_institution_ipeds: this.ipedsId,
          program_city: this.program?.site?.city,
          program_country: this.program?.site?.country,
          program_id: this.program?.program?.salesforce_id,
          program_session_id: this.program?.salesforce_id,
          year: this.year,
          start_date: this.program?.start_date,
          end_date: this.program?.end_date,
          end_date_is_final: this.program?.end_date_is_final,
          start_date_is_final: this.program?.start_date_is_final,
          host_institution: this.program?.program?.university?.name,
          current_address: this.currentAddress.address,
          current_city: this.currentAddress.city,
          current_state: this.currentAddress.state,
          current_country: this.currentAddress.country,
          current_post_code: this.currentAddress.postCode,
          permanent_street: this.permanentAddress.address,
          permanent_city: this.permanentAddress.city,
          permanent_state_province: this.permanentAddress.state,
          permanent_country: this.permanentAddress.country,
          permanent_zip_postal_code: this.permanentAddress.postCode,
          cancellation_policy: this.cancellationPolicyCheckbox.policy,
          contract_number: this.appliedAgreement?.contract_number,
          product_name: "Application Deposit",
          price: this.program.price,
          session: this.program.session,
          is_application_fee: true,
          is_institution_pays: true,
          legacy_participant_account_id: this.customer_id,
          is_customized: this.program?.program?.customized ?? false,
        },
      };
    },
    async submit() {
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.paymentSubmitButton.startLoading();
      try {
        await this.checkForApplicationId();
        await this.validate();
        await this.formioValidateSubmission(
          this.formIoForm,
          this.createSubmissionDataForFormIo
        );
        if (!this.hasFormIoValidationErrors) {
          //Prevent duplicate submissions on Formio and Workato
          if (!this.submissionId) {
            if (this.stripePayDepositFormEnabled) this.submitToPaymentService();
            //all good submit to form.io
            this.submitToFormIo(
              this.formIoForm,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submissionId = response;
                // send avisor notification
                this.sendNotifications("submit", this.program.salesforce_id);
                //move to next step
                eventBus.$emit("changeStep");
              }
              this.$refs.paymentSubmitButton.stopLoading();
            });
          }
        } else {
          this.$refs.paymentSubmitButton.stopLoading();
        }
      } catch {
        this.$refs.paymentSubmitButton.stopLoading();
      }
    },
    async setIpedsId() {
      const opeId = this.userData.data?.schoolname?.value ?? "";

      return apiClient
        .get(`/home-university/ipeds/${opeId}`)
        .then((response) => {
          return response.data?.ipeds ?? null;
        })
        .catch(() => {
          return null;
        });
    },
  },
};
</script>

<template>
  <div :id="idTippyParent">
    <component :is="tag" v-if="showDates" data-cy="sidebar-program-dates-label">
      <slot>
        <span :id="id" class="inline-flex items-center" tabindex="0">
          <i
            class="mr-2"
            :data-feather="iconDetails.icon"
            :class="iconDetails.color"
          />
          <p>{{ dates }}</p>
        </span>
      </slot>
    </component>
  </div>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import feather from "feather-icons";
export default {
  name: "ProgramDates",
  props: {
    id: {
      type: String,
      default: "anid",
    },
    dates: {
      type: String,
      default: "",
      required: true,
    },
    startIsFinal: {
      type: Boolean,
      default: false,
      required: true,
    },
    endIsFinal: {
      type: Boolean,
      default: false,
      required: true,
    },
    tag: {
      type: String,
      default: "div",
    },
  },
  computed: {
    divElement() {
      return document.getElementById(this.id);
    },
    showDates() {
      return this.dates;
    },
    overlayMessage() {
      let message = "";
      if (this.startIsFinal === true && this.endIsFinal === true) {
        message = "Program dates are confirmed.";
      } else if (this.startIsFinal === true && this.endIsFinal === false) {
        message =
          "Start date is final but end date will be finalized closer to the start of the program.";
      } else if (this.startIsFinal === false && this.endIsFinal === true) {
        message =
          "End date is final but start date will be finalized closer to the start of the program.";
      } else {
        message = "Dates will be finalized closer to the start of the program."; // Both false
      }
      return message;
    },
    iconDetails() {
      let confirmed = this.startIsFinal === true && this.endIsFinal === true;
      return {
        icon: confirmed ? "check-circle" : "alert-triangle",
        color: confirmed ? "text-success-900" : "text-warning-900",
      };
    },
    idTippyParent() {
      return "tippy-parent-" + this.id;
    },
    getTippyParent() {
      return document.getElementById(this.idTippyParent);
    },
  },
  mounted() {
    if (this.divElement) {
      this.initIcon();
    }
  },
  methods: {
    initIcon() {
      feather.replace();
      tippy(this.divElement, {
        content: this.overlayMessage,
        theme: "light",
        role: "tooltip",
        appendTo: this.getTippyParent,
        aria: {
          content: "describedby",
          expanded: "auto",
        },
      });
    },
  },
};
</script>

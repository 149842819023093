<template>
  <section :class="mainPanelClass">
    <header
      class="flex flex-row-wrap items-baseline md:items-start justify-between border-b border-gray-200"
      :class="[
        mainHeaderClass,
        !removeDefaultMargins ? 'mb-6' : '',
        !removeDefaultPaddings ? 'pb-2' : '',
        removeDefaultMargins && newMargin ? newMargin : '',
      ]"
    >
      <h2>
        <button
          :disabled="disabledPanel"
          :aria-controls="id"
          :aria-expanded="expanded"
          class="flex items-center text-lg md:text-xl"
          :class="{ 'cursor-not-allowed': disabledPanel }"
          @click="controlSection()"
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-down transition-transform transform"
            :class="{ '-rotate-90': !expanded }"
          >
            <polyline points="6 9 12 15 18 9" />
          </svg>
          <span
            class="ml-1 text-sm font-semibold tracking-widest text-left uppercase"
            :class="panelButtonTextClass"
            data-cy="titleForm"
          >
            {{ title }}
          </span>
        </button>
      </h2>
      <div class="md:hidden basis-full"></div>
      <div
        v-if="hasPanelHeaderCheckboxSlot"
        class="flex justify-center text-gray-600"
        :class="[
          !removeDefaultMargins ? 'mb-6' : '',
          { 'order-last md:order-none': hasPanelHeaderItemSlot },
        ]"
      >
        <slot name="panelHeaderCheckbox" />
      </div>
      <div class="basis-full hidden md:block"></div>
      <slot v-if="expanded" name="panelHeaderItem" />
    </header>
    <div
      :id="id"
      :class="panelContentClass"
      :style="{ display: expanded === true ? 'grid' : 'none' }"
    >
      <slot name="content" />
    </div>
  </section>
</template>
<script>
export default {
  name: "FormPanel",
  props: {
    title: {
      type: String,
      default: "",
    },
    initiallyExpanded: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "someid",
      required: true,
    },
    panelContentClass: {
      type: String,
      default: "pb-6 text-sm text-gray-600 border-gray-200",
      required: true,
    },
    mainPanelClass: {
      type: String,
      default: "",
    },
    panelButtonTextClass: {
      type: String,
      default: "",
    },
    mainHeaderClass: {
      type: String,
      default: "",
    },
    disabledPanel: {
      type: Boolean,
      default: false,
    },
    removeDefaultMargins: {
      type: Boolean,
      default: false,
    },
    removeDefaultPaddings: {
      type: Boolean,
      default: false,
    },
    newMargin: {
      type: String,
      default: "",
    },
  },
  emits: ["panelExpandedEvent"],
  compatConfig: {
    MODE: 3,
  },
  data() {
    return {
      expanded: null,
    };
  },
  computed: {
    hasCardImageSlot() {
      return typeof this.$slots.cardImage === "function";
    },
    hasPanelHeaderCheckboxSlot() {
      return typeof this.$slots.panelHeaderCheckbox === "function";
    },
    hasPanelHeaderItemSlot() {
      return typeof this.$slots.panelHeaderItem === "function";
    },
  },
  watch: {
    initiallyExpanded(newVal) {
      this.expanded = newVal;
    },
  },
  created() {
    this.expanded = this.initiallyExpanded;
  },
  methods: {
    controlSection() {
      this.$emit("panelExpandedEvent", !this.expanded);
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
.flex-row-wrap {
  flex-flow: row wrap;
}
.basis-full {
  flex-basis: 100%;
}
</style>

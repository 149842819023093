<template>
  <div role="region" aria-label="Enrollment Landing">
    <div
      v-if="applicationWithDrawn"
      class="w-full bg-warning-900 sm:h-20 md:h-10 p-4 flex items-center justify-center rounded mb-4"
    >
      <p class="text-white text-base ml-auto">
        Your application has been withdrawn from this program. Please contact
        your program manager with any questions.
      </p>
      <p
        data-cy="travel-banner"
        class="text-white text-base ml-auto mr-4 cursor-pointer"
      >
        X
      </p>
    </div>
    <template v-if="loading || !Boolean(formioToken)">
      <spinner>
        <template v-if="Boolean(formioToken)" #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
          <p>
            Something not working as expected?
          </p>
          <router-link
            :to="{
              name: 'applications',
            }"
            class="api-link underline hover:no-underline"
          >
            Click here to go back.
          </router-link>
        </template>
      </spinner>
    </template>
    <div
      v-else
      class="w-full px-4 pb-4 mx-auto applications max-w-7xl sm:px-8 sm:pb-8"
    >
      <header class="my-6 sm:my-10" role="banner" aria-labelledby="summary">
        <span id="summary" class="hidden">Program Summary</span>
        <div v-if="!bulkEnrollmentId" class="mb-4">
          <a
            :href="returnToApplication"
            class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
            <span class="ml-1">View Application</span>
          </a>
        </div>
        <div class="sm:flex justify-between items-center">
          <h1
            v-if="program.program"
            class="mb-2 text-xl font-semibold md:text-2xl"
          >
            {{ program.program.name }}
          </h1>
          <div class="sm:flex justify-between items-center">
            <p
              v-if="program.start_date && program.end_date"
              class="text-xs text-gray-500 tracking-widest font-semibold"
            >
              PROGRAM DATES
            </p>
            <program-dates
              :id="selectedProgramId"
              :dates="buildStringDateRange"
              :start-is-final="program.start_date_is_final > 0"
              :end-is-final="program.end_date_is_final > 0"
              tag="span"
              class="sm:ml-4 text-sm text-black font-extralight tracking-normal"
            />
          </div>
        </div>
        <TimelineBar
          :copy="copy"
          :days-until-start="daysUntilStart"
          :application-id="$route.params.applicationId"
          :add-payment="addPaymentStep"
        />
        <div class="flex justify-end">
          <p
            class="text-teal-500 font-semibold text-xs cursor-pointer underline mt-5px"
            @click="toggleModalProgramChange(displayProgramChangeModal)"
          >
            Program Change, Deferral or Withdraw
          </p>
        </div>
        <div v-if="showCancellationPolicy" class="text-sm mt-5px text-right">
          <a
            data-cy="cancellation-policy-link"
            class="text-teal-500 font-semibold text-xs cursor-pointer underline"
            :href="cancellationPolicyLink"
            target="_blank"
          >
            Cancellation policy
          </a>
        </div>
        <ModalComponent
          :open="displayProgramChangeModal"
          :title="'Program Change, Deferral or Withdraw'"
          @closeModal="toggleModalProgramChange(displayProgramChangeModal)"
        >
          <template #modal-content>
            <ProgramChangeEnrollment
              :manager-name="
                program.program_manager ? program.program_manager : ''
              "
              :manager-mail="
                program.program_manager_email
                  ? program.program_manager_email
                  : ''
              "
            />
          </template>
          <template #modal-footer>
            <button
              class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900"
              @click="toggleModalProgramChange(displayProgramChangeModal)"
            >
              <span>Close</span>
            </button>
          </template>
        </ModalComponent>
      </header>
      <div
        class="grid gap-6 xs:gap-10"
        :class="[displayToDo ? 'grid-cols-12' : 'grid-cols-1']"
      >
        <section :class="[displayToDo ? 'col-span-12 md:col-span-8' : '']">
          <OnboardingSection v-if="!applicationWithDrawn" />
          <section v-if="showPaymentSection">
            <PaymentSection
              :sort-payment-cards="sortPaymentCards"
              :display-to-do="displayToDo"
              :show-financial-aid="showFinancialAid"
            />
          </section>
          <PreDeparture
            v-if="showPreDepartureSection && !applicationWithDrawn"
            :program-details="programDetails"
          />
        </section>
        <section
          v-if="displayToDo"
          class="col-span-12 mt-4 md:col-span-4 order-first md:order-last"
        >
          <ToDoList :paid-with-promo-code="paidWithPromoCode" />
        </section>
      </div>
    </div>
    <ProfileModal v-if="!loading" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Spinner from "./helpers/Spinner";
import postFormRules from "../mixins/postFormRules";
import Modal from "./modals/Modal";
import ProgramChangeEnrollment from "./modals/EnrollmentProgramChange";
import ProgramDates from "./ProgramDates.vue";
import { daysUntilDate, formatDate } from "../mixins/helpers";
import OnboardingSection from "./Onboarding/OnboardingSection.vue";
import ToDoList from "./ToDoEnrollment.vue";
import { eventBus } from "../app";
import PreDeparture from "./onboardingStep2/PreDeparture.vue";
import TimelineBar from "./TimelineBar.vue";
import ProfileModal from "@/components/forms/ProfileReview/ProfileReviewModal.vue";
import PaymentSection from "@/components/paymentCards/PaymentsSection.vue";
import forms from "@/mixins/forms.js";

export default {
  name: "EnrollmentLanding",
  components: {
    Spinner: Spinner,
    ModalComponent: Modal,
    ProgramChangeEnrollment,
    ProgramDates,
    OnboardingSection,
    ToDoList,
    PreDeparture,
    ProfileModal,
    TimelineBar,
    PaymentSection,
  },
  mixins: [postFormRules, forms],
  data() {
    return {
      copy: "",
      loading: true,
      message: "Getting Student applications",
      applicationId: "",
      programDetails: {},
      daysUntilStart: 0,
      displayProgramChangeModal: false,
      addPaymentStep: false,
      preDepartureForms: [],
      preDepartureCompleted: true,
      paidWithPromoCode: false,
    };
  },
  computed: {
    ...mapGetters([
      "getStudentType",
      "canSkipStep2",
      "getCurrentStudentApplication",
    ]),
    ...mapState([
      "program",
      "selectedProgramId",
      "billingRules",
      "payments",
      "uiVersion",
      "completedSections",
      "formioToken",
      "studentApplications",
      "currentApplicationSubmissions",
    ]),

    applicationWithDrawn() {
      return this.getCurrentStudentApplication
        ? this.getCurrentStudentApplication.enrollmentStatus === "Withdrawn"
        : false;
    },
    showPaymentSection() {
      return this.sortPaymentCards.length > 0;
    },
    showFinancialAid() {
      return this.showPaymentSection && this.getStudentType === "college";
    },
    showPreDepartureSection() {
      //if no post acceptance rules hide the pre departure section
      if (Object.keys(this.program.post_acceptance_form_rules).length === 0) {
        return false;
      }

      if (
        this.showAgreementSection ||
        this.showHealthAndWellnessTile ||
        this.showHousingTile ||
        this.showNetworkOfSupportTile ||
        this.showGroupVisaTile ||
        this.showPassportTile ||
        this.showVisaAppointmentTile ||
        this.showVisaAndPermitTile ||
        this.showHeadshotTile ||
        this.showCourseSelectionCard
      ) {
        return true;
      }

      return false;
    },
    returnToApplication() {
      return (
        "/applications/" +
        this.applicationId +
        (this.uiVersion === "v2" ? "/next-steps" : "/application-received")
      );
    },
    sortPaymentCards() {
      let lesserArray = [];
      let greaterArray = [];
      this.payments.forEach((payment) => {
        if (payment.paid_by === "Participant") {
          if (payment.name === "Application Deposit") {
            const isPromoCode = Boolean(
              payment.status === "Open" && this.paidWithPromoCode
            );

            if (!isPromoCode) greaterArray.push(payment);
          } else {
            lesserArray.push(payment);
          }
        }
      });
      return greaterArray.concat(lesserArray);
    },
    showAgreementSection() {
      let hasFormRules = Object.hasOwnProperty.call(
        this.programDetails,
        "post_acceptance_form_rules"
      );
      let rules = hasFormRules
        ? this.programDetails.post_acceptance_form_rules
        : [];
      let hasAgreement =
        rules.includes("Virtual Participant Agreement") ||
        rules.includes("Study and Intern Participant Agreement") ||
        rules.includes("Intern Code of Conduct") ||
        rules.includes("Study Code of Conduct") ||
        rules.includes("Virtual Study Code of Conduct") ||
        rules.includes("Parsons Paris Use Agreement") ||
        rules.includes("Virtual Intern Code of Conduct") ||
        rules.includes("Polimoda Student Regulations Policy");
      return hasFormRules && hasAgreement;
    },
    currentApp() {
      return this.$store.getters.currentApplication(
        this.$route.params.applicationId
      );
    },
    buildStringDateRange() {
      let formattedFrom = formatDate(this.program.start_date);
      let formattedTo = formatDate(this.program.end_date);
      return formattedFrom + " - " + formattedTo;
    },
    preDepartureTitle() {
      return this.uiVersion === "v2" ? "Onboarding Step 2" : "Pre-departure";
    },
    isV2() {
      return this.uiVersion === "v2";
    },
    displayToDo() {
      return this.isV2;
    },
    cancellationPolicyType() {
      return this.program.cancellation_policy_type
        ? this.program.cancellation_policy_type
        : "";
    },
    cancellationPolicyLink() {
      let link =
        this.cancellationPolicyType === "Customized Student Cancellation Policy"
          ? this.program.link_to_custom_cancelation_policy
          : "https://apiabroad.com/withdrawal-policy-safeguards";

      if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
        return link;
      } else {
        return "https://" + link;
      }
    },
    showCancellationPolicy() {
      // hide if custom student policy but no link supplied
      if (
        this.cancellationPolicyType ===
          "Customized Student Cancellation Policy" &&
        !this.program.link_to_custom_cancelation_policy
      ) {
        return false;
      }
      if (
        this.cancellationPolicyType !==
        "Customized University Cancellation Policy"
      ) {
        return true;
      }
      return false;
    },
    bulkEnrollmentId() {
      return this.getCurrentStudentApplication?.bulkEnrollmentId ?? "";
    },
  },
  watch: {
    currentApp: {
      handler: function () {
        this.getEnrollmentCopy();
      },
      immediate: true,
      deep: true, // Added in case the small chance that this comes out as empty array
    },
    program: {
      handler: function () {
        this.setDaysUntilStart();
      },
      deep: true,
    },
    payments: {
      handler: function (val) {
        this.setPaymentProps(val);
      },
      immediate: true,
      deep: true,
    },
    studentApplications: {
      handler: function () {
        this.setPromoCode();
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {
    eventBus.$on("addPreDepartureForm", (val) => {
      this.addPreDepartureForm(val);
    });
    await this.$store.dispatch("getCurrentApplicationSubmissions");

    this.applicationId = this.$route.params.applicationId;
    await this.$store.dispatch("getEnrollmentData");
    await this.$store.dispatch("userSetup");

    await this.$store.dispatch("getPayments", this.$route.params.applicationId);

    await this.$store.dispatch("setProgramDataByApplicationId", {
      applicationId: this.applicationId,
      apolloClient: this.$apollo,
    });

    if (!this.studentApplications.length) {
      await this.$store.dispatch("getFormioSubmissions");
      await this.$store.dispatch("getStudentApplications");
    }

    await this.getProgramDetails();

    this.setDaysUntilStart();
    this.loading = false;
  },
  methods: {
    toggleModalProgramChange(value) {
      this.displayProgramChangeModal = !value;
    },
    async getProgramDetails() {
      if (this.selectedProgramId) {
        let url = process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT;
        url = this.trailingSlash(url);
        url += "session/" + this.selectedProgramId;
        let programData = await fetch(url);
        if (programData.ok) {
          let data = await programData.json();
          this.programDetails = data;
        }
      }
    },
    getEnrollmentCopy() {
      let status = "";
      if (
        Object.hasOwnProperty.call(this.currentApp, "enrollment_status") &&
        this.currentApp["enrollment_status"]
      ) {
        status = this.currentApp["enrollment_status"];
      }
      switch (status) {
        case "Ready for Review":
          this.copy =
            "Thank you for submitting your application! We are actively reviewing all the materials you submitted as part of your application. Please feel free to get a head start on the next set of information to process your enrollment. API will not begin reviewing these materials until after your acceptance.";
          break;
        case "Provisionally Accepted":
          this.copy =
            "Congratulations! You have been provisionally accepted into your program. Make sure to complete your confirmation payment to confirm your spot in the program.";
          break;
        case "Accepted":
          this.copy = "Congratulations on your admission. You are in!";
          break;
        case "Confirmed/Paid":
        case "Enrollment Forms Complete":
        case "Abroad":
        case "Completed/Post Program":
        case "Withdrawn":
          this.copy = "";
          break;
      }
    },
    setDaysUntilStart() {
      if (
        Object.hasOwnProperty.call(this.program, "start_date") &&
        this.program.start_date.length
      ) {
        this.daysUntilStart = daysUntilDate(this.program.start_date);
      }
    },
    setPaymentProps(payments) {
      let paymentsPendingBalance = false;
      let participantPayments = payments.filter(
        (payment) => payment.paid_by === "Participant"
      );
      this.addPaymentStep = participantPayments.length > 0;

      let confirmationPayment = participantPayments.find(
        (payment) => payment.name.toLowerCase() === "confirmation payment"
      );

      let pendingPayments = participantPayments.find(
        (payment) =>
          typeof payment.status !== "undefined" &&
          payment.status !== "Paid In Full"
      );

      if (confirmationPayment !== undefined) {
        paymentsPendingBalance =
          typeof confirmationPayment.balance !== "undefined" &&
          parseFloat(confirmationPayment.balance) !== 0;
      }

      this.$store.commit("setCompletedSections", {
        field: "payment",
        value: !paymentsPendingBalance,
      });

      this.$store.commit("setCompletedSections", {
        field: "otherPayments",
        value: pendingPayments === undefined,
      });
    },
    addPreDepartureForm(form) {
      this.preDepartureCompleted = this.preDepartureCompleted && form.submitted;
      this.preDepartureForms.push(form);
      this.setPreDepartureCompleted();
    },
    setPreDepartureCompleted() {
      let completed = this.preDepartureForms.filter((form) => form.submitted);

      this.$store.commit("setOnboarding2Forms", {
        forms: Object.assign([], this.preDepartureForms),
        totalForms: this.preDepartureForms.length,
        submittedForms: completed.length,
      });

      this.$store.commit("setCompletedSections", {
        field: "onboarding2",
        value: !!this.preDepartureCompleted,
      });
    },
    setPromoCode() {
      if (this.getCurrentStudentApplication) {
        let paymentSubmission = this.getCurrentStudentApplication.submissions.find(
          (submission) => {
            return this.paymentIds.includes(submission.form);
          }
        );
        this.paidWithPromoCode = !!paymentSubmission?.data.promotionCode;
      }
    },
  },
};
</script>

<style></style>

<template>
  <div v-if="showPanel" class="bg-white sm:mt-4 sm:rounded-lg sm:shadow-md">
    <div
      v-if="uiVersion === 'v2'"
      class="flex py-10 px-6 items-center justify-between mb-4 md:mb-6 w-100"
    >
      <div class="relative z-10 flex-none step-current left-0.4r">
        <div
          class="flex items-center justify-center w-4 h-4 bg-white border-2 border-blue-700 rounded-full w-6 h-6"
        >
          <div class="w-2 h-2 bg-blue-700 rounded-full w-3 h-3"></div>
        </div>
        <span
          :class="inReview ? 'text-gray-600 block' : 'hidden md:block'"
          class="text-xs mt-2 leading-tight font-semibold whitespace-nowrap w-auto md:w-30 lg:w-48 absolute top-full truncate text-center -right-1r transform translate-x-1/2"
        >
          Application in Review
        </span>
      </div>
      <div class="relative z-0 h-2 w-11/12 md:w-4/5 flex rounded bg-blue-200">
        <span
          v-for="(step, index) in filterSteps"
          :key="step.label + step.suffix"
          class="absolute"
          :class="
            step.label + step.suffix === currentStep
              ? 'block'
              : 'hidden md:block'
          "
          :style="{ width: getPercentage(index + 1) + '%' }"
        >
          <span
            class="absolute text-xs mt-6 leading-tight font-semibold whitespace-nowrap -right-3r w-30 truncate text-center"
          >
            {{ step.label + step.suffix }}
          </span>
        </span>
        <div
          :style="{ width: percentage + '%' }"
          :class="timelineColor"
          class="relative shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded"
        >
          <div
            v-if="percentage > 0"
            class="absolute -top-1.5r -right-0.5r"
            :class="percentage === 100 ? '-right-0.005r' : '-right-0.5r'"
          >
            <img
              :src="'/images/' + iconPerson + '.svg'"
              alt=""
              class="m-auto"
            />
          </div>
        </div>
      </div>
      <div class="relative right-0.4r">
        <div :class="iconClasses" class="absolute">
          <img :src="'/images/' + icon + '.svg'" class="m-auto" alt="" />
        </div>
        <DateCountdown
          :application-deadline="program.start_date"
          :is-large-icon="false"
          :hide-date-label="true"
          :time-line-bar="false"
        />
      </div>
    </div>
    <div
      v-else
      class="grid grid-cols-4 flex items-start py-4 border-b border-gray-200 md:items-center text-gray-650 sm:px-4 sm:py-2"
    >
      <div class="col-span-3">
        <p class="text-gray-600">
          {{ copy }}
        </p>
      </div>
      <DateCountdown
        v-if="daysUntilStart > 0"
        :application-deadline="program.start_date"
        :is-large-icon="false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DateCountdown from "./DateCountdown";

export default {
  name: "TimelineBar",
  components: {
    DateCountdown,
  },
  props: {
    copy: {
      type: String,
      default: "",
    },
    daysUntilStart: {
      type: Number,
      default: 0,
    },
    applicationId: {
      type: String,
      default: "",
    },
    addPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      steps: [
        {
          label: "Accepted",
          suffix: "",
          show: false,
          completed: false,
        },
        {
          label: "Onboarding",
          suffix: " Step 1",
          show: false,
          completed: false,
        },
        {
          label: "Payments",
          suffix: "",
          show: false,
          completed: false,
        },
        {
          label: "Onboarding",
          suffix: " Step 2",
          show: false,
          completed: false,
        },
      ],
      currentStep: "",
    };
  },
  computed: {
    ...mapState([
      "program",
      "studentApplications",
      "uiVersion",
      "completedSections",
      "payments",
    ]),
    inReview() {
      return [
        "Application Started",
        "Ready for Review",
        "Not Enrolled",
        "",
      ].includes(this.status);
    },
    currentApp() {
      return this.$store.getters.currentApplication(this.applicationId);
    },
    status() {
      let status = "";
      if (
        Object.hasOwnProperty.call(this.currentApp, "enrollment_status") &&
        this.currentApp.enrollment_status
      ) {
        status = this.currentApp.enrollment_status;
      }

      return status;
    },
    showPanel() {
      return this.uiVersion !== "v2"
        ? this.daysUntilStart > 0 || this.copy.length > 0
        : true;
    },
    percentage() {
      let percentage = 0;

      if (this.status === "Accepted" || !this.inReview) {
        const totalSteps = this.filterSteps.length + 1;
        const currentStep =
          this.filterSteps.findIndex(
            (step) => step.label + step.suffix === this.currentStep
          ) + 1;

        return this.formsCompleted
          ? 100
          : parseInt((currentStep / totalSteps) * 100);
      }
      return percentage;
    },
    formsCompleted() {
      const notCompleted = this.filterSteps.some((step) => !step.completed);
      return !notCompleted;
    },
    timelineColor() {
      return this.program.deadline < 0 && !this.formsCompleted
        ? "bg-help-warning"
        : "bg-help-success";
    },
    isVirtual() {
      return this.program.site.country.toLowerCase() === "virtual";
    },
    icon() {
      return this.isVirtual ? "icon_abroad" : "icon_airplane";
    },
    iconPerson() {
      return this.isVirtual ? "icon_person_virtual" : "icon_person_abroad";
    },
    iconClasses() {
      let classes = "";

      if (this.percentage >= 75 && this.percentage < 90) {
        classes = this.isVirtual
          ? "-left-0.5r md:-left-1.5r -top-1.5r md:top-0"
          : "-left-1r md:-left-3r -top-1.5r md:top-0";
      } else if (this.percentage >= 90) {
        classes = this.isVirtual
          ? "-left-0.5r -top-1.5r"
          : "-left-1r -top-1.5r";
      } else {
        classes = this.isVirtual ? "-left-1.5r" : "-left-3r";
      }

      return classes;
    },
    filterSteps() {
      return this.steps.filter((step) => step.show);
    },
  },
  watch: {
    addPayment: {
      handler: function (val) {
        this.setSteps(val);
      },
    },
    completedSections: {
      handler: function (val) {
        this.setCurrentStep(val);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.setSteps(this.addPayment);
  },
  methods: {
    setSteps(addPayment) {
      const addOnboarding1 = this.program.form_rules.length > 0;
      const addOnboarding2 = this.program.post_acceptance_form_rules.length > 0;
      const onboardingPrefix = addOnboarding1 && addOnboarding2;

      // Always add Accepted
      this.steps[0].show = true;

      if (addOnboarding1) {
        this.steps[1].show = true;
        this.steps[1].suffix = onboardingPrefix ? " Step 1" : "";
      }

      if (addPayment) this.steps[2].show = true;

      if (addOnboarding2) {
        this.steps[3].show = true;
        this.steps[3].suffix = onboardingPrefix ? " Step 2" : "";
      }

      this.setCurrentStep(this.completedSections);
    },
    getPercentage(step) {
      return parseInt((step / (this.filterSteps.length + 1)) * 100);
    },
    setCurrentStep(completedSections) {
      let currentStep = "";
      let completedAccepted = this.status === "Accepted" || !this.inReview;

      this.steps[0].completed = completedAccepted;
      this.steps[1].completed = completedSections.onboarding1;
      this.steps[2].completed = completedSections.payment;
      this.steps[3].completed = completedSections.onboarding2;

      if (completedAccepted) {
        let firtsNotCompleted = this.filterSteps.find(
          (step) => !step.completed
        );

        if (firtsNotCompleted)
          currentStep = firtsNotCompleted.label + firtsNotCompleted.suffix;
      }
      this.currentStep = currentStep;
    },
  },
};
</script>

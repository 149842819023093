<template>
  <fieldset>
    <legend>
      Do you have any mental health conditions, past or present, that may
      require medical treatment while you are abroad? We can ensure that the
      type of care you need is available in the location you are traveling to,
      inform you about available health care providers in advance of your
      departure, and assist you with logistics.
    </legend>
    <RadioButton
      name="mental-health"
      aria-controls="mental-health"
      :options="['yes', 'no']"
      :selected-option="selectedOption"
      :validation-errors="v$.value.selectedRadio"
      @input="onFieldChange('selectedRadio', $event)"
    />

    <div
      v-if="value.selectedRadio === 'yes'"
      id="mental-health"
      :aria-expanded="value.selectedRadio === 'yes'"
    >
      <div class="grid grid-cols-1 gap-y-8 ml-6">
        <ApiTextArea
          name="mental-health-textarea"
          label="If yes, please list the illness here."
          :max-length="500"
          :typed-text="typedText"
          :validation-errors="v$.value.textarea"
          @textUpdate="onFieldChange('textarea', $event)"
        />
      </div>
    </div>
  </fieldset>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "MentalHealth",
  components: { RadioButton, ApiTextArea },
  mixins: [formInputs],
  props: {
    mental: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:mental-health"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:mental-health", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      selectedRadio: {
        required,
      },
      textarea: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "yes";
        }),
        maxLength: maxLength(500),
      },
    },
  },
};
</script>

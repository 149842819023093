<template>
  <div
    :id="code"
    class="w-full px-4 mx-auto max-w-7xl flex flex-col items-center justify-center text-center h-100 sm:px-8 md:px-8 md:mb-8"
  >
    <img class="-mb-4" src="../../images/no-records.svg" alt="" />
    <h3 class="mb-sm text-lg font-semibold md:text-xl">
      {{ errorMessage }}
    </h3>
    <p class="max-w-md text-2sm mb-sm text-gray-600">
      Error code: {{ errorCode }}
    </p>
    <p v-if="showLinks" class="max-w-md text-2sm text-gray-600">
      Here are some helpful links instead:
    </p>
    <router-link
      v-for="(link, index) in redirectLinks"
      :key="index"
      class="student-link api-link"
      :to="link.path"
      >{{ link.label }}</router-link
    >
    <img class="-mb-4" src="../../images/no-records.svg" alt="" />
    <h3 class="mb-sm text-lg font-semibold md:text-xl">
      {{ errorMessage }}
    </h3>
    <p class="max-w-md text-2sm mb-sm text-gray-600">
      Error code: {{ errorCode }}
    </p>
    <p v-if="showLinks" class="max-w-md text-2sm text-gray-600">
      Here are some helpful links instead:
    </p>
    <router-link
      v-for="(link, index) in redirectLinks"
      :key="index"
      class="student-link api-link"
      :to="link.path"
      >{{ link.label }}</router-link
    >
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  props: {
    message: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "404",
    },
    links: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    showLinks() {
      return !_.isEmpty(this.redirectLinks);
    },
    redirectLinks() {
      return !_.isEmpty(this.links)
        ? this.links
        : [
            { path: "/applications", label: "Home" },
            { path: "applications/new", label: "Select a program" },
          ];
    },
    errorMessage() {
      return this.message
        ? this.message
        : "Oops! We can't seem to find the page you're looking for.";
    },
    errorCode() {
      return this.code ? this.code : "404";
    },
  },
  created() {
    this.$store.commit("clearProgram");
  },
};
</script>

<style scoped>
.mb-sm {
  margin-bottom: 10px;
}
</style>

<template>
  <div class="grid mb-2 sm:gap-4 sm:grid-cols-3">
    <div class="flex flex-col justify-center">
      <label class="grid xs:grid-cols-2 sm:grid-cols-1">
        <span
          class="flex items-center sm:sr-only bg-teal-100 text-white font-semibold whitespace-nowrap capitalize xs:border-b-2 px-2"
        >
          <span>{{ "award type / name" }}</span>
        </span>
        <input
          :id="'award-type-' + row"
          v-model="value.award"
          type="text"
          class="form-input max-h-8 w-full mb-2"
          :class="{
            'bg-error-100':
              validationErrors['award'] && validationErrors['award'].$error,
          }"
        />
      </label>
      <div
        v-for="(validationValue, rule) in sortNestedObjectsValidationRules[
          'award'
        ]"
        :key="rule"
      >
        <p
          v-if="!validationErrors['award'][validationValue]"
          class="error text-error-900"
          :class="{ hidden: !validationErrors['award'].$error }"
        >
          {{ showMessage("award", validationValue) }}
        </p>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <label class="grid xs:grid-cols-2 sm:grid-cols-1">
        <span
          class="flex items-center sm:sr-only bg-teal-100 text-white font-semibold whitespace-nowrap capitalize xs:border-b-2 px-2"
        >
          <span>{{ "disbursement date" }}</span>
        </span>
        <input
          :id="'disbursement-date-' + row"
          v-model="date"
          type="date"
          :name="'disbursement-date-' + row"
          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
          placeholder="mm-dd-yyyy"
          class="form-input max-h-8 w-full mb-2"
          :class="{
            'bg-error-100':
              validationErrors['date'] && validationErrors['date'].$error,
          }"
        />
      </label>
      <div
        v-for="(validationValue, rule) in sortNestedObjectsValidationRules[
          'date'
        ]"
        :key="rule"
      >
        <p
          v-if="!validationErrors['date'][validationValue]"
          class="error text-error-900"
          :class="{ hidden: !validationErrors.$error }"
        >
          {{ showMessage("date", validationValue) }}
        </p>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <label class="grid xs:grid-cols-2 sm:grid-cols-1">
        <span
          class="flex items-center sm:sr-only bg-teal-100 text-white font-semibold whitespace-nowrap capitalize xs:border-b-2 px-2"
        >
          <span>{{ "award amount" }}</span>
        </span>
        <input
          :id="'award-amount-' + row"
          v-model="value.amount"
          type="text"
          class="form-input max-h-8 w-full mb-2"
          :class="{
            'bg-error-100':
              validationErrors['amount'] && validationErrors['amount'].$error,
          }"
        />
      </label>
      <div
        v-for="(validationValue, rule) in sortNestedObjectsValidationRules[
          'amount'
        ]"
        :key="rule"
      >
        <p
          v-if="
            !validationErrors['amount'][validationValue] &&
            validationValue !== 'mustNotHaveHyphen'
          "
          class="error text-error-900"
          :class="{ hidden: !validationErrors.$error }"
        >
          {{ showMessage("amount", validationValue) }}
        </p>
        <p
          v-else-if="
            validationValue === 'mustNotHaveHyphen' &&
            !validationErrors['amount']['mustNotHaveHyphen'] &&
            validationErrors['amount']['decimal']
          "
          class="error text-error-900"
          :class="{ hidden: !validationErrors.$error }"
        >
          {{ validationMessage["mustNotHaveHyphen"] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import validationMessages from "../../mixins/validationMessages";
import { format, isValid, parse } from "date-fns";
import { isDateSupported } from "@/mixins/helpers";

export default {
  name: "AwardItem",
  mixins: [validationMessages],
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    row: {
      type: Number,
      default: 0,
    },
    submittedData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:single-award"],
  data() {
    return {
      value: {
        award: "",
        amount: "",
        date: "",
      },
      date: "",
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:single-award", this.value);
      },
      deep: true,
      immediate: true,
    },
    date: {
      handler: function (newVal) {
        if (isDateSupported()) {
          this.value.date = newVal;
        } else {
          if (isValid(parse(newVal, "MM-dd-yyyy", new Date()))) {
            this.value.date = format(
              parse(newVal, "MM-dd-yyyy", new Date()),
              "yyyy-MM-dd"
            );
          } else {
            this.value.date = "";
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.submittedData.award) {
      this.value.award = this.submittedData.award.trim();
    }
    if (this.submittedData.amount) {
      this.value.amount = this.submittedData.amount;
    }
    if (this.submittedData.date) {
      let formatDate = isDateSupported() ? "yyyy-MM-dd" : "MM-dd-yyyy";
      this.date = format(
        parse(this.submittedData.date, "MM/dd/yyyy", new Date()),
        formatDate
      );
    }
  },
};
</script>

<template>
  <div class="w-full px-4 pb-4 mx-auto max-w-7xl sm:px-8 sm:pb-8">
    <!-- Back -->
    <div class="my-4">
      <router-link
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        :to="{
          name: 'applications/landing',
          params: { applicationId: $route.params.applicationId },
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <!-- Content -->
    <div
      class="px-4 py-6 -mx-4 bg-white border-t border-gray-200 sm:-mx-8 md:mx-auto sm:px-8 md:p-12 md:border md:rounded"
    >
      <h1 id="participant" class="mb-2 text-xl md:font-semibold md:text-2xl">
        Participant Agreement
      </h1>
      <div
        id="formiodiv"
        role="form"
        aria-labelledby="participant"
        class="relative"
      />
    </div>
    <!-- Loader -->
    <spinner v-if="loading">
      <template #spinnercontent>
        <p class="text-gray-600">
          {{ loadingMessage }}
        </p>
        <p>
          Something not working as expected?
        </p>
        <router-link
          :to="{
            name: 'applications',
            params: { applicationId: $route.params.applicationId },
          }"
          class="api-link underline hover:no-underline"
        >
          Click here to go back.
        </router-link>
      </template>
    </spinner>
  </div>
</template>

<script>
import { Formio } from "formiojs";
import tailwind from "@apiabroad/formio-tailwind-template";
import Spinner from "../helpers/Spinner";
import formIoApi from "../../mixins/formIoApi.js";
import impersonationFormSniffer from "../../mixins/ImpersonationFormSniffer";
import formService from "../../services/form";

export default {
  name: "ParticipantAgreement",
  components: { Spinner },
  mixins: [formIoApi, impersonationFormSniffer],
  data() {
    return {
      loading: true,
      loadingMessage: "Checking which agreement applies for you...",
      programType: "",
      programEndpoint: process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT,
      formioEndpoint: "/api/forms",
      submissionId: "",
    };
  },
  computed: {
    currentApp() {
      return this.$store.getters.currentApplication(
        this.$route.params.applicationId
      );
    },
  },
  watch: {
    currentApp: {
      handler: function () {
        this.getProgramType();
      },
      deep: true, // Added in case the small chance that this comes out as empty array
    },
  },
  mounted() {
    if (
      Object.hasOwnProperty.call(
        this.currentApp,
        "salesforce_program_session_id"
      )
    ) {
      this.getProgramType();
    } else {
      this.loadingMessage = "Getting application details.";
      this.$store.dispatch("getEnrollmentData");
    }
  },
  methods: {
    applicationBelongsToStudent(list, appId) {
      let belongs = false;
      let found = list.find(
        (application) =>
          Object.hasOwnProperty.call(application, "application_id") &&
          application.application_id === appId
      );
      belongs = found !== undefined;
      return belongs;
    },
    async getProgramType() {
      let appBelongsToStudent = this.applicationBelongsToStudent(
        this.$store.state.enrollmentApplications,
        this.$route.params.applicationId
      );
      if (!appBelongsToStudent) {
        this.loadingMessage =
          "Error, the application ID does not match any of your applied programs";
        return;
      }
      this.loadingMessage =
        "Checking which participant agreement applies based in your program type.";
      let url = this.trailingSlash(this.programEndpoint);
      url += "session/" + this.currentApp["salesforce_program_session_id"];
      let programData = await fetch(url);
      if (programData.ok) {
        let data = await programData.json();
        if (Object.hasOwnProperty.call(data, "post_acceptance_form_rules")) {
          if (
            data["post_acceptance_form_rules"].includes(
              "Virtual Participant Agreement"
            )
          ) {
            this.programType = "Virtual";
            this.getSubmission();
          } else if (
            data["post_acceptance_form_rules"].includes(
              "Study and Intern Participant Agreement"
            )
          ) {
            this.programType = "Study or Intern";
            this.getSubmission();
          } else {
            this.loadingMessage =
              "Your program does not qualify to submit this agreement, redirecting you back in a few seconds";
            setTimeout(() => {
              this.$router.push({
                name: "applications/landing",
                params: { applicatioonId: this.$route.params.applicationId },
              });
            }, 3000);
          }
        }
      } else {
        this.loadingMessage =
          "An error ocurred: We could not determine the application type, please try again later.";
      }
    },
    async getSubmission() {
      let formPrefix =
        this.programType === "Virtual"
          ? "participantAgreementVirtual"
          : "participantAgreementStudyIntern";

      this.formioSubmissionExists(
        formPrefix,
        this.$route.params.applicationId
      ).then((submissionId) => {
        this.submissionId = submissionId;
        this.loadForm();
      });
    },
    loadForm() {
      let url = this.trailingSlash(this.formioEndpoint);
      let formPrefix =
        this.programType === "Virtual"
          ? "participantAgreementVirtual"
          : "participantAgreementStudyIntern";
      url += formPrefix;
      if (this.submissionId) {
        url += "/submission/" + this.submissionId;
      }
      // Formio
      Formio.setBaseUrl(`${window.location.protocol}//${window.location.host}`);
      Formio.setAuthUrl(
        `${window.location.protocol}//${window.location.host}/api/forms`
      );
      Formio.use(tailwind);
      Formio.createForm(document.getElementById("formiodiv"), url, {
        hooks: {
          beforeSubmit: (submission, next) => {
            submission = this.attachEmployeeToSubmission(submission, "post");
            next();
          },
        },
      }).then((form) => {
        // suppress Form.io SK default submit behavior so that we can submit
        // to our own backend with all necessary CSRF protections
        form.nosubmit = true;
        form.on("submit", (submission) => {
          formService
            .createOrUpdateSubmission(formPrefix, submission, this.submissionId)
            .then((submission) => {
              form.emit("submitDone", submission);
            });
        });
        form.data.application_id = this.$route.params.applicationId;
        form.on("submitDone", (submission) => {
          this.logSubmission(formPrefix, {}, submission.data);
          this.$router.push({
            name: "applications/landing",
            params: {
              applicationId: this.$route.params.applicationId,
            },
          });
        });
      });
      this.loading = false;
      this.loadingMessage = "Done";
    },
  },
};
</script>

<style>
.animate-spinner {
  -webkit-animation: spinner 1.1s linear infinite;
  animation: spinner 1.1s linear infinite;
}

@-webkit-keyframes spinner {
  10% {
    opacity: 100%;
  }

  100% {
    opacity: 10%;
  }
}

@keyframes spinner {
  10% {
    opacity: 100%;
  }

  100% {
    opacity: 10%;
  }
}
</style>

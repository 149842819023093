<template>
  <div>
    <header class="flex items-start justify-between mb-5">
      <div class="sm:flex-wrap sm:flex sm:items-center">
        <h3 class="text-xl font-semibold text-gray-700 sm:mr-4">
          Add my support
        </h3>
      </div>
    </header>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6">
      <label for="firstName" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>First Name</span>
        </span>
        <input
          id="firstName"
          v-model.trim="v$.value.firstName.$model"
          data-cy="first-name-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.firstName.$error }"
        />
        <div
          v-if="
            v$.value.firstName.required.$invalid && v$.value.firstName.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.firstName.nameRegex.$invalid"
          class="error text-error-900"
        >
          Field does not match the pattern
        </div>
      </label>

      <label for="lastName" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Last Name</span>
        </span>
        <input
          id="lastName"
          v-model.trim="v$.value.lastName.$model"
          data-cy="last-name-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.lastName.$error }"
        />
        <div
          v-if="v$.value.lastName.required.$invalid && v$.value.lastName.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.lastName.nameRegex.$invalid"
          class="error text-error-900"
        >
          Field does not match the pattern
        </div>
      </label>

      <label for="relationship" class="block text-sm md:col-span-2">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Relationship</span>
        </span>
        <VSelect
          id="relationship"
          v-model="v$.value.relationship.$model"
          :value="v$.value.relationship.$model"
          :reduce="(relationship) => relationship.value"
          :options="RELATIONSHIP_OPTIONS"
          :get-option-label="(option) => option.label"
          :class="{ 'bg-error-100': v$.value.relationship.$error }"
          data-cy="relationship-dropdown"
          aria-label="Relationship"
        />
        <div
          v-if="
            v$.value.relationship.required.$invalid &&
            v$.value.relationship.$error
          "
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </label>

      <div class="o-10 space-y-6 md:col-span-2">
        <label for="legalGuardian" class="flex">
          <input
            id="legalGuardian"
            v-model="v$.value.legalGuardian.$model"
            data-cy="legal-guardian-checkbox"
            type="checkbox"
            name="legalGuardian"
            class="mt-1 text-blue-700 form-checkbox"
          />

          <div class="flex items-start ml-8 -mt-2">
            <img src="/images/ico-legal-guardian.svg" alt="" />
            <p class="ml-4 text-gray-600">
              Legal Guardian: For participants under the age of 18, API will
              include the legal guardian on all communications. This person will
              also be designated an emergency contact and billing contact.
            </p>
          </div>
        </label>
        <label for="emergencyContact" class="flex">
          <input
            id="emergencyContact"
            v-model="v$.value.emergencyContact.$model"
            data-cy="emergency-contact-checkbox"
            type="checkbox"
            name="emergencyContact"
            class="mt-1 text-blue-700 form-checkbox"
          />

          <div class="flex items-start ml-8 mt-1">
            <img src="/images/ico-emergency-contact.svg" alt="" />
            <p class="ml-4 text-gray-600">
              Emergency Contact: This is the person we will contact in the event
              of an emergency. You may have up to 3 emergency contacts.
            </p>
          </div>
        </label>
        <label for="billingContact" class="flex">
          <input
            id="billingContact"
            v-model="v$.value.billingContact.$model"
            data-cy="billing-contact-checkbox"
            type="checkbox"
            name="billingContact"
            class="mt-1 text-blue-700 form-checkbox"
          />

          <div class="flex items-start ml-8 mt-1">
            <img src="/images/ico-billing-contact.svg" alt="" />
            <p class="ml-4 text-gray-600">
              Billing Contact: All participants have the option to designate a
              contact as a billing contact so that they may view statements and
              remit payment on behalf of the participant
            </p>
          </div>
        </label>
      </div>

      <label for="email" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Email</span>
        </span>
        <input
          id="email"
          v-model.trim="v$.value.email.$model"
          type="text"
          data-cy="email-text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.email.$error }"
        />
        <div
          v-if="v$.value.email.required.$invalid && v$.value.email.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="
            v$.value.email.email.$invalid ||
            v$.value.email.validEmailAddress.$invalid
          "
          class="error text-error-900"
        >
          Invalid email address. Please try another email address.
        </div>
      </label>

      <label for="phone" class="block text-sm">
        <span class="inline-flex justify-between mb-2 text-gray-600">
          <span class="font-semibold mr-1">Phone</span>
          <Tooltip
            tooltip="The best phone number to contact you at. Your mobile number is usually best."
          />
        </span>
        <MazPhoneNumberInput
          id="phone"
          :model-value="value.phone"
          data-cy="phone-text"
          type="tel"
          :fetch-country="false"
          :default-country-code="v$.value.phoneCode.$model ? null : 'US'"
          :no-use-browser-locale="v$.value.phoneCode.$model ? true : false"
          :color="'info'"
          :clearable="true"
          :preferred-countries="['US']"
          :no-example="true"
          :no-search="true"
          :required="true"
          :error="v$.value.phone.$error"
          :class="{
            'bg-error-100': v$.value.phone.$error,
            'scoped-class': true,
          }"
          :size="'sm'"
          @update="updatePhone($event)"
        />
        <div
          v-if="v$.value.phone.required.$invalid && v$.value.phone.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="
            v$.value.phone.validPhoneNumber.$invalid && v$.value.phone.$error
          "
          class="error text-error-900"
        >
          Invalid phone number. Please try another phone number.
        </div>
      </label>

      <label for="address" class="block text-sm md:col-span-2">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Address</span>
        </span>
        <input
          id="address"
          v-model.trim="v$.value.address.$model"
          data-cy="address-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.address.$error }"
        />
        <div
          v-if="v$.value.address.required.$invalid && v$.value.address.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label for="city" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>City</span>
        </span>
        <input
          id="city"
          v-model.trim="v$.value.city.$model"
          data-cy="city-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.city.$error }"
        />
        <div
          v-if="v$.value.city.required.$invalid && v$.value.city.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label for="state" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>State</span>
        </span>
        <input
          id="state"
          v-model.trim="v$.value.state.$model"
          data-cy="state-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.state.$error }"
        />
        <div
          v-if="v$.value.state.required.$invalid && v$.value.state.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label for="country" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Country</span>
        </span>
        <VSelect
          id="country"
          v-model="v$.value.country.$model"
          data-cy="country-text"
          aria-label="country"
          :value="value.country"
          :reduce="(country) => country.value"
          :options="countries"
          :class="{ 'bg-error-100': v$.value.country.$error }"
          @search="fetchCountries"
        />
        <div
          v-if="v$.value.country.required.$invalid && v$.value.country.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label for="postalCode" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Postal Code</span>
        </span>
        <input
          id="postalCode"
          v-model="v$.value.postalCode.$model"
          data-cy="postal-code-text"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.postalCode.$error }"
        />
        <div
          v-if="v$.value.postalCode.required && v$.value.postalCode.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <div class="my-5 md:col-span-2">
        <ButtonWithSpinner
          ref="mySupportSubmit"
          data-cy="my-support-submitt-button"
          type="submit"
          variant="secondary"
          variant-type="normal"
          custom-class="text-sm"
          button-height="min-h-8"
          @click="submitForm"
        >
          <span>
            Save
          </span>
        </ButtonWithSpinner>
        <ButtonWithSpinner
          ref="mySupportCancel"
          data-cy="my-support-cancel-button"
          type="submit"
          variant="secondary-outlined"
          variant-type="normal"
          custom-class="text-sm"
          button-height="min-h-8"
          @click="cancel"
        >
          <span>
            Cancel
          </span>
        </ButtonWithSpinner>
        <div v-if="value.submitError" class="error text-error-900 mt-2">
          {{ value.submitError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import Tooltip from "@/components/forms/SharedComponents/Tooltip.vue";
import countryListApi from "@/mixins/countryListApi";
import {
  nameRegex,
  validEmailAddress,
  validPhoneNumber,
} from "@/mixins/customValidators";
import { email, required } from "@vuelidate/validators";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import "maz-ui/css/main.css";
import useVuelidate from "@vuelidate/core";

const relationshipOptions = [
  {
    label: "Aunt",
    value: "aunt",
  },
  {
    label: "Family Friend",
    value: "familyFriend",
  },
  {
    label: "Grandparent",
    value: "grandparent",
  },
  {
    label: "Legal Guardian",
    value: "legalGuardian",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Parent",
    value: "parent",
  },
  {
    label: "Uncle",
    value: "uncle",
  },
];

export default {
  name: "EditView",
  components: {
    ButtonWithSpinner,
    Tooltip,
    MazPhoneNumberInput,
  },
  mixins: [countryListApi],
  props: {
    itemData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["save", "cancel"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        firstName: "",
        lastName: "",
        relationship: "",
        legalGuardian: false,
        emergencyContact: false,
        billingContact: false,
        email: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        phone: "",
        phoneCode: "",
        isValidPhoneNumber: true,
        editView: false,
        id: "",
        submitError: "",
      },
    };
  },
  validations: {
    value: {
      firstName: { required, nameRegex },
      lastName: { required, nameRegex },
      relationship: { required },
      legalGuardian: {},
      emergencyContact: {},
      billingContact: {},
      email: {
        required,
        email,
        validEmailAddress,
      },
      address: { required },
      city: { required },
      state: { required },
      country: { required },
      postalCode: { required },
      phone: { required, validPhoneNumber },
      phoneCode: {},
      isValidPhoneNumber: {},
      editView: {},
      id: {},
      submitError: {},
    },
  },
  watch: {
    itemData: {
      handler: function (newVal) {
        this.setData(newVal);
      },
      immediate: true,
      deep: true,
    },
    "value.submitError": {
      handler: function (newval) {
        if (newval) this.$refs.mySupportSubmit.stopLoading();
      },
      immediate: true,
    },
  },
  created() {
    this.setConstants();
  },
  methods: {
    setConstants() {
      this.RELATIONSHIP_OPTIONS = relationshipOptions;
    },
    setData(item) {
      Object.entries(item).forEach(([key, val]) => {
        if (key === "phone") {
          const phoneFormat = item.phone && item.phoneCode ? "+" : "";
          this.value.phone = phoneFormat + item.phoneCode + item.phone;
        } else {
          this.value[key] = val;
        }
      });
    },
    updatePhone(data) {
      this.value.isValidPhoneNumber = data.isValid;
      this.value.phone = data.nationalNumber;
      this.value.phoneCode = data.countryCallingCode ?? "";
    },
    async submitForm() {
      this.$refs.mySupportSubmit.startLoading();
      this.value.submitError = "";
      this.v$.$touch();

      if (!this.v$.$invalid) {
        // Avoids watcher->itemData from changing this.value later
        this.$emit("save", JSON.parse(JSON.stringify(this.value)));
      } else {
        this.$refs.mySupportSubmit.stopLoading();
      }
    },
    cancel() {
      this.$emit("cancel", this.value.id);
    },
  },
};
</script>

<style scoped>
.scoped-class :deep(.m-phone-number-input__select) {
  width: 110px !important;
}
</style>

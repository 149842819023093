const UMASS_OPE8 = ["00222100", "00221000", "00216100"];

let UMASS_PRICING = [];
if (process.env.MIX_APP_ENV === "production") {
  UMASS_PRICING = [
    {
      price: 26900,
      session: "a056e00001JjiLZAAZ",
      housing_fee: 6418,
      educational_fees: 19320,
    },
    {
      price: 20900,
      session: "a056e00001JjiOEAAZ",
      housing_fee: 5013,
      educational_fees: 14913,
    },
    {
      price: 25900,
      session: "a056e00001JjiKRAAZ",
      housing_fee: 18921,
      educational_fees: 5875,
    },
    {
      price: 21500,
      session: "a056e00001JjiJlAAJ",
      housing_fee: 11591,
      educational_fees: 8935,
    },
    {
      price: 21500,
      session: "a056e00001JjiJdAAJ",
      housing_fee: 11591,
      educational_fees: 8935,
    },
    {
      price: 24500,
      session: "a056e00001JjiL8AAJ",
      housing_fee: 4448,
      educational_fees: 19063,
    },
    {
      price: 24500,
      housing_fee: 9623,
      educational_fees: 13866,
      session: "a056e00001JjiOcAAJ",
    },
    {
      price: 24500,
      session: "a056e00001JjiMFAAZ",
      housing_fee: 9185,
      educational_fees: 14341,
    },
    {
      price: 25500,
      session: "a056e00001JjiLeAAJ",
      housing_fee: 14696,
      educational_fees: 9848,
    },
    {
      price: 25500,
      session: "a056e00001JjiJeAAJ",
      housing_fee: 14696,
      educational_fees: 9848,
    },
    {
      price: 24500,
      session: "a056e00001JjiNdAAJ",
      housing_fee: 7308,
      educational_fees: 16230,
    },
    {
      price: 24500,
      session: "a056e00001JjiO8AAJ",
      housing_fee: 7308,
      educational_fees: 16230,
    },
    {
      price: 26900,
      session: "a056e00001JjiOXAAZ",
      housing_fee: 8202,
      educational_fees: 17506,
    },
    {
      price: 26900,
      session: "a056e00001JjiO9AAJ",
      housing_fee: 8264,
      educational_fees: 17506,
    },
    {
      price: 26900,
      session: "a056e00001JjiK3AAJ",
      educational_fees: 19545,
      housing_fee: 6355,
    },
    {
      price: 26500,
      session: "a056e00001JjiO4AAJ",
      educational_fees: 18884,
      housing_fee: 6512,
    },
    {
      price: 26500,
      session: "a056e00001JjiKdAAJ",
      educational_fees: 18651,
      housing_fee: 6972,
    },
    {
      price: 26500,
      session: "a056e00001JjiKeAAJ",
      educational_fees: 18086,
      housing_fee: 7524,
    },
    {
      price: 30900,
      session: "a056e00001JjiLRAAZ",
      educational_fees: 25099,
      housing_fee: 4755,
    },
    {
      price: 32500,
      session: "a056e00001JjiJtAAJ",
      educational_fees: 19269,
      housing_fee: 12231,
    },
    {
      price: 36900,
      session: "a056e00001JjiL2AAJ",
      educational_fees: 25563,
      housing_fee: 10348,
    },
    {
      price: 22500,
      session: "a056e00001JjiP5AAJ",
      educational_fees: 25563,
      housing_fee: 10348,
    },
    {
      price: 32900,
      session: "a056e00001JjiMMAAZ",
      educational_fees: 22353,
      housing_fee: 9812,
    },
    {
      price: 29900,
      session: "a056e00001JjiJRAAZ",
      educational_fees: 14480,
      housing_fee: 14434,
    },
    {
      price: 32500,
      session: "a056e00001JjiMBAAZ",
      educational_fees: 17224,
      housing_fee: 14265,
    },
    {
      price: 31900,
      session: "a056e00001JjiJpAAJ",
      educational_fees: 16500,
      housing_fee: 14377,
    },
    {
      price: 36900,
      session: "a056e00001JjiGqAAJ",
      educational_fees: 34729,
      housing_fee: 1056,
    },
    {
      price: 39900,
      session: "a056e00001JjiM3AAJ",
      educational_fees: 28333,
      housing_fee: 10601,
    },
    {
      price: 35500,
      session: "a056e00001JjiM7AAJ",
      educational_fees: 24078,
      housing_fee: 10433,
    },
    {
      price: 44500,
      session: "a056e00001JjiMfAAJ",
      educational_fees: 34943,
      housing_fee: 8568,
    },
    {
      price: 46500,
      session: "a056e00001JjiPLAAZ",
      educational_fees: 38694,
      housing_fee: 6794,
    },
    {
      price: 47900,
      session: "a056e00001JjiKjAAJ",
      educational_fees: 33638,
      housing_fee: 13285,
    },
    {
      price: 51900,
      session: "a056e00001JjiJZAAZ",
      educational_fees: 36604,
      housing_fee: 14342,
    },
    {
      price: 15200,
      session: "a056e00001JjiK6AAJ",
      educational_fees: 36604,
      housing_fee: 14342,
    },
    {
      price: 12750,
      session: "a056e00001JjiOuAAJ",
      educational_fees: 11088,
      housing_fee: 3319,
    },
    {
      price: 11450,
      session: "a056e00001JjiNwAAJ",
      educational_fees: 7700,
      housing_fee: 3016,
    },
    {
      price: 9950,
      session: "a056e00001JjiNiAAJ",
      educational_fees: 7087,
      housing_fee: 2175,
    },
    {
      price: 17250,
      session: "a056e00001JjiMKAAZ",
      educational_fees: 11341,
      housing_fee: 5198,
    },
    {
      price: 15650,
      session: "a056e00001JjiLnAAJ",
      educational_fees: 10542,
      housing_fee: 4374,
    },
    {
      price: 11980,
      session: "a056e00001JjiJLAAZ",
      educational_fees: 9824,
      housing_fee: 1433,
    },
    {
      price: 12950,
      session: "a056e00001JjiOCAAZ",
      educational_fees: 8441,
      housing_fee: 3766,
    },
    {
      price: 12800,
      session: "a056e00001JjiLWAAZ",
      educational_fees: 9038,
      housing_fee: 3078,
    },
    {
      price: 12800,
      session: "a056e00001JjiLHAAZ",
      educational_fees: 8696,
      housing_fee: 3384,
    },
    {
      price: 10495,
      session: "a056e00001JjiMNAAZ",
      educational_fees: 8696,
      housing_fee: 3384,
    },
    {
      price: 10495,
      session: "a056e00001JjiJfAAJ",
      educational_fees: 6957,
      housing_fee: 2830,
    },
    {
      price: 10980,
      session: "a056e00001JjiNlAAJ",
      educational_fees: 6957,
      housing_fee: 2830,
    },
    {
      price: 10980,
      session: "a056e00001JjiOnAAJ",
      educational_fees: 6623,
      housing_fee: 3637,
    },
    {
      price: 11995,
      session: "a056e00001JjiHtAAJ",
      educational_fees: 7432,
      housing_fee: 3819,
    },
    {
      price: 12500,
      session: "a056e00001JjiJHAAZ",
      educational_fees: 9320,
      housing_fee: 2445,
    },
    {
      price: 16900,
      session: "a056e00001JjiPBAAZ",
      educational_fees: 12383,
      housing_fee: 3701,
    },
    {
      price: 11500,
      session: "a056e00001JjiOAAAZ",
      educational_fees: 8064,
      housing_fee: 2728,
    },
    {
      price: 12500,
      session: "a056e00001JjiJmAAJ",
      educational_fees: 7928,
      housing_fee: 3888,
    },
    {
      price: 12500,
      session: "a056e00001JjiJmAAJ",
      educational_fees: 7928,
      housing_fee: 3888,
    },
    {
      price: 15900,
      session: "a056e00001JjiPDAAZ",
      educational_fees: 10290,
      housing_fee: 4967,
    },
    {
      price: 16900,
      session: "a056e00001JjiMPAAZ",
      educational_fees: 10290,
      housing_fee: 4967,
    },
    {
      price: 14900,
      session: "a056e00001JjiJSAAZ",
      educational_fees: 8197,
      housing_fee: 5947,
    },
    {
      price: 14500,
      session: "a056e00001JjiMSAAZ",
      educational_fees: 7752,
      housing_fee: 6028,
    },
    {
      price: 15500,
      session: "a056e00001JjiMCAAZ",
      educational_fees: 8799,
      housing_fee: 5957,
    },
    {
      price: 13500,
      session: "a056e00001JjiKgAAJ",
      educational_fees: 9729,
      housing_fee: 3036,
    },
    {
      price: 17900,
      session: "a056e00001JjiO2AAJ",
      educational_fees: 16946,
      housing_fee: 173,
    },
    {
      price: 15500,
      session: "a056e00001JjiKXAAZ",
      educational_fees: 13100,
      housing_fee: 1665,
    },
    {
      price: 15500,
      session: "a056e00001JjiMDAAZ",
      educational_fees: 12640,
      housing_fee: 2113,
    },
    {
      price: 12900,
      session: "a056e00001JjiOmAAJ",
      educational_fees: 7592,
      housing_fee: 4576,
    },
    {
      price: 21500,
      session: "a056e00001JjiJ0AAJ",
      educational_fees: 17581,
      housing_fee: 3184,
    },
    {
      price: 19900,
      session: "a056e00001JjiLoAAJ",
      educational_fees: 14244,
      housing_fee: 4921,
    },
    {
      price: 23500,
      session: "a056e00001JjiKkAAJ",
      educational_fees: 15385,
      housing_fee: 7380,
    },
    {
      price: 22900,
      session: "a056e00001JjiPMAAZ",
      educational_fees: 19079,
      housing_fee: 3063,
    },
    {
      price: 25500,
      session: "a056e00001JjiL3AAJ",
      educational_fees: 19079,
      housing_fee: 3063,
    },
    {
      price: 29500,
      session: "a056e00001JjiJbAAJ",
      educational_fees: 18143,
      housing_fee: 6622,
    },
    {
      price: 12250,
      session: "a056e00001GPjgpAAD",
      educational_fees: 9036,
      housing_fee: 2480,
    },
    {
      price: 15200,
      session: "a056e00001GPivqAAD",
      educational_fees: 11168,
      housing_fee: 3251,
    },
    {
      price: 12750,
      session: "a056e00001GPjfxAAD",
      educational_fees: 9100,
      housing_fee: 2916,
    },
    {
      price: 11450,
      session: "a056e00001GPjiUAAT",
      educational_fees: 7688,
      housing_fee: 3016,
    },
    {
      price: 17850,
      session: "a056e00001GPjiBAAT",
      educational_fees: 7688,
      housing_fee: 3016,
    },
    {
      price: 18150,
      session: "a056e00001GPjYTAA1",
      educational_fees: 12449,
      housing_fee: 4944,
    },
    {
      price: 15650,
      session: "a056e00001GPjeUAAT",
      educational_fees: 10539,
      housing_fee: 4377,
    },
    {
      price: 11980,
      session: "a056e00001GPjg3AAD",
      educational_fees: 8558,
      housing_fee: 2664,
    },
    {
      price: 12800,
      session: "a056e00001GPjfkAAD",
      educational_fees: 8558,
      housing_fee: 2664,
    },
    {
      price: 12800,
      session: "a056e00001GPivnAAD",
      educational_fees: 8963,
      housing_fee: 3129,
    },
    {
      price: 14980,
      session: "a056e00001GPjeZAAT",
      educational_fees: 8117,
      housing_fee: 3129,
    },
    {
      price: 12150,
      session: "a056e00001GPjfuAAD",
      educational_fees: 7498,
      housing_fee: 3896,
    },
    {
      price: 11645,
      session: "a056e00001GPjg1AAD",
      educational_fees: 6863,
      housing_fee: 4014,
    },
    {
      price: 11645,
      session: "a056e00001GPjfoAAD",
      educational_fees: 6863,
      housing_fee: 4014,
    },
    {
      price: 10980,
      session: "a056e00001GPjgzAAD",
      educational_fees: 6039,
      housing_fee: 4282,
    },
    {
      price: 10980,
      session: "a056e00001GPjiEAAT",
      educational_fees: 5996,
      housing_fee: 4252,
    },
    {
      price: 11995,
      session: "a056e00001GPjiIAAT",
      educational_fees: 7475,
      housing_fee: 3776,
    },
    {
      price: 16900,
      session: "a056e00001GPjhGAAT",
      educational_fees: 12520,
      housing_fee: 3587,
    },
    {
      price: 13500,
      session: "a056e00001GPjhBAAT",
      educational_fees: 8281,
      housing_fee: 4438,
    },
    {
      price: 15900,
      session: "a056e00001GPjgYAAT",
      educational_fees: 7683,
      housing_fee: 7461,
    },
    {
      price: 16900,
      session: "a056e00001GPjiOAAT",
      educational_fees: 11347,
      housing_fee: 4806,
    },
    {
      price: 15500,
      session: "a056e00001GPjeYAAT",
      educational_fees: 7924,
      housing_fee: 6795,
    },
    {
      price: 13500,
      session: "a056e00001GPjh2AAD",
      educational_fees: 9882,
      housing_fee: 2871,
    },
    {
      price: 17900,
      session: "a056e00001GPjjXAAT",
      educational_fees: 16923,
      housing_fee: 173,
    },
    {
      price: 15500,
      session: "a056e00001GPjkeAAD",
      educational_fees: 9820,
      housing_fee: 4945,
    },
    {
      price: 15500,
      session: "a056e00001GPjeEAAT",
      educational_fees: 12629,
      housing_fee: 2113,
    },
    {
      price: 12900,
      session: "a056e00001GPjeeAAD",
      educational_fees: 6899,
      housing_fee: 5233,
    },
    {
      price: 21500,
      session: "a056e00001GPjgUAAT",
      educational_fees: 16401,
      housing_fee: 4318,
    },
    {
      price: 19900,
      session: "a056e00001GPji4AAD",
      educational_fees: 13936,
      housing_fee: 5206,
    },
    {
      price: 23500,
      session: "a056e00001GPjiKAAT",
      educational_fees: 15668,
      housing_fee: 7074,
    },
    {
      price: 22900,
      session: "a056e00001GPjeJAAT",
      educational_fees: 18687,
      housing_fee: 3443,
    },
    {
      price: 25500,
      session: "a056e00001GPjgFAAT",
      educational_fees: 18835,
      housing_fee: 5930,
    },
    {
      price: 6900,
      session: "a056e00001JjiUzAAJ",
      educational_fees: 9346,
      housing_fee: 3534,
    },
    {
      price: 6900,
      session: "a056e00001JjiSoAAJ",
      educational_fees: 4786,
      housing_fee: 1529,
    },
    {
      price: 6900,
      session: "a056e00001JjiR8AAJ",
      educational_fees: 5299,
      housing_fee: 993,
    },
    {
      price: 5500,
      session: "a056e00001JjiSVAAZ",
      educational_fees: 4814,
      housing_fee: 78,
    },
    {
      price: 5350,
      session: "a056e00001JjiUiAAJ",
      educational_fees: 3245,
      housing_fee: 1498,
    },
    {
      price: 5350,
      session: "a056e00001JjiSJAAZ",
      educational_fees: 3245,
      housing_fee: 1498,
    },
    {
      price: 5900,
      session: "a056e00001JjiV2AAJ",
      educational_fees: 3676,
      housing_fee: 1613,
    },
    {
      price: 5900,
      session: "a056e00001JjiTXAAZ",
      educational_fees: 3608,
      housing_fee: 1584,
    },
    {
      price: 7500,
      session: "a056e00001JjiTSAAZ",
      educational_fees: 5935,
      housing_fee: 957,
    },
    {
      price: 6500,
      session: "a056e00001JjiTWAAZ",
      educational_fees: 5035,
      housing_fee: 857,
    },
    {
      price: 7500,
      session: "a056e00001JjiVTAAZ",
      educational_fees: 5391,
      housing_fee: 1489,
    },
    {
      price: 7500,
      session: "a056e00001JjiTLAAZ",
      educational_fees: 5418,
      housing_fee: 1497,
    },
    {
      price: 6900,
      session: "a056e00001JjiThAAJ",
      educational_fees: 5166,
      housing_fee: 1114,
    },
    {
      price: 7900,
      session: "a056e00001JjiSiAAJ",
      educational_fees: 5565,
      housing_fee: 1715,
    },
    {
      price: 5680,
      session: "a056e00001JjiVBAAZ",
      educational_fees: 3547,
      housing_fee: 1498,
    },
    {
      price: 5350,
      session: "a056e00001JjiUiAAJ",
      educational_fees: 3245,
      housing_fee: 1498,
    },
    {
      price: 5350,
      session: "a056e00001JjiSJAAZ",
      educational_fees: 3245,
      housing_fee: 1498,
    },
    {
      price: 9650,
      session: "a056e00001JjiUWAAZ",
      educational_fees: 5974,
      housing_fee: 3011,
    },
    {
      price: 7500,
      session: "a056e00001JjiVTAAZ",
      educational_fees: 5391,
      housing_fee: 1489,
    },
    {
      price: 7500,
      session: "a056e00001JjiTLAAZ",
      educational_fees: 5418,
      housing_fee: 1497,
    },
    {
      price: 13900,
      session: "a056e00001JjiSOAAZ",
      educational_fees: 8118,
      housing_fee: 5162,
    },
    {
      price: 13500,
      session: "a056e00001JjiUKAAZ",
      educational_fees: 4786,
      housing_fee: 1529,
    },
    {
      price: 5900,
      session: "a056e00001JjiTnAAJ",
      educational_fees: 7371,
      housing_fee: 3470,
    },
    {
      price: 5900,
      session: "a056e00001JjiV2AAJ",
      educational_fees: 3676,
      housing_fee: 1613,
    },
    {
      price: 11500,
      session: "a056e00001JjiTXAAZ",
      educational_fees: 3608,
      housing_fee: 1584,
    },
    {
      price: 14500,
      session: "a056e00001JjiVPAAZ",
      educational_fees: 12010,
      housing_fee: 1859,
    },
    {
      price: 34500,
      session: "a056e00001JjiJiAAJ",
      educational_fees: 21084,
      housing_fee: 12450,
    },
    {
      price: 16900,
      session: "a056e00001JjiLjAAJ",
      educational_fees: 10432,
      housing_fee: 5076,
    },
    {
      price: 16900,
      session: "a056e00001GPjgtAAD",
      educational_fees: 9640,
      housing_fee: 6583,
    },
    {
      price: 36900,
      session: "a056e00001JjiJ8AAJ",
      educational_fees: 23351,
      housing_fee: 12549,
    },
    {
      price: 17900,
      session: "a056e00001JjiLUAAZ",
      educational_fees: 10816,
      housing_fee: 6303,
    },
    {
      price: 17900,
      session: "a056e00001GPjguAAD",
      educational_fees: 10624,
      housing_fee: 6495,
    },
  ];
} else {
  UMASS_PRICING = [
    {
      price: 14650,
      session: "a051g0000033MrfAAE",
      educational_fees: 0,
      housing_fee: 0,
    },
    {
      price: 63724,
      session: "a051g000002JzFMAA0",
      educational_fees: 3202,
      housing_fee: 2374,
    },
    {
      price: 44430,
      session: "a051g0000033LtGAAU",
      educational_fees: 3320,
      housing_fee: 2430,
    },
    {
      price: 14370,
      session: "a051g0000032TgRAAU",
      educational_fees: 2340,
      housing_fee: 3410,
    },
  ];
}

export { UMASS_OPE8, UMASS_PRICING };

<template>
  <div class="mb-6 space-y-6 text-base">
    <p data-cy="etranscriptcopyone">
      All applicants are required to submit a transcript from each college or
      university attended. The transcript does not have to be the original
      sealed copy, but it must be an official copy.
    </p>

    <p data-cy="etranscriptcopytwo">
      To request an official e-transcript use either
      <a
        class="api-link"
        href="https://www.parchment.com/order/my-credentials/"
        target="_blank"
        >Parchment</a
      >
      or
      <a
        class="api-link"
        href="https://tsorder.studentclearinghouse.org/school/select"
        target="_blank"
        >Student Clearinghouse</a
      >
      to have a transcript sent to api@apiabroad.com directly.
    </p>
    <CheckBox
      label="Check this off your to do list here once you send
    us your transcript."
      label-class="text-base"
      name="eTranscript"
      :checked="checked"
      :validation-errors="v$.value.checkedOption"
      @input="onFieldChange('checkedOption', $event)"
    />
    <ButtonWithSpinner
      ref="SubmitForm"
      type="submit"
      variant="primary"
      variant-type="block"
      :disabled="!allowSubmit"
      @click.prevent="submit()"
    >
      <span
        >Submit
        <svg
          v-if="formSubmitted && !hasErrors"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="inline feather feather-check"
        >
          <polyline points="20 6 9 17 4 12"></polyline></svg
      ></span>
    </ButtonWithSpinner>

    <p v-if="hasErrors" class="text-error-900">
      Please complete the required fields correctly.
    </p>
    <div v-if="formioErrors" class="text-error-900">
      <p v-for="error in formioErrors" :key="error">
        {{ error }}
      </p>
    </div>
    <div v-if="missingAppId" class="text-error-900">
      <p>
        No Application ID, please contact your API program manager to help you
        with your application.
      </p>
    </div>
    <div v-if="formSubmitted && !hasErrors" class="text-success-900">
      <p>
        Submission Complete, thank you!
      </p>
    </div>
  </div>
</template>

<script>
import CheckBox from "../SharedComponents/CheckBox.vue";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import { sameAs } from "@vuelidate/validators";
import formInputs from "../../../mixins/formInputs";
import formValidation from "../../../mixins/formValidation";
import formioHelpers from "../../../mixins/formioHelpers";
import formIoApi from "../../../mixins/formIoApi";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
export default {
  name: "ETranscriptForm",
  components: { CheckBox, ButtonWithSpinner },
  mixins: [formInputs, formValidation, formioHelpers, formIoApi],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      applicationId: "",
      formSubmitted: false,
      checked: false,
      value: {
        checkedOption: false,
      },
    };
  },
  computed: {
    ...mapState(["formioToken"]),
    allowSubmit() {
      return this.value.checkedOption;
    },
    createSubmissionDataForFormIo() {
      const body = {
        data: {
          application_id: this.applicationId,
          eTranscriptSubmitted: true,
        },
      };
      return body;
    },
    patchSubmissionDataForFormIo() {
      const body = [
        {
          op: "add",
          path: "/data/eTranscriptSubmitted",
          value: true,
        },
      ];
      return body;
    },
    formPath() {
      return "studentapplicationstage2step1";
    },
  },
  async created() {
    //set the application ID
    this.applicationId = this.setApplicationId;
    await this.prefetchFormData();
  },
  methods: {
    prefetchFormData() {
      this.formioSubmissionExists(this.formPath, this.applicationId).then(
        (formSubmissionId) => {
          if (formSubmissionId) {
            this.getFormioSubmission(this.formPath, formSubmissionId).then(
              (response) => {
                this.checked = response.eTranscriptSubmitted;
                this.loading = false;
              }
            );
          } else {
            this.loading = false;
          }
        }
      );
    },
    async submit() {
      this.submitted = false;
      //calling the startLoading method of the button, which enables the button to loading state
      this.$refs.SubmitForm.startLoading();
      try {
        await this.checkForApplicationId();
        if (this.allowSubmit) {
          if (this.submission_id) {
            this.submitToFormIo(
              this.formPath,
              this.patchSubmissionDataForFormIo,
              "PATCH",
              this.submission_id
            ).then((response) => {
              if (response) {
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          } else {
            this.submitToFormIo(
              this.formPath,
              this.createSubmissionDataForFormIo
            ).then((response) => {
              if (response) {
                this.submission_id = response;
                this.formSubmitted = true;
              }
              this.$refs.SubmitForm.stopLoading();
            });
          }
        } else {
          this.$refs.SubmitForm.stopLoading();
        }
      } catch {
        this.$refs.SubmitForm.stopLoading();
      }
    },
  },
  validations: {
    value: {
      checkedOption: {
        sameAs: sameAs(true),
      },
    },
  },
};
</script>

<template class="grid grid-cols-1 gap-6 xs:gap-10">
  <div class="min-h-screen items-center">
    <template v-if="isLoading">
      <spinner>
        <template #spinnercontent>
          <p class="text-gray-600">
            {{ message }}
          </p>
        </template>
      </spinner>
    </template>
    <div class="dashboard">
      <div
        class="w-full px-4 pb-4 mx-auto applications max-w-7xl sm:px-8 sm:pb-8"
      >
        <header
          class="my-6 xs:flex xs:flex-col"
          role="banner"
          aria-labelledby="viewtitle"
        >
          <section
            v-if="isImpersonating"
            id="impersonation-banner"
            class="xs:flex xs:items-center bg-cream-100 font-semibold italic p-3 rounded-lg w-full"
          >
            <span class="text-gray-800 w-full text-center">
              You are currently viewing {{ getUserName }} account. Please exit
              Student View before viewing another student's profile.
            </span>
            <button class="inline-block" @click="exitImpersonation()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 stroke-current text-teal-500 hover:text-teal-100 stroke-2"
                fill="none"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </button>
          </section>
          <section
            class="w-full xs:flex xs:justify-between xs:items-center mt-3"
          >
            <h1 id="viewtitle" class="text-xl md:text-2xl">
              My Applications
            </h1>
            <button
              class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white bg-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white flex items-center justify-center w-full mt-4 xs:w-auto xs:mt-0"
              data-cy="new-application-button"
              @click="redirectNewApplication"
            >
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus-circle mr-2"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="16" />
                <line x1="8" y1="12" x2="16" y2="12" />
              </svg>
              Start New Application
            </button>
          </section>
        </header>
        <div
          class="grid grid-cols-1 gap-6 xs:gap-10"
          role="region"
          aria-labelledby="appsregion"
        >
          <span id="appsregion" class="hidden">Applied Applications</span>
          <div
            v-for="application in studentApplications"
            :key="application.id"
            class="sm:flex sm:items-stretch"
          >
            <div
              class="w-full overflow-hidden bg-gray-100 bg-gray-300 bg-center bg-no-repeat rounded-t-lg sm:flex-none sm:rounded-l-lg sm:rounded-tr-none sm:w-1/2 md:w-2/5"
              style="background-image: url(../../img/image-lg.svg);"
            >
              <img
                class="sm:object-cover sm:w-full sm:h-full"
                loading="lazy"
                :src="
                  getProgramImage(application.programSession.program.site.city)
                "
                alt="[SHORT IMAGE DESCRIPTION]"
              />
            </div>
            <div
              class="flex-auto p-6 bg-white border border-t-0 border-gray-300 rounded-bl-lg rounded-br-lg md:p-8 lg:p-12 sm:border-t sm:border-l-0 sm:rounded-bl-none sm:rounded-tr-lg"
            >
              <h2
                class="mb-2 text-base font-semibold xs:text-lg sm:text-xl md:mb-6"
              >
                {{ application.programSession.program.name }}
              </h2>
              <p class="flex items-center text-gray-600 md:mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-map-pin"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span class="ml-2">
                  {{ application.programSession.program.site.city }},
                  {{ application.programSession.program.site.country }}
                </span>
              </p>
              <program-dates
                :id="application.id"
                :dates="
                  buildStringDateRange(
                    application.programSession.start_date,
                    application.programSession.end_date
                  )
                "
                :start-is-final="
                  application.programSession.start_date_is_final > 0
                "
                :end-is-final="application.programSession.end_date_is_final > 0"
                tag="p"
                class="text-gray-600"
              />
              <div class="mt-6 md:flex md:items-center md:mt-16">
                <div class="flex items-center justify-between sm:flex-auto">
                  <p class="text-xl font-semibold" />
                  <div
                    class="flex flex-col md:flex-row md:justify-end md:items-center"
                  >
                    <p class="text-sm text-gray-600 md:mb-0 md:mx-6">
                      Last saved
                      {{
                        formatDateShort(lastModified(application.submissions))
                      }}
                    </p>
                  </div>
                </div>
                <template
                  v-if="
                    application.programChange.requestedChange &&
                    !application.programChange.isNewProgram
                  "
                >
                  <button
                    v-if="
                      application.programChange.changeIsPending &&
                      !application.programChange.isNewProgramSelected
                    "
                    class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900 w-full mt-2 md:mt-0 md:w-auto"
                    @click="
                      redirectProgramChanges(
                        application.programChange,
                        application.uiVersion
                      )
                    "
                  >
                    Resume Program Change
                  </button>
                  <p
                    v-else
                    class="text-error-900"
                    @click="
                      redirectContinueApplication(
                        application.id,
                        application.programSession.salesforce_id,
                        application.programChange,
                        application.uiVersion,
                        application.bulkEnrollmentId
                      )
                    "
                  >
                    Application Withdrawn
                  </p>
                </template>
                <template
                  v-else-if="application.enrollmentStatus === 'Withdrawn'"
                >
                  <p
                    class="text-error-900"
                    @click="
                      redirectContinueApplication(
                        application.id,
                        application.programSession.salesforce_id,
                        application.programChange,
                        application.uiVersion,
                        application.bulkEnrollmentId
                      )
                    "
                  >
                    Application Withdrawn
                  </p>
                </template>
                <template v-else>
                  <button
                    :data-cy="application.id"
                    class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900 w-full mt-2 md:mt-0 md:w-auto"
                    @click="
                      redirectContinueApplication(
                        application.id,
                        application.programSession.salesforce_id,
                        application.programChange,
                        application.uiVersion,
                        application.bulkEnrollmentId
                      )
                    "
                  >
                    {{
                      continueButton(
                        application.id,
                        application.uiVersion,
                        application.bulkEnrollmentId
                      )
                    }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import forms from "../mixins/forms.js";
import ProgramDates from "./ProgramDates";
import programImages from "../assets/programImages.json";
import Spinner from "./helpers/Spinner";
import { buildStringDateRange } from "../mixins/helpers";
import { CONSTANT_KEYS } from "../constants.js";

export default {
  name: "MyApplications",
  components: {
    "program-dates": ProgramDates,
    Spinner,
  },
  mixins: [forms],
  data() {
    return {
      isLoading: true, //show loaders
      count: 0, //keep track of number of times watch has runned
      message: "",
    };
  },
  computed: {
    ...mapState([
      "formioToken",
      "studentApplications",
      "studentFormioSubmissions",
      "currentApplicationId",
      "userData",
      "currentUser",
      "isImpersonating",
    ]),
    ...mapGetters({
      permissions: "getPermissions",
    }),
    getUserName() {
      return this.userData && this.userData.data
        ? `${this.userData.data.firstname} ${this.userData.data.lastname}`
        : "";
    },
  },
  watch: {
    studentApplications: {
      handler() {
        if (this.count > 0) {
          this.isLoading = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  async created() {
    //clear state
    let resetPayload = {
      userData: this.userData,
      currentUser: this.currentUser,
      isImpersonating: this.isImpersonating,
    };

    this.$store.commit("reset", resetPayload);
    this.$store.commit("resetPreviousApplicationState");
    this.message = "Loading student applications.";
    this.isLoading = true;

    //Prepare initial data
    await this.$store.dispatch("initialSetUp");
    await this.$store.dispatch("getEnrollmentData");
    await this.$store.dispatch("getFormioSubmissions");
    await this.$store.dispatch("getStudentApplications");
  },
  methods: {
    exitImpersonation() {
      this.$store.dispatch("exitImpersonation");
    },
    setPreviousApplicationData(submission) {
      // Setting up data
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "previousApplicationId",
        value: submission.data.data["application_id"],
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "session",
        value: submission.data.data["session"],
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "paidFee",
        value:
          Object.hasOwnProperty.call(submission.data.data, "applicationPaid") &&
          submission.data.data["applicationPaid"],
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "applicationFee",
        value: Object.hasOwnProperty.call(
          submission.data.data,
          "applicationFee"
        )
          ? submission.data.data["applicationFee"]
          : 0,
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "transactionId",
        value: Object.hasOwnProperty.call(submission.data.data, "transactionId")
          ? submission.data.data["transactionId"]
          : "",
      });
      this.$store.commit("setPreviousApplicationIdByField", {
        handle: "submissionId",
        value: submission.data["_id"],
      });
    },
    redirectProgramChanges(submission, version) {
      this.$store.commit("setUiVersion", version);
      this.setPreviousApplicationData(submission);

      // Redirect
      this.$router.push({
        name: "applications/new",
        params: {
          slug: "program-selection",
        },
      });
    },
    buildStringDateRange,
    continueButton(applicationId, uiVersion, bulkId) {
      if (this.formIsComplete(applicationId, uiVersion) || bulkId) {
        return "Continue Enrollment";
      } else {
        return "Continue Application";
      }
    },
    redirectContinueApplication(
      applicationId,
      programId,
      programChange,
      version,
      bulkId
    ) {
      this.$store.commit("setSelectedProgramId", programId);
      this.$store.dispatch("getProgramData", this.$apollo);
      this.$store.commit("setCurrentApplicationId", applicationId);
      this.$store.commit("setUiVersion", version);

      if (programChange.requestedChange) {
        this.setPreviousApplicationData(programChange);
      }

      if (this.formIsComplete(applicationId, version) || bulkId) {
        this.$router.push({
          name: "applications/landing",
          params: {
            applicationId: applicationId,
          },
        });
      } else {
        this.$router.push({
          name: "applications/resume",
          params: {
            applicationId: applicationId,
          },
        });
      }
    },
    formatDateShort(dateParam) {
      let date = new Date(dateParam);
      date =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
      return date;
    },
    getProgramImage(city) {
      let programImage = this.fallbackImage();
      programImages.forEach((item) => {
        if (item.image && city === item.city) {
          programImage = item.image;
        }
      });
      return programImage;
    },
    fallbackImage() {
      return "https://api-abroad.sfo2.cdn.digitaloceanspaces.com/images/_1200x675_crop_center-center_82_line/england-london-big-ben-parliament-river-sunset-230637613.jpg";
    },
    lastModified(submissions) {
      return submissions
        .map((o) => o["modified"])
        .reduce((accumulator, current) => {
          return current > accumulator ? current : accumulator;
        });
    },
    redirectNewApplication() {
      this.$store.commit("setUiVersion", CONSTANT_KEYS.UI_VERSION);
      this.$router.push({ path: "/applications/new" });
    },
  },
};
</script>

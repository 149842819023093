<template>
  <div>
    <p>
      Already have an account?
      <router-link :to="{ path: '/sign-in' }" class="api-link">
        <span> Log in here</span>
      </router-link>
    </p>
    <p>
      <router-link :to="{ path: '/create-account' }" class="api-link">
        Create an account
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "AlreadyHaveAccount",
};
</script>

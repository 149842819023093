<template>
  <div>
    <input
      id="autocomplete"
      type="text"
      class="form-input block w-full min-h-10"
      :class="customClass"
      :placeholder="customPlaceholder"
      @change.prevent="validate"
      @keyup.enter="validate"
    />
    <div class="w-full">
      <div
        v-if="!v$.value.addressInfo.required && v$.value.addressInfo.$error"
        class="error text-error-900"
      >
        {{ customErrorCopy }}
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "AddressComponent",
  props: {
    customClass: {
      type: String,
      default: "",
    },
    customPlaceholder: {
      type: String,
      default: "Search your address",
    },
    customErrorCopy: {
      type: String,
      default: "This field is required.",
    },
  },
  emits: ["updateAddressInfo"],
  data() {
    return {
      v$: useValidate(),
      value: {
        addressInfo: {
          street_address_1: null,
          street_address_2: null,
          number: null,
          city: null,
          state: null,
          country: null,
          postal_code: null,
          lat: null,
          long: null,
        },
      },
      address: null,
      cast: {
        route: "street_address_1",
        subpremise: "street_address_2",
        street_number: "number",
        postal_code: "postal_code",
        country: "country",
        administrative_area_level_2: "city",
        administrative_area_level_1: "state",
      },
      autocomplete: null,
    };
  },
  computed: {
    rawData() {
      return { ...this.value.addressInfo };
    },
  },
  watch: {
    address: {
      handler: function (val) {
        if (!Object.prototype.hasOwnProperty.call(val, "address_components")) {
          this.resetAddressInfo();
          return;
        }
        this.mapAttributes(val);
        this.validate();
        this.sendToVuex();
        this.$emit("updateAddressInfo", this.rawData);
      },
      deep: true,
    },
  },
  validations: {
    value: {
      addressInfo: {
        street_address_1: { required },
        country: { required },
        lat: { required },
        long: { required },
      },
    },
  },
  mounted() {
    this.initGooglePlaces();
  },
  methods: {
    initGooglePlaces() {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        {
          types: ["address"],
          fields: ["address_component", "geometry.location"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.address = autocomplete.getPlace();
      });
    },
    validate() {
      this.v$.$touch();
    },
    sendToVuex() {
      if (
        this.value.addressInfo.stree_address_1 &&
        !this.v$.value.addressInfo.$error
      ) {
        this.$store.commit("setAddressInfo", this.rawData);
      } else {
        this.$store.commit("setAddressInfo", {});
      }
    },
    resetAddressInfo() {
      this.value.addressInfo = {
        street_address_1: null,
        street_address_2: null,
        number: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        lat: null,
        long: null,
      };
    },
    mapAttributes(attributes) {
      this.value.addressInfo.lat = attributes?.geometry?.location?.lat() ?? "";
      this.value.addressInfo.long = attributes?.geometry?.location?.lng() ?? "";
      attributes.address_components.forEach((component) => {
        component.types.forEach((type) => {
          if (Object.prototype.hasOwnProperty.call(this.cast, type)) {
            this.value.addressInfo[this.cast[type]] = component.long_name;
          }
        });
      });
    },
  },
};
</script>

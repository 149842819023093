<template>
  <div id="pagination" class="flex align-center justify-center">
    <ul class="flex">
      <li class="p-6" :class="page === 1 ? 'text-teal-black' : 'text-teal-500'">
        <button
          :disabled="page === 1"
          aria-label="Go to first page"
          class="font-semibold"
          @click="firstPage"
        >
          First
        </button>
      </li>
      <li
        class="p-6 font-semibold"
        :class="page === 1 ? 'text-teal-black' : 'text-teal-500'"
      >
        <button
          :disabled="page === 1"
          aria-label="Go to previous page"
          @click="previousPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      </li>
      <li
        v-for="item in pagesArray"
        :key="item.id"
        class="p-6"
        :class="item.name === page ? 'text-teal-black' : 'text-teal-500'"
      >
        <button class="font-semibold" @click="item.clickable && goTo(item)">
          {{ item.name }}
        </button>
      </li>
      <li
        class="p-6"
        :class="page === pageCount ? 'text-teal-black' : 'text-teal-500'"
      >
        <button
          class="font-semibold"
          :disabled="page === pageCount"
          aria-label="Go to next page"
          @click="nextPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </li>
      <li
        class="p-6"
        :class="page === pageCount ? 'text-teal-black' : 'text-teal-500'"
      >
        <button
          class="font-semibold"
          :disabled="page === pageCount"
          aria-label="Go to last page"
          @click="lastPage"
        >
          Last
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["pageCount", "page"],
  emits: ["onPageChange"],
  data() {
    return {
      i: 1,
    };
  },
  computed: {
    pagesArray() {
      const pages = [];
      if (this.pageCount === 1) {
        pages.push({ id: 1, name: this.i, clickable: false });
      } else if (this.page < 3) {
        pages.push(
          { id: 1, name: this.i, clickable: true },
          { id: 2, name: this.i + 1, clickable: true },
          { id: 3, name: "...", clickable: false },
          { id: 4, name: this.pageCount - 1, clickable: true },
          { id: 5, name: this.pageCount, clickable: true }
        );
      } else if (this.page >= this.pageCount - 3) {
        pages.push(
          { id: 1, name: this.i, clickable: true },
          { id: 2, name: "...", clickable: false },
          { id: 3, name: this.pageCount - 3, clickable: true },
          { id: 4, name: this.pageCount - 2, clickable: true },
          { id: 5, name: this.pageCount - 1, clickable: true },
          { id: 6, name: this.pageCount, clickable: true }
        );
      } else {
        pages.push(
          { id: 1, name: this.i, clickable: true },
          { id: 2, name: "...", clickable: false },
          { id: 3, name: this.page - 1, clickable: true },
          { id: 4, name: this.page, clickable: true },
          { id: 5, name: this.page + 1, clickable: true },
          { id: 6, name: "...", clickable: false },
          { id: 7, name: this.pageCount, clickable: true }
        );
      }
      return pages;
    },
  },
  methods: {
    previousPage() {
      this.$emit("onPageChange", this.page - 1);
    },
    nextPage() {
      this.$emit("onPageChange", this.page + 1);
    },
    firstPage() {
      this.$emit("onPageChange", 1);
    },
    lastPage() {
      this.$emit("onPageChange", this.pageCount);
    },
    goTo(item) {
      this.$emit("onPageChange", item.name);
    },
  },
};
</script>
<style></style>

<template>
  <div
    class="flex items-center justify-end flex-1 px-4 mx-auto md:justify-start max-w-7xl sm:px-8"
  >
    <button
      id="menu-open"
      aria-label="Mobile Menu Toggle"
      class="block text-gray-600 md:hidden hover:text-gray-700 z-40"
      @click="toggleBurgerMenu()"
    >
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12" />
        <line x1="3" y1="6" x2="21" y2="6" />
        <line x1="3" y1="18" x2="21" y2="18" />
      </svg>
    </button>
    <div
      id="menu"
      class="fixed inset-0 z-50 items-center justify-between hidden flex-auto bg-white md:bg-transparent md:static md:flex md:ml-12"
      :style="OpenBurgerMenu && !isDesktop ? 'display:block;' : ''"
    >
      <div
        class="flex items-center justify-between h-12 px-4 py-2 mb-6 border-b border-gray-100 md:hidden"
      >
        <span class="ml-1 text-base font-semibold text-gray-600">
          {{ userName }}
        </span>
        <button
          id="menu-close"
          aria-label="Mobile Close"
          class="block h-6 w-6 flex items-center justify-center md:hidden"
          @click="toggleBurgerMenu()"
        >
          <span class="sr-only">Close</span>
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x md:hidden"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      </div>
      <ul class="md:flex">
        <li
          v-if="studentDataAvailable || isParticipant"
          class="md:mx-6 md:hidden"
        >
          <router-link v-slot="{ href, navigate, isActive }" to="/applications">
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[isActive ? 'border-blue-700' : '']"
              :href="href"
              @click="navigate"
            >
              <span>
                {{ isParticipant ? "My Applications" : "Student Applications" }}
              </span>
              <span
                :class="{
                  'absolute inset-x-0 bottom-0 hidden w-full h-1 bg-blue-700 rounded-sm md:block': isActive,
                }"
              ></span>
            </a>
          </router-link>
        </li>
        <li v-if="fareFinder" class="md:mx-6 md:hidden">
          <router-link
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'fare-finder' }"
          >
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[isActive ? 'border-blue-700' : '']"
              :href="href"
              @click="navigate"
            >
              <span>
                Fare Finder
              </span>
              <span
                :class="{
                  'absolute inset-x-0 bottom-0 hidden w-full h-1 bg-blue-700 rounded-sm md:block': isActive,
                }"
              ></span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            (hasStudentManagementPermission || dynamicUniversity) &&
            !isImpersonating
          "
          class="md:mx-6 md:hidden"
        >
          <router-link v-slot="{ href, navigate, isActive }" to="/my-students">
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[isActive ? 'border-blue-700' : '']"
              :href="href"
              @click="navigate"
            >
              <span>My Students</span>
              <span
                :class="{
                  'absolute inset-x-0 bottom-0 hidden w-full h-1 bg-blue-700 rounded-sm md:block': isActive,
                }"
              ></span>
            </a>
          </router-link>
        </li>
        <li v-if="studentView" class="md:mx-6 md:hidden">
          <router-link v-slot="{ href, navigate, isActive }" to="/students">
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[isActive ? 'border-blue-700' : '']"
              :href="href"
              @click="navigate"
            >
              <span>Student View</span>
              <span
                :class="{
                  'absolute inset-x-0 bottom-0 hidden w-full h-1 bg-blue-700 rounded-sm md:block': isActive,
                }"
              ></span>
            </a>
          </router-link>
        </li>
        <li
          v-if="hasStudentManagementPermission"
          class="md:mx-6 md:hidden sm:block"
        >
          <router-link
            v-slot="{ href, navigate, isActive }"
            to="/notification-settings"
          >
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[isActive ? 'border-blue-700' : '']"
              :href="href"
              @click="navigate"
            >
              <span>Notification Settings</span>
              <span
                :class="{
                  'absolute inset-x-0 bottom-0 hidden w-full h-1 bg-blue-700 rounded-sm md:block': isActive,
                }"
              ></span>
            </a>
          </router-link>
        </li>
        <slot name="nav-mobile-list-items" />
        <li class="md:mx-6 md:hidden">
          <button
            class="block font-normal md:font-semibold border-transparent pl-8 md:pl-0 md:border-l-0 text-base h-12 md:h-14 flex items-center relative text-gray-600 hover:text-gray-700 hover:no-underline w-full"
            @click="logout"
          >
            {{ logoutName }}
          </button>
        </li>
        <li
          v-if="dynamicUniversity && !studentDataAvailable"
          class="md:mx-6 md:hidden sm:block pl-8"
        >
          <UniversityChange class="w-90-p" :show-current="true" />
        </li>
      </ul>
      <UniversityChangeDesktop v-if="!studentDataAvailable" />
      <ul class="items-stretch self-stretch hidden text-base md:flex">
        <li class="relative flex items-center ml-6">
          <button
            data-toggle="user-menu"
            aria-label="Desktop Menu Toggle"
            data-toggle-indicator="flip"
            class="flex items-center text-base text-gray-600 hover:text-gray-700 hover:no-underline"
            @click="openOrClose"
          >
            <span class="mr-2 font-semibold">{{ userName }}</span>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down transform"
              :class="{ '-rotate-180': open }"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </button>
          <div
            id="user-menu"
            class="absolute right-0 hidden w-48 bg-white shadow-lg menu top-full"
            :style="{ display: open ? 'block' : 'none' }"
          >
            <ul class="text-sm text-center">
              <li
                v-if="isParticipant || isImpersonating"
                class="border-et border-gray-200"
                @click="openOrClose"
              >
                <router-link
                  class="block px-4 py-5 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
                  to="/applications"
                >
                  {{
                    isImpersonating ? "Student Applications" : "My Applications"
                  }}
                </router-link>
              </li>
              <li
                v-if="fareFinder"
                class="border-t border-gray-200"
                @click="openOrClose"
              >
                <router-link
                  class="block px-4 py-5 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
                  :to="{ name: 'fare-finder' }"
                >
                  Fare Finder
                </router-link>
              </li>
              <li v-if="$store.state.featureFlags['help-link']">
                <a
                  class="block px-4 py-3 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
                  href="#"
                  data-cy="help-menu-link"
                >
                  Help
                </a>
              </li>
              <li
                v-if="
                  (hasStudentManagementPermission || dynamicUniversity) &&
                  !isImpersonating
                "
                class="border-gray-200"
                @click="openOrClose"
              >
                <router-link
                  class="block px-4 py-5 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
                  to="/my-students"
                >
                  My Students
                </router-link>
              </li>
              <li
                v-if="hasStudentManagementPermission"
                class="border-t border-gray-200"
                @click="openOrClose"
              >
                <router-link
                  class="block px-4 py-5 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
                  to="/notification-settings"
                  >Notification Settings</router-link
                >
              </li>
              <li
                v-if="studentView"
                class="border-gray-200"
                :class="{ 'border-t': hasStudentManagementPermission }"
                @click="openOrClose"
              >
                <router-link
                  class="block px-4 py-5 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline"
                  to="/students"
                >
                  Student View
                </router-link>
              </li>
              <slot name="nav-desktop-list-items" />
              <li class="border-t border-gray-200">
                <button
                  class="block px-4 py-5 font-normal text-gray-600 hover:bg-blue-300 hover:text-gray-700 hover:no-underline w-full"
                  @click="logout"
                >
                  {{ logoutName }}
                </button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UniversityChange from "@/components/Header/universityChange.vue";
import UniversityChangeDesktop from "@/components/Header/universityChangeDesktop.vue";
import { eventBus } from "../../app";

export default {
  name: "NavBar",
  components: { UniversityChange, UniversityChangeDesktop },
  data() {
    return {
      open: false,
      OpenBurgerMenu: false,
      windowWidth: 0,
    };
  },
  computed: {
    ...mapState(["userData", "currentUser", "isImpersonating", "featureFlags"]),
    ...mapGetters({
      dynamicUniversity: "university/dynamicUniversity",
      permissions: "getPermissions",
    }),
    fareFinder() {
      return this.featureFlags["fare-finder"];
    },
    isDesktop() {
      if (this.windowWidth > 900) {
        return true;
      } else {
        return false;
      }
    },
    studentDataAvailable() {
      return this.userData !== null && Object.entries(this.userData).length > 0;
    },
    studentView() {
      return (
        this.permissions.includes("api-employee-student-view") &&
        !this.isImpersonating
      );
    },
    hasStudentManagementPermission() {
      return (
        this.permissions.includes("student-management") && !this.isImpersonating
      );
    },
    hasStudentViewPermission() {
      return this.permissions.includes("api-employee-student-view");
    },
    isParticipant() {
      return this.permissions.includes("participants");
    },
    userName() {
      return this.isImpersonating
        ? `Student View: ${this.userData.data.firstname} ${this.userData.data.lastname}`
        : `Hi ${this.currentUser?.firstName ?? ""} ${
            this.currentUser?.lastName ?? ""
          }`;
    },
    logoutName() {
      return this.isImpersonating ? "Logout Student View" : "Logout";
    },
  },
  watch: {
    windowWidth: {
      handler: function (newVal) {
        if (newVal > 900) this.OpenBurgerMenu = false;
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleBurgerMenu() {
      this.OpenBurgerMenu = !this.OpenBurgerMenu;
      if (this.featureFlags["sidebar-enabled"]) {
        eventBus.$emit("toggleMobileSidebar");
      }
    },
    openOrClose() {
      this.open = !this.open;
    },
    async logout() {
      this.openOrClose();
      if (this.isImpersonating) {
        await this.$store.dispatch("exitImpersonation");
      } else {
        await this.$store.dispatch("logout", {
          reload: true,
        });
      }
    },
  },
};
</script>
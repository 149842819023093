<template>
  <div class="grid pb-4 pt-2 border-b border-gray-200">
    <label class="inline-flex">
      <input
        v-model="value.policy"
        type="checkbox"
        class="mt-2 text-blue-700 form-checkbox"
        :class="{ 'bg-error-100': v$.value.policy.$error }"
      />
      <span class="ml-2">
        I read and agree to the
        <template v-if="showCancellationPolicy">
          <a
            data-cy="cancellation-policy-link"
            class="api-link"
            :href="cancellationPolicyLink"
            target="_blank"
          >
            Cancellation Policy
          </a>
          and
        </template>
        <a
          class="api-link"
          href="#"
          data-modal="Terms of Use"
          tabindex="0"
          @click.prevent="toggle('Terms of Use')"
          >Terms of Use</a
        ></span
      >
    </label>
    <div
      v-if="v$.value.policy.sameAs.$invalid"
      class="error text-error-900"
      :class="{ hidden: !v$.value.policy.$error }"
    >
      Field is Required
    </div>

    <TermsOfUse v-if="termsOfUseModal" @close="termsOfUseModal = false" />
  </div>
</template>

<script>
import TermsOfUse from "../../modals/TermsOfUse";
import billingRules from "../../../mixins/billingRules";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";

const validateChecked = (value) => {
  return value === true;
};
export default {
  name: "CancellationPolicyCheckbox",
  components: { TermsOfUse },
  mixins: [billingRules],
  props: {
    cancellationPolicy: {
      type: Object,
      default: function () {
        return {};
      },
    },
    applicationFee: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:cancellation-policy"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      termsOfUseModal: false,
      value: {
        policy: false,
      },
    };
  },
  computed: {
    ...mapState(["program"]),
    cancellationPolicyType() {
      return this.program.cancellation_policy_type
        ? this.program.cancellation_policy_type
        : "";
    },
    cancellationPolicyLink() {
      let link =
        this.cancellationPolicyType === "Customized Student Cancellation Policy"
          ? this.program.link_to_custom_cancelation_policy
          : "https://apiabroad.com/withdrawal-policy-safeguards";

      if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
        return link;
      } else {
        return "https://" + link;
      }
    },
    showCancellationPolicy() {
      // hide if custom student policy but no link supplied
      if (
        this.cancellationPolicyType ===
          "Customized Student Cancellation Policy" &&
        !this.program.link_to_custom_cancelation_policy
      ) {
        return false;
      }
      if (
        this.cancellationPolicyType !==
        "Customized University Cancellation Policy"
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:cancellation-policy", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggle(modalName) {
      switch (modalName) {
        case "Terms of Use":
          this.termsOfUseModal = true;
      }
    },
  },
  validations: {
    value: {
      policy: {
        sameAs: validateChecked,
      },
    },
  },
};
</script>

<style></style>

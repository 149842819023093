<template>
  <FormPanel
    :id="'accommodationStylePreference'"
    :title="'Accommodation Style Preference'"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <div data-fancy-radio="bg-blue-100" class="space-y-4">
        <template
          v-for="preferenceOption in preferenceOptions"
          :key="preferenceOption.value"
        >
          <label :for="preferenceOption.value" class="flex md:items-center">
            <input
              :id="preferenceOption.value"
              v-model="v$.value.option.$model"
              type="radio"
              name="accommodation-style-pref"
              :value="preferenceOption.value"
              class="mt-1 text-blue-700 form-radio"
              :class="{ 'bg-error-100': v$.value.option.$error }"
            />
            <div class="flex-1 ml-4">
              <div
                data-fancy-radio-obj
                class="grid gap-y-4 gap-x-4 p-5 transition-colors duration-150 ease-linear border rounded grid-cols-60-1fr md:gap-x-8 md:gap-y-4 md:grid-cols-80-1fr"
              >
                <div
                  class="flex col-span-1 row-span-1 md:items-center md:row-span-2"
                >
                  <img
                    :src="'/images/' + preferenceOption.icon + '.svg'"
                    class="w-full"
                    alt=""
                  />
                </div>

                <div class="flex items-center row-span-1">
                  <div class="flex items-center justify-between sm:flex-auto">
                    <h4
                      class="text-gray-700 text-base font-semibold md:text-xl"
                    >
                      {{ preferenceOption.label }}
                    </h4>
                  </div>
                  <div
                    v-if="preferenceOption.pillLabel"
                    class="flex flex-col md:flex-row md:justify-end md:items-center"
                  >
                    <span
                      class="bg-help-warning px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-nowrap rounded-full text-1xs"
                    >
                      {{ preferenceOption.pillLabel }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-span-2 text-sm text-gray-600 md:col-span-1 md:text-base"
                >
                  <p>{{ preferenceOption.description }}</p>
                  <div
                    v-if="preferenceOption.greatFor"
                    class="items-baseline mt-4 md:flex"
                  >
                    <h5
                      class="text-xs font-semibold tracking-widest text-gray-500 uppercase md:mr-4"
                    >
                      Great For:
                    </h5>
                    <p class="text-gray-600">{{ preferenceOption.greatFor }}</p>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </template>
        <div
          v-if="v$.value.option.required.$invalid && v$.value.option.$error"
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </div>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import housingForm from "../../../mixins/housingForm.js";

export default {
  name: "AccommodationStylePreference",
  mixins: [housingForm],
  props: {
    preference: {
      type: Object,
      default: function () {
        return {};
      },
    },
    preferenceOptions: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
  },
  emits: ["update:preference"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        option: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:preference", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      option: {
        required: requiredIf(function () {
          return this.preferenceOptions.length > 0;
        }),
      },
    },
  },
};
</script>

<template>
  <div id="validations-container" data-cy="validations-container" class="space-y-5">
    <p class="text-gray-800">
      <span class="font-semibold text-gray-650">Validation result of</span>
      {{ fileName }}
    </p>

    <Container>
      <slot>
        <div class="text-gray-800 space-y-3">
          <p>
            <img src="/images/icon-completed.svg" class="inline mr-3" />
            {{ passed }} students passed validation.
          </p>
          <template v-if="failed">
            <p>
              <img src="/images/icon-warning.svg" class="inline mr-3" />
              {{ failed }} students have 1 or more required fields with missing
              or invalid data
            </p>

            <ul class="pl-8 space-y-3">
              <li v-for="record in failedRecords" :key="record.line">
                Line {{ record.line }}:
                <span class="font-semibold">{{ record.name }}</span
                >:
                <template v-if="record.missing.length">
                  <span
                    v-for="(missing, index) in record.missing"
                    :key="record.line + 'missing' + missing"
                    class="font-semibold"
                  >
                    {{
                      transformString(
                        missing,
                        index !== record.missing.length - 1
                      )
                    }}
                  </span>
                  missing
                </template>
                {{
                  record.missing.length && record.invalid.length ? "and" : ""
                }}
                <template v-if="record.invalid.length">
                  <span
                    v-for="(invalid, index) in record.invalid"
                    :key="record.line + 'invalid' + invalid"
                    class="font-semibold"
                  >
                    {{
                      transformString(
                        invalid,
                        index !== record.invalid.length - 1
                      )
                    }}
                  </span>
                  invalid
                </template>
              </li>
            </ul>
          </template>
        </div>
      </slot>
    </Container>

    <p v-if="failed" class="text-gray-800 mb-10">
      You may want to fix the missing or invalid data in your CSV file and
      upload again. Thank you!
    </p>

    <FormPanel
      id="formatRulesPanel"
      title="See Data Format Rules"
      class="mb-6"
      panel-content-class="text-gray-600 border-gray-200"
      :initially-expanded="false"
    >
      <template #content>
        <Container>
          <slot>
            <p class="font-semibold text-gray-800">
              <img src="/images/icon-warning.svg" class="inline mr-3" />
              Important Reminder on Data Format
            </p>
            <ul class="pl-8 space-y-3 mt-3 text-gray-800">
              <li>Birth Day: MM/DD/YYYY (e.g., 12/04/2002)</li>
              <li>
                Phone: +CountryCode-AreaCode-XXXXXXX (If CountryCode is not
                provided, we assume it's United States. +1-512-5555555 is same
                as 512-5555555)
              </li>
              <li>Gender: Allowed values are Male, Female, or Non-binary</li>
              <li>
                Citizenship: Allowed values are here
                <a
                  class="text-teal-500"
                  href="https://support.apiabroad.com/hc/en-us/articles/6149130150295-List-of-Countries"
                  target="_blank"
                >
                  here
                </a>
              </li>
            </ul>
          </slot>
        </Container>
      </template>
    </FormPanel>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
      <ButtonWithSpinner
        ref="cancelBtn"
        data-cy="cancel-btn"
        type="button"
        variant="secondary-outlined"
        variant-type="normal"
        @click="$emit('cancel-btn')"
      >
        <span>Cancel </span>
      </ButtonWithSpinner>

      <ButtonWithSpinner
        ref="uploadAgainBtn"
        data-cy="upload-again-btn"
        type="button"
        variant="secondary"
        variant-type="block"
        @click="$emit('upload-again-btn')"
      >
        <span>Upload Again</span>
      </ButtonWithSpinner>

      <ButtonWithSpinner
        ref="proceedBtn"
        data-cy="proceed-btn"
        :disabled="disableBtn"
        type="submit"
        variant="primary"
        variant-type="block"
        class="col-span-1 md:col-span-2"
        @click="$emit('next-btn')"
      >
        <span>Proceed to Add {{ total }} Students</span>
      </ButtonWithSpinner>
    </div>
  </div>
</template>

<script>
import Container from "@/components/helpers/GeneralContainer.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";

export default {
  name: "Validation",
  components: {
    Container,
    FormPanel,
    ButtonWithSpinner,
  },
  props: {
    file: {
      type: Object,
      default() {
        return {};
      },
    },
    validationData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["cancel-btn", "next-btn", "upload-again-btn"],
  computed: {
    fileName() {
      if (Object.keys(this.file).length) return this.file.originalName;
      else return "";
    },
    isValidProp() {
      return Boolean(
        this.validationData && this.validationData.validation_report
      );
    },
    passed() {
      return this.isValidProp
        ? this.validationData.validation_report.passed ?? 0
        : 0;
    },
    failed() {
      return this.isValidProp
        ? this.validationData.validation_report.failed ?? 0
        : 0;
    },
    total() {
      return this.isValidProp
        ? this.validationData.validation_report.total ?? 0
        : 0;
    },
    failedRecords() {
      return this.isValidProp
        ? this.validationData.validation_report.failedRecords ?? []
        : [];
    },
    disableBtn() {
      return this.passed === 0 ? true : false;
    },
  },
  methods: {
    transformString(name, addComma) {
      let newName = name.replace(/_/g, " ").toLowerCase();
      const comma = addComma ? "," : "";

      if (newName === "phone") return "Phone Number" + comma;
      return newName.replace(/\b\w/g, (letter) => letter.toUpperCase()) + comma;
    },
  },
};
</script>

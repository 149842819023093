<template>
  <div
    class="w-full px-4 pb-4 mx-auto bg-white applications max-w-7xl sm:px-8 sm:pb-8"
  >
    <section class="max-w-md mx-auto md:max-w-2xl">
      <header
        class="pt-6 mb-10 text-center"
        aria-labelledby="applicationstatus"
      >
        <img
          src="../../images/logo-api-connect.png"
          class="h-12 mx-auto mb-6 md:h-20"
          alt="API"
        />
        <h1 id="applicationstatus" class="text-lg leading-tight md:text-3xl">
          Application Recieved
        </h1>
      </header>

      <div class="mb-10 text-gray-600 space-y-6">
        <p id="app-student-name" class="uppercase">STUDENT NAME HERE-</p>
        <p>
          Thank you for your application to study abroad in
          <span>{{ getCity }} </span>,
          <span id="app-student-firstname"> [first name]</span>!
        </p>
        <p>
          Our team is now reviewing your application. Once your application
          status changes, you will receive an email from us to learn more about
          the next steps toward your API International Experience.
        </p>
        <p>
          Please do not hesitate to reach out to me if you have any questions
          about your application or the program you have selected. My contact
          information is below. I look forward to hearing from you and
          supporting you as you prepare for your experience with API!
        </p>
      </div>
      <div class="flex md:items-center" id="signature">
        <div class="flex-initial">
          <div class="font-semibold" id="coordinator-name">
            {{ getManagerName }}
          </div>
          <div class="flex flex-col items-baseline mt-4 md:flex-row">
            <span
              class="mr-6 text-xs font-semibold tracking-widest text-gray-500 uppercase"
            >
              Email
            </span>
            <span>
              <a
                id="coordinator-email"
                class="font-normal text-gray-700 break-all hover:underline hover:text-gray-700"
                :href="'mailto:' + getEmail"
              >
                {{ getEmail }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

//
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      city: "",
    };
  },
  computed: {
    ...mapState(["formioToken", "program"]),
    getCity() {
      return this.program.site ? this.program.site.city : "";
    },
    getManagerName() {
      return this.program.program_manager &&
        this.program.program_manager.length > 1
        ? this.program.program_manager
        : "API Enrollment Management Team";
    },
    getEmail() {
      return this.program.program_manager_email &&
        this.program.program_manager_email.length > 1
        ? this.program.program_manager_email
        : "enrollment@apiabroad.com";
    },
    getFallbackEmailLabel() {
      return this.program.program_manager_email &&
        this.program.program_manager_email.length > 1
        ? this.program.program_manager_email
        : "Enrollment Management <enrollment@apiabroad.com>";
    },
  },
  mounted() {
    this.setStudentName();
  },
  methods: {
    setStudentName() {
      //get first and lastname from token
      if (
        typeof Storage !== "undefined" &&
        localStorage.getItem("formioUser") !== null
      ) {
        document.getElementById("app-student-name").textContent =
          JSON.parse(localStorage.getItem("formioUser")).data.firstname +
          " " +
          JSON.parse(localStorage.getItem("formioUser")).data.lastname;
        document.getElementById(
          "app-student-firstname"
        ).textContent = JSON.parse(
          localStorage.getItem("formioUser")
        ).data.firstname;
      }
    },
  },
};
</script>

<template>
  <FormPanel
    :id="'housingQuestionnaire'"
    :title="'Housing Questionnaire'"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p>
        Our accommodations are coordinated based on our availability, first come
        first serve basis, and your preferences. Tell us more about you and your
        habits.
      </p>
      <ol class="pl-4 mt-6 list-decimal space-y-4">
        <template v-for="question in QUESTIONS" :key="question.model">
          <li
            v-if="
              question.show ||
              (question.model === 'abroadBeforeText' &&
                value.abroadBefore === 'yes')
            "
          >
            <legend v-if="question.type !== 'textarea'">
              {{ question.question }}
            </legend>
            <fieldset v-if="question.type !== 'textarea'">
              <template v-if="question.type === 'radio'">
                <label
                  v-for="option in question.options"
                  :key="question.model + option.value"
                  class="flex"
                  :for="question.model + option.value"
                >
                  <input
                    :id="question.model + option.value"
                    v-model="v$.value[question.model].$model"
                    type="radio"
                    :name="question.model"
                    :value="option.value"
                    class="mt-1 text-blue-700 form-radio"
                    :class="{
                      'bg-error-100': v$.value[question.model].$error,
                    }"
                  />
                  <span class="ml-2">{{ option.label }}</span>
                </label>
              </template>
              <div
                v-for="option in question.options"
                v-else-if="question.type === 'checkbox'"
                :key="option.model"
              >
                <label class="inline-flex" :for="option.model">
                  <input
                    :id="option.model"
                    v-model="v$.value[option.model].$model"
                    type="checkbox"
                    class="mt-1 text-blue-700 form-checkbox"
                  />
                  <span class="ml-2">{{ option.label }}</span>
                  <Tooltip
                    v-if="option.tooltip"
                    class="pl-1"
                    :tooltip="option.tooltip"
                  />
                </label>
              </div>

              <div
                v-if="
                  question.type === 'radio' &&
                  v$.value[question.model].required.$invalid &&
                  v$.value[question.model].$error
                "
                class="error text-error-900 text-sm"
              >
                Field is required
              </div>
            </fieldset>
            <template v-else-if="question.type === 'textarea'">
              <ApiTextArea
                :ref="question.model"
                :name="question.model"
                :label="question.question"
                :max-length="500"
                :validation-errors="v$.value[question.model]"
                :typed-text="formioData[question.model]"
                custom-error-class="text-sm"
                @textUpdate="onFieldChange(question.model, $event)"
              />
            </template>
          </li>
        </template>
      </ol>
    </template>
  </FormPanel>
</template>

<script>
import Tooltip from "../SharedComponents/Tooltip.vue";
import ApiTextArea from "../SharedComponents/ApiTextArea.vue";
import { required, requiredIf } from "@vuelidate/validators";
import housingForm from "../../../mixins/housingForm.js";
import useVuelidate from "@vuelidate/core";

const QUESTIONS = [
  {
    question: "I would describe my morning routine as…",
    model: "morningRoutine",
    type: "radio",
    options: [
      {
        label: "Early bird! I get up early.",
        value: "earlyBirdIGetUpEarly",
      },
      {
        label: "I like to sleep in when possible.",
        value: "iLikeToSleepInWhenPossible",
      },
    ],
    show: true,
  },
  {
    question: "I would describe my nighttime routine as…",
    model: "nighttimeRoutine",
    type: "radio",
    options: [
      {
        label: "I’m a night owl, I stay up late.",
        value: "imANightOwlIStayUpLate",
      },
      {
        label: "I go to bed early.",
        value: "iGoToBedEarly",
      },
    ],
    show: true,
  },
  {
    question: "When working or studying, I typically do best with…",
    model: "noisePreference",
    type: "radio",
    options: [
      {
        label: "General quiet",
        value: "generalQuiet",
      },
      {
        label: "Some music or ambient noise",
        value: "someMusicOrAmbientNoise",
      },
      {
        label: "Constant noise and activity",
        value: "constantNoiseAndActivity",
      },
    ],
    show: true,
  },
  {
    question: "Past roommates or housemates might say I am…",
    model: "cleanliness",
    type: "radio",
    options: [
      {
        label: "So neat and tidy!",
        value: "soNeatAndTidy",
      },
      {
        label: "Generally organized",
        value: "generallyOrganized",
      },
      {
        label: "More messy than neat",
        value: "moreMessyThanNeat",
      },
    ],
    show: true,
  },
  {
    question: "I tend to socialize outside my living space…",
    model: "socialize",
    type: "radio",
    options: [
      {
        label: "I’m a homebody.",
        value: "imAHomebody",
      },
      {
        label: "1-2 nights a week",
        value: "12NightsAWeek",
      },
      {
        label: "3-4 nights a week",
        value: "34NightsAWeek",
      },
      {
        label: "5+ nights a week",
        value: "5NightsAWeek",
      },
    ],
    show: true,
  },
  {
    question: "Do you smoke?",
    model: "smoke",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
    show: true,
  },
  {
    question: "I am comfortable in… (please check all that apply)",
    type: "checkbox",
    options: [
      {
        model: "singleGenderHousing",
        label: "Single gender housing arrangements",
        tooltip:
          "All direct roommates would be of the same gender, and all flatmates as well for those in apartments. Bathrooms would be shared only by individuals of the same gender",
      },
      {
        model: "mixedGenderCoEdHousing",
        label: "Mixed gender / co-ed housing",
        tooltip:
          "Individuals of different genders could share bathroom and living space in an apartment, but bedrooms would be only shared by two individuals of the same gender. Mixed gender housing is not intended for romantic couples.",
      },
      {
        model: "genderInclusiveHousing",
        label: "Gender inclusive housing arrangements",
        tooltip:
          "This is an option that is affirming and supportive of LGBTQIA+, transgender, gender non-conforming, non-binary, and ally students. Housing applicants who opt into gender-inclusive housing will live with other housing applicants who have also opted into gender-inclusive housing, regardless of biological sex or gender identity/expression. This housing will be assigned without considering gender as a factor. Students are placed by other compatibility factors in shared bathrooms and/or bedrooms. Gender-inclusive housing is not intended for romantic couples.",
      },
    ],
    show: true,
  },
  {
    question: "Have you lived, studied or traveled abroad before?",
    model: "abroadBefore",
    type: "radio",
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
    show: true,
  },
  {
    question: "If so, when and where and for how long?",
    type: "textarea",
    model: "abroadBeforeText",
    show: false,
  },
  {
    question:
      "Do you have any housing concerns or special requests? API will make every effort to accommodate your preferences but special requests cannot be guaranteed.",
    type: "textarea",
    model: "specialRequests",
    show: true,
  },
];

export default {
  name: "HousingQuestionnaire",
  components: {
    ApiTextArea,
    Tooltip,
  },
  mixins: [housingForm],
  props: {
    questionnaire: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update-questionnaire"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        morningRoutine: "",
        nighttimeRoutine: "",
        noisePreference: "",
        cleanliness: "",
        socialize: "",
        smoke: "",
        singleGenderHousing: false,
        mixedGenderCoEdHousing: false,
        genderInclusiveHousing: false,
        abroadBefore: "",
        abroadBeforeText: "",
        specialRequests: "",
      },
    };
  },
  validations: {
    value: {
      morningRoutine: { required },
      nighttimeRoutine: { required },
      noisePreference: { required },
      cleanliness: { required },
      socialize: { required },
      smoke: { required },
      singleGenderHousing: {},
      mixedGenderCoEdHousing: {},
      genderInclusiveHousing: {},
      abroadBefore: { required },
      abroadBeforeText: {
        required: requiredIf(function () {
          return this.value.abroadBefore === "yes";
        }),
      },
      specialRequests: { required },
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update-questionnaire", this.value);
      },
      deep: true,
      immediate: true,
    },
    disabledPanel: {
      handler: function (val) {
        if (val) {
          //Clear all textareas if panel is disabled
          for (const ref in this.$refs) {
            this.$refs[ref][0].clear();
          }
        }
      },
    },
    "value.abroadBefore": {
      handler: function (val) {
        if (val === "no") this.value.abroadBeforeText = "";
      },
    },
  },
  created() {
    this.QUESTIONS = QUESTIONS;
  },
  methods: {
    onFieldChange(field, value) {
      this.value[field] = value;
    },
  },
};
</script>

import { trailingSlash } from "@/mixins/helpers";

const countHelper = require("@/university/countFunctions.js");

const university = {
  namespaced: true,
  state: {
    userDetails: {
      email: "",
      name: "",
      university: "",
      universityId: "",
      loggedIn: false,
      dynamicUniversity: false,
    },
    menu: false,
    tabProgramSessionId: "",
    tabs: {
      list: [
        {
          title: "Prospective",
          count: 0,
          componentName: "prospective",
          handle: "prospective",
          hide: false,
          active: true,
        },
        {
          title: "Application",
          count: 0,
          componentName: "application",
          handle: "application",
          hide: false,
          active: true,
        },
        {
          title: "Accepted",
          count: 0,
          componentName: "accepted",
          handle: "accepted",
          hide: false,
          active: true,
        },
        {
          title: "Active Participants",
          count: 0,
          componentName: "onsite",
          handle: "onsite",
          hide: false,
          active: true,
        },
        {
          title: "Alumni",
          count: 0,
          componentName: "alumni",
          handle: "alumni",
          hide: false,
          active: true,
        },
        {
          title: "Withdrawn",
          count: 0,
          componentName: "withdrawn",
          handle: "withdrawn",
          hide: false,
          active: true,
        },
        {
          title: "All",
          count: 0,
          componentName: "all",
          handle: "all",
          hide: true,
          active: false,
        },
      ],
      currentTab: 0, // tab index
      lastTabUrl: "", // pagination
      lastInput: "", // text search
      lastFilters: {
        city: "",
        sessions: [],
        majors: [],
        approvalStatus: "",
      },
      prospectiveTab: {
        type: "", // prospective tab status
        timeframe: "", // prospective tab timeframe
        major: "", // prospective ta
        dateFrom: "",
        dateTo: "",
      },
      appliedOrder: {},
    },
    formModalDesign: {
      open: false,
      selected: {},
    },
    selectedApplicationId: "",
  },
  getters: {
    getUserName(state) {
      return state?.userDetails?.name ?? "Unknown";
    },
    getUniversity(state) {
      return state.advisor.university;
    },
    isMenuOpen(state) {
      return state.menu;
    },
    getToken(state) {
      return state.advisor.token;
    },
    getTabProgramSessionId(state) {
      return state.tabProgramSessionId;
    },
    getAdvisorId(state) {
      return state.advisor.user._id;
    },
    tabNamesAndCounts(state) {
      return state.tabs.list;
    },
    currentTab(state) {
      return state.tabs.currentTab;
    },
    currentTabComponent(state) {
      return state.tabs.list[state.tabs.currentTab];
    },
    lastViewedTabUrl(state) {
      return state.tabs.lastTabUrl;
    },
    getLastSearchInput(state) {
      return state.tabs.lastInput;
    },
    getProspectiveType(state) {
      return state.tabs.prospectiveTab.type;
    },
    getProspectiveTimeframe(state) {
      return state.tabs.prospectiveTab.timeframe;
    },
    getProspectiveMajor(state) {
      return state.tabs.prospectiveTab.major;
    },
    getProspectiveFrom(state) {
      return state.tabs.prospectiveTab.dateFrom;
    },
    getProspectiveTo(state) {
      return state.tabs.prospectiveTab.dateTo;
    },
    getLastFilters(state) {
      return state.tabs.lastFilters;
    },
    formDesignModal(state) {
      return state.formModalDesign.open;
    },
    formDesignSelected(state) {
      return state.formModalDesign.selected;
    },
    applicationId(state) {
      return state.selectedApplicationId;
    },
    currentOrderIndex(state) {
      return Object.hasOwnProperty.call(state.tabs.appliedOrder, "column")
        ? state.tabs.appliedOrder.column
        : undefined;
    },
    currentOrder(state) {
      return state.tabs.appliedOrder;
    },
    dynamicUniversity(state) {
      return Boolean(state.userDetails && state.userDetails.dynamicUniversity);
    },
  },
  mutations: {
    setUniversity(state, university) {
      localStorage.setItem("formioUniversity", JSON.stringify(university));
      state.userDetails.university = university;
    },
    setUserDetails(state, payload) {
      state.userDetails = payload;
      localStorage.setItem("userDetails", JSON.stringify(payload));
    },
    logout(state) {
      state.userDetails = {
        email: "",
        name: "",
        university: "",
        universityId: "",
        loggedIn: false,
        dynamicUniversity: false,
      };
      state.tabs.currentTab = 0;
      state.tabs.lastTabUrl = "";
      state.tabs.lastInput = "";
      state.tabs.prospectiveTab.type = "";
      state.tabs.prospectiveTab.timeframe = "";
      state.tabs.prospectiveTab.major = "";
      state.tabs.prospectiveTab.dateFrom = "";
      state.tabs.prospectiveTab.dateTo = "";

      state.tabs.lastFilters.city = "";
      state.tabs.lastFilters.sessions = [];
      state.tabs.lastFilters.majors = [];
      state.tabs.lastFilters.approvalStatus = "";
      state.tabs.appliedOrder = {};

      localStorage.removeItem("formioUniversity");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("tabIndex");
      localStorage.removeItem("tabUrl");
      localStorage.removeItem("tabInput");
      localStorage.removeItem("prospectiveType");
      localStorage.removeItem("prospectiveTimeframe");
      localStorage.removeItem("prospectiveMajor");
      localStorage.removeItem("prospectiveFrom");
      localStorage.removeItem("prospectiveTo");

      localStorage.removeItem("tabProgramSessionId");
      localStorage.removeItem("application_id");
      localStorage.removeItem("tabsFilters");
      localStorage.removeItem("tabOrders");
    },
    closeBothMenus(state) {
      state.menu = false;
    },
    toggleMenu(state) {
      state.menu = !state.menu;
    },
    setTabProgramSessionId(state, sessionId) {
      state.tabProgramSessionId = sessionId;
      localStorage.setItem("tabProgramSessionId", JSON.stringify(sessionId));
    },
    setAllUniversities(state, university) {
      state.userDetails.university = university.selected.label;
      state.userDetails.universityId = university.selected.value;

      localStorage.setItem(
        "formioUniversity",
        JSON.stringify(university.object)
      );
      localStorage.setItem("userDetails", JSON.stringify(state.userDetails));
    },
    setCurrentTab(state, payload) {
      // payload: {index: <Number>, clear: <Boolean>}
      state.tabs.currentTab = payload.index;

      localStorage.setItem("tabIndex", payload.index);

      if (payload.clean) {
        //clears latest pagination url
        state.tabs.lastTabUrl = "";
        localStorage.setItem("tabUrl", "");
      }
    },
    setLastViewedPage(state, paginationUrl) {
      state.tabs.lastTabUrl = paginationUrl;
      localStorage.setItem("tabUrl", paginationUrl);
    },
    setLastSearchInput(state, inputText) {
      state.tabs.lastInput = inputText;
      localStorage.setItem("tabInput", inputText);
    },
    setProspectiveType(state, type) {
      state.tabs.prospectiveTab.type = type;
      localStorage.setItem("prospectiveType", type);
    },
    setProspectiveTimeframe(state, timeframe) {
      state.tabs.prospectiveTab.timeframe = timeframe;
      localStorage.setItem("prospectiveTimeframe", timeframe);
    },
    setProspectiveMajor(state, major) {
      state.tabs.prospectiveTab.major = major;
      localStorage.setItem("prospectiveMajor", major);
    },
    setProspectiveFrom(state, from) {
      state.tabs.prospectiveTab.dateFrom = from;
      localStorage.setItem("prospectiveFrom", from);
    },
    setProspectiveTo(state, to) {
      state.tabs.prospectiveTab.dateTo = to;
      localStorage.setItem("prospectiveTo", to);
    },
    setLastFilters(state, filters) {
      state.tabs.lastFilters = Object.assign({}, filters);
      localStorage.setItem("tabsFilters", JSON.stringify(filters));
    },
    updateTabCount(state, tabData) {
      state.tabs.list[tabData["index"]]["count"] = tabData.count;
    },
    updateFormDesignModalOpen(state, value) {
      let formOpen = typeof value === "boolean" ? value : false;
      state.formModalDesign.open = formOpen;
    },
    updateFormDesignSelected(state, value) {
      state.formModalDesign.selected = value;
    },
    setApplicationId(state, value) {
      state.selectedApplicationId = value;
      localStorage.setItem("application_id", value);
    },
    setOrder(state, value) {
      state.tabs.appliedOrder = value;
      // Update or Remove localstorage
      if (Object.keys(value).length === 0 && value.constructor === Object) {
        localStorage.removeItem("tabOrders");
      } else {
        localStorage.setItem("tabOrders", JSON.stringify(value));
      }
    },
  },
  actions: {
    alterOrder({ commit }, data) {
      if (data["remove"] === true) {
        commit("setOrder", {});
        return;
      }
      commit("setOrder", {
        column: data["column"],
        order: data["order"],
        name: data["name"],
      });
    },
    resetCounts({ commit, state }) {
      let i = 0;
      for (i; i < state.tabs.list.length; i++) {
        commit("updateTabCount", {
          index: i,
          count: 0,
        });
      }
    },
    updateCounts({ commit, state }, data) {
      // update each tab count if its different from the response
      const { totals, pagination } = data;
      let i = 0;
      for (i; i < state.tabs.list.length; i++) {
        let tabName = state.tabs.list[i]["handle"];
        let currentTabTotal = state.tabs.list[i]["count"];
        let responseTotal = 0;
        const currentTabIndex = parseInt(state.tabs.currentTab);
        /**
         * AP-532 Counter should respond to searches and filtering
         */
        if (currentTabIndex === i) {
          responseTotal = pagination.total;
        } else {
          switch (tabName) {
            case "prospective":
              responseTotal = countHelper.countProspective(totals);
              break;
            case "application":
              responseTotal = countHelper.countApplication(totals);
              break;
            case "accepted":
              responseTotal = countHelper.countAccepted(totals);
              break;
            case "onsite":
              responseTotal = countHelper.countOnSite(totals);
              break;
            case "alumni":
              responseTotal = countHelper.countAlumni(totals);
              break;
            case "withdrawn":
              responseTotal = countHelper.countWithdrawn(totals);
              break;
            case "all":
              responseTotal = countHelper.countAll(totals);
              break;
          }
        }
        if (responseTotal !== currentTabTotal) {
          commit("updateTabCount", {
            index: i,
            count: responseTotal,
          });
        }
      }
    },
    async updateUniversity({ commit }, ope8_id) {
      let response = await fetch(
        trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "university/" +
          ope8_id,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      let university = null;
      if (response.status == 200) {
        university = await response.json();
      }

      commit("setUniversity", university);
    },
  },
};

export default university;

import client from "./API";

const queryString = function (query) {
  if (typeof query === "string") {
    return query.replace("?", "");
  }
  return Object.entries(query)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
};

const formService = {
  async currentFormServiceUser() {
    const response = await client.get("/forms/current");
    let user = response.data;
    user["x-jwt-token"] = response.headers["x-jwt-token"];
    return user;
  },

  async getSchema(form) {
    return (await client.get(`/forms/${form}`)).data;
  },

  async getSubmission(form, id) {
    return (await client.get(`/forms/${form}/submission/${id}`)).data;
  },

  async updateSubmission(form, id, payload) {
    return (await client.put(`/forms/${form}/submission/${id}`, payload)).data;
  },

  async partiallyUpdateSubmission(form, id, payload) {
    return (await client.patch(`/forms/${form}/submission/${id}`, payload))
      .data;
  },

  async listSubmissions(form, query = { limit: 200, sort: "-created" }) {
    return (await client.get(`/forms/${form}/submission?${queryString(query)}`))
      .data;
  },

  async submissionExists(form, query) {
    return (await client.get(`/forms/${form}/exists?${queryString(query)}`))
      .data;
  },

  async validateSubmission(form, payload) {
    return (await client.post(`/forms/${form}/validate`, payload)).data;
  },

  async createSubmission(form, payload) {
    return (await client.post(`/forms/${form}/submission`, payload)).data;
  },

  async getSubmissionReport(payload) {
    return (await client.post("/forms/submission-report", payload)).data;
  },

  async createOrUpdateSubmission(form, payload, id) {
    if (id) {
      return this.updateSubmission(form, id, payload);
    }

    return this.createSubmission(form, payload);
  },
};

export default formService;

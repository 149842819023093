<template>
  <a
    role="link"
    tabindex="0"
    :aria-label="stepLabel"
    class="step-current flex-none px-1 relative"
  >
    <div
      class="flex items-center justify-center h-8 w-8 bg-white border-2 border-blue-700 rounded-full"
    >
      <div class="h-4 w-4 bg-blue-700 rounded-full" />
    </div>
    <span
      class="text-xs mt-2 leading-tight font-semibold whitespace-nowrap block w-auto md:w-30 lg:w-48 absolute top-full truncate text-center right-1/2 transform translate-x-1/2"
      >{{ name }}</span
    >
  </a>
</template>

<script>
import titlesMixin from "../../mixins/pageTitles";
export default {
  mixins: [titlesMixin],
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    stepLabel() {
      return "Current step " + this.name;
    },
  },
  watch: {
    name: {
      handler: function (newVal) {
        this.setFormTitle(newVal);
      },
      immediate: true,
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="relative">
    <div class="relative">
      <slot name="dropdown-trigger">
        <button
          @click="openOrClose"
          :aria-controls="getId"
          :aria-expanded="openDropdown.toString()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-more-horizontal"
          >
            <circle cx="12" cy="12" r="1"></circle>
            <circle cx="19" cy="12" r="1"></circle>
            <circle cx="5" cy="12" r="1"></circle>
          </svg>
        </button>
      </slot>
    </div>
    <button
      v-if="openDropdown"
      @click="closeDropdown"
      class="fixed h-full w-full inset-x-0 inset-y-0 cursor-default z-50"
      tabindex="-1"
    ></button>
    <div
      v-if="openDropdown"
      class="absolute right-0 z-50 w-48 bg-white shadow-lg menu top-full"
      :id="getId"
    >
      <ul class="py-2 text-sm">
        <slot name="dropdown-options" />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownComponent",
  props: {
    row: {
      type: Number,
      default: 0,
    },
    tabName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openDropdown: false,
    };
  },
  computed: {
    getId() {
      return this.tabName + "-actions-row-" + this.row;
    },
  },
  created() {
    document.addEventListener("keydown", this.escapeFn);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.escapeFn);
  },
  methods: {
    escapeFn(e) {
      if (e.key === "Esc" || e.key === "Escape") {
        this.openDropdown = false;
      }
    },
    openOrClose() {
      this.openDropdown = !this.openDropdown;
    },
    closeDropdown() {
      this.openDropdown = false;
    },
  },
};
</script>

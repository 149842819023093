<template>
  <FormPanel
    :id="'visaAppointmentInformationOnline'"
    :title="'Visa Appointment Information'"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <form
        id="visa-appointment-online-form"
        class="mb-6"
        action=""
        aria-label="Visa Appointment Online Information Form"
        @submit.prevent="submitForm()"
      >
        <p class="mb-6">
          You are required to obtain a student visa in order to study abroad. It
          is your responsibility as a participant in this program to obtain a
          student visa prior to your departure. Before you apply for this visa,
          you must have applied for and been accepted to study full-time at an
          educational institution abroad.
        </p>
        <label class="inline-flex">
          <input
            v-model="value.formSubmitted"
            type="checkbox"
            class="mt-1 text-blue-700 form-checkbox"
            :class="{ 'bg-error-100': v$.value.formSubmitted.$error }"
          />
          <span class="ml-2"> Check this off your to do list here.</span>
        </label>
        <div
          v-if="
            v$.value.formSubmitted.sameAs.$invalid &&
            v$.value.formSubmitted.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>

        <ButtonWithSpinner
          ref="onlineSubmit"
          class="mt-6"
          type="submit"
          variant="primary"
          variant-type="block"
          :disabled="v$.$invalid"
        >
          <span>
            Submit
            <svg
              v-if="successSubmission && !v$.$invalid"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="inline feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </span>
        </ButtonWithSpinner>
        <div v-if="submitError" class="error text-error-900 mt-2">
          {{ submitError }}
        </div>
        <div v-if="successSubmission && !v$.$invalid" class="text-success-900">
          Submission Complete, thank you!
        </div>
      </form>
    </template>
  </FormPanel>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";

import { sameAs } from "@vuelidate/validators";
import formIoApi from "../../../mixins/formIoApi.js";
import useVuelidate from "@vuelidate/core";

export default {
  name: "Online",
  components: {
    FormPanel,
    ButtonWithSpinner,
  },
  mixins: [formIoApi],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    formioURL: {
      type: String,
      default: "",
    },
    formURL: {
      type: String,
      default: "",
    },
    submissionId: {
      type: String,
      default: "",
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["updatedSubmission"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        formSubmitted: false,
      },
      successSubmission: false,
      submitError: "",
    };
  },
  validations: {
    value: {
      formSubmitted: { sameAs: sameAs(true) },
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.successSubmission = false;
      },
      deep: true,
    },
    formioData: {
      handler: function (val) {
        this.value.formSubmitted = val.onlineSubmitted ?? false;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submitForm() {
      this.submitError = "";
      this.successSubmission = false;
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.$refs.onlineSubmit.startLoading();
        if (this.submissionId) {
          this.updateSubmission();
        } else {
          this.createSubmission();
        }
      }
    },
    updateSubmission() {
      const jsonData = [
        {
          op: "add",
          path: "/data/onlineSubmitted",
          value: this.value.formSubmitted,
        },
      ];

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.onlineSubmit.stopLoading();
        });
    },
    createSubmission() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          onlineSubmitted: this.value.formSubmitted,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.$emit("updatedSubmission", response);
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.onlineSubmit.stopLoading();
        });
    },
  },
};
</script>

<template>
  <div>
    <label :class="labelClass">
      <span v-if="labelBeforeInput" :class="spanClass">
        {{ labelBeforeInput }}
      </span>
      <input
        v-model="inputValue"
        type="date"
        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
        placeholder="mm-dd-yyyy"
        :class="setStyleClass"
        :aria-labelledby="labelledby"
      />
      <span v-if="labelAfterInput" class="ml-2">{{ labelAfterInput }}</span>
    </label>
    <div v-for="(value, rule) in sortedValidationRules" :key="rule">
      <div
        v-if="validationErrors[rule].$invalid"
        class="error text-error-900"
        :class="{ hidden: !validationErrors.$error }"
      >
        {{ validationMessage[rule] }}
      </div>
    </div>
  </div>
</template>

<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";
import validationMessages from "../../../mixins/validationMessages";
import { isDateSupported } from "@/mixins/helpers";
import { format, isValid, parse } from "date-fns";

export default {
  mixins: [nestedModelComponent, validationMessages],
  props: {
    labelledby: {
      type: String,
      default: "",
    },
    labelBeforeInput: {
      type: String,
      default: "",
    },
    labelAfterInput: {
      type: String,
      default: "",
    },
    valueFromDatabase: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "flex items-center text-gray-600",
    },
    spanClass: {
      type: String,
      default: "mr-2",
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  computed: {
    setStyleClass() {
      let classes = "form-input min-h-10";
      if (this.customClass) {
        classes = this.customClass;
      }

      if (this.validationErrors.$error) {
        classes = classes + " bg-error-100";
      }

      return classes;
    },
  },
  watch: {
    inputValue: function (newVal) {
      let formatDate = "";

      if (isDateSupported()) {
        formatDate = newVal;
      } else {
        if (isValid(parse(newVal, "MM-dd-yyyy", new Date()))) {
          formatDate = format(
            parse(newVal, "MM-dd-yyyy", new Date()),
            "yyyy-MM-dd"
          );
        }
      }

      this.$emit("input", formatDate);
    },
    valueFromDatabase: function (newVal) {
      //the value for the input must be in the following format to function properly 'yyyy-MM-dd' or and empty string
      this.setDateFromDatabase(newVal);
    },
  },
  created() {
    if (this.valueFromDatabase) {
      //the value for the input must be in the following format to function properly 'yyyy-MM-dd' or and empty string
      this.setDateFromDatabase(this.valueFromDatabase);
    }
  },
  methods: {
    setDateFromDatabase(date) {
      if (date) {
        this.inputValue = isDateSupported()
          ? date
          : format(parse(date, "yyyy-MM-dd", new Date()), "MM-dd-yyyy");
      }
    },
  },
};
</script>

<template>
  <div>
    <fieldset>
      <legend>
        Do you have any allergies to medications or foods?
      </legend>
      <RadioButton
        name="allergies"
        aria-controls="allergies"
        :options="['yes', 'no']"
        :selected-option="selectedOption"
        :validation-errors="v$.value.selectedRadioMedAllergies"
        @input="onFieldChange('selectedRadioMedAllergies', $event)"
      />

      <div
        v-if="value.selectedRadioMedAllergies === 'yes'"
        id="allergies"
        :aria-expanded="value.selectedRadioMedAllergies === 'yes'"
      >
        <div class="grid grid-cols-1 gap-y-8 ml-6">
          <ApiTextArea
            name="allergies-textarea"
            label="If yes, specify the allergy and reaction you have."
            :max-length="500"
            :typed-text="typedText"
            :validation-errors="v$.value.medicationAllergicReaction"
            @textUpdate="onFieldChange('medicationAllergicReaction', $event)"
          />
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>
        Does your living space need to be completely free of the food you are
        allergic to?
      </legend>
      <RadioButton
        name="allergiesEnvironment"
        aria-controls="share-alergies"
        :options="['yes', 'no']"
        :selected-option="selectedOptionEnv"
        :validation-errors="v$.value.selectedRadioEnv"
        @input="onFieldChange('selectedRadioEnv', $event)"
      />

      <div
        v-if="value.selectedRadioEnv === 'yes'"
        id="share-alergies"
        :aria-expanded="value.selectedRadioEnv === 'yes'"
      >
        <p>
          If yes, API prefers to share allergy information with your roommates
          if you are placed in shared accommodations so that they can be asked
          to avoid bringing known allergens into shared spaces. Do you authorize
          us to make those notifications once housing assignments are
          finalized??
        </p>
        <RadioButton
          name="shareAllergies"
          :selected-option="selectedOptionShareAllergies"
          :options="['yes', 'no', 'Not Applicable/Not Necessary']"
          :validation-errors="v$.value.selectedRadioShareAllergies"
          @input="onFieldChange('selectedRadioShareAllergies', $event)"
        />
      </div>
      <p v-if="value.selectedRadio === 'yes'" class="italic">
        Note: API encourages all students with severe food allergies to carry an
        epipen at all times while abroad. Food labeling in other countries may
        not be as consistent or comprehensive as it is in the U.S. and may not
        be available in English. For any group dinners, API will make every
        effort to pre-arrange meals according to individual needs that are
        listed here.
      </p>
    </fieldset>
    <fieldset>
      <legend>
        Do you have any pet allergies?
      </legend>
      <RadioButton
        name="petAllergies"
        aria-controls="pet-alergies"
        :options="['yes', 'no']"
        :selected-option="selectedOptionPetAllergies"
        :validation-errors="v$.value.selectedRadioPetAllergies"
        @input="onFieldChange('selectedRadioPetAllergies', $event)"
      />

      <div
        v-if="value.selectedRadioPetAllergies === 'yes'"
        id="pet-allergies"
        :aria-expanded="value.selectedRadioPetAllergies === 'yes'"
      >
        <div class="grid grid-cols-1 gap-y-8 ml-6">
          <ApiTextArea
            name="pet-allergies-textarea"
            label="If yes, specify the allergy and reaction you have."
            :max-length="500"
            :typed-text="petAllergiesReactionFromDatabase"
            :validation-errors="v$.value.petAllergiesReaction"
            @textUpdate="onFieldChange('petAllergiesReaction', $event)"
          />
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>
        Are you allergic to smoke?
      </legend>
      <RadioButton
        name="smokeAllergies"
        aria-controls="smoke-alergies"
        :options="['yes', 'no']"
        :selected-option="selectedOptionSmokeAllergies"
        :validation-errors="v$.value.selectedRadioSmokeAllergies"
        @input="onFieldChange('selectedRadioSmokeAllergies', $event)"
      />

      <div
        v-if="value.selectedRadioSmokeAllergies === 'yes'"
        id="smoke-allergies"
        :aria-expanded="value.selectedRadioSmokeAllergies === 'yes'"
      >
        <div class="grid grid-cols-1 gap-y-8 ml-6">
          <ApiTextArea
            name="smoke-allergies-textarea"
            label="If yes, specify the allergy and reaction you have."
            :max-length="500"
            :typed-text="smokeAllergiesReactionFromDatabase"
            :validation-errors="v$.value.smokeAllergiesReaction"
            @textUpdate="onFieldChange('smokeAllergiesReaction', $event)"
          />
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>
        Do you have any other allergies that might affect your housing
        placement?
      </legend>
      <RadioButton
        name="otherAllergies"
        aria-controls="other-alergies"
        :options="['yes', 'no']"
        :selected-option="selectedOptionOtherAllergies"
        :validation-errors="v$.value.selectedRadioOtherAllergies"
        @input="onFieldChange('selectedRadioOtherAllergies', $event)"
      />

      <div
        v-if="value.selectedRadioOtherAllergies === 'yes'"
        id="other-allergies"
        :aria-expanded="value.selectedRadioOtherAllergies === 'yes'"
      >
        <div class="grid grid-cols-1 gap-y-8 ml-6">
          <ApiTextArea
            name="other-allergies-textarea"
            label="If yes, specify the allergy and reaction you have."
            :max-length="500"
            :typed-text="otherAllergiesReactionFromDatabase"
            :validation-errors="v$.value.otherAllergiesReaction"
            @textUpdate="onFieldChange('otherAllergiesReaction', $event)"
          />
          <p class="italic">
            API encourages all students with severe food allergies to carry an
            epipen at all times while abroad. Food labeling in other countries
            may not be as consistent or comprehensive as it is in the U.S. and
            may not be available in English. For any group dinners, API will
            make every effort to pre-arrange meals according to individual needs
            that are listed here.
          </p>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "Allergies",
  components: { RadioButton, ApiTextArea },
  mixins: [formInputs],
  props: {
    allergies: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selectedOptionEnv: {
      type: String,
      default: "",
    },
    selectedOptionPetAllergies: {
      type: String,
      default: "",
    },
    selectedOptionSmokeAllergies: {
      type: String,
      default: "",
    },
    selectedOptionOtherAllergies: {
      type: String,
      default: "",
    },
    selectedOptionShareAllergies: {
      type: String,
      default: "",
    },
    petAllergiesReactionFromDatabase: {
      type: String,
      default: "",
    },
    smokeAllergiesReactionFromDatabase: {
      type: String,
      default: "",
    },
    otherAllergiesReactionFromDatabase: {
      type: String,
      default: "",
    },
  },
  emits: ["update:allergies"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        selectedRadioMedAllergies: "",
        selectedRadioEnv: "",
        selectedRadioPetAllergies: "",
        selectedRadioSmokeAllergies: "",
        selectedRadioShareAllergies: "",
        selectedRadioOtherAllergies: "",
        petAllergiesReaction: "",
        smokeAllergiesReaction: "",
        otherAllergiesReaction: "",
        medicationAllergicReaction: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:allergies", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {},
  validations: {
    value: {
      selectedRadioMedAllergies: {
        required,
      },
      medicationAllergicReaction: {
        required: requiredIf(function () {
          return this.value.selectedRadioMedAllergies === "yes";
        }),
        maxLength: maxLength(500),
      },
      selectedRadioEnv: {
        required,
      },
      selectedRadioShareAllergies: {
        required: requiredIf(function () {
          return this.value.selectedRadioEnv === "yes";
        }),
      },
      selectedRadioPetAllergies: {
        required,
      },
      petAllergiesReaction: {
        required: requiredIf(function () {
          return this.value.selectedRadioPetAllergies === "yes";
        }),
      },
      selectedRadioSmokeAllergies: {
        required,
      },
      smokeAllergiesReaction: {
        required: requiredIf(function () {
          return this.value.selectedRadioSmokeAllergies === "yes";
        }),
      },
      selectedRadioOtherAllergies: {
        required,
      },
      otherAllergiesReaction: {
        required: requiredIf(function () {
          return this.value.selectedRadioOtherAllergies === "yes";
        }),
      },
    },
  },
};
</script>

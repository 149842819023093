<template>
  <div class="w-full mx-auto max-w-7xl md:px-8 md:mb-8">
    <div class="m-4">
      <router-link
        :to="{
          name: '/',
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </div>
    <div
      class="px-4 py-6 bg-white border-t border-gray-200 dashboard sm:grid sm:grid-cols-12 sm:gap-6 md:gap-12 sm:px-8 md:p-12 md:border md:rounded"
    >
      <div class="col-span-12">
        <template v-if="loading">
          <spinner>
            <template #spinnercontent>
              <p class="text-gray-600">
                {{ message }}
              </p>
              <p>
                Something not working as expected?
              </p>
              <router-link
                :to="{
                  name: '/',
                }"
                class="api-link underline hover:no-underline"
              >
                Click here to go back.
              </router-link>
            </template>
          </spinner>
        </template>
        <template v-else>
          <PageSection
            :id="'item-' + itemId + 'details'"
            :title="'Internship Details'"
          >
            <template #content>
              <h2>Content goes here for Item # {{ itemId }}</h2>
            </template>
          </PageSection>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PageSection from "@/components/forms/SharedComponents/Layout/PageSection.vue";
import Spinner from "@/components/helpers/Spinner.vue";
export default {
  name: "InternshipDetailPage",
  components: { PageSection, Spinner },
  data() {
    return {
      loading: true,
      message: "Loading Internship Library Item",
      itemId: 0,
    };
  },
  created() {
    this.itemId = parseInt(this.$route.params?.id.split("-").pop().trim());
    if (!this.itemId > 0) {
      this.$router.push("/error-page");
    } else {
      //fetch item details
      this.loading = false;
    }
  },
};
</script>

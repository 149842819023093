<template>
  <div>
    <div
      class="flex flex-col xs:flex-row my-4"
      :class="{ 'xs:flex-col': !inlineOptions }"
    >
      <label v-for="(option, index) in options" :key="index" class="flex ml-12">
        <input
          v-model="selectedRadio"
          type="radio"
          :name="name"
          class="mt-1 text-blue-700 form-radio"
          :class="{
            'bg-error-100': validationErrors.$error,
          }"
          :value="option"
        />
        <span class="ml-2" :class="{ capitalize: capitalizedOptions }">{{
          option
        }}</span>
      </label>
    </div>
    <div v-for="(value, rule) in sortedValidationRules" :key="rule">
      <div
        v-if="validationErrors[rule].$invalid"
        :data-cy="name + '-validation'"
        class="error text-error-900"
        :class="{ hidden: !validationErrors.$error }"
      >
        {{ validationMessage[rule] }}
      </div>
    </div>
  </div>
</template>

<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";
import validationMessages from "../../../mixins/validationMessages";
export default {
  mixins: [nestedModelComponent, validationMessages],
  props: {
    name: {
      type: String,
      default: "",
    },
    ariaControls: {
      type: String,
      default: "",
    },
    selectedOption: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //passing false to this prop will stack the options, true will show them inline
    inlineOptions: {
      type: Boolean,
      default: true,
    },
    //convert to uppercase first letter of each word in the radio button options
    capitalizedOptions: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["input"],
  data() {
    return {
      selectedRadio: "",
    };
  },
  watch: {
    selectedRadio: function (newVal) {
      this.$emit("input", newVal);
    },
  },
  created() {
    if (this.selectedOption) {
      this.selectedRadio = this.selectedOption;
    }
  },
};
</script>

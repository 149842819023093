<template>
  <div
    v-if="showTravelSection"
    id="onboardingStep2-section2"
    class="grid grid-cols-3 gap-2"
  >
    <div class="divider-onboarding col-span-3"></div>
    <div class="col-span-2 grid sm:grid-cols-2 auto-rows-min">
      <component
        :is="item.component"
        v-for="(item, index) in preDepartureForms"
        :key="index"
        class="flex flex-col justify-start items-start"
        :application-id="$route.params.applicationId"
        :program-details="programDetails"
      >
      </component>
    </div>
    <div>
      <div class="flex flex-col justify-start items-start p-4">
        <!-- countdown component -->
        <DateCountdown
          v-if="showCountdown"
          :application-deadline="formatDeadline"
          :is-large-icon="false"
          :is-forms-completion-count-down="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import postFormRules from "../../mixins/postFormRules";
import TravelPlans from "../travels/travel-plans/travel-plans-card.vue";
import GroupVisaCard from "../forms/GroupVisaInformation/GroupVisaCard.vue";
import VisaAppointmentCard from "../forms/VisaAppointment/VisaAppointmentCard.vue";
import VisaAndPermitCard from "../forms/VisaAndPermit/VisaAndPermitCard.vue";
import { mapState } from "vuex";
import DateCountdown from "../DateCountdown.vue";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import getTime from "date-fns/getTime";
import { endOfDay } from "date-fns";

export default {
  name: "TravelArrangementsSection",
  components: { DateCountdown },
  mixins: [postFormRules],
  props: {
    programDetails: {
      type: Object,
      default() {
        return {};
      },
    },
    showCountdown: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["program"]),
    formatDeadline() {
      //due date = start date - 30 days
      let date = getTime(
        subDays(endOfDay(new Date(this.program.start_date)), 30)
      );
      return format(date, "yyyy-MM-dd");
    },
    showTravelSection() {
      return (
        this.showTravelPlansTile ||
        this.showGroupVisaTile ||
        this.showVisaAppointmentTile ||
        this.showVisaAndPermitTile
      );
    },
    preDepartureForms() {
      //add the component for the form link that should display in this section
      //make sure to run the logic to show or hide the component inside of its self
      return [
        {
          component: TravelPlans,
        },
        {
          component: GroupVisaCard,
        },
        {
          component: VisaAppointmentCard,
        },
        {
          component: VisaAndPermitCard,
        },
      ];
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <template v-if="loading">
      <CountDown
        ref="counter"
        :message="message"
        @redirecting="(msg) => (message = msg)"
      />
    </template>
    <template v-else>
      <div
        class="grid grid-cols-badges place-items-center bg-blue-100 place-content-center gap-4"
      >
        <img src="/images/career-development.png" class="mt-4" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="career"
            v-model="v$.value.careerDevelopment.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="career"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>

        <img src="/images/community-engagement.png" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="community"
            v-model="v$.value.communityEngagement.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="community"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>

        <img src="/images/global-agility.png" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="agility"
            v-model="v$.value.globalAgility.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="agility"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>

        <img src="/images/global-workforce-communication.png" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="workspace"
            v-model="v$.value.globalWorkspaceCommunication.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="workspace"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>

        <img src="/images/intercultural-learning.png" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="inter"
            v-model="v$.value.interculturalLearning.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="inter"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
        <img src="/images/leadership.png" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="leader"
            v-model="v$.value.leadership.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="leader"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>

        <img src="/images/mindful-travel.png" />
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="mindful"
            v-model="v$.value.mindfulTravels.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="mindful"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>

        <p class="text-2xl font-semibold text-gray-700">No Badges</p>
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            id="nobad"
            v-model="v$.value.noBadges.$model"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            type="checkbox"
          />
          <label
            for="nobad"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-2">
        <ButtonSpin
          ref="prim"
          :disabled="buttonStats.requestOngoing || v$.$invalid"
          :variant="'primary'"
          :type="'button'"
          @click.prevent="sendSubmission()"
        >
          {{ buttonStats.primary }}
        </ButtonSpin>
        <div
          v-if="buttonStats.finished"
          class="text-center text-success-900 col-span-2"
        >
          Submission Complete, thank you!
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ButtonSpin from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import formioApi from "@/mixins/formIoApi";
import forms from "@/mixins/forms";
import { sameAs } from "@vuelidate/validators";
import CountDown from "@/components/forms/SharedComponents/CountDown.vue";
import useVuelidate from '@vuelidate/core';
export default {
  name: "BadgesForm",
  components: {
    ButtonSpin,
    CountDown,
  },
  mixins: [formioApi, forms],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      invalidForm: true,
      check: false,
      value: {
        careerDevelopment: false,
        communityEngagement: false,
        globalWorkspaceCommunication: false,
        globalAgility: false,
        interculturalLearning: false,
        leadership: false,
        mindfulTravels: false,
        noBadges: false,
      },
      loading: true,
      message: "",
      buttonStats: {
        primary: "Save",
        requestOngoing: false,
        finished: false,
      },
    };
  },
  computed: {
    formMethod() {
      return this.submission_id ? "PATCH" : "post";
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  validations() {
    return {
      invalidForm: {
        somethingSelected: sameAs("check"),
      },
      value: {
        careerDevelopment: {},
        communityEngagement: {},
        globalWorkspaceCommunication: {},
        globalAgility: {},
        interculturalLearning: {},
        leadership: {},
        mindfulTravels: {},
        noBadges: {},
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.invalidForm = this.badgeChecker();
      },
      deep: true,
    },
    "value.careerDevelopment": {
      handler: function (newval) {
        if (newval) this.changeBadge();
      },
    },
    "value.communityEngagement": {
      handler: function (newval) {
        if (newval) this.changeBadge();
      },
    },
    "value.globalWorkspaceCommunication": {
      handler: function (newval) {
        if (newval) this.changeBadge();
      },
    },
    "value.globalAgility": {
      handler: function (newval) {
        if (newval) this.changeBadge();
      },
    },
    "value.interculturalLearning": {
      handler: function (newval) {
        if (newval) this.changeBadge();
      },
    },
    "value.leadership": {
      handler: function (newval) {
        if (newval) this.changeBadge();
      },
    },
    "value.mindfulTravels": {
      handler: function (newval) {
        if (newval) this.value.noBadges = false;
      },
    },
    "value.noBadges": {
      handler: function (newval) {
        if (newval) {
          this.value.careerDevelopment = false;
          this.value.communityEngagement = false;
          this.value.globalWorkspaceCommunication = false;
          this.value.globalAgility = false;
          this.value.interculturalLearning = false;
          this.value.leadership = false;
          this.value.mindfulTravels = false;
        }
      },
    },
  },
  async created() {
    let canView = await this.canView();
    if (canView) {
      let id = await this.checkSubmission();
      if (id) {
        let data = await this.getSubmission(id);
        this.setData(data);
      }
      this.loading = false;
      return;
    }
    this.$refs.counter.startCountDown();
  },
  methods: {
    changeBadge(badgeName = "noBadges", value = false) {
      this.value[badgeName] = value;
    },
    setData(data) {
      if (Object.hasOwnProperty.call(data, "digitalBadges")) {
        this.value.careerDevelopment = data.digitalBadges.careerDevelopment;
        this.value.communityEngagement = data.digitalBadges.communityEngagement;
        this.value.globalWorkspaceCommunication =
          data.digitalBadges.globalWorkspaceCommunication;
        this.value.globalAgility = data.digitalBadges.globalAgility;
        this.value.interculturalLearning =
          data.digitalBadges.interculturalLearning;
        this.value.leadership = data.digitalBadges.leadership;
        this.value.mindfulTravels = data.digitalBadges.mindfulTravels;
        this.value.noBadges = data.digitalBadges.noBadges;
      }
    },
    async canView() {
      this.message = "Checking if form is aplicable to your program";
      const valid = await this.isValidApplicationAndIsOnRules(
        this.applicationId,
        "Badge Interests"
      );
      return valid.valid;
    },
    async checkSubmission() {
      this.message = "Gathering form submission, please wait";
      return this.formioSubmissionExists("badges", this.applicationId);
    },
    async getSubmission(id) {
      this.message = "Submission found, getting information, please wait.";
      return this.getFormioSubmission("badges", id);
    },
    async sendSubmission() {
      let data = this.formatData();
      this.buttonStats.finished = false;
      this.buttonAction(true);
      try {
        let submission = await this.submitToFormIo(
          "badges",
          data,
          this.formMethod,
          this.submission_id
        );
        if (submission) {
          this.submission_id = submission;
          this.buttonStats.finished = true;
        }
      } catch (e) {
        // False positive
        if (e instanceof Error && Object.hasOwnProperty.call(e, "response")) {
          let error = await e.response.json();
          if (
            Object.hasOwnProperty.call(error, "errors") &&
            Array.isArray(error.errors) &&
            error.errors.find((err) => err === "Resource not found")
          ) {
            this.buttonStats.finished = true;
          }
        } else {
          console.error(e);
        }
      } finally {
        this.buttonAction();
        this.$refs.prim.buttonIsDisabled = true;
      }
    },
    buttonAction(activate = false) {
      this.buttonStats.requestOngoing = activate;
      if (activate) {
        this.$refs.prim.startLoading();
        return;
      }
      this.$refs.prim.stopLoading();
    },
    formatData() {
      let data = null;
      if (this.formMethod === "post") {
        data = {
          data: {
            application_id: this.applicationId,
            digitalBadges: { ...this.value },
            completedDigitalBadges: true,
          },
        };
      }
      if (this.formMethod === "PATCH") {
        data = [
          {
            op: "add",
            path: "/data/digitalBadges",
            value: { ...this.value },
          },
          {
            op: "add",
            path: "/data/application_id",
            value: this.applicationId,
          },
          {
            op: "add",
            path: "/data/completedDigitalBadges",
            value: true,
          },
        ];
      }
      return data;
    },
    badgeChecker() {
      let nothingSelected = true;
      for (let key in this.value) {
        if (this.value[key]) {
          nothingSelected = false;
          break;
        }
      }
      return nothingSelected;
    },
  },
};
</script>

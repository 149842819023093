<template>
  <div
    id="accepted-tab"
    tabindex="0"
    role="tabpanel"
    aria-labelledby="accepted"
    class="focus:outline-none"
  >
    <Teleport v-if="parentMounted" to="#csvBtn">
      <a
        class="items-center hidden md:flex text-teal-500 font-semibold"
        href="#"
        @click="csvExport()"
      >
        <span class="flex-none">
          <i class="mdi mdi-download"></i>
        </span>
        <span class="ml-2">CSV</span>
      </a>
    </Teleport>
    <modal
      v-if="showModal"
      :id="'breakdown'"
      :title="'Total Program Price Breakdown'"
      @closemodal="resetStudent"
    >
      <template #modal-content>
        <PriceBreakDown @close="resetStudent" />
      </template>
    </modal>
    <Teleport v-if="parentMounted" to="#searchbox">
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between sr-only">
          <span>Search</span>
        </span>
        <input
          v-model="tableData.search"
          class="form-input min-h-10 block w-full"
          type="text"
          placeholder="Search"
          @input="getStudents(searchUrl)"
        />
      </label>
    </Teleport>
    <Filters
      :show-city="true"
      :show-approval="true"
      @getFilterData="getFilterData"
    />
    <NoRecords v-if="!loading & (students.length == 0)" />
    <DataTables
      v-else
      :columns="columns"
      :sort-key="sortKey"
      :sort-orders="sortOrders"
      role="region"
      aria-label="Accepted Results"
      @sort="sortBy"
    >
      <tr v-if="loading">
        <td class="card-data">
          <div class="w-full md:w-columnfixed-md2">Loading Please Wait...</div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-ctrl"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-ctrl"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-data">
          <div class="w-full md:w-columnfixed-sm"></div>
        </td>
        <td class="card-controls">
          <div class="w-full md:w-columnfixed-ctrl"></div>
        </td>
      </tr>
      <p v-else-if="students.length == 0" class="text-red-600">
        No records found!
      </p>
      <tbody v-else>
        <tr
          v-for="(student, index) in students"
          :key="student.id + 'i' + index"
          class="card-row"
        >
          <td class="card-section">
            <div class="card-label">Student Name and Email</div>
            <div class="md:w-columnfixed-md2 break-words">
              <span
                class="block text-sm font-semibold text-gray-700 student-link cursor-pointer"
                @click="
                  setTabDataAndRedirect(
                    student.session_id,
                    student.approval_application,
                    student.student_id,
                    'accepted'
                  )
                "
              >
                {{ student.name }}
              </span>
              <span class="break-all">
                <a
                  :href="'mailto:' + student.email"
                  class="text-teal-500 focus:underline focus:text-teal-900 hover:text-teal-900"
                >
                  {{ student.email }}
                </a>
              </span>
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Major</div>
            <div
              class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm break-words"
            >
              {{ student.major || "-" }}
            </div>
          </td>
          <td class="card-section">
            <div class="card-label">Program Name</div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-md3">
              <a
                v-if="student.url"
                :href="student.url"
                target="_blank"
                class="text-teal-500 focus:underline focus:text-teal-900 hover:text-teal-900"
              >
                {{ student.program_name }}
              </a>
              <span v-else class="block">{{ student.program_name }}</span>
              <span class="block">{{ student.site }}</span>
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Program Dates</div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm">
              <ProgramDates
                :id="student.id + 'r' + index"
                :dates="student.program_dates"
                :start-is-final="student.start_date_final > 0"
                :end-is-final="student.end_date_final > 0"
              />
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Session</div>
            <div
              class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm break-words"
            >
              {{ student.session }}
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Student Confirmation</div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm">
              {{ student.confirmation_payment ? "Yes" : "Pending" }}
            </div>
          </td>

          <td class="card-data">
            <div class="card-label">Scholarship Award</div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-ctrl">
              -
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Student Discount</div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-ctrl">
              -
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Financial Aid</div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-ctrl">
              {{ student.financial_aid }}
            </div>
          </td>
          <td class="card-data">
            <div class="card-label">Approval Form</div>
            <div
              v-if="
                !student.form_rules ||
                !student.form_rules.includes('University Approval')
              "
            >
              <p>-</p>
            </div>
            <div
              v-else
              class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-columnfixed-sm"
            >
              <span
                v-if="
                  approvedApplications.includes(student.approval_application)
                "
                class="flex items-center"
              >
                <FeatherComponent
                  :id="student.id + 'r' + index + '-icon'"
                  :icon-name="'check-circle'"
                  :classes="'text-success-900 w-4'"
                />
                <span class="ml-2 text-sm text-gray-700">Completed</span>
              </span>
              <button
                v-else
                class="approve hover:bg-yellow-900 focus:bg-yellow-900 focus:outline-none"
                @click="approveStudent(student)"
              >
                <span>Approve</span>
              </button>
            </div>
          </td>
          <td class="card-controls">
            <DropdownComponent :row="index + 1" :tab-name="'accepted'">
              <template #dropdown-options>
                <li
                  @click="
                    setTabDataAndRedirect(
                      student.session_id,
                      student.approval_application,
                      student.student_id,
                      'accepted'
                    )
                  "
                >
                  <span class="dropdown-item cursor-pointer">View Detail</span>
                </li>
                <li class="border-t border-gray-200">
                  <a class="dropdown-item" :href="'mailto:' + student.email"
                    >Email Student</a
                  >
                </li>
                <li class="border-t border-gray-200">
                  <a class="dropdown-item" href="mailto:api-urs@apiabroad.com"
                    >Email API</a
                  >
                </li>
              </template>
            </DropdownComponent>
          </td>
        </tr>
      </tbody>
    </DataTables>
    <Pagination
      :pagination="pagination"
      @prev="getStudents(pagination.prevPageUrl)"
      @next="getStudents(pagination.nextPageUrl)"
    />
  </div>
</template>

<script>
import DataTables from "@/university/components/dataTables/DataTables.vue";
import Pagination from "@/university/components/dataTables/Pagination.vue";
import ProgramDates from "@/university/components/dataTables/programDates.vue";
import NoRecords from "@/university/components/dataTables/NoRecords.vue";
import { tabsMixin } from "@/mixins/tabsMixins.js";
import DropdownComponent from "@/university/components/SiteComponents/my-students/misc/dropdown.vue";
import Filters from "@/university/components/dataTables/filter/Filters.vue";
import FeatherComponent from "@/university/components/SiteComponents/featherIcon";
import PriceBreakDown from "@/university/components/SiteComponents/priceBreakDown";
import modal from "@/university/components/modal";

export default {
  components: {
    DataTables,
    Pagination,
    ProgramDates,
    NoRecords,
    DropdownComponent,
    Filters,
    FeatherComponent,
    PriceBreakDown,
    modal,
  },
  mixins: [tabsMixin],
  props: {
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let sortOrders = {};
    //columns headers for this table, mus match a row from columnsWhiteList->row->name in tabMixin.js
    let tabColumns = [
      "nameEmail",
      "major",
      "programName",
      "programDates",
      "term",
      "confirmed",
      "awardScholarship",
      "studentDiscount",
      "financialAid",
      "approvalForm",
    ];
    tabColumns.forEach((column) => {
      sortOrders[column] = 0;
    });
    return {
      searchUrl:
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "students",
      id: "",
      loading: true,
      students: [],
      tabColumns: tabColumns,
      sortKey: "col1",
      sortOrders: sortOrders,
      perPage: ["10", "20", "30"],
      tableData: {
        draw: 0,
        length: 25,
        search: "",
        column: 0,
        dir: "asc",
        status: ["Accepted"],
        year: new Date().getFullYear(),
        current_year: new Date().getFullYear(),
        filters: {
          city: "",
          sessions: [],
          majors: [],
          approvalStatus: "",
        },
      },
    };
  },
  created() {
    this.getStudents(this.searchUrl, true);
  },
  methods: {
    setYear(event) {
      this.tableData.year = event.target.value;
      this.getStudents(this.searchUrl);
    },
    prepDataForCsvExport(csvData) {
      return csvData.map((item) => ({
        Name: item.name,
        Email: item.email,
        Major: item.major,
        Program_Name: item.program_name,
        Location: item.site,
        Program_Dates: item.program_dates,
        Confirmed: item.confirmed,
        Award_Scholaship: item.award_scholarship,
        Discount: item.discount,
        Financial_Aid: item.financial_aid,
        Approval_form:
          !item.form_rules || !item.form_rules.includes("University Approval")
            ? "-"
            : this.approvedApplications.includes(item.approval_application)
            ? "Completed"
            : "Approve",
      }));
    },
  },
};
</script>

<style scoped>
.check-approval {
  @apply text-blue-700;
  font-size: 1.75rem;
}
button.approve {
  @apply inline-block text-center rounded font-semibold;
  @apply border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700;
}
</style>

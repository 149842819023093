<template>
  <button
    class="mt-6 flex items-center text-teal-500 hover:text-teal-900 active:text-teal-900"
    data-cy="add-item-button"
    @click="$emit('addItem')"
  >
    <span class="">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-plus-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="16"></line>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </span>
    <span class="ml-2 text-sm font-semibold text-gray-600">
      {{ label }}
    </span>
  </button>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    label: {
      type: String,
      default: "Add Another",
    },
  },
  emits: ["addItem"],
};
</script>

<template>
  <div :key="'edit' + value.id" class="mt-6">
    <header>
      <div class="flex items-center justify-between">
        <h3 class="mr-6 text-base font-semibold text-gray-700 md:text-xl">
          Add Travel Arrangement
        </h3>
        <div class="flex flex-none">
          <button
            class="inline-block text-center rounded font-semibold border-2 border-transparent text-sm px-3 py-1 bg-white border-teal-500 text-teal-500 hover:bg-teal-100 hover:text-white hover:border-teal-100 mr-2"
            @click="cancel(v$.value.id.$model)"
          >
            <span>Cancel</span>
          </button>
          <button
            data-cy="save-arrangement-button"
            class="inline-block text-center rounded font-semibold border-2 border-transparent text-sm px-3 py-1 text-white bg-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white"
            @click="save()"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
      <p v-if="submitError" class="text-right error text-error-900">
        Something went wrong while submitting the form, please try later.
      </p>
    </header>
    <div :key="'fields' + value.id" class="mt-4">
      <div class="mb-6">
        <div class="space-y-2">
          <label :for="'studentResponsible' + value.id" class="flex">
            <input
              :id="'studentResponsible' + value.id"
              v-model="v$.value.acknowledge.studentResponsible.$model"
              data-cy="student-responsible-checkbox"
              type="checkbox"
              name="travel-arrangements-acknowledgements"
              class="mt-1 text-blue-700 form-checkbox"
              :class="{ 'bg-error-100': v$.value.acknowledge.$error }"
            />
            <div class="flex-1 ml-2">
              <p>
                I acknowledge that if I book my travel outside the arrival or
                departure guidelines, that I will be responsible for my own
                transportation and / or accommodations.
              </p>
            </div>
          </label>
          <label :for="'studentFollowsGuidelines' + value.id" class="flex">
            <input
              :id="'studentFollowsGuidelines' + value.id"
              v-model="v$.value.acknowledge.studentFollowsGuidelines.$model"
              data-cy="student-follows-guidelines-checkbox"
              type="checkbox"
              name="travel-arrangements-acknowledgements"
              class="mt-1 text-blue-700 form-checkbox"
              :class="{ 'bg-error-100': v$.value.acknowledge.$error }"
            />
            <div class="flex-1 ml-2">
              <p>
                I acknowledge that I should follow any immigration guidelines
                for my program as I am making travel arrangements
              </p>
            </div>
          </label>
          <div
            v-if="
              v$.value.acknowledge.validateAcknowledge.$invalid &&
              v$.value.acknowledge.$error
            "
            class="error text-error-900 text-sm"
          >
            Both acknowledgements are required.
          </div>
        </div>
        <div class="mt-6">
          <fieldset>
            <legend class="text-sm font-semibold text-gray-600">
              Travel Type
            </legend>
            <div
              class="grid items-center grid-cols-2 gap-y-3 gap-x-6 mt-4 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4"
            >
              <div v-for="option in TRAVEL_OPTIONS" :key="option.value">
                <label
                  :for="v$.value.value + value.id"
                  class="flex items-center"
                >
                  <input
                    :id="option.value + value.id"
                    v-model.trim="v$.value.travelType.$model"
                    data-cy="travel-type-radio"
                    :value="option.value"
                    type="radio"
                    name="travel-type"
                    class="text-blue-700 form-radio mt-0"
                    :class="{ 'bg-error-100': v$.value.travelType.$error }"
                    @change="travelTypeHandler"
                  />
                  <div class="flex-1 ml-2">
                    <div class="flex items-center">
                      <template v-if="option.image">
                        <img
                          :src="'/images/' + option.image + '.svg'"
                          class="flex-none"
                          alt=""
                        />
                        <span class="ml-2">{{ option.label }}</span>
                      </template>
                      <span v-else>{{ option.label }}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div
              v-if="
                v$.value.travelType.required.$invalid &&
                v$.value.travelType.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </fieldset>
        </div>
      </div>
      <ApiTextArea
        v-if="v$.value.travelType.$model === 'other'"
        :ref="'specifyArrangements' + value.id"
        :name="'specifyArrangements' + value.id"
        label="Specify Arrangements"
        :max-length="500"
        :validation-errors="v$.value.specifyArrangements"
        :typed-text="v$.value.formioSpecifyArrangements.$model"
        custom-error-class="text-sm"
        @textUpdate="onFieldChange('specifyArrangements', $event)"
      />
      <template v-else-if="v$.value.travelType.$model">
        <div class="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
          <label
            v-if="v$.value.travelType.$model === 'flight'"
            :for="'airline' + value.id"
            class="block text-sm sm:col-span-2"
          >
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Airline</span>
            </span>
            <VSelect
              :id="'airline' + value.id"
              v-model="v$.value.airline.$model"
              data-cy="airline-drop-down"
              :value="v$.value.airline.$model"
              :options="AIRLINES"
              :get-option-label="(option) => option.label"
              :reduce="(airline) => airline.value"
              aria-label="Airline"
              :class="{ 'bg-error-100': v$.value.airline.$error }"
            />
            <div
              v-if="
                v$.value.airline.required.$invalid && v$.value.airline.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </label>

          <label
            v-if="v$.value.airline.$model === 'other'"
            :for="'otherAirline' + value.id"
            class="block text-sm sm:col-span-2"
          >
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Other Airline</span>
            </span>
            <input
              :id="'otherAirline' + value.id"
              v-model.trim="v$.value.otherAirline.$model"
              data-cy="other-airline-text-field"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.otherAirline.$error }"
            />
            <div
              v-if="
                v$.value.otherAirline.required.$invalid &&
                v$.value.otherAirline.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </label>

          <label
            v-if="v$.value.travelType.$model === 'flight'"
            :for="'flightNumber' + value.id"
            class="block text-sm sm:col-span-2"
          >
            <span
              data-cy="fligh-help"
              class="inline-flex justify-between font-semibold text-gray-600 flex justify-between mb-2"
            >
              <span>Flight Number</span>
              <Tooltip
                tooltip="A flight number is a code for any airline service that consists of a 1-4 digit number. Example: 4379 or 756 are flight numbers."
              />
            </span>
            <input
              :id="'flightNumber' + value.id"
              v-model="v$.value.flightNumber.$model"
              data-cy="flight-number-field"
              type="number"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.flightNumber.$error }"
            />
            <div
              v-if="
                v$.value.flightNumber.validateFlightNumber.$invalid &&
                v$.value.flightNumber.$error
              "
              class="error text-error-900"
            >
              Your flight number is a one to four digit number and is different
              from you record locator number.
            </div>
            <div
              v-if="v$.value.flightNumber.numeric.$invalid"
              class="error text-error-900"
            >
              Only numbers allowed
            </div>
            <div
              v-if="
                v$.value.flightNumber.required.$invalid &&
                v$.value.flightNumber.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </label>
          <label
            v-else
            :for="'travelNumber' + value.id"
            class="block text-sm sm:col-span-2"
          >
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Travel # (Train #, Bus #)</span>
            </span>
            <input
              :id="'travelNumber' + value.id"
              v-model.trim="v$.value.travelNumber.$model"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.travelNumber.$error }"
            />
            <div
              v-if="
                v$.value.travelNumber.required.$invalid &&
                v$.value.travelNumber.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </label>

          <label
            :for="'departureCity' + value.id"
            class="block text-sm sm:col-span-2"
          >
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Departure City</span>
            </span>
            <input
              :id="'departureCity' + value.id"
              v-model.trim="v$.value.departureCity.$model"
              data-cy="departure-city-text-field"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.departureCity.$error }"
            />
            <div
              v-if="
                v$.value.departureCity.required.$invalid &&
                v$.value.departureCity.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </label>
          <CalendarInput
            :name="'departureDate' + value.id"
            data-cy="departure-date-field"
            label-class="block text-sm"
            span-class="font-semibold text-gray-600 flex justify-between mb-2"
            custom-class="form-input block w-full min-h-10"
            label-before-input="Departure Date"
            :validation-errors="v$.value.departureDate"
            :value-from-database="v$.value.formioDepartureDate.$model"
            @input="onFieldChange('departureDate', $event)"
          />
          <label :for="'departureTime' + value.id" class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Departure Time</span>
            </span>
            <input
              :id="'departureTime' + value.id"
              v-model.trim="v$.value.departureTime.$model"
              data-cy="departure-time-text-field"
              maxlength="5"
              type="text"
              placeholder="__:__"
              pattern="[0-9]{2}:[0-9]{2}"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.departureTime.$error }"
              @keypress="isTimeValid($event)"
              @keyup="addColonDepartureTime(v$.value.departureTime.$model)"
            />
            <div
              v-if="
                v$.value.departureTime.required.$invalid &&
                v$.value.departureTime.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
            <div
              v-if="
                v$.value.departureTime.timeRegex.$invalid &&
                v$.value.departureTime.$error
              "
              class="error text-error-900 text-sm"
            >
              Please enter a valid time
            </div>
          </label>
          <label class="text-sm justify-end col-span-2">
            <div class="flex justify-end col-span-2">
              <div class="flex items-center">
                <input
                  v-model.trim="v$.value.DepartureTimeAmOrPm.$model"
                  type="radio"
                  value="AM"
                  class="form-radio ml-2"
                  :class="{
                    'bg-error-100': v$.value.DepartureTimeAmOrPm.$error,
                  }"
                />
                <span class="ml-2">AM</span>
              </div>
              <div class="flex items-center">
                <input
                  v-model.trim="v$.value.DepartureTimeAmOrPm.$model"
                  type="radio"
                  value="PM"
                  class="form-radio ml-2"
                  :class="{
                    'bg-error-100': v$.value.DepartureTimeAmOrPm.$error,
                  }"
                />
                <span class="ml-2"> PM </span>
              </div>
            </div>
            <div
              v-if="
                v$.value.DepartureTimeAmOrPm.required.$invalid &&
                v$.value.DepartureTimeAmOrPm.$error
              "
              class="error text-error-900 text-sm flex justify-end col-span-2"
            >
              Field is required
            </div>
          </label>

          <label
            :for="'arrivalCity' + value.id"
            class="block text-sm sm:col-span-2"
          >
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Arrival City</span>
            </span>
            <input
              :id="'arrivalCity' + value.id"
              v-model.trim="v$.value.arrivalCity.$model"
              data-cy="arrival-city-text-field"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.arrivalCity.$error }"
            />
            <div
              v-if="
                v$.value.arrivalCity.required.$invalid &&
                v$.value.arrivalCity.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </label>

          <CalendarInput
            :name="'arrivalDate' + value.id"
            data-cy="arrival-date-field"
            label-class="block text-sm"
            span-class="font-semibold text-gray-600 flex justify-between mb-2"
            custom-class="form-input block w-full min-h-10"
            label-before-input="Arrival Date"
            :validation-errors="v$.value.arrivalDate"
            :value-from-database="v$.value.formioArrivalDate.$model"
            @input="onFieldChange('arrivalDate', $event)"
          />
          <label :for="'arrivalTime' + value.id" class="block text-sm">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              <span>Arrival Time</span>
            </span>
            <input
              :id="'arrivalTime' + value.id"
              v-model.trim="v$.value.arrivalTime.$model"
              data-cy="arrival-time-text-field"
              type="text"
              maxlength="5"
              placeholder="__:__"
              pattern="[0-9]{2}:[0-9]{2}"
              class="form-input block w-full min-h-10"
              :class="{ 'bg-error-100': v$.value.arrivalTime.$error }"
              @keypress="isTimeValid($event)"
              @keyup="addColonArrivalTime(v$.value.arrivalTime.$model)"
            />
            <div
              v-if="
                v$.value.arrivalTime.required.$invalid &&
                v$.value.arrivalTime.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
            <div
              v-if="
                v$.value.arrivalTime.timeRegex.$invalid &&
                v$.value.arrivalTime.$error
              "
              class="error text-error-900 text-sm"
            >
              Please enter a valid time
            </div>
          </label>

          <label class="block text-sm justify-end col-span-2">
            <div class="flex justify-end">
              <div class="flex items-center">
                <input
                  v-model.trim="v$.value.ArrivalTimeAmOrPm.$model"
                  type="radio"
                  value="AM"
                  class="form-radio ml-2"
                  :class="{ 'bg-error-100': v$.value.arrivalTime.$error }"
                />
                <span class="ml-2">AM</span>
              </div>
              <div class="flex items-center">
                <input
                  v-model.trim="v$.value.ArrivalTimeAmOrPm.$model"
                  type="radio"
                  value="PM"
                  class="form-radio ml-2"
                  :class="{ 'bg-error-100': v$.value.ArrivalTimeAmOrPm.$error }"
                />
                <span class="ml-2"> PM </span>
              </div>
            </div>
            <div
              v-if="
                v$.value.ArrivalTimeAmOrPm.required.$invalid &&
                v$.value.ArrivalTimeAmOrPm.$error
              "
              class="error text-error-900 text-sm flex justify-end col-span-2"
            >
              Field is required
            </div>
          </label>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import nestedModelComponent from "../../../mixins/nestedModelComponent";

import CalendarInput from "../../forms/SharedComponents/CalendarInput.vue";
import ApiTextArea from "../../forms/SharedComponents/ApiTextArea.vue";
import Tooltip from "@/components/forms/SharedComponents/Tooltip.vue";
import { AIRLINES } from "@/constants";
import { required, requiredIf, numeric, helpers } from "@vuelidate/validators";
import {
  validatorDateFormat,
  minDateValidator,
} from "../../../mixins/customValidators";
import useVuelidate from "@vuelidate/core";

const TRAVEL_OPTIONS = [
  {
    label: "Flight",
    value: "flight",
    image: "icon_flight",
  },
  {
    label: "Train",
    value: "train",
    image: "icon_train",
  },
  {
    label: "Bus",
    value: "bus",
    image: "icon_bus",
  },
  {
    label: "Other",
    value: "other",
    image: "",
  },
];
const validateFlightNumber = (value, model) => {
  if (model.travelType === "flight") return value >= 1 && value <= 9999;
  else return true;
};
const validateAcknowledge = (value) => {
  return value.studentResponsible && value.studentFollowsGuidelines;
};
const timeRegex = helpers.regex(/^(0?[1-9]|1[012]):[0-5][0-9]$/);
const requiredIfNotOther = requiredIf(function () {
  return this.value.travelType !== "other";
});

export default {
  name: "ArrangementsEditView",
  components: { CalendarInput, ApiTextArea, Tooltip },
  mixins: [nestedModelComponent],
  props: {
    arragementData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["input", "saveArrangement", "cancelArrangement"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        travelType: "",
        specifyArrangements: "",
        formioSpecifyArrangements: "",
        travelNumber: "",
        airline: "",
        otherAirline: "",
        flightNumber: "",
        departureCity: "",
        departureDate: "",
        formioDepartureDate: "",
        departureTime: "",
        arrivalCity: "",
        arrivalDate: "",
        formioArrivalDate: "",
        arrivalTime: "",
        DepartureTimeAmOrPm: "",

        ArrivalTimeAmOrPm: "",
        acknowledge: {
          studentResponsible: false,
          studentFollowsGuidelines: false,
        },
        editView: false,
        id: "",
        submitError: false,
      },
      submitError: false,
    };
  },
  validations: {
    value: {
      acknowledge: {
        studentResponsible: {},
        studentFollowsGuidelines: {},
        validateAcknowledge,
      },
      travelType: { required },
      specifyArrangements: {
        required: requiredIf(function () {
          return this.value.travelType === "other";
        }),
      },
      formioSpecifyArrangements: {},
      travelNumber: {
        required: requiredIf(function () {
          return (
            this.value.travelType === "train" || this.value.travelType === "bus"
          );
        }),
      },
      airline: {
        required: requiredIf(function () {
          return this.value.travelType === "flight";
        }),
      },

      otherAirline: {
        required: requiredIf(function () {
          return this.value.airline === "other";
        }),
      },
      flightNumber: {
        required: requiredIf(function () {
          return this.value.travelType === "flight";
        }),
        numeric,
        validateFlightNumber,
      },
      departureCity: { required: requiredIfNotOther },
      departureDate: {
        required: requiredIfNotOther,
        isValidDate: validatorDateFormat("yyyy-mm-dd"),
        isValidMinDate: minDateValidator("1970"),
      },
      formioDepartureDate: {},
      departureTime: { required: requiredIfNotOther, timeRegex },
      arrivalCity: { required: requiredIfNotOther },
      arrivalDate: {
        required: requiredIfNotOther,
        isValidDate: validatorDateFormat("yyyy-mm-dd"),
        isValidMinDate: minDateValidator("1970"),
      },
      formioArrivalDate: {},
      arrivalTime: { required: requiredIfNotOther, timeRegex },
      DepartureTimeAmOrPm: { required: requiredIfNotOther },
      ArrivalTimeAmOrPm: { required: requiredIfNotOther },

      editView: {},
      id: {},
    },
  },
  watch: {
    arragementData: {
      handler: function (newVal) {
        this.setArrangement(newVal);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.TRAVEL_OPTIONS = TRAVEL_OPTIONS;
    //Sort and set airlines list
    const sortAirlines = AIRLINES.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    this.AIRLINES = sortAirlines;
  },
  methods: {
    addColonDepartureTime(value) {
      value = value.replace(":", "");

      this.value.departureTime =
        value.length < 3
          ? value.replace(/\b(\d{2,2})(\d{2})/g, "$1:$2")
          : value.replace(/\b(\d{1,2})(\d{2})/g, "$1:$2");
    },
    addColonArrivalTime(value) {
      value = value.replace(":", "");
      this.value.arrivalTime =
        value.length < 3
          ? value.replace(/\b(\d{2,2})(\d{2})/g, "$1:$2")
          : value.replace(/\b(\d{1,2})(\d{2})/g, "$1:$2");
    },

    travelTypeHandler() {
      if (this.value.travelType === "flight") {
        this.value.travelNumber = "";
        this.value.specifyArrangements = "";
        this.value.DepartureTimeAmOrPm = "";
        this.value.ArrivalTimeAmOrPm = "";
      } else if (
        this.value.travelType === "train" ||
        this.value.travelType === "bus"
      ) {
        this.value.flightNumber = "";
        this.value.airline = "";
        this.value.otherAirline = "";
        this.value.specifyArrangements = "";
        this.value.DepartureTimeAmOrPm = "";
        this.value.ArrivalTimeAmOrPm = "";
      } else {
        this.value.travelNumber = "";
        this.value.flightNumber = "";
        this.value.airline = "";
        this.value.otherAirline = "";
        this.value.departureCity = "";
        this.value.departureDate = "";
        this.value.departureTime = "";
        this.value.arrivalCity = "";
        this.value.arrivalDate = "";
        this.value.arrivalTime = "";
        this.value.DepartureTimeAmOrPm = "";
        this.value.ArrivalTimeAmOrPm = "";
      }
    },
    isTimeValid(event) {
      if (!/\d/.test(event.key) && event.key !== ":")
        return event.preventDefault();
    },
    onFieldChange(field, value) {
      this.value[field] = value;
      this.$emit("input", this.value);
    },
    setArrangement(arrangement) {
      Object.entries(arrangement).forEach(([key, val]) => {
        if (key === "acknowledge")
          this.value.acknowledge = Object.assign({}, val);
        else if (key === "submitError") this.submitError = val;
        else this.value[key] = val;
      });
    },
    save() {
      this.submitError = false;
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.$emit("saveArrangement", this.value);
      }
    },
    cancel(id) {
      this.$emit("cancelArrangement", id);
    },
  },
};
</script>

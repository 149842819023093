<template>
  <div>
    <Teleport v-if="parentMounted" to="#portal-title">
      <div v-if="isPassSet" role="region" aria-labelledby="viewtitle">
        <h2
          class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
        >
          Password was changed successfully
        </h2>
        <span id="viewtitle" class="hidden">Password Changed</span>
      </div>
      <div v-else role="region" aria-labelledby="viewtitle2">
        <h2
          class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
        >
          Check your email inbox
        </h2>
        <p class="text-gray-600">We just sent a link to {{ email }}</p>
        <span id="viewtitle2" class="hidden">Email sent</span>
      </div>
    </Teleport>
    <div class="grid grid-cols-1 gap-4 sm:gap-6">
      <div class="mt-6 text-center text-gray-600">
        <router-link :to="{ path: linkTo }">
          <button
            class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900"
            data-cy="gotItButton"
          >
            <span
              ><span v-if="!isPassSet">Got it</span>
              {{ isPassSet ? copy : "" }}
            </span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResetConfirmation",
  props: {
    email: {
      type: String,
      default: "",
    },
    isPassSet: {
      type: Boolean,
      default: false,
    },
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeComponent"],

  data() {
    return {
      linkTo: "/sign-in",
      copy: "Got it, let me login",
    };
  },
  //check if token is null

  created() {
    if (
      typeof Storage !== "undefined" &&
      localStorage.getItem("formioToken") !== null
    ) {
      this.copy = "Go to My Applications";
      this.linkTo = "/applications";
    } else if (
      this.$cookies.isKey("formio_api_token") ||
      this.$cookies.isKey("formio_owner_id")
    ) {
      this.copy = "Go to My Applications";
      this.linkTo = "/applications";
    }
  },
};
</script>

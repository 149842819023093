const messages = {
  required: "Field is required",
  requiredIf: "Field is required",
  sameAs: {
    acceptCheckbox: "You need to accept this in order to save this form.",
  },
  maxLength: "Max length must be:",
  decimal: "Only decimal values allowed.",
  email: "Invalid email.",
  isValidDate: "Invalid date",
  isValidMinDate: "Invalid minimum year",
  mustBePositive: "Invalid value",
  mustNotHaveHyphen: "Hyphens are not allowed",
};

export default {
  props: {
    validationErrors: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      validationMessage: messages,
    };
  },
  methods: {
    /**
     * Show the validation message and the validation rule param of the specified key
     * rule: corresponds to vuelidate rule name ex: 'required'
     * key : corresponds to the field name we are validating wich is an object key in the v$ validations object that vuelidate generates
     * this method was created to be use with the maxlength validation, in this case it returns the validation message for maxlength and the max
     * number of characters allowed that is in v$.fieldwearevalidating.$params.maxlength.max
     * more rules/params combination can be added by adding additional if statements
     * vuelidates v$ has to be passed as a prop 'validationErrors' to the nested component that will use this showMessage function.
     * If you do not need the param you can just call this.validationMessage[rule] in your code instead of show message.
     */
    showMessage(key, rule) {
      if (rule === "maxLength") {
        return (
          this.validationMessage[rule] +
          " " +
          this.validationErrors[key].maxLength.$params.max
        );
      }
      return this.validationMessage[rule];
    },
  },
  computed: {
    /**
     * Sort the passed v$ obj and remove non validation rules keys
     * @returns object with validation rules only
     */
    sortedValidationRules() {
      return Object.keys(this.validationErrors)
        .filter((key) => !key.startsWith("$"))
        .reduce((obj, key) => {
          obj[key] = this.validationErrors[key];
          return obj;
        }, {});
    },
    /**
     *  Same as above but for nested validations
     */
    sortNestedObjectsValidationRules() {
      return Object.keys(this.sortedValidationRules)
        .filter((key) => !key.startsWith("$"))
        .reduce((obj, key) => {
          obj[key] = Object.keys(this.validationErrors[key]).filter(
            (key) => !key.startsWith("$")
          );
          return obj;
        }, {});
    },
  },
};

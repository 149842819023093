export const CONSTANT_KEYS = Object.freeze({
  PASSWORD_STORAGE_KEY: "pending-forgot-password",
  RESET_TOKEN_STORAGE_KEY: "resettoken",
  RESET_EMAIL: "reset-email",
  UI_VERSION:
    process.env.MIX_UI_VERSION_FLAG &&
    process.env.MIX_UI_VERSION_FLAG.toLowerCase() === "true"
      ? "v2"
      : "",
});

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SEXES = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const GENDERS = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Non-binary",
    value: "nonBinary",
  },
];

export const ACADEMIC_YEARS = [
  {
    oldValue: "highSchoolSenior",
    value: "highSchoolFourthYear",
    label: "High School Fourth Year",
  },
  {
    oldValue: "Univesity Freshman",
    value: "universityFirstYear",
    label: "University First Year",
  },
  {
    oldValue: "universitySophomore",
    value: "universitySecondYear",
    label: "University Second Year",
  },
  {
    oldValue: "University Junior",
    value: "universityThirdYear",
    label: "University Third Year",
  },
  {
    oldValue: "University Senior",
    value: "universityFourthYear",
    label: "University Fourth Year",
  },
  {
    value: "graduateStudent",
    label: "Graduate Student",
  },
  {
    oldValue: "notCurrentlyEnrolled",
    value: "notEnrolled",
    label: "Not Enrolled",
  },
  {
    oldValue: "highSchoolFreshman",
    value: "highSchoolFirstYear",
    label: "High School First Year",
  },
  {
    oldValue: "highSchoolSophmore",
    value: "highSchoolSecondYear",
    label: "High School Second Year",
  },
  {
    oldValue: "highSchoolJunior",
    value: "highSchoolThirdYear",
    label: "High School Third Year",
  },
  {
    oldValue: "risingFreshman",
    value: "incomingUndergraduateStudent",
    label: "Incoming Undergraduate Student",
  },
];

export const LANGUAGES = [
  {
    label: "No Background",
    value: "noBackground",
  },
  {
    label: "Beginner",
    value: "beginner",
  },
  {
    label: "Intermediate",
    value: "intermediate",
  },
  {
    label: "Advanced",
    value: "advanced",
  },
  {
    label: "Native ",
    value: "native",
  },
];

export const MAJORS = [
  {
    label: "Architecture",
    value: "Architecture",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Agribusiness",
    value: "Agribusiness",
  },
  {
    label: "American Studies",
    value: "American Studies",
  },
  {
    label: "Animal Science",
    value: "Animal Science",
  },
  {
    label: "Agroecology",
    value: "Agroecology",
  },
  {
    label: "Agriculture Education",
    value: "Agriculture Education",
  },
  {
    label: "Plant Science",
    value: "Plant Science",
  },
  {
    label: "General",
    value: "General",
  },
  {
    label: "Area, Ethnic, and Cultural Studies",
    value: "Area, Ethnic, and Cultural Studies",
  },
  {
    label: "Latin American Studies",
    value: "Latin American Studies",
  },
  {
    label: "European Studies",
    value: "European Studies",
  },
  {
    label: "Judaic and Near Eastern Studies",
    value: "Judaic and Near Eastern Studies",
  },
  {
    label: "Cultural Studies, General",
    value: "Cultural Studies, General",
  },
  {
    label: "Biological and Biomedical Sciences",
    value: "Biological and Biomedical Sciences",
  },
  {
    label: "Anatomy/Physiology",
    value: "Anatomy/Physiology",
  },
  {
    label: "Biochemistry",
    value: "Biochemistry",
  },
  {
    label: "Biology",
    value: "Biology",
  },
  {
    label: "Botany, Ecology, and Biodiversity",
    value: "Botany, Ecology, and Biodiversity",
  },
  {
    label: "Marine Biology",
    value: "Marine Biology",
  },
  {
    label: "Microbiology",
    value: "Microbiology",
  },
  {
    label: "Neuroscience",
    value: "Neuroscience",
  },
  {
    label: "Zoology",
    value: "Zoology",
  },
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Accounting and Related Services",
    value: "Accounting and Related Services",
  },
  {
    label: "Business Administration, Management and Operations",
    value: "Business Administration, Management and Operations",
  },
  {
    label: "Entrepreneurial and Small Business Operations",
    value: "Entrepreneurial and Small Business Operations",
  },
  {
    label: "Finance, Applied Economics, and Financial Management Services",
    value: "Finance, Applied Economics, and Financial Management Services",
  },
  {
    label: "Hospitality and Tourism Management",
    value: "Hospitality and Tourism Management",
  },
  {
    label: "Human Resources Management and Services",
    value: "Human Resources Management and Services",
  },
  {
    label: "International Business",
    value: "International Business",
  },
  {
    label: "Management Information Systems",
    value: "Management Information Systems",
  },
  {
    label: "Marketing and Advertising",
    value: "Marketing and Advertising",
  },
  {
    label: "Sports and Recreation Management",
    value: "Sports and Recreation Management",
  },
  {
    label: "Communication and Media Studies",
    value: "Communication and Media Studies",
  },
  {
    label: "Computer and Information Sciences",
    value: "Computer and Information Sciences",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Engineering",
    value: "Engineering",
  },
  {
    label: "Aerospace, Aeronautical and Astronautical Engineering",
    value: "Aerospace, Aeronautical and Astronautical Engineering",
  },
  {
    label: "Agricultural Engineering",
    value: "Agricultural Engineering",
  },
  {
    label: "Bioengineering/Biomedical/Medical Engineering",
    value: "Bioengineering/Biomedical/Medical Engineering",
  },
  {
    label: "Chemical Engineering",
    value: "Chemical Engineering",
  },
  {
    label: "Civil Engineering",
    value: "Civil Engineering",
  },
  {
    label: "Computer and Electrical Engineering",
    value: "Computer and Electrical Engineering",
  },
  {
    label: "Computer and Electronic Engineering",
    value: "Computer and Electronic Engineering",
  },
  {
    label: "Environmental/Environmental Health Engineering",
    value: "Environmental/Environmental Health Engineering",
  },
  {
    label: "Industrial Engineering",
    value: "Industrial Engineering",
  },
  {
    label: "Mechanical Engineering",
    value: "Mechanical Engineering",
  },
  {
    label: "Petroleum Engineering",
    value: "Petroleum Engineering",
  },
  {
    label: "English Language and Literature",
    value: "English Language and Literature",
  },
  {
    label: "Language",
    value: "Language",
  },
  {
    label: "Literature",
    value: "Literature",
  },
  {
    label: "Writing",
    value: "Writing",
  },
  {
    label: "Environmental, Natural Resource, and Conservation Studies",
    value: "Environmental, Natural Resource, and Conservation Studies",
  },
  {
    label: "Foreign Languages, Literatures, and Linguistics",
    value: "Foreign Languages, Literatures, and Linguistics",
  },
  {
    label: "Arabic Language and Literature",
    value: "Arabic Language and Literature",
  },
  {
    label: "Chinese Language and Literature",
    value: "Chinese Language and Literature",
  },
  {
    label: "Celtic Languages and Literatures",
    value: "Celtic Languages and Literatures",
  },
  {
    label: "Classics and Classical Languages and Literatures",
    value: "Classics and Classical Languages and Literatures",
  },
  {
    label: "Comparative and Related Language Studies",
    value: "Comparative and Related Language Studies",
  },
  {
    label: "Eastern European Languages and Literatures",
    value: "Eastern European Languages and Literatures",
  },
  {
    label: "German Language and Literature",
    value: "German Language and Literature",
  },
  {
    label: "Spanish Language and Literature",
    value: "Spanish Language and Literature",
  },
  {
    label: "French Language and Literature",
    value: "French Language and Literature",
  },
  {
    label: "Italian Language and Literature",
    value: "Italian Language and Literature",
  },
  {
    label: "Linguistics",
    value: "Linguistics",
  },
  {
    label: "Health Sciences",
    value: "Health Sciences",
  },
  {
    label: "Food Science/Nutrition",
    value: "Food Science/Nutrition",
  },
  {
    label: "Nursing",
    value: "Nursing",
  },
  {
    label: "Public Health",
    value: "Public Health",
  },
  {
    label: "Sports and Exercise Science/Kinesiology",
    value: "Sports and Exercise Science/Kinesiology",
  },
  {
    label: "History",
    value: "History",
  },
  {
    label: "Mathematics and Statistics",
    value: "Mathematics and Statistics",
  },
  {
    label: "Philosophy",
    value: "Philosophy",
  },
  {
    label: "Physical Sciences",
    value: "Physical Sciences",
  },
  {
    label: "Astronomy and Astrophysics",
    value: "Astronomy and Astrophysics",
  },
  {
    label: "Atmospheric Sciences and Meteorology",
    value: "Atmospheric Sciences and Meteorology",
  },
  {
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    label: "Geological and Earth Sciences/Geosciences",
    value: "Geological and Earth Sciences/Geosciences",
  },
  {
    label: "Materials Sciences",
    value: "Materials Sciences",
  },
  {
    label: "Physics",
    value: "Physics",
  },
  {
    label: "Religious Studies",
    value: "Religious Studies",
  },
  {
    label: "Social Sciences",
    value: "Social Sciences",
  },
  {
    label: "Anthropology and Archaeology",
    value: "Anthropology and Archaeology",
  },
  {
    label: "Criminology, Law, and Legal Studies",
    value: "Criminology, Law, and Legal Studies",
  },
  {
    label: "Economics",
    value: "Economics",
  },
  {
    label: "Geography and Cartography",
    value: "Geography and Cartography",
  },
  {
    label: "International Relations and National Security Studies",
    value: "International Relations and National Security Studies",
  },
  {
    label: "Political Science and Government",
    value: "Political Science and Government",
  },
  {
    label: "Psychology",
    value: "Psychology",
  },
  {
    label: "Sociology, Social Policy, and Social Work",
    value: "Sociology, Social Policy, and Social Work",
  },
  {
    label: "Women, Gender and Sexuality Studies",
    value: "Women, Gender and Sexuality Studies",
  },
  {
    label: "Visual and Performing Arts",
    value: "Visual and Performing Arts",
  },
  {
    label: "Art History",
    value: "Art History",
  },
  {
    label: "Acting, Theater Arts, and Technical Theater",
    value: "Acting, Theater Arts, and Technical Theater",
  },
  {
    label: "Arts, Entertainment,and Media Management",
    value: "Arts, Entertainment,and Media Management",
  },
  {
    label: "Dance",
    value: "Dance",
  },
  {
    label: "Design, Fashion, and Applied Arts",
    value: "Design, Fashion, and Applied Arts",
  },
  {
    label: "Film/Video and Photographic Arts",
    value: "Film/Video and Photographic Arts",
  },
  {
    label: "Fine and Studio Arts",
    value: "Fine and Studio Arts",
  },
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Undecided",
    value: "Undecided",
  },
];

export const INSTRUCTION_LANGUAGES = [
  {
    value: "arabic",
    label: "Arabic",
  },
  {
    value: "basque",
    label: "Basque",
  },
  {
    value: "catalan",
    label: "Catalan",
  },
  {
    value: "chinese",
    label: "Chinese",
  },
  {
    value: "croatian",
    label: "Croatian",
  },
  {
    value: "czech",
    label: "Czech",
  },
  {
    value: "dzongkha",
    label: "Dzongkha",
  },
  {
    value: "english",
    label: "English",
  },
  {
    value: "french",
    label: "French",
  },
  {
    value: "german",
    label: "German",
  },
  {
    value: "hungarian",
    label: "Hungarian",
  },
  {
    value: "italian",
    label: "Italian",
  },
  {
    value: "latin",
    label: "Latin",
  },
  {
    value: "mandarin",
    label: "Mandarin",
  },
  {
    value: "polish",
    label: "Polish",
  },
  {
    value: "portuguese",
    label: "Portuguese",
  },
  {
    value: "spanish",
    label: "Spanish",
  },
];

export const INTERNSHIP_AREAS = [
  {
    name: "Accounting",
  },
  {
    name: "Addiction",
  },
  {
    name: "Advertising",
  },
  {
    name: "Advocacy",
  },
  {
    name: "Animal Welfare",
  },
  {
    name: "Animation",
  },
  {
    name: "Architecture",
  },
  {
    name: "Asset Management",
  },
  {
    name: "Autism",
  },
  {
    name: "Banking",
  },
  {
    name: "Biology",
  },
  {
    name: "Broadcasting",
  },
  {
    name: "Business",
  },
  {
    name: "Business Administration",
  },
  {
    name: "Business Development",
  },
  {
    name: "Business Operations",
  },
  {
    name: "Charities",
  },
  {
    name: "Chemical Engineering",
  },
  {
    name: "Chemistry",
  },
  {
    name: "Civil Engineering",
  },
  {
    name: "Climate Change",
  },
  {
    name: "Coaching",
  },
  {
    name: "Communications",
  },
  {
    name: "Computer Science",
  },
  {
    name: "Conservation",
  },
  {
    name: "Construction",
  },
  {
    name: "Copy Writing",
  },
  {
    name: "Criminology",
  },
  {
    name: "Culinary",
  },
  {
    name: "Curating",
  },
  {
    name: "Customer Service",
  },
  {
    name: "Data Analytics",
  },
  {
    name: "Ecology",
  },
  {
    name: "Economics",
  },
  {
    name: "Education Administration",
  },
  {
    name: "Elementary Education",
  },
  {
    name: "Engineering",
  },
  {
    name: "Entomology",
  },
  {
    name: "Environmental",
  },
  {
    name: "Environmental Planning",
  },
  {
    name: "Environmental Policy",
  },
  {
    name: "Event Management",
  },
  {
    name: "Event Planning",
  },
  {
    name: "Exercise Science",
  },
  {
    name: "Family Planning",
  },
  {
    name: "Fashion",
  },
  {
    name: "Film",
  },
  {
    name: "Finance",
  },
  {
    name: "Financial Analyst",
  },
  {
    name: "Forestry",
  },
  {
    name: "Geography",
  },
  {
    name: "Graphic Design",
  },
  {
    name: "Health",
  },
  {
    name: "Hospitality",
  },
  {
    name: "Hotel Management",
  },
  {
    name: "Human Rights",
  },
  {
    name: "Information Technology",
  },
  {
    name: "Interior Design",
  },
  {
    name: "International Development",
  },
  {
    name: "International Trade",
  },
  {
    name: "Investment Banking",
  },
  {
    name: "Journalism",
  },
  {
    name: "Landscape Design",
  },
  {
    name: "Legal Administration",
  },
  {
    name: "Legal Advocacy",
  },
  {
    name: "Legal Research",
  },
  {
    name: "Logistics",
  },
  {
    name: "Marketing",
  },
  {
    name: "Mental Health",
  },
  {
    name: "Natural Resources",
  },
  {
    name: "Neuroscience",
  },
  {
    name: "NGO",
  },
  {
    name: "Painting",
  },
  {
    name: "Performing Arts",
  },
  {
    name: "Photography",
  },
  {
    name: "Physical Education",
  },
  {
    name: "Preschool Education",
  },
  {
    name: "Product Design",
  },
  {
    name: "Psychology",
  },
  {
    name: "Public Health",
  },
  {
    name: "Public Relations",
  },
  {
    name: "Research",
  },
  {
    name: "Resorts",
  },
  {
    name: "Restaurant Management",
  },
  {
    name: "Sales",
  },
  {
    name: "Secondary Education",
  },
  {
    name: "Social Media",
  },
  {
    name: "Social Services",
  },
  {
    name: "Social Welfare",
  },
  {
    name: "Special Education",
  },
  {
    name: "Sports Events",
  },
  {
    name: "Sports Journalism",
  },
  {
    name: "Sports Management",
  },
  {
    name: "Sports Marketing",
  },
  {
    name: "Startups",
  },
  {
    name: "Structural Engineering",
  },
  {
    name: "Sustainability",
  },
  {
    name: "Taxes",
  },
  {
    name: "Theater",
  },
  {
    name: "Therapy",
  },
  {
    name: "Tourism Marketing",
  },
  {
    name: "Vet Clinic",
  },
  {
    name: "Website Development",
  },
  {
    name: "Wildlife Parks",
  },
  {
    name: "Youth Work",
  },
  {
    name: "Zoo Keeping",
  },
];

export const RANKS_OF_IMPORTANCE = [
  {
    name: "Size of the company",
    value: "sizeOfTheCompany",
  },
  {
    name: "Working independently",
    value: "workingIndependently",
  },
  {
    name: "Working as part of a team",
    value: "workingAsPartOfATeam",
  },
  {
    name: "Specific internship project and duties",
    value: "specificInternshipProjectAndDuties",
  },
  {
    name: "Destination",
    value: "destination",
  },
];

export const AREAS = [
  {
    label: "Agriculture Agriculture Operations and Related Sciences",
    value: "agricultureAgricultureOperationsAndRelatedSciences",
  },
  {
    label: "Natural Resources and Conservation",
    value: "naturalResourcesAndConservation",
  },
  {
    label: "Architecture and Related Services",
    value: "architectureAndRelatedServices",
  },
  {
    label: "Area Ethnic Cultural and Gender Studies",
    value: "areaEthnicCulturalAndGenderStudies",
  },
  {
    label: "Communication Journalism and Related Programs",
    value: "communicationJournalismAndRelatedPrograms",
  },
  {
    label: "Communications Technologies Technicians and Support Services",
    value: "communicationsTechnologiesTechniciansAndSupportServices",
  },
  {
    label: "Computer and Information Sciences and Support Services",
    value: "computerAndInformationSciencesAndSupportServices",
  },
  {
    label: "Personal and Culinary Services",
    value: "personalAndCulinaryServices",
  },
  {
    label: "Education",
    value: "education",
  },
  {
    label: "Engineering",
    value: "engineering",
  },
  {
    label: "Engineering, Technologies, Technicians",
    value: "engineeringTechnologiesTechnicians",
  },
  {
    label: "Foreign Languages Literatures and Linguistics",
    value: "foreignLanguagesLiteraturesAndLinguistics",
  },
  {
    label: "Family and Consumer Sciences Human Sciences",
    value: "familyAndConsumerSciencesHumanSciences",
  },
  {
    label: "Legal Professions and Studies",
    value: "legalProfessionsAndStudies",
  },
  {
    label: "English Language and Literature Letters",
    value: "englishLanguageAndLiteratureLetters",
  },
  {
    label: "Liberal Arts and Sciences General Studies and Humanities",
    value: "liberalArtsAndSciencesGeneralStudiesAndHumanities",
  },
  {
    label: "Library Science",
    value: "libraryScience",
  },
  {
    label: "Biological and Biomedical Sciences",
    value: "biologicalAndBiomedicalSciences",
  },
  {
    label: "Mathematics and Statistics",
    value: "mathematicsAndStatistics",
  },
  {
    label: "Reserve Officer Training Corps JrotcRotc",
    value: "reserveOfficerTrainingCorpsJrotcRotc",
  },
  {
    label: "MilitaryTechnologies",
    value: "militaryTechnologies",
  },
  {
    label: "MultiInterdisciplinary Studies",
    value: "multiInterdisciplinaryStudies",
  },
  {
    label: "Parks Recreation Leisure and Fitness Studies",
    value: "parksRecreationLeisureAndFitnessStudies",
  },
  {
    label: "Basic Skills",
    value: "basicSkills",
  },
  {
    label: "Citizenship Activities",
    value: "citizenshipActivities",
  },
  {
    label: "Health Related Knowledge and Skills",
    value: "healthRelatedKnowledgeAndSkills",
  },
  {
    label: "Interpersonal and Social Skills",
    value: "interpersonalAndSocialSkills",
  },
  {
    label: "Leisure and Recreational Activities",
    value: "leisureAndRecreationalActivities",
  },
  {
    label: "Personal Awareness and Self Improvement",
    value: "personalAwarenessAndSelfImprovement",
  },
  {
    label: "Philosophy and Religious Studies",
    value: "philosophyAndReligiousStudies",
  },
  {
    label: "Theology and Religious Vocations",
    value: "theologyAndReligiousVocations",
  },
  {
    label: "Physical Sciences",
    value: "physicalSciences",
  },
  {
    label: "Science Technologies Technicians",
    value: "scienceTechnologiesTechnicians",
  },
  {
    label: "Psychology",
    value: "psychology",
  },
  {
    label: "Security and Protective Services",
    value: "securityAndProtectiveServices",
  },
  {
    label: "Public Administration and Social Service Professions",
    value: "publicAdministrationAndSocialServiceProfessions",
  },
  {
    label: "Social Sciences",
    value: "socialSciences",
  },
  {
    label: "Construction Trades",
    value: "constructionTrades",
  },
  {
    label: "Mechanic and Repair Technologies Technicians",
    value: "mechanicAndRepairTechnologiesTechnicians",
  },
  {
    label: "Precision Production",
    value: "precisionProduction",
  },
  {
    label: "Transportation and Materials Moving",
    value: "transportationAndMaterialsMoving",
  },
  {
    label: "Visual and Performing Arts",
    value: "visualAndPerformingArts",
  },
  {
    label: "Health Professions and Related Clinical Sciences",
    value: "healthProfessionsAndRelatedClinicalSciences",
  },
  {
    label: "Business Management Marketing and Related Support Services",
    value: "businessManagementMarketingAndRelatedSupportServices",
  },
  {
    label: "High School Secondary Diplomas and Certificates",
    value: "highSchoolSecondaryDiplomasAndCertificates",
  },
  {
    label: "History",
    value: "history",
  },
  {
    label: "Residency Programs",
    value: "residencyPrograms",
  },
];

export const POST_FORM_RULES = {
  Housing: [
    "Housing",
    "Accommodation Style Preference",
    "Single Room Preference",
    "Requesting a Roommate",
    "Declining a Roommate",
    "Requesting a Housemate",
    "Ranking Your Housing Preference",
    "Housing Questionnaire",
  ],
  "Academic Information": ["Birth Certificate Copy"],
  "Participant Agreement": [
    "Virtual Participant Agreement",
    "Study and Intern Participant Agreement",
  ],
  "Code Of Conduct": [
    "Intern Code of Conduct",
    "Study Code of Conduct",
    "Virtual Study Code of Conduct",
    "Virtual Intern Code of Conduct",
  ],
  "Travel Documents": [
    "Visa Upload",
    "Visa Appointment Information (online)",
    "Visa Appointment Information (In Person)",
    "Passport Info",
    "Group Visa Information",
  ],
  "Travel Plans": [
    "CP Departure Guidelines",
    "Arrival Guidelines",
    "Arrival FAQ",
    "Departure Guidelines",
    "Student Travel Arrangements",
    "CP Arrival Guidelines - Group Flight",
    "CP Arrival FAQ",
  ],
  "Badge Interests": ["Badge Interests"],
  "Customized Code Of Conduct": ["CP Code of Conduct- Study"],
  "CP Participant Agreement Form - No Insurance or Direct Pay": [
    "CP Participant Agreement Form - No Insurance or Direct Pay",
  ],
};

export const APPROVAL_STATUS = [
  {
    label: "Approve",
    value: "Approve",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const DISCIPLINARY_RECORDS_UNIVERSITIES = [
  {
    name: "Bridgewater State University",
    email: "communitystandards@bridgew.edu",
  },
  {
    name: "Clemson University",
    email: "ljclay@clemson.edu",
  },
  {
    name: "San Diego State University",
    email: "csrr@sdsu.edu",
  },
  {
    name: "University of Nebraska-Lincoln",
    email: "studentconduct@unl.edu",
  },
  {
    name: "University of South Carolina-Columbia",
    email: "saosc@mailbox.sc.edu",
  },
];

export const AIRLINES = [
  { label: "Aegean Airlines", value: "Aegean Airlines" },
  { label: "Aer Lingus", value: "Aer Lingus" },
  { label: "Aeroflot", value: "Aeroflot" },
  { label: "Aerolineas Argentinas", value: "Aerolineas Argentinas" },
  { label: "Aeromexico", value: "Aeromexico" },
  { label: "Air Arabia", value: "Air Arabia" },
  { label: "Air Astana", value: "Air Astana" },
  { label: "Air Austral", value: "Air Austral" },
  { label: "Air Baltic", value: "Air Baltic" },
  { label: "Air Belgium", value: "Air Belgium" },
  { label: "Air Canada", value: "Air Canada" },
  { label: "Air Caraibes", value: "Air Caraibes" },
  { label: "Air China", value: "Air China" },
  { label: "Air Corsica", value: "Air Corsica" },
  { label: "Air Dolomiti", value: "Air Dolomiti" },
  { label: "Air Europa", value: "Air Europa" },
  { label: "Air France", value: "Air France" },
  { label: "Air India", value: "Air India" },
  { label: "Air India Express", value: "Air India Express" },
  { label: "Air Macau", value: "Air Macau" },
  { label: "Air Malta", value: "Air Malta" },
  { label: "Air Mauritius", value: "Air Mauritius" },
  { label: "Air Namibia", value: "Air Namibia" },
  { label: "Air New Zealand", value: "Air New Zealand" },
  { label: "Air North", value: "Air North" },
  { label: "Air Seoul", value: "Air Seoul" },
  { label: "Air Serbia", value: "Air Serbia" },
  { label: "Air Tahiti Nui", value: "Air Tahiti Nui" },
  { label: "Air Transat", value: "Air Transat" },
  { label: "Air Vanuatu", value: "Air Vanuatu" },
  { label: "AirAsia", value: "AirAsia" },
  { label: "AirAsia X", value: "AirAsia X" },
  { label: "Aircalin", value: "Aircalin" },
  { label: "Alaska Airlines", value: "Alaska Airlines" },
  { label: "Alitalia", value: "Alitalia" },
  { label: "Allegiant", value: "Allegiant" },
  { label: "American Airlines", value: "American Airlines" },
  { label: "ANA", value: "ANA" },
  { label: "Asiana", value: "Asiana" },
  { label: "Austrian", value: "Austrian" },
  { label: "Avianca", value: "Avianca" },
  { label: "Azerbaijan Hava Yollary", value: "Azerbaijan Hava Yollary" },
  { label: "Azores Airlines", value: "Azores Airlines" },
  { label: "Azul", value: "Azul" },
  { label: "Bamboo Airways", value: "Bamboo Airways" },
  { label: "Bangkok Airways", value: "Bangkok Airways" },
  { label: "British Airways", value: "British Airways" },
  { label: "Brussels Airlines", value: "Brussels Airlines" },
  { label: "Caribbean Airlines", value: "Caribbean Airlines" },
  { label: "Cathay Dragon", value: "Cathay Dragon" },
  { label: "Cathay Pacific", value: "Cathay Pacific" },
  { label: "Cayman Airways", value: "Cayman Airways" },
  { label: "CEBU Pacific Air", value: "CEBU Pacific Air" },
  { label: "China Airlines", value: "China Airlines" },
  { label: "China Eastern", value: "China Eastern" },
  { label: "China Southern", value: "China Southern" },
  { label: "Condor", value: "Condor" },
  { label: "Copa Airlines", value: "Copa Airlines" },
  { label: "Croatia Airlines", value: "Croatia Airlines" },
  { label: "Czech Airlines", value: "Czech Airlines" },
  { label: "Delta", value: "Delta" },
  { label: "easyJet", value: "easyJet" },
  { label: "Edelweiss Air", value: "Edelweiss Air" },
  { label: "Egyptair", value: "Egyptair" },
  { label: "EL AL", value: "EL AL" },
  { label: "Emirates", value: "Emirates" },
  { label: "Ethiopian Airlines", value: "Ethiopian Airlines" },
  { label: "Etihad", value: "Etihad" },
  { label: "Eurowings", value: "Eurowings" },
  { label: "EVA Air", value: "EVA Air" },
  { label: "Fiji Airways", value: "Fiji Airways" },
  { label: "Finnair", value: "Finnair" },
  { label: "flydubai", value: "flydubai" },
  { label: "FlyOne", value: "FlyOne" },
  { label: "French bee", value: "French bee" },
  { label: "Frontier", value: "Frontier" },
  { label: "Garuda Indonesia", value: "Garuda Indonesia" },
  { label: "Gol", value: "Gol" },
  { label: "Gulf Air", value: "Gulf Air" },
  { label: "Hainan Airlines", value: "Hainan Airlines" },
  { label: "Hawaiian Airlines", value: "Hawaiian Airlines" },
  { label: "Helvetic Airways", value: "Helvetic Airways" },
  { label: "HK Express", value: "HK Express" },
  { label: "Hong Kong Airlines", value: "Hong Kong Airlines" },
  { label: "Iberia", value: "Iberia" },
  { label: "Icelandair", value: "Icelandair" },
  { label: "IndiGo Airlines", value: "IndiGo Airlines" },
  { label: "InterJet", value: "InterJet" },
  { label: "Japan Airlines", value: "Japan Airlines" },
  { label: "Jeju Air", value: "Jeju Air" },
  { label: "Jet2", value: "Jet2" },
  { label: "JetBlue", value: "JetBlue" },
  { label: "Jetstar", value: "Jetstar" },
  { label: "Jin Air", value: "Jin Air" },
  { label: "Kenya Airways", value: "Kenya Airways" },
  { label: "KLM", value: "KLM" },
  { label: "Korean Air", value: "Korean Air" },
  { label: "Kulula", value: "Kulula" },
  { label: "La Compagnie", value: "La Compagnie" },
  { label: "LATAM", value: "LATAM" },
  { label: "Lion Airlines", value: "Lion Airlines" },
  { label: "LOT Polish Airlines", value: "LOT Polish Airlines" },
  { label: "Lufthansa", value: "Lufthansa" },
  { label: "Luxair", value: "Luxair" },
  { label: "Malaysia Airlines", value: "Malaysia Airlines" },
  { label: "Mango", value: "Mango" },
  { label: "Middle East Airlines", value: "Middle East Airlines" },
  { label: "Nok Air", value: "Nok Air" },
  { label: "Nordwind Airlines", value: "Nordwind Airlines" },
  {
    label: "Norwegian Air International",
    value: "Norwegian Air International",
  },
  { label: "Norwegian Air Shuttle", value: "Norwegian Air Shuttle" },
  { label: "Norwegian Air Sweden", value: "Norwegian Air Sweden" },
  { label: "Norwegian Air UK", value: "Norwegian Air UK" },
  { label: "Other", value: "other" },
  { label: "Oman Air", value: "Oman Air" },
  {
    label: "Pakistan International Airlines",
    value: "Pakistan International Airlines",
  },
  { label: "Peach", value: "Peach" },
  { label: "Pegasus Airlines", value: "Pegasus Airlines" },
  { label: "Philippine Airlines", value: "Philippine Airlines" },
  { label: "Porter", value: "Porter" },
  { label: "Qantas", value: "Qantas" },
  { label: "Qatar Airways", value: "Qatar Airways" },
  { label: "Regional Express", value: "Regional Express" },
  { label: "Rossiya - Russian Airlines", value: "Rossiya - Russian Airlines" },
  { label: "Royal Air Maroc", value: "Royal Air Maroc" },
  { label: "Royal Brunei", value: "Royal Brunei" },
  { label: "Royal Jordanian", value: "Royal Jordanian" },
  { label: "RwandAir", value: "RwandAir" },
  { label: "Ryanair", value: "Ryanair" },
  { label: "S7 Airlines", value: "S7 Airlines" },
  { label: "SAS", value: "SAS" },
  { label: "Saudia", value: "Saudia" },
  { label: "Scoot Airlines", value: "Scoot Airlines" },
  { label: "Shanghai Airlines", value: "Shanghai Airlines" },
  { label: "Silkair", value: "Silkair" },
  { label: "Silver", value: "Silver" },
  { label: "Singapore Airlines", value: "Singapore Airlines" },
  { label: "Skylanes", value: "Skylanes" },
  { label: "South African Airways", value: "South African Airways" },
  { label: "Southwest", value: "Southwest" },
  { label: "SpiceJet", value: "SpiceJet" },
  { label: "Spirit", value: "Spirit" },
  { label: "Spring Airlines", value: "Spring Airlines" },
  { label: "Spring Japan", value: "Spring Japan" },
  { label: "SriLankan Airlines", value: "SriLankan Airlines" },
  { label: "Sun Country", value: "Sun Country" },
  { label: "Sunclass Airlines", value: "Sunclass Airlines" },
  { label: "Sunwing", value: "Sunwing" },
  { label: "SWISS", value: "SWISS" },
  { label: "Swoop", value: "Swoop" },
  { label: "TAAG", value: "TAAG" },
  { label: "TACA", value: "TACA" },
  { label: "TAP Portugal", value: "TAP Portugal" },
  { label: "THAI", value: "THAI" },
  { label: "tigerair Australia", value: "tigerair Australia" },
  { label: "Transavia Airlines", value: "Transavia Airlines" },
  { label: "TUI UK", value: "TUI UK" },
  { label: "TUIfly", value: "TUIfly" },
  { label: "Tunis Air", value: "Tunis Air" },
  { label: "Turkish Airlines", value: "Turkish Airlines" },
  { label: "Ukraine International", value: "Ukraine International" },
  { label: "United", value: "United" },
  { label: "Ural Airlines", value: "Ural Airlines" },
  { label: "UTair Aviation", value: "UTair Aviation" },
  { label: "Uzbekistan Airways", value: "Uzbekistan Airways" },
  { label: "Vietnam Airlines", value: "Vietnam Airlines" },
  { label: "Virgin Atlantic", value: "Virgin Atlantic" },
  { label: "Virgin Australia", value: "Virgin Australia" },
  { label: "Vistara", value: "Vistara" },
  { label: "Viva Aerobus", value: "Viva Aerobus" },
  { label: "Volaris", value: "Volaris" },
  { label: "Volotea", value: "Volotea" },
  { label: "Vueling Airlines", value: "Vueling Airlines" },
  { label: "WestJet", value: "WestJet" },
  { label: "Wizzair", value: "Wizzair" },
  { label: "Xiamen Airlines", value: "Xiamen Airlines" },
];

export const SKUS = {
  cc: process.env.MIX_APP_ENV === "production" ? "1321" : "1318",
  ach: process.env.MIX_APP_ENV === "production" ? "1320" : "1317",
};

export const STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export const STRIPE_ERRORS = {
  insufficient_funds: "Insufficient funds",
  approve_with_id: "The payment can’t be authorized.",
  call_issuer: "The card was declined for an unknown reason.",
  card_not_supported: "The card does not support this type of purchase.",
  do_not_honor: "You need to contact your card issuer for more information.",
  do_not_try_again:
    "You need to contact your card issuer for more information.",
  duplicate_transaction:
    "A transaction with identical amount and credit card information was submitted very recently.",
  test_mode_live_card:
    "Your card was declined. Your request was in test mode, but used a non test (live) card. For a list of valid test cards, visit: https://stripe.com/docs/testing.",
  api_error:
    "Something went wrong while trying to pay. Please try again later or contact productsupport@apiabroad.com.",
  default: "The card was declined for an unknown reason.",
  incorrect_cvc:
    "The card’s security code is incorrect. Check the card’s security code or use a different card",
};

// Creates Titles for Formio Forms
export const TAB_TITLES = {
  "code-of-conduct": "Code of Conduct",
  "cover-letter": "Statement of Purpose",
  "digital-badges": "Digital Badges",
  etranscript: "E-Transcript",
  "global-course-selection": "Course Selection",
  "group-visa-information": "Group Visa Information",
  "grade-report": "Grade Report",
  headshot: "Headshot",
  housing: "Housing",
  "internship-credit-options": "Internship Credit Options",
  "internship-interests-form": "Internship Interest",
  "internship-letter-of-recommendation": "Letter of Recommendation",
  "ldm-registration-policy": "LDM Registration Policy",
  passport: "Passport",
  resume: "Resume",
  "statement-of-purpose": "Statement of Purpose",
  "study-abroad-letter-of-recommendation": "Letter of Recommendation",
  "supplemental-form": "Supplemental Form",
  "transcript-hard-copy": "Transcript",
  "university-approval-request": "University Approval Request",
  "unofficial-transcript": "Unofficial Transcript",
};

<template>
    <div
        v-bind:class="{ 'border-dashed opacity-33': dashed }"
        class="divider flex-auto border-b-2 border-blue-700 w-auto"
    ></div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        dashed: {
            default: false,
            type: Boolean
        }
    },
    computed: {},
    mounted() {}
};
</script>

<template>
  <div />
</template>

<script>
import formService from "@/services/form";
import * as Sentry from "@sentry/vue";
import { mapGetters } from "vuex";

export default {
  name: "LandingPage",
  computed: {
    ...mapGetters({
      permissions: "getPermissions",
      isLoggedIn: "isLoggedIn",
      currentUser: "getCurrentUser",
    }),
    hasStudentManagementPermission() {
      return this.permissions.includes("student-management");
    },
    hasStudentViewPermission() {
      return this.permissions.includes("api-employee-student-view");
    },
    isParticipant() {
      return this.permissions.includes("participants");
    },
  },
  async created() {
    if (!this.isLoggedIn) {
      await this.$store.dispatch("unifiedLogin");
      if (this.isParticipant) {
        await this.$store.dispatch("initialSetUp");
      } else if (this.hasStudentManagementPermission) {
        let user = await formService.currentFormServiceUser();

        if (this.hasStudentManagementPermission) {
          await this.$store.dispatch(
            "university/updateUniversity",
            user.data.university.value
          );

          this.$store.commit("university/setUserDetails", {
            email: user.data.email,
            name: user.data.firstname + " " + user.data.lastname,
            university: user.data.university.label,
            universityId: user.data.university.value,
            loggedIn: true,
            dynamicUniversity: user.data.dynamicUniversity,
          });
        }
      }
    }

    if (
      !this.permissions.includes("participants") &&
      !this.permissions.includes("student-management") &&
      !this.currentUser?.email?.includes("apiabroad.com") &&
      this.permissions.includes("everyone")
    ) {
      // Send error message to Sentry
      Sentry.captureMessage(
        `User ${this.currentUser.email} does not have the participant permission`
      );
    }

    if (this.hasStudentViewPermission) {
      await this.$router.push("/students");
      return;
    }

    if (this.hasStudentManagementPermission) {
      await this.$router.push("/my-students");
      return;
    }

    if (this.isParticipant) {
      await this.$router.push("/applications");
    }
  },
};
</script>

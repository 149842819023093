<template>
  <a
    role="link"
    tabindex="0"
    :aria-label="stepLabel"
    :aria-disabled="linkDisabled"
    @click.prevent="goToStep"
    :data-cy="'step-' + stepNumber"
    class="step-complete flex-none px-1 relative"
    :class="stepActive ? 'cursor-pointer clickable' : ''"
  >
    <div
      class="flex items-center justify-center h-8 w-8 bg-blue-700 border-2 border-blue-700 rounded-full"
    >
      <div class="text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-check"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
    </div>
    <span
      class="hidden md:block text-xs mt-2 leading-tight font-semibold whitespace-nowrap block w-auto md:w-30 lg:w-48 absolute top-full truncate text-center right-1/2 transform translate-x-1/2"
      >{{ name }}</span
    >
  </a>
</template>

<script>
import { mapState } from "vuex";
import forms from "../../mixins/forms";
import { eventBus } from "../../app";

export default {
  mixins: [forms],
  props: {
    name: String,
    stepNumber: Number,
    currentStepNumber: Number,
    allowBackNav: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["go-to-step"],
  data() {
    return {
      linkDisabled: false,
      paymentRecordSetOnDatabase: false,
    };
  },
  computed: {
    ...mapState(["applicationPaid"]),
    stepActive() {
      if (!this.allowBackNav) {
        return false;
      }
      //rules to determine if user is allowed to navigate to previus step
      // Given I am on the payment form, but haven't submitted my payment, I expect to be able to go back to the select program page by clicking the step in the navigation.
      // Given I submitted a payment, I expect not to be able to go back to the payment or select program form because I already started my application.
      if (
        this.currentStepNumber <= this.paymentFormIndex &&
        !this.applicationPaid &&
        !this.paymentRecordSetOnDatabase
      ) {
        return true;
      }

      // Given I am on step 3 "Academic Information", I expect not to be able to go back to pay deposit page.
      if (
        this.currentStepNumber >= this.paymentFormIndex + 1 &&
        this.stepNumber !== 0 &&
        this.stepNumber !== this.paymentFormIndex
      ) {
        return true;
      }
      return false;
    },
    stepLabel() {
      let stepName = this.forms[this.stepNumber].name;
      if (this.currentStepNumber === this.stepNumber) {
        return "Current step " + stepName;
      } else if (this.stepActive) {
        return "Go to step " + stepName;
      } else {
        this.setDisabledLink();
        return "Go to step " + stepName;
      }
    },
  },
  created() {
    eventBus.$on("paymentRecordOnDatabase", (value) => {
      this.paymentRecordSetOnDatabase = value;
    });
    this.linkDisabled = !this.allowBackNav;
  },
  methods: {
    goToStep() {
      if (this.stepActive && this.stepNumber !== this.forms.length - 1) {
        this.$emit("go-to-step", this.stepNumber);
      }
    },
    setDisabledLink() {
      this.linkDisabled = true;
    },
  },
};
</script>

<template>
  <div>
    <spinner>
      <template #spinnercontent>
        <p class="text-gray-600">
          {{ message }}
        </p>
        <p>
          Something not working as expected?
        </p>
        <router-link
          :to="{
            name: 'applications',
            params: { applicationId: $route.params.applicationId },
          }"
          class="api-link underline hover:no-underline"
        >
          Click here to go back to the main page.
        </router-link>
      </template>
    </spinner>
  </div>
</template>

<script>
import spinner from "../../helpers/Spinner.vue";
export default {
  name: "CountDown",
  components: {
    spinner,
  },
  props: {
    message: {
      type: String,
      default: "",
    },
    redirectUrlName: {
      type: String,
      default: "applications",
    },
    time: {
      type: Number,
      default: 5000,
    },
  },
  emits: ["redirecting"],
  methods: {
    startCountDown() {
      let counter = this.time / 1000;
      let redirectCountdown = setInterval(() => {
        if (counter === 0) {
          clearInterval(redirectCountdown);
          this.$router.push({ name: this.redirectUrlName });
        }
        counter--;
        this.$emit(
          "redirecting",
          "You don't have access to this form, redirecting back to main page in " +
            counter +
            " seconds"
        );
      }, 1000);
    },
  },
};
</script>

<template>
  <div
    v-if="showCard"
    class="flex items-start py-4 border-b border-gray-200 md:items-center text-gray-650 sm:px-4 sm:py-2 ml-12"
  >
    <div
      v-if="financialAidFormSubmitted"
      class="flex-1 md:gap-x-6 md:grid-cols-16 md:grid md:items-center"
    >
      <div
        class="text-base md:text-sm font-semibold text-gray-600 md:col-span-4 lg:col-span-3"
      >
        Financial Aid (Optional)
      </div>
      <div class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2"></div>
      <div
        class="flex mt-1 text-sm text-gray-600 md:mt-0"
        :class="
          displayToDo
            ? 'md:col-span-6 lg:col-span-7'
            : 'md:col-span-4 lg:col-span-5'
        "
      >
        <div class="items-center lg:flex">
          <div class="lg:col-span-4">
            <FormComplete />
          </div>
          <OptInToggler
            :toggle-selected="toggleSelected"
            @toggleValue="toggle"
          />
        </div>
      </div>
      <VerifyUpdateButton
        :financial-aid-form-completed="financialAidFormSubmitted"
        :toggle-selected="toggleSelected"
        :loading="loading"
        :financial-aid-selected="financialAidSelected"
        :display-to-do="displayToDo"
      />
    </div>
    <div
      v-else
      class="flex-1 md:gap-x-6 md:grid-cols-16 md:grid md:items-center"
    >
      <div
        class="text-base md:text-sm font-semibold text-gray-600 md:col-span-4 lg:col-span-3"
      >
        Financial Aid (Optional)
      </div>
      <div
        v-if="loading"
        class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2"
      >
        <SkeletonLoader
          :min-width="50"
          class="font-semibold text-gray-650"
          :max-width="60"
        />
      </div>
      <div
        v-else
        class="mt-1 text-sm md:col-span-2 md:mt-0 lg:col-span-2"
      ></div>
      <div
        class="flex mt-1 text-sm text-gray-600 md:mt-0"
        :class="
          displayToDo
            ? 'md:col-span-6 lg:col-span-7'
            : 'md:col-span-4 lg:col-span-5'
        "
      >
        <SkeletonLoader
          v-if="loading"
          :min-width="50"
          class="font-semibold text-gray-650"
          :max-width="60"
        />
        <div
          v-else-if="financialAidSelected && !loading"
          class="text-gray-600 leading-tight"
        >
          You indicated you would like to use Financial Aid as part of your
          application.
        </div>
        <div v-else class="items-center lg:flex">
          <div class="leading-tight text-gray-600 lg:col-span-4">
            You did not indicate that you want to use Financial Aid. It's ok if
            you've changed your mind! You can opt-in here.
          </div>
          <OptInToggler
            :toggle-selected="toggleSelected"
            @toggleValue="toggle"
          />
        </div>
      </div>
      <VerifyUpdateButton
        :financial-aid-form-completed="financialAidFormSubmitted"
        :toggle-selected="toggleSelected"
        :loading="loading"
        :financial-aid-selected="financialAidSelected"
        :display-to-do="displayToDo"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SkeletonLoader from "./../helpers/SkeletonLoader";
import OptInToggler from "./OptInToggler.vue";
import FormComplete from "./FormCompletedIcon.vue";
import VerifyUpdateButton from "./VerifyUpdateButton.vue";
import formIoApi from "../../mixins/formIoApi";
import formService from "@/services/form";

export default {
  name: "FinancialAidCard",
  components: {
    SkeletonLoader: SkeletonLoader,
    FormComplete,
    OptInToggler,
    VerifyUpdateButton,
  },
  mixins: [formIoApi],
  props: {
    displayToDo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggleSelected: false,
      loading: false,
      formURL: "programfeespaymentoptions",
    };
  },
  computed: {
    ...mapState(["formioToken", "userData", "currentApplicationSubmissions"]),
    financialAidFormSubmitted() {
      return Object.hasOwn(
        this.currentApplicationSubmissions,
        "financialaidverification"
      );
    },
    submissionId() {
      if (!Object.hasOwn(this.currentApplicationSubmissions, this.formURL)) {
        return "";
      }

      const submissions = this.currentApplicationSubmissions[this.formURL];
      return (
        submissions.filter((submission) => !!submission?.data?.financialAid)[0]
          ?._id ?? ""
      );
    },
    financialAidSelected() {
      if (!Object.hasOwn(this.currentApplicationSubmissions, this.formURL)) {
        return false;
      }

      const submissions = this.currentApplicationSubmissions[this.formURL];
      return submissions.some((submission) => !!submission?.data?.financialAid);
    },
    enrollmentApplicationId() {
      return this.$route.params.applicationId;
    },
    showCard() {
      return this.userData.data.student_type === "college";
    },
  },
  async created() {
    this.toggleSelected = this.financialAidSelected;
  },
  methods: {
    toggle() {
      this.toggleSelected = !this.toggleSelected;
      if (this.submissionId) {
        this.updateFinancialAid(this.toggleSelected);
      } else {
        this.createFinancialAid(this.toggleSelected);
      }
    },
    updateFinancialAid(check) {
      const updateInstructions = [
        { op: "replace", path: "/data/financialAid", value: check },
      ];
      formService
        .partiallyUpdateSubmission(
          this.formURL,
          this.submissionId,
          updateInstructions
        )
        .catch(() => {
          this.toggleSelected = !check;
        });
    },
    createFinancialAid(check) {
      const formioObject = {
        data: {
          application_id: this.enrollmentApplicationId,
          financialAid: check,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.submissionId = response;
        })
        .catch(() => {
          this.toggleSelected = !check;
        });
    },
  },
};
</script>

<template>
  <FormPanel
    id="personalProfilePanel"
    title="Personal Profile"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <PersonalProfile
        ref="profile"
        :show-student-type="handleAsNonEnrolled"
        :handle-as-non-enrolled="handleAsNonEnrolled"
        :enabled-email="false"
        :formio-data="formioData"
        @input="
          (newProfileData) => {
            profileData = newProfileData;
          }
        "
      />
      <div class="mt-10">
        <ButtonWithSpinner
          ref="personalProfileSubmitButton"
          variant="primary"
          variant-type="block"
          @click="submitForm"
        >
          <span>Continue</span>
        </ButtonWithSpinner>
        <div v-if="sendSubmissionError" class="error text-error-900 mt-2">
          {{ sendSubmissionError }}
        </div>
        <p v-if="hasErrors" class="text-error-900">
          Please complete the required fields correctly.
        </p>
      </div>
    </template>
  </FormPanel>
</template>

<script>
import PersonalProfile from "@/components/forms/ProfileReview/PersonalProfile/PersonalProfile.vue";
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import formValidation from "@/mixins/formValidation";
import formIoApi from "@/mixins/formIoApi.js";
import { mapGetters, mapState } from "vuex";
import { eventBus } from "@/app";
import axios from "axios";

export default {
  name: "PersonalProfileForm",
  components: {
    PersonalProfile,
    FormPanel,
    ButtonWithSpinner,
  },
  mixins: [formValidation, formIoApi],
  data() {
    return {
      sectionsToValidate: ["profile"],
      profileData: {},
      formioData: {},
      sendSubmissionError: "",
      submissionId: "",
    };
  },
  computed: {
    ...mapState(["userData"]),
    ...mapGetters(["getCurrentStudentApplication"]),
    handleAsNonEnrolled() {
      return !Object.prototype.hasOwnProperty.call(
        this.userData.data,
        "student_type"
      ) || this.userData.data.student_type !== "college"
        ? true
        : false;
    },
    phone() {
      let phone = this.profileData.phoneCode + this.profileData.phone;
      let numberPattern = /\d+/g;
      return phone.match(numberPattern).join("");
    },
  },
  async created() {
    if (!Object.prototype.hasOwnProperty.call(this.userData, "data"))
      await this.$store.dispatch("cacheFormioUser");
    this.prepopulateForm();
  },
  methods: {
    prepopulateForm() {
      this.formioData = this.userData.data;
      this.submissionId = this.userData._id;
    },
    async submitForm() {
      this.$refs.personalProfileSubmitButton.startLoading();
      this.sendSubmissionError = "";
      eventBus.$emit("dateChange");

      try {
        await this.validate();
        if (!this.hasErrors) this.submitData();
      } catch {
        this.$refs.personalProfileSubmitButton.stopLoading();
      }
    },
    add(field, value) {
      return {
        op: "add",
        path: "/data/" + field,
        value: value,
      };
    },
    async submitData() {
      const jsonData = [
        this.add("firstname", this.profileData.firstName),
        this.add("lastname", this.profileData.lastName),
        this.add("countryCode", this.profileData.phoneCode),
        this.add("phoneNumber", this.profileData.phone),
        this.add("phone", this.phone),
        this.add("birthday", this.profileData.birthday),
        this.add("citizenship", this.profileData.citizenship),
        this.add("gender", this.profileData.gender),
        this.add("ihavedualcitizenship", this.profileData.dualCitizenship),
        this.add("student_type", this.profileData.studentType),
        this.add("parentName", this.profileData.parentName),
        this.add("parentEmail", this.profileData.parentEmail),
      ];

      try {
        await this.submitToFormIo(
          "student",
          jsonData,
          "PATCH",
          this.submissionId
        );
        await this.submitToDB();
        eventBus.$emit("changeStep");
      } catch (error) {
        this.sendSubmissionError = error;
        this.$refs.personalProfileSubmitButton.stopLoading();
      }
    },
    async submitToDB() {
      try {
        let data = Object.assign({}, this.profileData);
        data.phone = this.phone;

        await axios.put("/api/user/update", data);
      } catch (e) {
        let error = new Error(
          "Something went wrong while submitting the form. " +
            e.response.data.message
        );
        throw error;
      }
    },
  },
};
</script>

<template>
  <div>
    <Teleport v-if="parentMounted" to="#portal-title">
      <h2 id="formtitle" class="okta-title mt-6 leading-tight text-center">
        Sign In
      </h2>
    </Teleport>
    <div class="grid grid-cols-1 gap-4 sm:gap-6">
      <h3 v-if="getSessionExpired" class="text-error-900 text-center">
        Your session has expired. Please sign in again.
      </h3>
      <form
        role="form"
        class="grid grid-cols-1 gap-4 sm:gap-6 md:place-items-center"
        aria-labelledby="formtitle"
        @submit.prevent="loginButtonHandler()"
      >
        <div v-if="errorText" class="text-center">
          <p class="mb-4">Please fix the following errors before submitting:</p>
          <p>{{ errorText }}</p>
        </div>
        <div v-else-if="userNotFound" class="text-center">
          <p class="mb-4">Please fix the following errors before submitting:</p>
          <p>
            These credentials do not match our records. Please email
            <a href="mailto:productsupport@apiabroad.com" class="text-teal-500"
              >productsupport@apiabroad.com</a
            >
            if you feel this is an error.
          </p>
        </div>
        <p v-if="loginSuccess">
          Submission Complete
        </p>

        <label class="block text-sm md:w-382px">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            Email
          </span>
          <input
            v-model.trim="v$.value.data.email.$model"
            data-cy="email"
            type="email"
            class="form-input min-h-10 block w-full"
            :class="{ 'bg-error-100': v$.value.data.email.$error }"
            placeholder="ex: my.name@gmail.com"
          />
          <span
            v-if="
              v$.value.data.email.required.$invalid &&
              v$.value.data.email.$error
            "
            class="error text-error-900"
          >
            Field is required
          </span>
          <span
            v-if="v$.value.data.email.email.$invalid"
            class="error text-error-900"
          >
            Email must be a valid email
          </span>
        </label>

        <template v-if="showPassword">
          <label class="block text-sm md:w-382px">
            <span class="font-semibold text-gray-600 flex justify-between mb-2">
              Password
            </span>
            <div class="relative">
              <input
                v-model.trim="v$.value.data.password.$model"
                data-cy="password"
                class="form-input min-h-10 block w-full pr-8"
                :class="{ 'bg-error-100': v$.value.data.password.$error }"
                :type="passwordInputType"
                placeholder="Password"
                required="required"
                minlength="8"
                maxlength="128"
              />
              <a
                class="text-teal-500 font-semibold underline absolute inset-y-0 right-0 flex items-center px-4 focus:outline-none focus:text-teal-900 hover:text-teal-900"
                @click="changeInputType"
              >
                <span v-if="passwordInputType === 'password'">Show</span>
                <span v-if="passwordInputType === 'text'">Hide</span>
              </a>
            </div>
            <span
              v-if="
                v$.value.data.password.required.$invalid &&
                v$.value.data.password.$error
              "
              class="error text-error-900"
            >
              Field is required
            </span>
          </label>
        </template>

        <div class="mt-6">
          <ButtonWithSpinner
            ref="signInSubmit"
            data-cy="sign-in-submit-button"
            type="submit"
            variant="primary"
            variant-type="block"
            class="md:w-186px min-h-50px"
          >
            <span>
              {{ buttonLabel }}
            </span>
          </ButtonWithSpinner>
        </div>
      </form>

      <div class="mt-6 text-center text-gray-600">
        <p>
          Created an account but haven't
          <router-link :to="{ path: '/sign-in/set-password' }" class="api-link">
            set a password?
          </router-link>
        </p>
        <p>
          <router-link
            :to="{ path: '/sign-in/forgot-password' }"
            class="api-link"
          >
            Forgot your Password?
          </router-link>
        </p>
        <p>
          <router-link :to="{ path: '/create-account' }" class="api-link">
            Create an account
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapState } from "vuex";
import { Formio } from "formiojs";
import axios from "axios";

import { email, maxLength, required, requiredIf } from "@vuelidate/validators";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "SignInForm",
  components: { ButtonWithSpinner },
  props: {
    redirectTo: {
      type: String,
      default: "/apply",
    },
    employeeRedirectTo: {
      type: String,
      default: "/students",
    },
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["changeComponent"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        data: {
          email: "",
          password: "",
        },
      },
      isEmployee: false,
      userNotFound: false,
      formUrl: "/login",
      formObject: {},
      passwordInputType: "password",
      errorText: "",
      loginSuccess: false,
      showPassword: false,
    };
  },
  validations: {
    value: {
      data: {
        email: {
          required,
          maxLength: maxLength(128),
          email,
        },
        password: {
          required: requiredIf(function () {
            return this.showPassword;
          }),
        },
      },
    },
  },
  computed: {
    ...mapState(["directProgramData"]),
    ...mapGetters(["getSessionExpired", "getRedirectPath"]),
    id() {
      return "formio-" + this._uid;
    },
    divElement() {
      return document.getElementById(this.id);
    },
    redirect() {
      if (this.hasStudentViewPermission) {
        return this.getSessionExpired && this.getRedirectPath
          ? this.getRedirectPath
          : this.employeeRedirectTo;
      }
      return this.getSessionExpired && this.getRedirectPath
        ? this.getRedirectPath
        : this.redirectTo;
    },
    buttonLabel() {
      return this.showPassword ? "Sign in" : "Next";
    },
  },
  methods: {
    loginButtonHandler() {
      this.$refs.signInSubmit.startLoading();
      this.v$.$touch();

      if (!this.v$.$invalid) {
        if (this.showPassword) this.login();
        else this.redirectIfUserSyncedToOkta();
      } else {
        this.$refs.signInSubmit.stopLoading();
      }
    },
    async login() {
      this.errorText = "";
      this.userNotFound = false;
      try {
        const response = await axios.post("/login", this.value.data);
        if (response.data.is_employee) {
          this.isEmployee = true;
          await this.$store.dispatch("enrollmentAuth");
          window.location.href = this.redirect;
        }
        let submission = await response.data?.formio_user;
        Formio.setBaseUrl(
          `${window.location.protocol}//${window.location.host}`
        );
        Formio.setAuthUrl(
          `${window.location.protocol}//${window.location.host}/api/forms`
        );
        Formio.setToken(response.headers["x-jwt-token"]);
        this.loginSuccess = true;
        await this.$store.dispatch("signIn", {
          apolloClient: this.$apollo,
          submission: submission,
        });
        if (!_.isEmpty(this.directProgramData)) {
          window.location.href =
            "/program/" + this.directProgramData.salesforce_id;
        } else {
          window.location.href = this.redirect;
        }
      } catch (e) {
        if (e?.response?.data?.resetLink) {
          window.location.href = e.response.data.resetLink;
        } else if (e?.response?.data?.userNotFound) {
          this.userNotFound = true;
        } else if (e.response.data?.hasOwnProperty("errors")) {
          Object.values(e.response.data.errors).forEach((field) => {
            field.forEach((error) => {
              this.errorText += error + "\n";
            });
          });
        } else if (e.response.data?.hasOwnProperty("message")) {
          this.errorText += e.response.data.message + "\n";
        } else {
          this.errorText += "Unable to login, try again later \n";
        }
      } finally {
        this.$refs.signInSubmit.stopLoading();
      }
    },
    async redirectIfUserSyncedToOkta() {
      try {
        const response = await axios.post("/synced-to-okta", {
          email: this.value.data.email,
        });

        if (response.data.syncedToOkta) {
          window.location.href =
            "/login/okta?email=" + encodeURIComponent(this.value.data.email);
        } else {
          this.v$.$reset();
          this.showPassword = true;
        }
      } catch (e) {
        this.v$.$reset();
        this.showPassword = true;
      } finally {
        this.$refs.signInSubmit.stopLoading();
      }
    },
    changeInputType() {
      this.passwordInputType =
        this.passwordInputType === "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.okta-title {
  font-size: 1.75rem;
}
</style>

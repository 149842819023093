import MyApplications from "./components/MyApplications.vue";
import EnrollmentLanding from "./components/EnrollmentLanding.vue";
import TravelPlans from "./components/travels/travel-plans/travel-plans.vue";
import CodeOfConduct from "./components/codeOfConduct/CodeOfConduct.vue";
import HealthWellnessForm from "./components/HealthWellness/HealthWellnessForm.vue";
import ParticipantAgreement from "./components/agreements/participant-agreement";
import FinancialAidVerification from "./components/financialAid/FinancialAidVerification.vue";
import NetworkOfSupportPage from "./components/NetworkOfSupport/NetworkOfSupportPage.vue";
import AcademicInformation from "./components/academicInformation/AcademicInformation.vue";
import ParsonsParisAgreement from "./components/parsonsParisAgreement/ParsonsParisAgreement.vue";
import store from "./store";
import PolimodaRegulationsPolicy from "./components/forms/PolimodaRegulationsPolicy/PolimodaRegulationsPolicy.vue";
import SingleFormLoader from "./components/Onboarding/SingleFormLoader";
import SignIn from "./components/SignIn.vue";
import CoursePage from "./components/forms/CourseSelection/CoursePage.vue";
import GroupVisaForm from "./components/forms/GroupVisaInformation/GroupVisaForm.vue";
import Housing from "./components/forms/Housing/HousingForm.vue";
import PassportsForm from "./components/forms/Passport/PassportsForm.vue";
import VisaAppointment from "./components/forms/VisaAppointment/VisaAppointmentForm.vue";
import VisaAndPermitForm from "./components/forms/VisaAndPermit/VisaAndPermitForm.vue";
import EULA_Form from "@/components/forms/EULA/EULA_Form.vue";

import HeadShotForm from "./components/forms/HeadShot/HeadshotForm.vue";
import BadgesForm from "@/components/forms/BadgesInterests/BadgesPage.vue";
import CPCodeOfConduct from "@/components/codeOfConduct/CustomizedCodeOfConduct.vue";
import SupplementalForm from "./components/forms/Supplemental/SupplementalForm.vue";
import ParticipantAgreementIND from "@/components/forms/ParticipantAgreementIND/ParticipantAgreementForm.vue";
import ParticipantAgreementID from "./components/forms/ParticipantAgreementID/ParticipantAgreementForm.vue";
import errorPage from "./components/errorPage";
import { CONSTANT_KEYS } from "./constants";
import NoInsuranceDirectPayForm from "./components/forms/ParticipantAgreement/NoInsuranceDirectPayForm.vue";
import CPAgreementNotInsuranceOrDpay from "@/components/agreements/customized/NoInsuranceOrPayForm.vue";
import HSCodeOfConductForm from "@/components/forms/HSCodeOfConduct/HSCodeOfConductForm.vue";
import EmployeeRegister from "./components/auth/EmployeeRegister";
import Impersonation from "./components/Impersonation/Students";
import PaymentView from "./components/PaymentView";
import LandingPage from "./components/LandingPage";
import MyStudents from "@/university/views/MyStudents";
import StudentDetail from "@/university/views/StudentDetail";
import NotificationSettings from "@/university/views/NotificationSettings.vue";
import StudentManagement from "@/components/StudentManagement.vue";
import FareFinderView from "@/components/FareFinder/FareFinderView";
import HousingModals from "@/components/modals/HousingModals";
import InternshipLibrary from "@/components/InternshipLibrary/InternshipLibrary";
import InternshipDetails from "@/components/InternshipLibrary/InternshipDetails";
import CreateInternshipForm from "@/components/InternshipLibrary/CreateInternshipForm";

const routes = [
  {
    path: "/",
    component: SignIn,
    name: "home",
    props: true,
    meta: {
      title: "Sign in",
      exemptFromLogin: true,
    },
  },
  {
    path: "/fare-finder",
    component: FareFinderView,
    name: "fare-finder",
    meta: {
      title: "Fare Finder",
    },
  },
  {
    path: "/landing-page",
    component: LandingPage,
    name: "landing-page",
    meta: {
      title: "API Connect",
    },
  },
  {
    path: "/EULA",
    component: EULA_Form,
    name: "EULA",
    meta: {
      title: "End User License Agreement",
    },
  },

  {
    path: "/applications",
    component: MyApplications,
    name: "applications",
    meta: {
      title: "My Applications",
    },
  },
  {
    path: "/:applicationId/make-payment",
    component: PaymentView,
    name: "payment",
    meta: {
      title: "Payment",
    },
  },
  {
    path: "/error-page",
    component: errorPage,
    name: "error-page",
    meta: {
      title: "Error",
    },
    props: true,
  },
  {
    path: "/programs/housing/create-new",
    component: HousingModals,
    name: "housing-create",
    meta: {
      title: "Create Housing",
    },
  },
  {
    path: "/program/:programSessionId?",
    component: () => {
      if (CONSTANT_KEYS.UI_VERSION === "v2") {
        return import(
          "./components/forms/customProgram/CustomProgramLandingPage"
        );
      } else {
        return import("./components/errorPage");
      }
    },
    name: "customProgram",
    meta: {
      title: "New custom program",
    },
  },

  {
    path: "/applications/new",
    redirect: "/applications/new/program-selection",
    meta: {
      title: "Program selection",
    },
  },
  {
    path: "/applications/new/:slug",
    component: () => {
      if (store.state.uiVersion === "v2") {
        return import("./components/forms/applicationSteps/StepsLayout.vue");
      } else {
        return import("./components/StudentApplication.vue");
      }
    },
    name: "applications/new",
    props: true,
    meta: {
      title: "New application",
    },
  },
  {
    path: "/:applicationId/university-approval-form",
    redirect: "/:applicationId/university-approval-request",
  },
  {
    path: "/applications/:applicationId/:slug?",
    component: () => {
      if (store.state.uiVersion === "v2") {
        return import("./components/forms/applicationSteps/StepsLayout.vue");
      } else {
        return import("./components/StudentApplication.vue");
      }
    },
    name: "applications/resume",
    props: true,
    meta: {
      title: "Resume Application",
    },
  },
  {
    path: "/enrollment/:applicationId",
    component: EnrollmentLanding,
    name: "applications/landing",
    props: true,
    meta: {
      title: "Enrollment",
    },
  },
  {
    path: "/enrollment/:applicationId/travel-plans",
    component: TravelPlans,
    name: "enrollment/travel-plans",
    props: true,
    meta: {
      title: "Travel Plans",
    },
  },
  {
    path: "/enrollment/:applicationId/health-wellness",
    component: HealthWellnessForm,
    name: "enrollment/health-wellness",
    props: true,
    meta: {
      title: "Health & Wellness",
    },
  },
  {
    path: "/enrollment/:applicationId/participant-agreement",
    component: ParticipantAgreement,
    name: "enrollment/participant-agreement",
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/enrollment/:applicationId/financial-aid-verification",
    component: FinancialAidVerification,
    name: "enrollment/financial-aid-verification",
    props: true,
    meta: {
      title: "Financial aid verification",
    },
  },
  {
    path: "/enrollment/:applicationId/code-of-conduct",
    component: CodeOfConduct,
    name: "enrollment/code-of-conduct",
    props: true,
    meta: {
      title: "Code of Conduct",
    },
  },
  {
    path: "/enrollment/:applicationId/network-of-support",
    component: NetworkOfSupportPage,
    name: "enrollment/network-of-support",
    props: true,
    meta: {
      title: "Network of Support",
    },
  },
  {
    path: "/sign-in/:component?",
    component: SignIn,
    name: "sign-in",
    props: true,
    meta: {
      title: "Sign in",
      exemptFromLogin: true,
    },
  },
  {
    path: "/sign-in/change-password/:token",
    component: SignIn,
    name: "change-password",
    props: true,
    meta: {
      title: "Sign in",
      exemptFromLogin: true,
    },
  },
  {
    path: "/employee-register",
    component: EmployeeRegister,
    name: "employee-register",
    meta: {
      title: "Register employee account",
    },
    props: true,
  },
  {
    path: "/create-account",
    component: SignIn,
    name: "create-account",
    meta: {
      title: "Create account",
      exemptFromLogin: true,
    },
  },
  {
    path: "/enrollment/:applicationId/academic-information",
    component: AcademicInformation,
    name: "enrollment/academic-information",
    props: true,
    meta: {
      title: "Academic Information",
    },
  },
  {
    path: "/enrollment/:applicationId/parsons-paris-agreement",
    component: ParsonsParisAgreement,
    name: "enrollment/parsons-paris-agreement",
    props: true,
    meta: {
      title: "Parsons Paris Agreement",
    },
  },
  {
    path: "/:applicationId/polimoda-student-regulation-policy",
    component: PolimodaRegulationsPolicy,
    name: "polimoda-student-regulation-policy",
    props: true,
    meta: {
      title: "Polimoda Student Regulation Policy",
    },
  },
  {
    path: "/:applicationId/global-course-selection",
    component: CoursePage,
    name: "global-course-form",
    props: true,
    meta: {
      title: "Global Course Form",
    },
  },
  {
    path: "/:applicationId/passport",
    component: PassportsForm,
    name: "passport",
    props: true,
    meta: {
      title: "Passport",
    },
  },
  {
    path: "/:applicationId/housing",
    component: Housing,
    name: "housing",
    props: true,
    meta: {
      title: "Housing",
    },
  },
  {
    path: "/:applicationId/group-visa-information",
    component: GroupVisaForm,
    name: "group-visa-information",
    props: true,
    meta: {
      title: "Group Visa Information",
    },
  },
  {
    path: "/:applicationId/visa-appointment-details",
    component: VisaAppointment,
    name: "visa-appointment-details",
    props: true,
    meta: {
      title: "Visa Appointment Details",
    },
  },
  {
    path: "/:applicationId/visa-permit",
    component: VisaAndPermitForm,
    name: "visa-permit",
    props: true,
    meta: {
      title: "Visa Permit",
    },
  },
  {
    path: "/:applicationId/headshot",
    component: HeadShotForm,
    name: "headshot",
    props: true,
    meta: {
      title: "Headshot",
    },
  },
  {
    path: "/:applicationId/digital-badges",
    component: BadgesForm,
    name: "digitalBadges",
    props: true,
    meta: {
      title: "Digital Badges",
    },
  },
  {
    path: "/:applicationId/customized-code-of-conduct",
    component: CPCodeOfConduct,
    name: "cpcodeofconduct",
    props: true,
    meta: {
      title: "Customized Code of Conduct",
    },
  },
  {
    path: "/:applicationId/supplemental-form",
    component: SupplementalForm,
    name: "supplemental-form",
    props: true,
    meta: {
      title: "Supplemental Form",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-i-nd",
    component: ParticipantAgreementIND,
    name: "custom-participant-agreement-i-nd",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-i-d",
    component: ParticipantAgreementID,
    name: "custom-participant-agreement-i-d",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-ni-d",
    component: NoInsuranceDirectPayForm,
    name: "custom-participant-agreement-ni-d",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/custom-participant-agreement-ni-nd",
    component: CPAgreementNotInsuranceOrDpay,
    name: "noinsuranceorpayagreement",
    props: true,
    meta: {
      title: "Participant Agreement",
    },
  },
  {
    path: "/:applicationId/hs-code-of-conduct",
    component: HSCodeOfConductForm,
    name: "hs-code-of-conduct",
    props: true,
    meta: {
      title: "HS Code of Conduct",
    },
  },
  {
    path: "/:applicationId/:form",
    component: SingleFormLoader,
    name: "form-loader",
    props: true,
  },
  {
    path: "/students",
    component: Impersonation,
    name: "impersonation",
    props: true,
    meta: { permissions: ["api-employee-student-view"] },
  },
  {
    path: "/my-students",
    component: MyStudents,
    name: "student-management",
    props: true,
    meta: {
      title: "Student Management",
      permissions: ["student-management"],
    },
  },
  {
    path: "/student-management",
    component: StudentManagement,
    name: "student-management-view",
    props: true,
    meta: {
      title: "Student Management",
      permissions: ["student-management"],
    },
  },
  {
    path: "/student-detail/:id/:tab",
    name: "Student Details",
    component: StudentDetail,
    props: true,
  },
  {
    path: "/notification-settings",
    name: "Notification Settings",
    component: NotificationSettings,
  },
  {
    path: "/program-manager/internships/view-all",
    name: "Internship Library",
    component: InternshipLibrary,
    meta: {
      title: "Internship Library",
      permissions: ["items.read"],
    },
  },
  {
    path: "/program-manager/internships/create-new",
    name: "Create Internship",
    component: CreateInternshipForm,
    meta: {
      title: "Internship Library",
      permissions: ["items.write"],
    },
  },
  {
    path: "/program-manager/internships/internships/:id",
    name: "Internship Item",
    component: InternshipDetails,
    meta: {
      title: "Internship Library",
      permissions: ["items.read"],
    },
  },
];
export default routes;

<template>
  <div>
    <h1 v-if="paymentType === 'bankAccount'" class="text-center text-3xl mb-8">
      Payment Processing
    </h1>
    <h1
      v-else-if="
        paymentType === 'usCreditCard' ||
        paymentType === 'internationalCreditCard'
      "
      class="text-center text-3xl mb-8"
    >
      Successful Payment!
    </h1>
    <div class="flex flex-col items-center mb-8">
      <img
        v-if="paymentType === 'bankAccount'"
        src="/images/icon-processing.png"
        class="w-auto"
        alt=""
      />
      <img
        v-else-if="
          paymentType === 'usCreditCard' ||
          paymentType === 'internationalCreditCard'
        "
        src="/images/icon-completed-md.svg"
        class="w-auto"
        alt=""
      />
    </div>
    <p
      v-if="
        paymentType === 'usCreditCard' ||
        paymentType === 'internationalCreditCard'
      "
      class="text-center text-lg text-gray-650 mb-8"
    >
      Check your email for your receipt.
    </p>
    <p
      v-else-if="paymentType === 'bankAccount'"
      class="text-center text-lg text-gray-650 mb-8"
    >
      The transaction is currently processing with your bank. If your bank
      requires micro-deposit verification, please follow the steps outlined in
      your email. Your receipt will be emailed to you once your transaction
      successfully processes.
    </p>
    <PaymentDetails
      :items="items"
      :items-total="itemsTotal"
      :payment-type="paymentType"
    />
    <div v-if="logged" class="mt-6 text-center">
      <a
        class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 py-1 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900 w-full mt-2 md:mt-0 w-auto sm:w-41"
        :href="redirectLink"
      >
        Continue
      </a>
    </div>
  </div>
</template>

<script>
import PaymentDetails from "@/components/PaymentRequest/PaymentDetails.vue";

export default {
  name: "Success",
  components: {
    PaymentDetails,
  },
  props: {
    paymentRequest: {
      type: Object,
      default() {
        return {};
      },
    },
    paymentType: {
      type: String,
      default: "",
    },
    applicationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localItems: [],
      logged: false,
      localFee: {},
    };
  },
  computed: {
    items() {
      if (
        this.paymentRequest &&
        Object.hasOwnProperty.call(this.paymentRequest, "items")
      ) {
        let newItems = JSON.parse(JSON.stringify(this.paymentRequest.items));
        if (this.localFee && Object.keys(this.localFee).length)
          newItems.push(this.localFee);

        return newItems;
      } else {
        return this.localItems;
      }
    },
    itemsTotal() {
      return this.items.reduce(
        (accumulator, current) => accumulator + current.balance,
        0
      );
    },
    redirectLink() {
      return this.applicationId
        ? "/enrollment/" + this.applicationId
        : "/applications";
    },
  },
  mounted() {
    this.logged =
      (typeof Storage !== "undefined" &&
        localStorage.getItem("formioToken") !== null) ||
      this.$cookies.isKey("formio_api_token");
  },
  created() {
    //Get items and remove local storage
    localStorage.removeItem("payment-backup");
    if (localStorage.getItem("payment-details")) {
      let items = JSON.parse(localStorage.getItem("payment-details"));
      this.localItems = items.map((item) => {
        return {
          name: item.name,
          balance: item.amount,
        };
      });
      localStorage.removeItem("payment-details");
    }

    if (localStorage.getItem("fee-details")) {
      let fee = JSON.parse(localStorage.getItem("fee-details"));
      this.localFee = {
        name: fee.name,
        balance: fee.amount,
      };
      localStorage.removeItem("fee-details");
    }
  },
};
</script>

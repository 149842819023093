<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'global-course-form',
        params: { applicationId: $route.params.applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />

      <p
        class="text-sm font-semibold ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Course Selection
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../SharedComponents/CheckMarkIcon.vue";
import formApi from "../../../mixins/formIoApi";
import forms from "../../../mixins/forms";
import { mapState } from "vuex";
import { eventBus } from "../../../app";

export default {
  name: "CodeOfConduct",
  components: {
    CheckMarkIcon,
  },
  mixins: [formApi, forms],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "courseSelection",
    },
  },
  data() {
    return {
      showCard: false,
    };
  },
  computed: {
    ...mapState(["program", "uiVersion", "currentApplicationSubmissions"]),
    completed() {
      if (
        !Object.hasOwn(
          this.currentApplicationSubmissions,
          this.versionCorrectFormSubmissionURL
        )
      ) {
        return false;
      }

      const submissions = this.currentApplicationSubmissions[
        this.versionCorrectFormSubmissionURL
      ];
      return submissions.some(
        (submission) => !!submission?.data?.completedCourseSelection
      );
    },
    versionCorrectFormSubmissionURL() {
      return this.uiVersion === "v2" ? "courseform" : this.formSubmissionURL;
    },
  },
  watch: {
    program: {
      handler: function () {
        this.showForm();
      },
      deep: true,
    },
  },
  mounted() {
    this.showForm();
  },
  methods: {
    async showForm() {
      this.showCard = await this.isFormAllowedByFormRules(
        "Course Selection",
        this.applicationId,
        "post_acceptance_form_rules"
      );
      if (!this.showCard) return;
      eventBus.$emit("addPreDepartureForm", {
        label: "Course Selection",
        ruleName: "Course Selection",
        routeName: "global-course-form",
        submitted: this.completed,
      });
    },
  },
};
</script>

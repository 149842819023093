<template>
  <div id="students" class="w-full mx-auto max-w-7xl px-8 mb-8">
    <div class="flex my-6 items-center justify-between mx-0">
      <h1 class="mr-4 text-2xl font-semibold student-impersonate-title">
        View As a Student
      </h1>
      <label class="input-search flex-auto">
        <input
          v-model="searchCriteria"
          class="form-input min-h-10 block w-full pr-8"
          data-cy="search"
          type="text"
          placeholder="Search for students"
          maxlength="50"
          @keydown="triggerTypingTimeout"
        />
      </label>
      <div v-if="canBulkEnroll" class="md:flex md:w-100">
        <button
          class="bg-teal-900 h-10 text-white font-semibold py-1 px-4 rounded inline-flex items-center md:mx-8 mt-2 md:mt-0"
          @click.prevent="toggleDisplayBulkEnrollment"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          <span class="ml-2 text-sm font-semibold text-white">
            Bulk Enrollment
          </span>
        </button>
      </div>
    </div>
    <div v-if="!isLoading" class="table-container">
      <simple-data-table
        :on-clicked="impersonate"
        :columns="tableColumnsConfig"
        :items="studentList"
      ></simple-data-table>
      <pagination
        :page="metadata.page"
        :page-count="metadata.page_count"
        @onPageChange="onPageChange"
      />
    </div>
    <div v-if="isLoading" class="spinner-container">
      <SpinnerVue />
    </div>
    <BulkEnrollment
      :open="displayBulkEnrollment"
      @toggledisplay="toggleDisplayBulkEnrollment"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SimpleDataTable from "./SimpleDataTable.vue";
import Pagination from "./Pagination.vue";
import SpinnerVue from "../helpers/Spinner";
import BulkEnrollment from "../BulkEnrollment/ParentModal.vue";
import studentService from "../../services/student";

export default {
  name: "Index",
  components: {
    SpinnerVue,
    "simple-data-table": SimpleDataTable,
    pagination: Pagination,
    BulkEnrollment,
  },

  data() {
    return {
      displayBulkEnrollment: false,
      isLoading: true,
      studentsEndpoint:
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        `impersonation/student`,
      studentList: [],
      searchCriteria: "",
      isSearching: false,
      metadata: {},
      tableColumnsConfig: [
        {
          id: "name",
          title: "Name",
          sortable: true,
          customRender: false,
        },
        {
          id: "email",
          title: "Email",
          sortable: true,
          customRender: false,
        },
        {
          id: "birthday",
          title: "Birthday",
          sortable: false,
          customRender: false,
        },
        {
          id: "phone",
          title: "Phone",
          sortable: false,
          customRender: false,
        },
        {
          id: "homeInstitution",
          title: "Home Institution",
          sortable: false,
          customRender: false,
        },
        {
          id: "impersonate",
          title: "Student View",
          sortable: false,
          customRender: true,
        },
      ],
      typingTimeout: null,
      typingInterval: 800,
    };
  },
  computed: {
    ...mapState(["userData", "enrollmentToken", "featureFlags"]),
    token() {
      if (!this.enrollmentToken && localStorage.getItem("enrollment_auth")) {
        let token = localStorage.getItem("enrollment_auth");
        this.$store.commit("setEnrollmentToken", token);
        return token;
      } else {
        return this.enrollmentToken;
      }
    },
    canBulkEnroll() {
      return (
        this.featureFlags.hasOwnProperty("bulk-enrollment") &&
        this.featureFlags["bulk-enrollment"] === true
      );
    },
  },
  watch: {
    searchCriteria() {
      if (this.searchCriteria.length === 0) {
        this.isSearching = false;
      }
      if (this.searchCriteria.length > 0) {
        this.isSearching = true;
        this.retrieveStudents();
      }
    },
  },
  created() {
    this.retrieveStudents();
  },
  methods: {
    ...mapMutations(["setIsImpersonating"]),
    toggleDisplayBulkEnrollment() {
      this.displayBulkEnrollment = !this.displayBulkEnrollment;
    },
    onPageChange(page) {
      this.retrieveStudents(page);
    },
    impersonate(student) {
      fetch("/impersonate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.head.querySelector(
            "[name=csrf-token][content]"
          ).content,
        },
        body: JSON.stringify({
          email: student.email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("formioUser", JSON.stringify(data.student));
          localStorage.setItem("formioToken", data.token);
          this.setIsImpersonating(true);
          this.$store.commit("setFormioUser");
          this.$router.push({ path: "applications" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    retrieveStudents(page = 1) {
      studentService
        .listStudents(page, this.searchCriteria)
        .then((payload) => {
          this.studentList = payload.data;
          this.metadata = payload.metadata;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    triggerTypingTimeout() {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(
        this.retrieveStudents,
        this.typingInterval
      );
    },
  },
};
</script>

<style>
.student-impersonate-title {
  flex: 3 1 0;
}

.input-search {
  position: relative;
}

.input-search::before {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' stroke='%23e5e7e8' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E")
    center / contain no-repeat;
}
</style>

<template>
  <div
    id="details"
    class="w-full mx-auto mb-4 max-w-7xl sm:px-8 md:px-8 md:mb-8"
  >
    <div class="justify-between px-4 my-6 sm:px-0 sm:items-center sm:flex">
      <nav aria-label="Breadcrumb">
        <ol class="flex text-sm">
          <li class="flex items-center">
            <router-link
              class="font-normal text-gray-500 hover:text-gray-700"
              to="/my-students"
              >Student List</router-link
            >
            <i class="mdi mdi-chevron-right w-3 h-4 mx-2 text-gray-500"></i>
          </li>

          <li>
            <template v-if="!student">
              <a class="text-gray-700" aria-current="page" href="#">
                <span>Loading...</span>
              </a>
            </template>
            <template v-if="student">
              <a class="text-gray-700" :href="studentLink">
                {{ student.firstname }} {{ student.lastname }}
              </a>
            </template>
          </li>
        </ol>
      </nav>
      <div class="flex mt-2 sm:mt-0 sm:ml-4">
        <button
          class="prospective-student hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white"
          @click="open"
        >
          <i class="mdi mdi-plus-circle-outline mr-2"></i>
          <span>Prospective Student</span>
        </button>
      </div>
    </div>
    <!-- Prospective Student Modal -->
    <modal
      v-if="prospectiveModal"
      :id="'prospective'"
      :title="'Add Prospective Student'"
      @closemodal="close"
    >
      <template #modal-content>
        <prospective-content :in-modal="true" @close="close" />
      </template>
    </modal>
    <!-- Form Design Modal -->
    <modal
      v-if="formDesignModal"
      :id="'formmodal'"
      :title="'Form Design'"
      @closeModal="updateFormDesignModalOpen(false)"
    >
      <template #modal-content>
        <formRenderer
          :minimum-gpa="minimumGpa"
          :formrules="tabApplication.form_rules"
          :postacceptancerules="tabApplication.post_acceptance_form_rules"
          :housingrules="tabApplication.housing_rules"
          :statement-of-purpose="tabApplication.statement_of_purpose"
        />
      </template>
    </modal>

    <div v-if="student" class="grid gap-6 sm:grid-cols-2">
      <section>
        <pageSection
          :id="'studentinfo'"
          :title="'Student Info'"
          role="region"
          aria-label="Student Information"
        >
          <template #content="">
            <student-info
              :loading="loading"
              :student-data="student"
              :tab="tab"
              :tab-application="tabApplication"
              :full-name="student.firstname + ' ' + student.lastname"
            />
          </template>
        </pageSection>
        <pageSection
          :id="'selprograms'"
          :title="'Selected Programs'"
          role="region"
          aria-label="Selected Programs"
        >
          <template #content="">
            <programs-list
              class="mt-8"
              :loading="loading"
              :programs="progress"
            />
          </template>
        </pageSection>
      </section>

      <section>
        <pageSection
          :id="'formsstatus'"
          :title="'Application Forms & Status'"
          role="region"
          aria-label="Application Forms & Status"
        >
          <template #content="">
            <forms-status
              :loading="loading"
              :forms="selectedApplicationId.form_status"
              :simplified-approval="selectedApplicationId.simplifiedApproval"
            />
          </template>
        </pageSection>

        <pageSection
          v-if="['accepted', 'onsite', 'alumni'].includes(tab)"
          :id="'formsstatus2'"
          :title="'Enrollment Forms & Status'"
          role="region"
          aria-label="Enrollment Forms & Status"
        >
          <template #content="">
            <forms-status
              :loading="loading"
              :forms="selectedApplicationId.form_status_enrollment"
              :simplified-approval="selectedApplicationId.simplifiedApproval"
            />
          </template>
        </pageSection>

        <pageSection
          v-if="
            tab === 'application' &&
            tabApplication.programId &&
            showPotentialClasses
          "
          :id="'potentialclasses'"
          :title="'Potential Classes'"
          role="region"
          aria-label="Potential Classes"
        >
          <template #content="">
            <potential-classes
              class="mt-8"
              :site="tabApplication.site"
              :program-salesforce-id="tabApplication.programId"
            />
          </template>
        </pageSection>
      </section>
    </div>
  </div>
</template>

<script>
import modal from "../components/modal.vue";
import prospectiveContent from "../components/SiteComponents/prospectiveStudent.vue";
import studentInfo from "../components/SiteComponents/my-students/student-info.vue";
import formsStatus from "../components/SiteComponents/my-students/forms-and-status.vue";
import potentialClasses from "../components/SiteComponents/my-students/potential-classes.vue";
import programsList from "../components/SiteComponents/my-students/programs.vue";
import pageSection from "../components/SiteComponents/my-students/misc/section.vue";
import gql from "graphql-tag";
import formRenderer from "../components/SiteComponents/student-details/formio-render.vue";
import { mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/app";
import universityApproval from "@/mixins/universityApproval.js";

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "student-detail",
  components: {
    modal: modal,
    prospectiveContent: prospectiveContent,
    studentInfo: studentInfo,
    formsStatus: formsStatus,
    potentialClasses: potentialClasses,
    "programs-list": programsList,
    pageSection,
    formRenderer,
  },
  mixins: [universityApproval],
  provide() {
    return {
      tabApplication: this.tabApplication,
    };
  },
  props: {
    tab: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      minimumGpa: 0,
      prospectiveModal: false,
      loading: false,
      student: null,
      progress: null,
      showPotentialClasses: false,
      selectedApplicationId: {
        programSessionId: "",
        applicationId: "",
        form_status: {}, // Application Forms
        form_status_enrollment: {}, // Enrollment Forms
        simplifiedApproval: false,
        programName: "",
        startDate: "",
        endDate: "",
        programType: "",
      },
      tabApplication: {
        programSessionId: "",
        applicationId: "",
        confirmed: "",
        financialAid: "",
        site: "",
        programType: "",
        programId: "",
        form_rules: [],
        post_acceptance_form_rules: [],
        housing_rules: [],
        statement_of_purpose: "",
      },
    };
  },
  computed: {
    ...mapGetters("university", ["formDesignModal", "applicationId"]),
    tabProgramSessionId() {
      return this.$store.getters["university/getTabProgramSessionId"];
    },
    salesforceId() {
      return this.tabApplication.programSessionId;
    },
    studentLink() {
      return "/student-detail/" + this.student.id + "/" + this.tab;
    },
  },
  mounted() {
    eventBus.$on("approveApplication", () => {
      this.approveApplication();
    });
    this.getData(this.$route.params.id);
  },
  unmounted() {
    eventBus.$off("approveApplication");
  },
  methods: {
    ...mapMutations("university", ["updateFormDesignModalOpen"]),
    async getData(id) {
      this.loading = true;

      let response = await fetch(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "student-details/" +
          id,
        {
          headers: {
            "x-jwt-token": this.$store.state.currentUser.advisorToken,
          },
        }
      );

      let status = await response.status;
      if (status == 404) {
        this.$router.push("/my-students");
        return false;
      }

      this.student = await response.json();
      this.progress = this.student.progress;
      if (Array.isArray(this.progress) && this.progress.length > 0) {
        await this.getTabProgress(this.progress);
        let currentProgress = this.progress.find(
          (app) => app["application_id"] === this.applicationId
        );

        if (currentProgress === undefined || this.applicationId === "") {
          this.$store.commit("university/setApplicationId", ""); // If no match, remove application ID from state
          currentProgress = this.progress[0]; // Set current progress to the 1st if no match/application id present
        }

        this.selectedApplicationId.applicationId =
          Object.prototype.hasOwnProperty.call(
            currentProgress,
            "application_id"
          ) && currentProgress["application_id"]
            ? currentProgress["application_id"]
            : "";
        this.selectedApplicationId.programSessionId =
          Object.prototype.hasOwnProperty.call(
            currentProgress,
            "salesforce_program_session_id"
          ) && currentProgress["salesforce_program_session_id"]
            ? currentProgress["salesforce_program_session_id"]
            : "";
        this.selectedApplicationId.form_status =
          Object.prototype.hasOwnProperty.call(
            currentProgress,
            "form_status"
          ) &&
          currentProgress["form_status"] &&
          !Array.isArray(JSON.parse(currentProgress["form_status"]))
            ? JSON.parse(currentProgress["form_status"])
            : {};
        this.selectedApplicationId.form_status_enrollment =
          Object.prototype.hasOwnProperty.call(
            currentProgress,
            "enrollment_form_status"
          ) &&
          currentProgress["enrollment_form_status"] &&
          !Array.isArray(JSON.parse(currentProgress["enrollment_form_status"]))
            ? JSON.parse(currentProgress["enrollment_form_status"])
            : {};

        this.selectedApplicationId.simplifiedApproval = Object.prototype.hasOwnProperty.call(
          currentProgress,
          "simplified_approval"
        )
          ? !!currentProgress.simplified_approval
          : false;

        this.selectedApplicationId.programName =
          currentProgress.program_name ?? "";
        this.selectedApplicationId.startDate = currentProgress.start_date ?? "";
        this.selectedApplicationId.endDate = currentProgress.end_date ?? "";
        this.selectedApplicationId.programType =
          currentProgress.program_type ?? "";
      }
      this.modalPropToProgress();

      this.loading = false;
    },
    toggle() {
      this.prospectiveModal = !this.prospectiveModal;
    },
    open() {
      this.prospectiveModal = true;
    },
    close() {
      this.prospectiveModal = false;
    },
    modalPropToProgress() {
      return this.progress.map(function (program) {
        program["modalopen"] = false;
        return program;
      });
    },
    async getTabProgress(progress) {
      let tabProgress = {};
      tabProgress = progress.find(
        (item) =>
          item.salesforce_program_session_id === this.tabProgramSessionId
      );

      if (tabProgress) {
        this.tabApplication.programSessionId =
          tabProgress.salesforce_program_session_id;
        this.tabApplication.applicationId = tabProgress.application_id;
        this.tabApplication.confirmed = tabProgress.confirmation_payment_received
          ? "Yes"
          : "Waiting";
        this.tabApplication.financialAid = tabProgress.financial_aid_plan;
        this.tabApplication.site = tabProgress.program_site;
        await this.getProgramType();
      }
    },
    programIsNotIntern(programTypeText) {
      return programTypeText.toLowerCase().indexOf("intern") === -1;
    },
    async getProgramType() {
      await this.$apollo
        .query({
          query: gql`
            query programSessionBySalesforceId($salesforce_id: String!) {
              programSessionBySalesforceId(salesforce_id: $salesforce_id) {
                form_rules
                minimum_gpa
                housing_rules
                post_acceptance_form_rules
                statement_of_purpose
                program {
                  salesforce_id
                  type {
                    name
                  }
                }
              }
            }
          `,
          variables: {
            salesforce_id: this.salesforceId,
          },
        })
        .then((response) => {
          this.tabApplication.programType =
            response.data.programSessionBySalesforceId.program.type.name;
          this.tabApplication.programId =
            response.data.programSessionBySalesforceId.program.salesforce_id;
          this.showPotentialClasses = this.programIsNotIntern(
            this.tabApplication.programType
          );
          this.tabApplication.form_rules = Array.isArray(
            response.data.programSessionBySalesforceId.form_rules
          )
            ? response.data.programSessionBySalesforceId.form_rules
            : [];
          this.tabApplication.post_acceptance_form_rules = Array.isArray(
            response.data.programSessionBySalesforceId
              .post_acceptance_form_rules
          )
            ? response.data.programSessionBySalesforceId
                .post_acceptance_form_rules
            : [];
          this.tabApplication.housing_rules = Array.isArray(
            response.data.programSessionBySalesforceId.housing_rules
          )
            ? response.data.programSessionBySalesforceId.housing_rules
            : [];
          this.tabApplication.statement_of_purpose =
            typeof response.data.programSessionBySalesforceId
              .statement_of_purpose === "string"
              ? response.data.programSessionBySalesforceId.statement_of_purpose
              : "";
          this.minimumGpa =
            response.data.programSessionBySalesforceId.minimum_gpa;
        })
        .catch((error) => {
          console.log("Error something went wrong: " + error);
        });
    },
    async approveApplication() {
      let data = {
        approval_application: this.selectedApplicationId.applicationId,
        session_id: this.selectedApplicationId.programSessionId,
        program_name: this.selectedApplicationId.programName,
        university_ope8_id: this.userDetails.universityId,
        university_name: this.userDetails.university,
        name: this.student.firstname + " " + this.student.lastname,
        start_date: this.selectedApplicationId.startDate,
        end_date: this.selectedApplicationId.endDate,
        program_type: this.selectedApplicationId.programType,
        formatDate: true,
      };
      let approvalResponse = await this.submitApproval(data);

      if (approvalResponse) {
        let flagResponse = await this.setApprovedFlag(
          data.approval_application
        );
        this.selectedApplicationId.simplifiedApproval = flagResponse.success;
      }
    },
  },
};
</script>

<style scoped>
.prospective-student {
  @apply inline-block px-4 min-h-10 flex items-center flex-1 w-full;
  @apply text-center font-semibold text-white bg-teal-500 justify-center;
  @apply rounded border-2 border-transparent;
  display: none;
}
</style>

<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'visa-appointment-details',
        params: { applicationId: applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />
      <p
        class="text-sm font-semibold ml-4 ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Visa Appointment Information
      </p>
    </router-link>
  </div>
</template>

<script>
import CheckMarkIcon from "../SharedComponents/CheckMarkIcon.vue";
import { mapState } from "vuex";
import { eventBus } from "@/app";
import formIoApi from "../../../mixins/formIoApi.js";

export default {
  name: "VisaAppointmentCard",
  components: { CheckMarkIcon },
  mixins: [formIoApi],
  data() {
    return {
      completed: false,
      showCard: false,
      rules: {
        online: {
          name: "Visa Appointment Information (online)",
          exist: false,
          completed: false,
          prop: "onlineSubmitted",
        },
        inPerson: {
          name: "Visa Appointment Information (In Person)",
          exist: false,
          completed: false,
          prop: "inPersonSubmitted",
        },
      },
      formURL: "visaappointmentinformationinperson",
    };
  },
  computed: {
    ...mapState(["program", "currentApplicationSubmissions"]),
    submission() {
      if (!Object.hasOwn(this.currentApplicationSubmissions, this.formURL)) {
        return {};
      }

      return this.currentApplicationSubmissions[this.formURL][0];
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async mounted() {
    await this.setFormRules();
  },
  methods: {
    async setFormRules() {
      if (Object.keys(this.program).length === 0) {
        await this.$store.dispatch("setProgramDataByApplicationId", {
          applicationId: this.applicationId,
          apolloClient: this.$apollo,
        });
      }
      const formRules = this.program.post_acceptance_form_rules;

      if (formRules && formRules.length) {
        Object.entries(this.rules).forEach(([key, val]) => {
          this.rules[key].exist = formRules.includes(val.name);
        });
      }

      this.showCard = Object.values(this.rules).some((rule) => rule.exist);
      Object.entries(this.rules).forEach(([key, val]) => {
        if (this.rules[key].exist) {
          /*
           * If the form is not submitted, the submission object will be empty.
           * So, we need to check for a submission before trying
           * to read something on it. In this case, we need to return false.
           *
           * Or, if the submission does not include the prop we're checking for, also return false
           */
          this.rules[key].completed = this.submission.data
            ? this.submission.data[val.prop] ?? false
            : false;
        }
      });
      this.setCompleted();
    },
    setCompleted() {
      const notCompleted = Object.values(this.rules).some(
        (rule) => rule.exist && !rule.completed
      );

      this.completed = !notCompleted;
      if (this.showCard) {
        eventBus.$emit("addPreDepartureForm", {
          label: "Visa Appointment Information",
          ruleName: [
            "Visa Appointment Information (online)",
            "Visa Appointment Information (In Person)",
          ],
          routeName: "visa-appointment-details",
          submitted: this.completed,
          travelSection: true,
        });
      }
    },
  },
};
</script>

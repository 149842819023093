<template>
  <Modal
    :open="modalIsOpen"
    title="Bulk Enrollment"
    :show-close-button="false"
    :close-on-outside-click="false"
    @closeModal="toggleModal"
  >
    <template #modalHeader>
      <div class="flex flex-col mb-10">
        <Tracker
          v-if="!steps[currentStep].hide"
          :forms="steps"
          :application-step-tracker="false"
          :active-step="currentStep"
        />
      </div>
    </template>
    <template #modal-content>
      <ToggleStatus
        v-if="!steps[currentStep].hide"
        :selected="selectedStatus"
        :current-step="currentStep"
        @toggle-status-accepted="setStatus"
      />
      <Transition name="fade" mode="out-in">
        <component
          :is="currentComponent"
          :file="s3File"
          :validation-data="validationData"
          :bulk-enrollment-id="bulkEnrollmentId"
          :process-result="stepsData.processResult"
          @next-btn="handleEventPayload"
          @cancel-btn="closeModal"
          @upload-again-btn="handleBackToUpload"
          @validate-csv="validateCsv"
        ></component>
      </Transition>
    </template>
  </Modal>
</template>

<script>
import { shallowRef } from "vue";
import { mapState } from "vuex";
import Modal from "../modals/Modal.vue";
import Tracker from "../multistep/StepTracker.vue";
import ProgramSelection from "./ProgramSelection.vue";
import FormatScreen from "./FormatScreen.vue";
import ToggleStatus from "./ToggleStatus.vue";
import UploadScreen from "./UploadScreen.vue";
import Validation from "./Validations";
import LoadingScreen from "./LoadingScreen";
import UploadComplete from "./UploadComplete";
import apiClient from "@/services/API";

export default {
  name: "BulkEnrollmentParentContainer",
  components: {
    Modal,
    Tracker,
    ProgramSelection,
    FormatScreen,
    ToggleStatus,
    UploadScreen,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggledisplay"],
  data() {
    return {
      modalIsOpen: false,
      currentStep: 0,
      stepsData: {},
      steps: [
        { name: "Selections", component: shallowRef(ProgramSelection) },
        { name: "Format", component: shallowRef(FormatScreen) },
        { name: "Upload", component: shallowRef(UploadScreen) },
        { name: "Validation", component: shallowRef(Validation) },
        { name: "Progress", component: shallowRef(LoadingScreen), hide: true },
        { name: "Complete", component: shallowRef(UploadComplete), hide: true },
      ],
      s3File: {},
      validationData: {},
      school: {},
      applicationStatus: "",
      bulkEnrollmentId: null,
    };
  },
  computed: {
    ...mapState(["userData", "enrollmentToken", "currentUser"]),
    selectedStatus() {
      return this.currentStep === 0 ? "" : this.applicationStatus.toLowerCase();
    },
    currentComponent() {
      return this.steps[this.currentStep].component;
    },
  },
  watch: {
    open(newVal) {
      this.modalIsOpen = newVal;
    },
  },
  methods: {
    setStatus(event) {
      this.applicationStatus = event ? "Accepted" : "Application Started";
    },
    toggleModal() {
      this.modalIsOpen = !this.open;
      this.$emit("toggledisplay");
    },
    closeModal() {
      this.modalIsOpen = false;
      this.resetData();
      this.$emit("toggledisplay");
    },
    handleEventPayload(payload) {
      //get the data passed from child to parent,must be an obj.
      if (
        typeof payload === "object" &&
        payload !== null &&
        !_.isEmpty(payload)
      ) {
        // convert object to key's array
        const keys = Object.keys(payload);
        // iterate over object
        keys.forEach((key) => {
          //add each object entry to stepsData property
          this.stepsData[key] = payload[key];
        });
      }

      //move to next step
      this.currentStep += 1;
    },
    handleBackToUpload() {
      //return to upload component
      this.currentStep = this.steps.findIndex((object) => {
        return object.name === "Upload";
      });
    },
    resetData() {
      this.currentStep = 0;
      this.stepsData = {};
      this.s3File = {};
      this.validatedCsv = {};
    },
    async validateCsv(payload) {
      this.s3File = payload;
      //object for the validation endpoint
      const body = {
        s3object: this.s3File,
        user_id: this.currentUser.id,
        school: this.stepsData.homeUniversity,
        application_status: this.applicationStatus,
        ...this.stepsData.programSessionData,
      };

      //validation endpoint
      const { result } = await (await apiClient.post("/bulkenrollment", body))
        .data;

      this.bulkEnrollmentId = result.id;
      this.validationData = { validation_report: result.validation_report };
      this.currentStep++;
    },
  },
};
</script>
<style scoped>
.fade-enter-active {
  transition: all 0.4s;
}

.fade-enter {
  opacity: 0;
  margin-left: 90px;
}

.fade-leave-active {
  transition: all 0.4s;
  opacity: 0;
  margin-left: -100px;
}
</style>

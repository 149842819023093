<template>
  <div>
    <PageStyle>
      <template #pageHeader>
        <router-link
          :to="{
            name: 'applications/landing',
            params: { applicationId: $route.params.applicationId },
          }"
          class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
          Back
        </router-link>
      </template>
      <template #pageContent>
        <PageSection :id="'course-form'" :title="'Course Selection'">
          <template #content>
            <section class="mb-6 text-gray-600">
              <h4 class="flex items-center mb-2 font-semibold">
                <span class="flex-none mr-2">
                  <svg
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-bell"
                  >
                    <path
                      d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"
                    ></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                  </svg>
                </span>
                <span>Reminders</span>
              </h4>
              <ul class="pl-5 list-disc">
                <li>Apply early, as courses may fill up quickly.</li>
                <li>
                  Course offerings, times, descriptions, prerequisites and/or
                  fees are subject to change.
                </li>
                <li>Attendance is required for all classes.</li>
                <li>
                  Receiving credit at your home university depends on its own
                  requirements and regulations.
                </li>
                <li>
                  Keep in mind any requirements you may be trying to fulfill at
                  your home university.
                </li>
              </ul>
            </section>
            <section class="mb-6 text-gray-600 space-y-8">
              <Courses />
            </section>
          </template>
        </PageSection>
      </template>
    </PageStyle>
  </div>
</template>

<script>
import PageSection from "../SharedComponents/Layout/PageSection.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import Courses from "./Courses.vue";
export default {
  name: "CoursesPage",
  components: {
    PageSection,
    PageStyle,
    Courses,
  },
};
</script>

<template>
  <div class="px-4 py-12 sm:px-6 md:px-12">
    <div class="max-w-2xl mx-auto xw-full md:w-2/3">
      <div class="flex flex-col items-center mb-10">
        <img class="mr-2 h-20" :src="logo" alt="API Abroad" />
      </div>
      <Spinner v-if="loading">
        <template #spinnercontent>
          <p class="text-gray-600">
            Submitting approval form, please wait.
          </p>
        </template>
      </Spinner>
      <template v-else>
        <h2 v-if="invalidHash">
          You do not have access to view this student data. Please contact
          product support at
          <a class="api-link" :href="'mailto:productsupport@apiabroad.com'">
            productsupport@apiabroad.com
          </a>
          if you feel you have reached this message in error.
        </h2>
        <h2 v-else-if="success" class="text-center">
          Thank you! Your approval for this student has been submitted.
        </h2>
        <h2 v-else>
          We were not able to process your approval. Please navigate back to the
          email and click approve again to process your approval. If you
          continue to see this error, please contact product support at
          <a class="api-link" :href="'mailto:productsupport@apiabroad.com'">
            productsupport@apiabroad.com
          </a>
          .
        </h2>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import ProgramSessionDates from "../mixins/ProgramSessionAndDates";
import { buildStringDateRange } from "../mixins/helpers";
import Spinner from "@/components/helpers/Spinner.vue";
import formIoApi from "@/mixins/formIoApi.js";
import { DISCIPLINARY_RECORDS_UNIVERSITIES } from "@/constants.js";

export default {
  name: "UniversityApproval",
  components: { Spinner },
  mixins: [ProgramSessionDates, formIoApi],
  props: {
    hash: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
      formUrl: "advisorapproval",
      enrollmentEndpoint: this.trailingSlash(
        process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
      ),
      responseData: {},
      logo: process.env.MIX_CDN_ENDPOINT + "/images/api-connect-logo.png",
      invalidHash: false,
      success: false,
    };
  },
  async mounted() {
    try {
      //Get validate hash and get enrollment data
      const enrollmentData = await this.getData();
      this.responseData = Object.assign({}, enrollmentData);

      //Validate if form was submitted show success messages, if not submit form
      const submissionExists = await this.formioSubmissionExists(
        this.formUrl,
        this.responseData.application_id
      );

      if (!submissionExists) {
        this.success = await this.submitApproval();
        if (this.success) this.setApprovedFlag();
      } else {
        this.success = true;
      }

      this.loading = false;
    } catch (e) {
      this.invalidHash = true;
      this.loading = false;
    }
  },
  methods: {
    async getData() {
      let formData = new FormData();
      formData.append("hash", this.hash);
      return fetch(this.enrollmentEndpoint + "approval/validate", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      });
    },
    async submitApproval() {
      try {
        const programSession = await this.getProgramSessionAndDates({
          program_id: this.responseData.program_id,
          apolloClient: this.$apollo,
        });

        let programName = this.responseData.program;
        if (
          programSession.data.programSessionBySalesforceId.start_date &&
          programSession.data.programSessionBySalesforceId.end_date
        ) {
          programName +=
            " (" +
            buildStringDateRange(
              programSession.data.programSessionBySalesforceId.start_date,
              programSession.data.programSessionBySalesforceId.end_date
            ) +
            ")";
        }

        const data = {
          data: {
            application_id: this.responseData.application_id,
            studentname:
              this.responseData.firstname + " " + this.responseData.lastname,
            advisoremail: this.responseData.advisor_email,
            advisorname: this.responseData.advisor_name,
            programname: programName,
            session: programSession.data.programSessionBySalesforceId.session,
            institutionname: this.responseData.school,
            programType: this.responseData.program_type,
            program: {
              value: this.responseData.program_id,
              label: this.responseData.program,
            },
            institution: {
              value: this.responseData.school_id,
              label: this.responseData.school,
            },
          },
        };

        let disciplinaryEmail = this.getDisciplinaryEmail(
          this.responseData.school
        );
        if (disciplinaryEmail) {
          data.data.certifytype = "approvedNoDisciplinaryRecord";
          data.data.campusOfficialEmail = disciplinaryEmail;
        } else {
          data.data.certifytype = "approved";
        }

        const submissionExists = await this.formioSubmissionExists(
          this.formUrl,
          this.responseData.application_id
        );

        let submissionResponse = false;

        if (!submissionExists)
          submissionResponse = await this.submitToFormIo(this.formUrl, data);
        else submissionResponse = true;

        return submissionResponse;
      } catch (e) {
        return false;
      }
    },
    async setApprovedFlag() {
      let response = await fetch(
        this.enrollmentEndpoint + "progress/approval/email/" + this.hash,
        {
          headers: {
            "Content-type": "application/json",
          },
          method: "post",
        }
      );

      let status = response.status;
      if (status !== 200) {
        return false;
      }
    },
    getDisciplinaryEmail(school) {
      const schoolObj = DISCIPLINARY_RECORDS_UNIVERSITIES.find(
        (item) => item.name === school
      );
      return schoolObj !== undefined ? schoolObj.email : "";
    },
  },
};
</script>

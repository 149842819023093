<template>
  <FormPanel
    :id="'universitySupport'"
    :title="'University Support'"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200 px-4"
  >
    <template #content>
      <DetailView
        :items="detailViewItems"
        @edit="editItem"
        @delete="deleteItem"
      />
      <EditView
        v-for="item in editViewItems"
        :key="item.id"
        :item-data="item"
        @save="saveItem"
        @cancel="cancelEditView"
      />
      <AddButton
        v-if="detailViewItems.length + editViewItems.length < 3"
        @addItem="addItem"
      />
    </template>
  </FormPanel>
</template>

<script>
import FormPanel from "@/components/forms/SharedComponents/panel.vue";
import AddButton from "@/components/NetworkOfSupport/Shared/AddButton.vue";
import DetailView from "@/components/NetworkOfSupport/Shared/DetailView.vue";
import EditView from "@/components/NetworkOfSupport/UniversitySupport/EditView.vue";
import grid from "@/mixins/grid.js";
import formService from "@/services/form";

export default {
  name: "UniversitySupportForm",
  components: {
    AddButton,
    DetailView,
    EditView,
    FormPanel,
  },
  mixins: [grid],
  data() {
    return {
      formioData: {},
      formURL: "universitysupport",
      submissionId: "",
    };
  },
  created() {
    this.getFormioData();
  },
  methods: {
    getFormioData() {
      formService
        .listSubmissions(this.formURL, {
          "data.application_id": this.applicationId,
          limit: 1,
        })
        .then((submissions) => {
          let items = [];
          if (submissions.length) {
            const submission = submissions[0];
            this.submissionId = submission._id ?? "";

            items = submission.data?.universitySupportGrid ?? [];
            items.forEach((item) => {
              this.idIndex++;
              this.gridItems.push({
                id: this.idIndex,
                firstName: item.firstName1 ?? "",
                lastName: item.lastName1 ?? "",
                email: item.email ?? "",
                role: item.role ?? "",
                shareInformation:
                  item.shareMyAcademicInformationWithMyStudyAbroadAdvisor ??
                  false,
                editView: false,
                submitError: "",
              });
            });
          }

          if (!items.length) this.addItem();
        });
    },
    addItem() {
      this.idIndex++;
      this.editViewItems.push({
        id: this.idIndex,
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        shareInformation: false,
        editView: true,
        submitError: "",
      });
    },
    getJsonData(items) {
      let mapItems = [];
      mapItems = items.map((item) => ({
        firstName1: item.firstName,
        lastName1: item.lastName,
        email: item.email,
        role: item.role,
        shareMyAcademicInformationWithMyStudyAbroadAdvisor:
          item.shareInformation,
      }));
      return {
        data: {
          application_id: this.applicationId,
          universitySupportGrid: mapItems,
        },
      };
    },
  },
};
</script>

<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: $route.params.applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        Back
      </router-link>
    </template>
    <template #pageContent>
      <p class="text-2xl font-semibold text-gray-700 mb-4">Digital Badges</p>
      <p class="text-base text-gray-800 mb-2">
        Earning a digital badge is an easy way to show employers the skills
        you’ve earned during your API program experience. You can display your
        digital badge on your LinkedIn profile, your e-portfolio, your personal
        website, your digital resume, and more. <br />
        <br />
        Each digital badge awarded certifies that you have completed an
        intentional skills-based curriculum designed to grow unique skills that
        employers value. Digital badges will differentiate your resume,
        interviews, and cover letters by certifying these skills. <br />
        <br />
        Digital badges will require around 10-15 hours of work over the course
        of your API program. The program is self-paced and you are welcome to
        earn more than one digital badge. All digital badges must be completed
        within one month of your program end date. <br />
        <br />
        For more information
        <a
          class="text-teal-500"
          href="https://apiabroad.com/digital-badges/"
          target="_blank"
        >
          https://apiabroad.com/digital-badges/
        </a>
        <br />
        <br />
        Please indicate below the digital badge(s) you would like to earn during
        your API program:
      </p>
      <Form />
    </template>
  </PageStyle>
</template>

<script>
import PageStyle from "@/components/forms/SharedComponents/Layout/PageDefault.vue";
import Form from "@/components/forms/BadgesInterests/BadgesForm.vue";

export default {
  name: "BadgesPage",
  components: {
    PageStyle,
    Form,
  },
};
</script>

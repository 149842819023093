<template>
  <div
    id="all-tab"
    tabindex="0"
    role="tabpanel"
    aria-labelledby="all"
    class="focus:outline-none"
  >
    <Teleport v-if="parentMounted" to="#csvBtn">
      <a
        class="items-center hidden md:flex text-teal-500 font-semibold"
        href="#"
        @click="csvExport()"
      >
        <span class="flex-none">
          <i class="mdi mdi-download"></i>
        </span>
        <span class="ml-2">CSV</span>
      </a>
    </Teleport>
    <modal
      v-if="showModal"
      :id="'breakdown'"
      :title="'Total Program Price Breakdown'"
      @closemodal="resetStudent"
    >
      <template #modal-content>
        <price-break @close="resetStudent" />
      </template>
    </modal>
    <Teleport v-if="parentMounted" to="#searchbox">
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between sr-only">
          <span>Search</span>
        </span>
        <input
          v-model="tableData.search"
          class="form-input min-h-10 block w-full"
          type="text"
          placeholder="Search"
          @input="getStudents(searchUrl)"
        />
      </label>
    </Teleport>

    <NoRecords v-if="!loading & (students.length == 0)" />
    <DataTables
      v-else
      :columns="columns"
      :sort-key="sortKey"
      :sort-orders="sortOrders"
      @sort="sortBy"
    >
      <p v-if="loading">Loading...</p>
      <p v-else-if="students.length == 0" class="text-red-600">
        No records found!
      </p>
      <tbody v-else>
        <tr
          v-for="(student, index) in students"
          :key="student.id + 'i' + index"
          class="card-row"
        >
          <td class="card-section">
            <div class="card-label">
              | Student Name and Email
            </div>
            <span
              class="block text-sm font-semibold text-gray-700 student-link cursor-pointer"
              @click="
                setTabDataAndRedirect(
                  student.session_id,
                  student.approval_application,
                  student.student_id,
                  'all'
                )
              "
            >
              {{ student.name }}
            </span>
            <span>
              <a :href="'mailto:' + student.email"> {{ student.email }}</a>
            </span>
          </td>
          <td class="card-data">
            <div class="card-label">
              Major
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-full">
              {{ student.major || "-" }}
            </div>
          </td>
          <td class="card-section">
            <div class="card-label">
              Program Name
            </div>
            <div>
              <a v-if="student.url" :href="student.url" target="_blank">{{
                student.program_name
              }}</a>
              <span v-else class="block">{{ student.program_name }}</span>
              <span class="block">{{ student.site }}</span>
            </div>
          </td>
          <td class="card-controls">
            <DropdownComponent :row="index + 1" :tab-name="'all'">
              <template #dropdown-options>
                <li
                  @click="
                    setTabDataAndRedirect(
                      student.session_id,
                      student.approval_application,
                      student.student_id,
                      'all'
                    )
                  "
                >
                  <span class="dropdown-item cursor-pointer">View Detail</span>
                </li>
                <li class="border-t border-gray-200">
                  <a class="dropdown-item" :href="'mailto:' + student.email"
                    >Email Student</a
                  >
                </li>
                <li class="border-t border-gray-200">
                  <a class="dropdown-item" href="mailto:api-urs@apiabroad.com"
                    >Email API</a
                  >
                </li>
              </template>
            </DropdownComponent>
          </td>
        </tr>
      </tbody>
    </DataTables>
    <Pagination
      :pagination="pagination"
      @prev="getStudents(pagination.prevPageUrl)"
      @next="getStudents(pagination.nextPageUrl)"
    />
  </div>
</template>

<script>
import DataTables from "@/university/components/dataTables/DataTables.vue";
import Pagination from "@/university/components/dataTables/Pagination.vue";
import NoRecords from "@/university/components/dataTables/NoRecords.vue";
import { tabsMixin } from "@/mixins/tabsMixins.js";
import DropdownComponent from "@/university/components/SiteComponents/my-students/misc/dropdown.vue";

export default {
  components: {
    DataTables,
    Pagination,
    NoRecords,
    DropdownComponent,
  },
  mixins: [tabsMixin],
  props: {
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let sortOrders = {};
    //columns headers for this table, mus match a row from columnsWhiteList->row->name in tabMixin.js
    let tabColumns = ["nameEmail", "major", "programName"];
    tabColumns.forEach((column) => {
      sortOrders[column] = -1;
    });
    return {
      searchUrl:
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "students",
      id: "",
      loading: true,
      students: [],
      tabColumns: tabColumns,
      sortKey: "col1",
      sortOrders: sortOrders,
      perPage: ["10", "20", "30"],
      tableData: {
        draw: 0,
        length: 25,
        search: "",
        column: 0,
        dir: "desc",
        status: ["all"],
        year: new Date().getFullYear(),
        current_year: new Date().getFullYear(),
        filters: {
          city: "",
          sessions: [],
          majors: [],
          approvalStatus: "",
        },
      },
    };
  },
  created() {
    this.getStudents(this.searchUrl, true);
  },
  methods: {
    setYear(event) {
      this.tableData.year = event.target.value;
      this.getStudents(this.searchUrl);
    },
    prepDataForCsvExport(csvData) {
      return csvData.map((item) => ({
        Name: item.name,
        Email: item.email,
        Major: item.major,
        Program_Name: item.program_name,
        Location: item.site,
      }));
    },
  },
};
</script>

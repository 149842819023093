<template>
  <div>
    <h3 class="uppercase text-gray-700">
      Reviewing Country-Specific Health Information:
    </h3>
    <p>
      API also requires all participants to visit the CDC website and carefully
      review all health-related recommendations for their host country,
      including dietary guidelines, prevention of endemic diseases, and
      recommended vaccinations. Please complete the exercise below to document
      that you have visited and reviewed the relevant information on the CDC
      website.
    </p>
    <ol class="mt-4 mb-6 ml-6 list-decimal">
      <li>
        Visit
        <a href="http://wwwnc.cdc.gov/travel/" class="api-link" target="_blank"
          >http://wwwnc.cdc.gov/travel/</a
        >
      </li>
      <li>
        Select your host country from the drop-down menu in the "For Travelers"
        section.
      </li>
      <li>
        Scroll down to the Vaccines and Medicines section under the country map.
      </li>
      <li>
        Copy and paste into the box here the first sentence under the Vaccines
        and Medicines section.
      </li>
    </ol>
    <ApiTextArea
      name="countrySpecificHealth"
      :typed-text="typedText"
      :validation-errors="v$.value.textarea"
      @textUpdate="onFieldChange('textarea', $event)"
    />
  </div>
</template>
<script>
import formInputs from "../../mixins/formInputs";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "CountrySpecificInfo",
  components: { ApiTextArea },
  mixins: [formInputs],
  props: {
    countryInfo: {
      type: [Object, String],
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:country-info"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:country-info", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      textarea: {
        required,
      },
    },
  },
};
</script>

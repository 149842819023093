<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-full"
    width="116"
    height="16"
  >
    <defs>
      <linearGradient
        :id="'gradient_' + this._uid"
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
      >
        <stop offset="25%" stop-color="#4456a6">
          <animate
            attributeName="stop-color"
            values="#4456a6; #f5f6fa; #4456a6"
            dur="5s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="50%" stop-color="#f5f6fa">
          <animate
            attributeName="stop-color"
            values="#f5f6fa; #4456a6; #f5f6fa"
            dur="5s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="75%" stop-color="#4456a6">
          <animate
            attributeName="stop-color"
            values="#4456a6; #f5f6fa; #4456a6"
            dur="5s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
      <pattern
        :id="'pattern_' + this._uid"
        x="0"
        y="0"
        width="10"
        height="16"
        patternUnits="userSpaceOnUse"
      >
        <rect fill="#4354A8" width="6" height="16" rx="3" />
      </pattern>
      <mask :id="'mask_' + this._uid" x="0" y="0" width="1" height="1">
        <rect
          width="100%"
          height="16"
          :fill="'url(#pattern_' + this._uid + ')'"
        />
      </mask>
    </defs>
    <rect
      :fill="'url(#gradient_' + this._uid + ')'"
      width="100%"
      height="100%"
      :mask="'url(#mask_' + this._uid + ')'"
    />
  </svg>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    maxWidth: {
      // The default maxiumum width is 100%.
      default: 100,
      type: Number,
    },
    minWidth: {
      // Lines have a minimum width of 80%.
      default: 80,
      type: Number,
    },
    height: {
      // Make lines the same height as text.
      default: "1em",
      type: String,
    },
    width: {
      // Make it possible to define a fixed
      // width instead of using a random one.
      default: null,
      type: String,
    },
  },
  computed: {
    computedWidth() {
      return (
        this.width ||
        `${Math.floor(
          Math.random() * (this.maxWidth - this.minWidth) + this.minWidth
        )}%`
      );
    },
  },
};
</script>

<template>
  <section id="favf-signature" class="mt-5">
    <label class="inline-flex mb-2">
      <input
        v-model="value.acknowledge"
        type="checkbox"
        class="mt-1 text-blue-700 form-checkbox"
        :class="{ 'bg-error-100': v$.value.acknowledge.$error }"
      />
      <span class="ml-2">
        I acknowledge that this completed form and any payment due to API not
        covered by my aid is due by my final payment due date. I understand that
        I may be required to provide a copy of my award letter to API.</span
      >
    </label>
    <div
      v-if="v$.value.acknowledge.sameAs.$invalid"
      class="error text-error-900"
      :class="{ hidden: !v$.value.acknowledge.$error }"
    >
      {{ validationMessage["required"] }}
    </div>
    <div
      class="grid xs:grid-cols-1 sm:grid-cols-3 sm:justify-center items-center mb-2"
    >
      <label for="advisor-name">Name of financial aid advisor:</label>
      <input
        id="advisor-name"
        v-model="value.advisorName"
        type="text"
        class="form-input min-h-10 col-span-2"
        :class="{ 'bg-error-100': v$.value.advisorName.$error }"
      />
      <div
        v-if="v$.value.advisorName.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.advisorName.$error }"
      >
        {{ validationMessage["required"] }}
      </div>
      <div
        v-if="v$.value.advisorName.maxLength.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.advisorName.$error }"
      >
        {{ validationMessage["maxLength"] }} 255 characters.
      </div>
    </div>

    <div
      class="grid xs:grid-cols-1 sm:grid-cols-3 sm:justify-center items-center mb-2"
    >
      <label for="advisor-email">Email of financial aid advisor:</label>
      <input
        id="advisor-email"
        v-model="value.advisorEmail"
        type="text"
        class="form-input min-h-10 col-span-2"
        :class="{ 'bg-error-100': v$.value.advisorEmail.$error }"
      />
      <div
        v-if="v$.value.advisorEmail.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.advisorEmail.$error }"
      >
        {{ validationMessage["required"] }}
      </div>
      <div
        v-if="v$.value.advisorEmail.email.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.advisorEmail.$error }"
      >
        {{ validationMessage["email"] }}
      </div>
    </div>
    <div
      class="grid xs:grid-cols-1 sm:grid-cols-3 sm:justify-center items-center mb-2"
    >
      <label for="student-signature">Student Signature</label>
      <input
        id="student-signature"
        v-model="value.studentSignature"
        type="text"
        class="form-input min-h-10 col-span-2"
        :class="{ 'bg-error-100': v$.value.studentSignature.$error }"
      />
      <div
        v-if="v$.value.studentSignature.required.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.studentSignature.$error }"
      >
        {{ validationMessage["required"] }}
      </div>
      <div
        v-if="v$.value.studentSignature.maxLength.$invalid"
        class="error text-error-900"
        :class="{ hidden: !v$.value.studentSignature.$error }"
      >
        {{ validationMessage["maxLength"] }} 255 characters.
      </div>
    </div>
  </section>
</template>

<script>
import { required, email, maxLength, sameAs } from "@vuelidate/validators";
import validationMessage from "../../mixins/validationMessages";
import useVuelidate from "@vuelidate/core";
export default {
  name: "Signature",
  mixins: [validationMessage],
  props: {
    signature: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submittedData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:signature"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        acknowledge: false,
        advisorName: "",
        advisorEmail: "",
        studentSignature: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:signature", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.value.acknowledge = this.submittedData.acknowledge;
    this.value.advisorName = this.submittedData.advisorName
      ? this.submittedData.advisorName
      : "";
    this.value.advisorEmail = this.submittedData.advisorEmail
      ? this.submittedData.advisorEmail
      : "";
    this.value.studentSignature = this.submittedData.studentSignatureText
      ? this.submittedData.studentSignatureText
      : "";
  },
  validations: {
    value: {
      acknowledge: {
        sameAs: sameAs(true),
      },
      advisorName: {
        required,
        maxLength: maxLength(255),
      },
      advisorEmail: {
        required,
        email,
      },
      studentSignature: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>

<template>
  <section>
    <DetailView
      :arrangements="detailArrangements"
      @edit="editArrangement"
      @delete="deleteArrangement"
    />
    <EditView
      v-for="arragement in editArrangements"
      :key="arragement.id"
      :arragement-data="arragement"
      @saveArrangement="saveArrangement"
      @cancelArrangement="cancelArrangement"
    />

    <footer class="mt-6">
      <button
        v-if="detailArrangements.length + editArrangements.length < 3"
        class="flex items-center text-teal-500 hover:text-teal-900 active:text-teal-900"
        data-cy="add-arrangement-button"
        @click="addArrangement"
      >
        <span class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
        </span>
        <span class="ml-2 text-sm font-semibold text-gray-600">
          Add Travel Arrangement
        </span>
      </button>
    </footer>
  </section>
</template>

<script>
import DetailView from "./arrangement-detail-view.vue";
import { eventBus } from "../../../app";
import { isValid, parse } from "date-fns";
import EditView from "./arrangements-edit-view.vue";
import { AIRLINES } from "@/constants";
import { from24to12 } from "../../../mixins/helpers";

export default {
  name: "ArrangementView",
  components: { DetailView, EditView },
  props: {
    formioData: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
  },
  emits: ["saveForm"],
  data() {
    return {
      arrangements: [],
      idIndex: 1,
      editArrangements: [],
    };
  },
  computed: {
    detailArrangements() {
      return this.arrangements.filter((item) => !item.editView);
    },
  },
  watch: {
    formioData: {
      handler: function (newVal) {
        this.setFormioArrangements(newVal);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.AIRLINES = AIRLINES;

    eventBus.$on("afterSubmission", (data) => {
      this.afterSubmission(data);
    });

    eventBus.$on("afterDeletingSubmission", (data) => {
      this.afterDeletingSubmission(data);
    });
  },
  methods: {
    time(valOne, valTwo) {
      return valOne ? valTwo + " " + valOne : valTwo ?? "";
    },
    saveArrangement(newItem) {
      let editIndex = this.getEditArrangementIndex(newItem.id);
      this.editArrangements[editIndex] = JSON.parse(JSON.stringify(newItem));

      let newArray = JSON.parse(JSON.stringify(this.arrangements));
      let itemIndex = newArray.findIndex((item) => item.id === newItem.id);

      if (itemIndex !== -1) newArray[itemIndex] = newItem;
      else newArray.push(newItem);

      const data = {
        arrangements: newArray,
        eventType: "add",
        id: newItem.id,
      };
      this.$emit("saveForm", data);
    },
    afterSubmission(data) {
      const editIndex = this.getEditArrangementIndex(data.id);
      this.editArrangements[editIndex].submitError = data.hasErrors;

      if (!data.hasErrors) {
        let index = this.getArrangementIndex(data.id);

        if (index !== -1) {
          this.arrangements[index] = JSON.parse(
            JSON.stringify(this.editArrangements[editIndex])
          );
        } else {
          this.arrangements.push(this.editArrangements[editIndex]);
          index = this.getArrangementIndex(data.id);
        }

        this.clearFormioFields(index);
        this.arrangements[index].editView = false;
        this.deleteEditArrangement(data.id);
      }
    },
    afterDeletingSubmission(data) {
      if (!data.hasErrors) {
        let index = this.getArrangementIndex(data.id);
        if (index !== -1) this.arrangements.splice(index, 1);
      }
    },
    getArrangementIndex(id) {
      return this.arrangements.findIndex(
        (arrangement) => arrangement.id === id
      );
    },
    getEditArrangementIndex(id) {
      return this.editArrangements.findIndex(
        (arrangement) => arrangement.id === id
      );
    },
    addArrangement() {
      this.idIndex++;
      let id = this.idIndex;

      this.editArrangements.push({
        travelType: "",
        specifyArrangements: "",
        formioSpecifyArrangements: "",
        travelNumber: "",
        airline: "",
        otherAirline: "",
        flightNumber: "",
        departureCity: "",
        departureDate: "",
        DepartureTimeAmOrPm: "",
        formioDepartureDate: "",
        departureTime: "",
        arrivalCity: "",
        arrivalDate: "",
        formioArrivalDate: "",
        arrivalTime: "",
        acknowledge: {
          studentResponsible: false,
          studentFollowsGuidelines: false,
        },
        editView: true,
        id: id,
      });
    },
    editArrangement(id) {
      let index = this.getArrangementIndex(id);
      this.arrangements[index].formioSpecifyArrangements = this.arrangements[
        index
      ].specifyArrangements;
      this.arrangements[index].formioDepartureDate = this.arrangements[
        index
      ].departureDate;
      this.arrangements[index].formioArrivalDate = this.arrangements[
        index
      ].arrivalDate;
      this.arrangements[index].editView = true;

      this.editArrangements.push(
        JSON.parse(JSON.stringify(this.arrangements[index]))
      );
      // Scroll to bottom
      window.scrollTo(0, document.body.scrollHeight);
    },
    deleteArrangement(id) {
      let newArray = JSON.parse(JSON.stringify(this.arrangements));
      let index = newArray.findIndex((arrangement) => arrangement.id === id);
      newArray.splice(index, 1);

      const data = {
        arrangements: newArray,
        eventType: "delete",
        id: id,
      };
      this.$emit("saveForm", data);
    },
    deleteEditArrangement(id) {
      let index = this.getEditArrangementIndex(id);
      if (index !== -1) this.editArrangements.splice(index, 1);
    },
    cancelArrangement(id) {
      const index = this.getArrangementIndex(id);
      this.deleteEditArrangement(id);

      if (index !== -1) {
        this.clearFormioFields(index);
        this.arrangements[index].editView = false;
      }
    },
    clearFormioFields(index) {
      this.arrangements[index].formioSpecifyArrangements = "";
      this.arrangements[index].formioDepartureDate = "";
      this.arrangements[index].formioArrivalDate = "";
    },
    setFormioArrangements(formioArrangements) {
      if (formioArrangements.length) {
        formioArrangements.forEach((item) => {
          this.idIndex++;
          let id = this.idIndex;
          let responsible = false;
          let followsGuidelines = false;
          let airline = "";
          let otherAirline = "";

          if (Object.hasOwnProperty.call(item, "acknowledge")) {
            responsible = item.acknowledge.studentResponsible ?? false;
            followsGuidelines =
              item.acknowledge.studentFollowsGuidelines ?? false;
          }

          //Check if airline exist
          if (item.airline) {
            let airlineExist = this.AIRLINES.some(
              (airline) => airline.value === item.airline
            );

            if (airlineExist) {
              airline = item.airline;
            } else {
              airline = "other";
              otherAirline = item.airline;
            }
          }
          let departureTime = from24to12(
            item.departureTime,
            item.DepartureTimeAmOrPm
          );
          let arrivalTime = from24to12(
            item.arrivalTime,
            item.ArrivalTimeAmOrPm
          );

          this.arrangements.push({
            travelType: item.travelType ?? "",
            specifyArrangements: item.specifyArrangements ?? "",
            formioSpecifyArrangements: "",
            travelNumber: item.travelNumber ?? "",
            airline: airline,
            otherAirline: otherAirline,
            flightNumber: item.flightNumber ?? "",
            departureCity: item.departureCity ?? "",
            departureDate: this.getformatData(item.departureDate),
            formioDepartureDate: "",
            departureTime: this.time(item.DepartureTimeAmOrPm, departureTime),
            DepartureTimeAmOrPm: item.DepartureTimeAmOrPm ?? "",
            ArrivalTimeAmOrPm: item.ArrivalTimeAmOrPm ?? "",
            arrivalCity: item.arrivalCity ?? "",
            arrivalDate: this.getformatData(item.arrivalDate),
            formioArrivalDate: "",
            arrivalTime: this.time(item.ArrivalTimeAmOrPm, arrivalTime),
            acknowledge: {
              studentResponsible: responsible,
              studentFollowsGuidelines: followsGuidelines,
            },
            editView: false,
            id: id,
            submitError: false,
          });
        });
      }
    },
    getformatData(date) {
      let formatDate = "";
      if (date) {
        let substring = date.substring(0, 10);
        formatDate = isValid(parse(substring, "yyyy-mm-dd", new Date()))
          ? substring
          : "";
      }
      return formatDate;
    },
  },
};
</script>

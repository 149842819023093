<template>
  <div>
    <!-- Intro -->
    <div class="mb-6 text-gray-600 space-y-6">
      <p>
        The below student has requested that you submit a letter of
        recommendation on their behalf for the international internship program
        they are applying to through API. Please indicate in the body of the
        letter why you believe they would be a good fit for the internship
        program, noting any relevant skills or academic knowledge they have in
        the field of study or industry in which they are seeking an internship,
        and state what you believe to be their overall level of maturity and
        probability of success in an international internship.
      </p>
      <p>
        Please submit this letter on company or university letterhead if
        possible and include a signature. You should address the letter
        generally (e.g. to whom it may concern).
      </p>
    </div>
    <!-- Form -->
    <form
      role="form"
      class="mb-6 space-y-6"
      aria-label="Internship Letter of recommendation"
      @submit.prevent="submitLetter"
    >
      <div class="mb-6 space-y-6">
        <div>
          <h2
            class="mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase"
          >
            Student Name
          </h2>
          <p class="text-gray-600">
            {{ fullName }}
          </p>
        </div>
        <div>
          <h2
            class="mb-2 text-xs font-semibold tracking-widest text-gray-500 uppercase"
          >
            Student Program
          </h2>
          <p class="text-gray-600">
            {{ programName }}
          </p>
          <p
            v-if="v$.formValues.applicationId.$invalid"
            class="text-sm text-help-error font-semibold mt-2"
          >
            You can't submit this form without an application id, reload the
            page and try again.
          </p>
          <input
            v-model="v$.formValues.applicationId.$model"
            type="hidden"
            aria-label="Student Name"
          />
          <FileUpload
            :name="'internship-letterofrec'"
            :formio-files="v$.formValues.files.$model"
            @updateProgress="updateProgress"
            @updateFiles="updateFiles"
          />
        </div>
      </div>
      <button
        type="submit"
        :disabled="v$.$invalid || button.doingSubmission || button.finished"
        class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900"
      >
        {{ button.message }}
      </button>
    </form>
  </div>
</template>

<script>
// Validations

import { required, minLength } from "@vuelidate/validators";
import { buildStringDateRange } from "../../../mixins/helpers";
import FileUpload from "../../forms/SharedComponents/FileUpload";
import formService from "@/services/form";
import useVuelidate from '@vuelidate/core';
export default {
  name: "InternshipLetterOfRecommendation",
  components: {
    FileUpload,
  },

  props: {
    student: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-component"],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      formValues: {
        applicationId: "",
        files: [],
      },
      uploadOnProgress: false,
      uploadErrors: false,
      button: {
        message: "Submit",
        doingSubmission: false,
        finished: false,
      },
    };
  },
  computed: {
    fullName() {
      let firstname = Object.hasOwnProperty.call(this.student, "firstname")
        ? this.student["firstname"]
        : "";
      let lastname = Object.hasOwnProperty.call(this.student, "lastname")
        ? this.student["lastname"]
        : "";
      return firstname + " " + lastname;
    },
    programName() {
      let startDate = Object.hasOwnProperty.call(this.program, "start_date")
        ? this.program["start_date"]
        : "";
      let endDate = Object.hasOwnProperty.call(this.program, "end_date")
        ? this.program["end_date"]
        : "";
      let dates = buildStringDateRange(startDate, endDate);
      let programName = Object.hasOwnProperty.call(this.student, "program")
        ? this.student["program"]
        : "";

      return programName + " (" + dates + ")";
    },
  },
  validations: {
    formValues: {
      applicationId: {
        required,
      },
      files: {
        required,
        minLength: minLength(1),
      },
    },
    uploadOnProgress: {
      isFalse: (value) => value === false,
    },
    uploadErrors: {
      isFalse: (value) => value === false,
    },
  },
  created() {
    this.formValues["applicationId"] = this.student["application_id"];
  },
  methods: {
    async submitLetter() {
      this.setButtonBehavior("start-submission");
      this.formioSubmission()
        .then(() => {
          this.setButtonBehavior("end-submission-ok");
          this.$emit("update-component", "thank-you");
        })
        .catch(() => {
          this.setButtonBehavior("end-submission-fail");
        });
    },
    formioSubmission() {
      return formService.createSubmission("letterOfRecommendation", {
        applicationId: this.formValues["applicationId"],
        upload: this.formValues["files"],
      });
    },
    setButtonBehavior(action = "") {
      switch (action) {
        case "start-submission":
          this.button["doingSubmission"] = true;
          this.button["message"] = "Submitting, please wait.";
          break;
        case "end-submission-ok":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Submission complete.";
          this.button["finished"] = true;
          break;
        case "end-submission-fail":
          this.button["doingSubmission"] = false;
          this.button["message"] = "Submission failed, retry.";
          break;
      }
    },
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.formValues["files"] = files;
      this.uploadErrors = error;
    },
  },
};
</script>

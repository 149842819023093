<template>
  <header
    class="flex items-center h-12 bg-white sm:h-14 sm:border-b sm:border-gray-200"
    role="banner"
    aria-label="Site"
  >
    <div
      class="flex items-center justify-between flex-1 px-4 mx-auto md:justify-start max-w-7xl sm:px-8"
    >
      <img
        :src="logo"
        data-cy="logoApiAbroad"
        class="mr-2 sm:h-10 logo z-40"
        alt="API"
      />
      <NavBar v-if="isLoggedIn" />
    </div>
  </header>
</template>
<script>
import NavBar from "@/components/Header/NavBar.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    NavBar,
  },
  props: {
    logo: {
      type: String,
      default: "/images/logo-api-connect.png",
    },
    ldClient: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  created() {
    //set feature flags to store
    if (this.ldClient) {
      const ldClient = JSON.parse(this.ldClient);
      const featureFlags = Object.keys(ldClient)
        .filter((key) => !key.includes("$"))
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: ldClient[key] });
        }, {});
      this.$store.commit("setFeatureFlags", featureFlags);
    }
  },
};
</script>
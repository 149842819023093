<template>
  <div class="grid grid-cols-2">
    <div
      class="col-span-2 flex flex-col md:flex-row md:justify-between lg:justify-between"
    >
      <div class="m-6 md:m-24 lg:m-24 flex flex-col md:w-4/6 lg:w-4/6">
        <div>
          <h1
            class="text-3xl text-blue-900 font-semibold tracking-tight leading-tight"
          >
            Enjoy Significant Travel Discounts Exclusively for Students!
          </h1>
        </div>
        <div class="text-lg mt-4">
          <span
            >Using APIConnect's Fare Finder app, students gain access to reduced
            airfare prices!</span
          >
        </div>
        <div class="mt-6">
          <a :href="keyTravelUrl" target="_blank">
            <button
              :class="btnClass"
              class="text-center px-12 md:px-6 lg:px-6 text-white rounded font-semibold border-2 border-transparent h-12"
            >
              <span>Find My Fare</span>
            </button>
          </a>
        </div>
        <div v-if="!hasAcceptedEula" class="text-sm mt-8">
          <span
            >It looks like you haven't accepted API's End User License Agreement
            yet which is needed to access our Fare Finder App. You can access
            that
            <a
              :href="eulaUrl"
              class="inline-flex items-center font-semibold text-sm text-teal-900 hover:text-teal-500 underline"
            >
              here.
            </a>
          </span>
        </div>
        <Alerts v-if="populateTravelForm" max-width="500px">
          <div id="alert-icon" class="mr-4">
            <img src="images/icon-warning.svg" class="iconwarning" />
          </div>
          <div id="alert-content">
            <p>
              To make your life a little easier, bookings made on the Fare
              Finder app sync into your
              <a
                v-if="getlatestApplicationUrl"
                :href="getlatestApplicationUrl"
                class="api-link"
                style="font-weight: normal;"
                >APIConnect Travel Plans Form</a
              >
              <span v-else>APIConnect Travel Plans Form</span>.
            </p>
          </div>
        </Alerts>
      </div>
      <div
        class="m-6 flex justify-end md:mt-24 lg:mt-24 md:w-screen self-end lg:w-Full"
      >
        <img src="/images/firefinder-print.png" />
      </div>
    </div>
    <div
      class="flex justify-end items-end h-screen md:mt-10 px-6 md:px-12 lg:px-12 col-span-2 col-start-2 mb-6"
    >
      <span class="text-sm">Powered by Key Travel</span>
    </div>
  </div>
</template>

<script>
import formService from "@/services/form.js";
import { mapState } from "vuex";
import Alerts from "@/components/helpers/Alerts";
import { POST_FORM_RULES } from "@/constants";

export default {
  components: { Alerts },
  props: {
    userEmail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hasAcceptedEula: false,
      eulaUrl: "/EULA",
      keyTravelUrl:
        "https://sp.keytravel.com/cr/login?entityId=http%3A%2F%2Fwww.okta.com%2Fexk7a10jy2tw1efuS5d7&atlasUrl=https%3A%2F%2Finteractive.keytravel.com&departmentId=1001436",
      formUrl: "eulaacceptance",
    };
  },
  computed: {
    ...mapState(["studentApplications", "currentUser", "featureFlags"]),
    btnClass() {
      return !this.hasAcceptedEula
        ? "opacity-33 cursor-not-allowed text-white bg-teal-500"
        : "bg-teal-900 hover:bg-teal-500 focus:bg-teal-500";
    },
    email() {
      return this.userEmail !== "" ? this.userEmail : this.currentUser.email;
    },
    getlatestApplicationUrl() {
      let app = this.studentApplications.find((app) => {
        return (
          app.applicationPaid &&
          app.programSession.post_acceptance_form_rules.some((item) =>
            POST_FORM_RULES["Travel Plans"].includes(item)
          )
        );
      });
      if (app) {
        return `enrollment/${app.id}/travel-plans`;
      } else {
        return null;
      }
    },
    populateTravelForm() {
      return this.featureFlags["fare-finder-travel-form"];
    },
  },
  created() {
    formService
      .submissionExists(this.formUrl, {
        "data.email": this.email,
      })
      .then(() => {
        this.hasAcceptedEula = true;
      });
  },
  methods: {
    goToKeyTravel() {
      if (this.hasAcceptedEula) {
        window.location.href = this.keyTravelUrl;
      }
    },
  },
};
</script>

<template>
  <div
    id="prospective-tab"
    tabindex="0"
    role="tabpanel"
    aria-labelledby="prospective"
    class="focus:outline-none"
  >
    <Teleport v-if="parentMounted" to="#csvBtn">
      <a
        class="items-center hidden md:flex text-teal-500 font-semibold"
        href="#"
        @click="csvExport()"
      >
        <span class="flex-none">
          <i class="mdi mdi-download"></i>
        </span>
        <span class="ml-2">CSV</span>
      </a>
    </Teleport>
    <Teleport v-if="parentMounted" to="#searchbox">
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between sr-only">
          <span>Search</span>
        </span>
        <input
          v-model="tableData.search"
          class="form-input min-h-10 block w-full"
          type="text"
          placeholder="Search"
          @input="getStudents(searchUrl)"
        />
      </label>
    </Teleport>
    <div
      class="px-4 py-4 bg-white border-b border-gray-200 shadow-sm md:shadow-none sm:flex"
      role="search"
      aria-label="Prospective Criteria"
      aria-description="Filter students by type, timeframe or major"
    >
      <div class="sm:flex-auto sm:flex">
        <label class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5">
          <select
            v-model="tableData.status"
            class="form-select min-h-10 text-sm block w-full"
          >
            <option value="all_prospects" disabled selected hidden
              >Prospect Type</option
            >
            <option value="programSelected">Program Selected</option>
            <option value="shownInterest">Shown Interest</option>
            <option value="generalInterest">General Interest</option>
          </select>
        </label>
        <label class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5">
          <select
            v-model="tableData.timeframe"
            class="form-select min-h-10 text-sm block w-full"
          >
            <option value="" disabled selected hidden>Time Frame</option>
            <option
              v-for="timeframe in timeframes"
              :key="timeframe.value"
              :value="timeframe.value"
              >{{ timeframe.label }}</option
            >
          </select>
        </label>
        <label class="block text-sm mb-2 sm:mb-0 sm:flex-1 sm:mr-5">
          <select
            v-model="tableData.major"
            class="form-select min-h-10 text-sm block w-full"
          >
            <option value="" disabled selected hidden>Major</option>
            <option
              v-for="major in MAJORS"
              :key="major.value"
              :value="major.value"
              >{{ major.label }}</option
            >
          </select>
        </label>
        <calendarFilter
          name="from"
          placeholder="Created Date From"
          :state-date="stateDateFrom"
          :default-value="oldestDate"
          :other-filter-value="dateTo"
          @input="(newDateFrom) => (dateFrom = newDateFrom)"
        />
        <calendarFilter
          name="to"
          placeholder="Created Date To"
          :state-date="stateDateTo"
          :default-value="currentDate"
          :other-filter-value="dateFrom"
          @input="(newDateTo) => (dateTo = newDateTo)"
        />
      </div>
      <div class="flex items-center flex-none">
        <button
          class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-white border-teal-500 text-teal-500 hover:bg-teal-100 hover:text-white hover:border-teal-100 flex-auto"
          @click="filter()"
        >
          <span>Filter</span>
        </button>
        <button
          class="flex items-center justify-center px-4 ml-2 text-sm min-h-10 text-gray-650 focus:outline-none"
          type="reset"
          @click="resetFilters()"
        >
          Reset
        </button>
      </div>
    </div>
    <div
      v-if="loading"
      class="relative flex flex-col px-4 py-6 mt-4 bg-white border-b border-gray-200 shadow-sm sm:mt-0 md:shadow-none md:flex-row lg:items-center"
    >
      <span>Loading data, please wait...</span>
    </div>
    <noRecords v-else-if="!loading & (students.length == 0)" />
    <datatable
      v-else
      :columns="columns"
      :sort-key="sortKey"
      :sort-orders="sortOrders"
      aria-label="Prospective Results"
      role="region"
      @sort="sortBy"
    >
      <tbody>
        <tr
          v-for="(student, index) in students"
          :key="student.id + 'i' + index"
          class="card-row"
        >
          <td class="card-section">
            <div class="card-label">
              Prospect Type
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-full">
              <template v-if="getProspectiveType !== 'all_prospects'">
                <span
                  v-if="getProspectiveType === 'programSelected'"
                  class="bg-help-warning px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-no-wrap rounded-full text-1xs"
                >
                  Program Selected
                </span>
                <span
                  v-if="getProspectiveType === 'shownInterest'"
                  class="bg-blue-700 px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-no-wrap rounded-full text-1xs"
                >
                  Shown Interest
                </span>
                <span
                  v-if="getProspectiveType === 'generalInterest'"
                  class="bg-blue-500 px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-no-wrap rounded-full text-1xs"
                >
                  General Interest
                </span>
              </template>
              <template v-else-if="getProspectiveType === 'all_prospects'">
                <span
                  v-if="student.program_name"
                  class="bg-help-warning px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-no-wrap rounded-full text-1xs"
                >
                  Program Selected
                </span>
                <span
                  v-else-if="
                    student.email &&
                    student.name &&
                    student.program_type &&
                    student.timeframe
                  "
                  class="bg-blue-700 px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-no-wrap rounded-full text-1xs"
                >
                  Shown Interest
                </span>
                <span
                  v-else-if="
                    student.name &&
                    student.email &&
                    (!student.program_type || !student.timeframe)
                  "
                  class="bg-blue-500 px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-no-wrap rounded-full text-1xs"
                >
                  General Interest
                </span>
              </template>
            </div>
          </td>
          <td class="card-section">
            <div class="card-label">
              Student Name and Email
            </div>
            <div class="md:w-columnfixed-md2 break-words">
              <span class="block text-sm font-semibold text-gray-700">
                {{ student.name }}
              </span>
              <span class="break-all">
                <a
                  :href="'mailto:' + student.email"
                  class="text-teal-500 focus:underline focus:text-teal-900 hover:text-teal-900"
                >
                  {{ student.email }}
                </a>
              </span>
            </div>
          </td>

          <td class="card-section">
            <div class="card-label">
              Major
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-full">
              <span>
                {{ student.major }}
              </span>
            </div>
          </td>

          <td class="card-section">
            <div class="card-label">
              Time Frame
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-full">
              <span
                v-if="
                  !student.program_name &&
                  student.name &&
                  student.email &&
                  student.program_type &&
                  student.timeframe
                "
              >
                {{ student.timeframe }}
              </span>
            </div>
          </td>
          <td class="card-section">
            <div class="card-label">
              Program type
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-full">
              <span v-if="student.timeframe && student.program_type">
                {{ student.program_type }}
              </span>
            </div>
          </td>

          <td class="card-section">
            <div class="card-label">
              Program Name
            </div>
            <div class="w-full md:w-columnfixed-md break-words">
              <div v-if="student.program_name">
                <a
                  v-if="student.url"
                  :href="student.url"
                  target="_blank"
                  class="text-teal-500 focus:underline focus:text-teal-900 hover:text-teal-900"
                >
                  {{ student.program_name }}
                </a>
                <span v-else class="block">{{ student.program_name }}</span>
                <span class="block">{{ student.site }}</span>
              </div>
            </div>
          </td>
          <td class="card-section">
            <div class="card-label">
              Created
            </div>
            <div class="xs:text-left xs:w-1/2 sm:w-2/3 md:w-full">
              {{ formatDate(student.lead_created_at) }}
            </div>
          </td>

          <td class="card-controls">
            <dropdownComponent :row="index + 1" :tab-name="'prospective'">
              <template #dropdown-options>
                <li class="border-t.border-gray-200">
                  <a class="dropdown-item" :href="'mailto:' + student.email"
                    >Email Student</a
                  >
                </li>
                <li class="border-t.border-gray-200">
                  <a class="dropdown-item" href="mailto:api-urs@apiabroad.com"
                    >Email API</a
                  >
                </li>
              </template>
            </dropdownComponent>
          </td>
        </tr>
      </tbody>
    </datatable>
    <pagination
      :pagination="pagination"
      @prev="getStudents(pagination.prevPageUrl)"
      @next="getStudents(pagination.nextPageUrl)"
    />
  </div>
</template>

<script>
import pagination from "../Pagination";
import noRecords from "../NoRecords";
import { tabsMixin } from "@/mixins/tabsMixins";
import majorsList from "@/mixins/majorsList";
import { mapGetters, mapMutations, mapActions } from "vuex";
import dropdownComponent from "../../SiteComponents/my-students/misc/dropdown";
import datatable from "../DataTables";
import calendarFilter from "../filter/CalendarFilter.vue";

import { isValid, format } from "date-fns";
import { eventBus } from "@/app";

export default {
  components: {
    pagination: pagination,
    noRecords: noRecords,
    dropdownComponent: dropdownComponent,
    datatable: datatable,
    calendarFilter: calendarFilter,
  },
  mixins: [tabsMixin, majorsList],
  props: {
    parentMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let sortOrders = {};
    //columns headers for this table, mus match a row from columnsWhiteList->row->name in tabMixin.js
    let tabColumns = [
      "prospectType",
      "nameEmail",
      "major",
      "timeFrame",
      "programType",
      "programName",
      "createdDate",
    ];
    tabColumns.forEach((column) => {
      sortOrders[column] = 0;
    });
    return {
      searchUrl: `${this.trailingSlash(
        process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
      )}leads`,
      id: "",
      loading: true,
      timeframes: [],
      students: [],
      tabColumns: tabColumns,
      sortKey: "col1",
      sortOrders: sortOrders,
      perPage: ["10", "20", "30"],
      tableData: {
        draw: 0,
        length: 25,
        search: "",
        column: 6,
        dir: "desc",
        status: "all_prospects",
        major: "",
        timeframe: "",
        year: new Date().getFullYear(),
        current_year: new Date().getFullYear(),
        min_lead_created_at: "",
        max_lead_created_at: "",
      },
      stateDateFrom: "",
      stateDateTo: "",
      dateFrom: "",
      dateTo: "",
      oldestDate: "",
    };
  },
  computed: {
    ...mapGetters("university", [
      "lastViewedTabUrl",
      "getLastSearchInput",
      "getProspectiveType",
      "getProspectiveTimeframe",
      "getProspectiveMajor",
      "getProspectiveFrom",
      "getProspectiveTo",
    ]),
    currentDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
  created() {
    this.isProspective = true;
    this.getOldestDate();
    this.getStudents(this.searchUrl, true);
    this.getTimeframes();
  },
  methods: {
    ...mapMutations("university", [
      "setLastViewedPage",
      "setLastSearchInput",
      "setProspectiveType",
      "setProspectiveTimeframe",
      "setProspectiveMajor",
      "setProspectiveFrom",
      "setProspectiveTo",
    ]),
    ...mapActions("university", ["updateCounts"]),
    formatDate(dateString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const newDate = new Date(dateString).toLocaleDateString(
        undefined,
        options
      );
      return dateString ? newDate : "";
    },
    async getTimeframes(
      url = this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "leads/timeframes"
    ) {
      let response = await fetch(url, {
        headers: {
          "Content-type": "application/json",
          "x-jwt-token": this.$store.state.currentUser.advisorToken,
        },
      });

      let status = await response.status;
      if (status !== 200) {
        return false;
      }

      let data = await response.json();
      this.timeframes = data;
    },
    prepareDataForFetch(data) {
      let returnData = JSON.parse(JSON.stringify(data)); // Deep Clone
      let statusString = returnData.status;
      let column = 0;
      switch (this.sortKey) {
        case "nameEmail":
          column = 0;
          break;
        default:
          column = 3;
          break;
      }

      returnData.status = [statusString];
      returnData.column = column;
      return returnData;
    },
    async getStudents(
      url = this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "leads",
      loadLastViewedPage = false
    ) {
      let validToken = await this.isTokenValid();

      if (validToken) {
        if (loadLastViewedPage) {
          if (this.lastViewedTabUrl) url = this.lastViewedTabUrl;

          if (this.getLastSearchInput)
            this.tableData.search = this.getLastSearchInput;
          if (this.getProspectiveType)
            this.tableData.status = this.getProspectiveType;
          if (this.getProspectiveTimeframe)
            this.tableData.timeframe = this.getProspectiveTimeframe;
          if (this.getProspectiveMajor)
            this.tableData.major = this.getProspectiveMajor;
          if (this.getProspectiveFrom) {
            this.stateDateFrom = this.getProspectiveFrom;
            this.tableData.min_lead_created_at = this.getProspectiveFrom;
          }
          if (this.getProspectiveTo) {
            this.stateDateTo = this.getProspectiveTo;
            this.tableData.max_lead_created_at = this.getProspectiveTo;
          }
        }
        if (loadLastViewedPage) this.setColumnsOrder();

        this.setLastViewedPage(url);
        this.setLastSearchInput(this.tableData.search);
        this.setProspectiveType(this.tableData.status);
        this.setProspectiveTimeframe(this.tableData.timeframe);
        this.setMajorFilters();
        this.setProspectiveFrom(this.tableData.min_lead_created_at);
        this.setProspectiveTo(this.tableData.max_lead_created_at);

        this.tableData.draw++;
        this.loading = true;
        let response = await fetch(url, {
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": this.$store.state.currentUser.advisorToken,
          },
          method: "post",
          body: JSON.stringify(this.prepareDataForFetch(this.tableData)),
        });

        let status = await response.status;
        if (status !== 200) {
          return false;
        }

        let data = await response.json();
        if (this.tableData.draw == data.draw) {
          this.students = data.data;
          this.configPagination(data.pagination);
          if (
            Object.hasOwnProperty.call(data, "totals") &&
            Object.hasOwnProperty.call(data, "pagination")
          ) {
            this.updateCounts(data);
          }
        }

        this.loading = false;
      } else {
        this.logout();
      }
    },
    setYear(event) {
      this.tableData.year = event.target.value;
      this.getStudents(this.searchUrl);
    },
    prepDataForCsvExport(csvData) {
      return csvData.map((item) => ({
        Prospect_Type: item.program_name
          ? "Program Selected"
          : item.name && item.email && item.major && item.timeframe
          ? "Shown Interest"
          : "General Interest",
        Name: item.name,
        Email: item.email,
        Major: item.major,
        Timeframe: item.timeframe,
        Program_Name: item.program_name,
        Created_Date: this.formatDateCVS(item.lead_created_at),
      }));
    },
    //get all records for csv export
    async csvExport() {
      this.tableData.csv = true;
      await fetch(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "leads",
        {
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": this.$store.state.currentUser.advisorToken,
          },
          method: "post",
          body: JSON.stringify(this.prepareDataForFetch(this.tableData)),
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          return false;
        })
        .then((json) => {
          let csvData = this.prepDataForCsvExport(json.data);
          if (csvData.length > 0) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
              Object.keys(csvData[0]).join(","),
              ...csvData.map((item) =>
                Object.values(item)
                  .map((item) => '"' + item + '"')
                  .join(",")
              ),
            ].join("\n");
            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
          }
        })
        .catch(() => {
          console.log("parsing failed");
        });
    },
    filter() {
      this.tableData.min_lead_created_at = this.dateFrom;
      this.tableData.max_lead_created_at = this.dateTo;
      this.getStudents();
    },
    resetFilters() {
      this.tableData.major = "";
      this.tableData.timeframe = "";
      this.tableData.status = "all_prospects";
      this.tableData.min_lead_created_at = "";
      this.tableData.max_lead_created_at = "";
      this.stateDateFrom = "";
      this.stateDateTo = "";
      this.dateFrom = "";
      this.dateTo = "";
      eventBus.$emit("reset");
      this.getStudents(this.searchUrl);
    },
    formatDateCVS(date) {
      const dateOpt = { year: "numeric", month: "2-digit", day: "2-digit" };
      const newDate = new Date(date).toLocaleDateString("en-US", dateOpt);
      return date ? newDate : "";
    },
    setColumnsOrder() {
      if (this.currentOrderIndex !== undefined) {
        let column = 6;
        let order = this.currentOrder["order"];
        switch (this.currentOrder["name"]) {
          case "nameEmail":
            column = 1;
            break;
          case "createdDate":
            column = 6;
            break;
          default:
            column = 6;
            order = "desc";
            break;
        }
        this.tableData.column = column; // Sets up the order and column
        this.tableData.dir = order;
        this.sortOrders[this.currentOrder["name"]] = this.setOrderByName(order);
      }
    },
    setMajorFilters() {
      let filters = Object.assign({}, this.getLastFilters);
      filters.majors = this.tableData.major ? [this.tableData.major] : [];
      this.setProspectiveMajor(this.tableData.major);
      this.setLastFilters(filters);
    },
    async getOldestDate() {
      const data = {
        draw: 0,
        length: 10,
        search: "",
        column: 3,
        dir: "asc",
        status: ["all_prospects"],
        major: "",
        timeframe: "",
        year: new Date().getFullYear(),
        current_year: new Date().getFullYear(),
        min_lead_created_at: "",
        max_lead_created_at: "",
      };

      let response = await fetch(
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
          "leads",
        {
          headers: {
            "Content-type": "application/json",
            "x-jwt-token": this.$store.state.currentUser.advisorToken,
          },
          method: "post",
          body: JSON.stringify(data),
        }
      );

      if (response.status === 200) {
        let json = await response.json();
        if (json.data && json.data.length) {
          let date = json.data[0].lead_created_at;
          if (date && isValid(new Date(date))) {
            this.oldestDate = format(new Date(date), "yyyy-MM-dd");
          }
        }
      }
    },
  },
};
</script>

<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'registrationPolicy'"
        :title="'LDM Registration Policy'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <form
            id="registration-policy"
            class="mb-6"
            action=""
            aria-label="LDM Registration Policy Form"
            @submit.prevent="submitForm()"
          >
            <div class="space-y-6 mb-6">
              <p class="mb-6">
                LdM, the host institution for your program, requires you to
                review their registration policy, hand sign, and upload a copy
                of the signed document here to your application.
              </p>
              <a
                href="/files/LdM_Application_Form_2022-2023.pdf"
                target="_blank"
                class="api-link"
              >
                Click here to download the Registration Policy
              </a>
              <p>
                Upload the Registration Policy once you have hand signed the
                document.
              </p>
            </div>
            <FileUpload
              name="registration-policy-upload"
              :formio-files="formioFiles"
              :multiple-files="false"
              :file-types="['*']"
              @updateProgress="updateProgress"
              @updateFiles="updateFiles"
            />
            <ButtonWithSpinner
              ref="ldmSubmit"
              type="submit"
              variant="primary"
              variant-type="block"
              :disabled="v$.$invalid"
            >
              <span>
                Submit
                <svg
                  v-if="successSubmission && !v$.$invalid"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="inline feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
            </ButtonWithSpinner>
            <div
              v-if="successSubmission && !v$.$invalid"
              class="text-success-900"
            >
              Submission Complete, thank you!
            </div>
            <div v-if="submitError" class="error text-error-900 mt-2">
              {{ submitError }}
            </div>
          </form>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import FormPanel from "../SharedComponents/panel.vue";
import FileUpload from "../SharedComponents/FileUpload.vue";
import formIoApi from "../../../mixins/formIoApi.js";

import { required } from "@vuelidate/validators";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import forms from "../../../mixins/forms.js";
import useVuelidate from '@vuelidate/core';

const validateFileUpload = (value) => value === false;

export default {
  name: "LDMRegistrationPolicy",
  components: {
    FormPanel,
    PageStyle,
    FileUpload,
    ButtonWithSpinner,
  },
  mixins: [formIoApi, forms],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      value: {
        files: [],
      },
      formioFiles: [],
      uploadOnProgress: false,
      uploadErrors: false,
      formURL: "studentapplicationstage2step2",
      successSubmission: false,
      submitError: "",
      submissionId: "",
    };
  },
  validations: {
    value: {
      files: { required },
    },
    uploadOnProgress: { validateFileUpload },
    uploadErrors: { validateFileUpload },
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "LdM Supplemental Application Form"
    );

    if (valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value.files = files;
      this.uploadErrors = error;
      this.successSubmission = false;
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;

          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                if (Object.hasOwnProperty.call(response, "ldMImage")) {
                  this.formioFiles = response.ldMImage ? response.ldMImage : [];
                }
              }
            );
          }
        }
      );
    },
    submitForm() {
      this.v$.$touch();
      this.submitError = "";
      this.successSubmission = false;

      if (!this.v$.$invalid) {
        this.$refs.ldmSubmit.startLoading();

        if (this.submissionId) {
          this.updateSubmission();
        } else {
          this.createSubmission();
        }
      }
    },
    updateSubmission() {
      const jsonData = [
        {
          op: "add",
          path: "/data/ldMImage",
          value: this.value.files,
        },
        {
          op: "add",
          path: "/data/ldmSubmitted",
          value: true,
        },
      ];
      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.ldmSubmit.stopLoading();
        });
    },
    createSubmission() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          ldMImage: this.value.files,
          ldmSubmitted: true,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.ldmSubmit.stopLoading();
        });
    },
  },
};
</script>

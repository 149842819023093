<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'internship-credit'"
        :title="'Internship Credit Options'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <form
            id="internship-credit-form"
            class="mb-6"
            action=""
            aria-label="Internship Credit"
            @submit.prevent="submitForm()"
          >
            <CreditOptions
              ref="CreditOptions"
              :credit-option="creditOption"
              :formio-data="formioCreditOption"
              @update:credit-option="creditOption = $event"
            />
            <StudentSignature
              ref="StudentSignature"
              :student-signature="studentSignature"
              :formio-data="formioSignature"
              @update:student-signature="studentSignature = $event"
            />

            <ButtonWithSpinner
              ref="intershipCredit"
              type="submit"
              variant="primary"
              variant-type="block"
            >
              <span>
                Submit
                <svg
                  v-if="successSubmission && !hasErrors"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="inline feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
            </ButtonWithSpinner>
            <div v-if="submitError" class="error text-error-900 mt-2">
              {{ submitError }}
            </div>
            <div
              v-if="successSubmission && !hasErrors"
              class="text-success-900"
            >
              Submission Complete, thank you!
            </div>
          </form>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import StudentSignature from "./StudentSignature.vue";
import CreditOptions from "./CreditOptions.vue";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import formValidation from "../../../mixins/formValidation";
import { eventBus } from "../../../app";
import formIoApi from "../../../mixins/formIoApi.js";
import forms from "../../../mixins/forms.js";

export default {
  name: "InternshipCreditForm",
  components: {
    FormPanel,
    PageStyle,
    StudentSignature,
    CreditOptions,
    ButtonWithSpinner,
  },
  mixins: [formValidation, formIoApi, forms],
  data() {
    return {
      sectionsToValidate: ["StudentSignature", "CreditOptions"],
      studentSignature: {},
      creditOption: {},
      successSubmission: false,
      submitError: "",
      submissionId: "",
      formURL: "studentapplicationstage2step1",
      formioCreditOption: "",
      formioSignature: {
        name: "",
        date: "",
      },
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  watch: {
    studentSignature: {
      handler: function () {
        this.successSubmission = false;
      },
      deep: true,
    },
    creditOption: {
      handler: function () {
        this.successSubmission = false;
      },
      deep: true,
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "Fixed Date Internship Academic Credit Options"
    );

    if (valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    async submitForm() {
      this.submitError = "";
      this.successSubmission = false;
      this.$refs.intershipCredit.startLoading();

      try {
        eventBus.$emit("dateChange");
        await this.validate();

        if (!this.hasErrors) {
          if (this.submissionId) {
            this.updateSubmission();
          } else {
            this.createSubmission();
          }
        }
      } catch {
        this.$refs.intershipCredit.stopLoading();
      }
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;

          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.formioCreditOption = response.creditOption ?? "";
                this.formioSignature.name = response.nameOfStudent ?? "";

                if (response.internshipCreditSignatureDate)
                  this.setFormioDate(response.internshipCreditSignatureDate);
              }
            );
          }
        }
      );
    },
    updateSubmission() {
      const jsonData = [
        {
          op: "add",
          path: "/data/creditOption",
          value: this.creditOption.creditOption,
        },
        {
          op: "add",
          path: "/data/internshipCreditSignatureDate",
          value: this.formatDate(this.studentSignature.date),
        },
        {
          op: "add",
          path: "/data/nameOfStudent",
          value: this.studentSignature.studentName,
        },
        {
          op: "add",
          path: "/data/internshipCreditSubmitted",
          value: true,
        },
      ];

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.intershipCredit.stopLoading();
        });
    },
    createSubmission() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          creditOption: this.creditOption.creditOption,
          internshipCreditSignatureDate: this.formatDate(
            this.studentSignature.date
          ),
          nameOfStudent: this.studentSignature.studentName,
          internshipCreditSubmitted: true,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.intershipCredit.stopLoading();
        });
    },
    setFormioDate(date) {
      const formatDate = new Date(date);

      this.formioSignature.date =
        this.formatNumber(formatDate.getMonth() + 1) +
        "/" +
        this.formatNumber(formatDate.getDate()) +
        "/" +
        formatDate.getFullYear();
    },
    formatNumber(number) {
      return number <= 9 ? "0" + parseInt(number, 10) : number;
    },
    formatDate(data) {
      let formatDate = new Date(data);
      return formatDate.toISOString();
    },
  },
};
</script>

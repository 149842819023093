<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'group-visa-information'"
        :title="'Group Visa Information'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <form
            id="group-visa-information-form"
            class="mb-6"
            action=""
            aria-label="Group Visa Information"
            @submit.prevent="submitForm()"
          >
            <div class="space-y-6">
              <p>
                The program you are applying to has a Group Visa Service option
                for you if you fall within the jurisdictions of the Boston,
                Chicago, Los Angeles, Miami, New York, and San Francisco
                consulates. This means that you have the option to pay a visa
                processing fee ($185) to API, then send us your visa application
                and supporting documentation. An API representative will appear
                in person at the consulate on your behalf to submit your
                application, you would not need to make an independent trip to
                the consulate. If you do not fall within one of the
                jurisdictions list above then you will have to secure your visa
                independently. Click
                <a
                  href="https://apiabroad.com/student-visas/"
                  target="_blank"
                  class="api-link"
                  >here</a
                >
                for more detailed information on the visa/immigration process
                for your program. We recommend starting this process ASAP.
              </p>
              <p>To be eligible for this service, you must:</p>
              <ol class="ml-6 list-disc">
                <li>
                  Hold a driver’s license from or attend school in a state
                  within one of these consulate's jurisdiction
                </li>
                <li>
                  You must send your materials to API by the visa deadline
                </li>
              </ol>
              <p class="italic">
                NOTE: API Group Visa Service appointment dates are chosen by the
                consular official to accommodate their workload and may occur
                before your application and enrollment deadlines.
              </p>
            </div>

            <fieldset class="mt-6 mb-6">
              <legend class="font-semibold">
                Are you Interested in using the API Group Visa Service?
              </legend>
              <div class="grid grid-cols-1 gap-y-2 mt-6">
                <div v-for="option in VISA_OPTIONS" :key="option.value">
                  <label :for="option.value" class="flex mb-2">
                    <input
                      :id="option.value"
                      v-model.trim="v$.value.interested.$model"
                      type="radio"
                      name="group-visa"
                      :value="option.value"
                      class="mt-1 text-blue-700 form-radio"
                      :class="{ 'bg-error-100': v$.value.interested.$error }"
                      aria-controls="api-group-visa"
                    />
                    <span class="ml-2">{{ option.label }}</span>
                  </label>

                  <div
                    v-if="option.value === 'Yes' && value.interested === 'Yes'"
                    class="mt-4 mb-4 ml-6 space-y-4"
                  >
                    <p>
                      Great! API will email you a student visa guide. This email
                      may be sent to you prior to your acceptance. If you have
                      not received an email within 2 weeks of acceptance, please
                      contact our Visa Director, Jennibeth
                      (jennibeth.rodriguez@apiabroad.com) and request that she
                      send the visa guide to you. It is imperative that you
                      closely follow API's guidelines to be eligible for our
                      visa service.
                    </p>
                    <p>
                      If you already received the visa guide, please state the
                      date that you sent in your application.
                    </p>
                    <Datepicker
                      label-name="Application Sent Date"
                      :date="v$.value.sentDate.$model"
                      :required="true"
                      :min-year="1950"
                      @updateDate="updateSentDate($event)"
                    />
                  </div>

                  <div
                    v-if="
                      value.interested === option.value &&
                      value.interested !== 'Yes'
                    "
                    class="mt-4 mb-6 ml-6 space-y-4"
                  >
                    <Datepicker
                      label-name="When is your scheduled visa appointment?"
                      :date="v$.value.appointmentDate.$model"
                      :required="true"
                      :min-year="1950"
                      @updateDate="updateAppointmentDate($event)"
                    />
                    <p>
                      Please make sure your appointment allows for enough time
                      to get your passport back before the start of your
                      program.
                    </p>
                    <label for="consulateName" class="block text-sm">
                      <span
                        class="font-semibold text-gray-600 flex justify-between mb-2"
                      >
                        <span>
                          Name of the consulate you will be attending:
                        </span>
                      </span>
                      <input
                        id="consulateName"
                        v-model.trim="v$.value.consulateName.$model"
                        type="text"
                        class="form-input block w-full min-h-10"
                        :class="{
                          'bg-error-100': v$.value.consulateName.$error,
                        }"
                      />
                      <div
                        v-if="
                          v$.value.consulateName.required.$invalid &&
                          v$.value.consulateName.$error
                        "
                        class="error text-error-900"
                      >
                        Field is required
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  v$.value.interested.required.$invalid &&
                  v$.value.interested.$error
                "
                class="error text-error-900 text-sm"
              >
                Field is required
              </div>
            </fieldset>

            <ButtonWithSpinner
              ref="visaSubmit"
              type="submit"
              variant="primary"
              variant-type="block"
              :disabled="v$.$invalid"
            >
              <span>
                Submit
                <svg
                  v-if="successSubmission && !v$.$invalid"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="inline feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
            </ButtonWithSpinner>
            <div v-if="submitError" class="error text-error-900 mt-2">
              {{ submitError }}
            </div>
            <div
              v-if="successSubmission && !v$.$invalid"
              class="text-success-900"
            >
              Submission Complete, thank you!
            </div>
          </form>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";

import Datepicker from "../SharedComponents/Datepicker.vue";
import { required, requiredIf } from "@vuelidate/validators";
import { eventBus } from "../../../app";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import { mapState } from "vuex";
import forms from "../../../mixins/forms.js";
import formIoApi from "../../../mixins/formIoApi.js";
import useVuelidate from "@vuelidate/core";

const VISA_OPTIONS = [
  {
    value: "Yes",
    label: "Yes, I want to opt into this service",
  },
  {
    value: "NoMyOwn",
    label: "No, I want to get my Visa on my own",
  },
  {
    value: "NotEligible",
    label:
      "No, I'm not eligible because I do not live in one of the jurisdictions listed",
  },
];
const requiredIfAppointment = requiredIf(function () {
  return (
    this.value.interested === "NoMyOwn" ||
    this.value.interested === "NotEligible"
  );
});

export default {
  name: "GroupVisaForm",
  components: {
    FormPanel,
    PageStyle,
    Datepicker,
    ButtonWithSpinner,
  },
  mixins: [forms, formIoApi],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        interested: "",
        sentDate: "",
        appointmentDate: "",
        consulateName: "",
      },
      successSubmission: false,
      submitError: "",
      ruleType: "",
      rule: "Group Visa Information",
      submissionId: "",
    };
  },
  validations: {
    value: {
      interested: { required },
      sentDate: {
        required: requiredIf(function () {
          return this.value.interested === "Yes";
        }),
      },
      appointmentDate: {
        required: requiredIfAppointment,
      },
      consulateName: {
        required: requiredIfAppointment,
      },
    },
  },
  computed: {
    ...mapState(["program"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    formURL() {
      let url = "";
      if (this.ruleType) {
        url =
          this.ruleType === "form_rules"
            ? "studentapplicationstage2step2"
            : "groupvisainformation";
      }

      return url;
    },
  },
  watch: {
    "value.interested": {
      handler: function (newval, oldValue) {
        if (newval === "NoMyOwn" || newval === "NotEligible")
          this.value.sentDate = "";

        if (newval === "Yes" || (oldValue.length && oldValue !== "Yes")) {
          this.value.appointmentDate = "";
          this.value.consulateName = "";
        }
      },
    },
    value: {
      handler: function () {
        if (this.successSubmission) this.successSubmission = false;
      },
      deep: true,
    },
  },
  async created() {
    this.VISA_OPTIONS = VISA_OPTIONS;

    const valid = await this.isValidApplicationAndIsOnRules(
      this.applicationId,
      this.rule
    );
    this.ruleType = valid.ruleType;

    if (valid.valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    updateSentDate(date) {
      this.value.sentDate = date;
    },
    updateAppointmentDate(date) {
      this.value.appointmentDate = date;
    },
    submitForm() {
      this.submitError = "";
      this.successSubmission = false;

      this.v$.$touch();
      eventBus.$emit("dateChange");

      if (!this.v$.$invalid) {
        this.$refs.visaSubmit.startLoading();
        if (this.submissionId) {
          this.updateSubmission();
        } else {
          this.createSubmission();
        }
      }
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;

          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.value.interested =
                  response.interestedUsingVisaService ?? "";
                this.value.sentDate = response.applicationSentDate ?? "";
                this.value.appointmentDate = response.visaAppointmentDate ?? "";
                this.value.consulateName = response.consulateName ?? "";
              }
            );
          }
        }
      );
    },
    updateSubmission() {
      const jsonData = [
        {
          op: "add",
          path: "/data/interestedUsingVisaService",
          value: this.value.interested,
        },
        {
          op: "add",
          path: "/data/applicationSentDate",
          value: this.value.sentDate,
        },
        {
          op: "add",
          path: "/data/visaAppointmentDate",
          value: this.value.appointmentDate,
        },
        {
          op: "add",
          path: "/data/consulateName",
          value: this.value.consulateName,
        },
      ];

      if (this.ruleType === "form_rules")
        jsonData.push({
          op: "add",
          path: "/data/groupVisaSubmitted",
          value: true,
        });

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.visaSubmit.stopLoading();
        });
    },
    createSubmission() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          interestedUsingVisaService: this.value.interested,
          applicationSentDate: this.value.sentDate,
          visaAppointmentDate: this.value.appointmentDate,
          consulateName: this.value.consulateName,
        },
      };

      if (this.ruleType === "form_rules")
        formioObject.data.groupVisaSubmitted = true;

      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.visaSubmit.stopLoading();
        });
    },
  },
};
</script>

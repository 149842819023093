const countApplication = function (totals = {}) {
  let status = ["Applicant"];
  let total = sumStatus(status, totals);
  return total;
};

const countProspective = function (totals = {}) {
  let status = ["programSelected", "shownInterest", "generalInterest"];
  let total = sumStatus(status, totals);
  return total;
};

const countAccepted = function (totals = {}) {
  let status = ["Accepted"];
  let total = sumStatus(status, totals);
  return total;
};

const countOnSite = function (totals = {}) {
  let status = ["Onsite"];
  let total = sumStatus(status, totals);
  return total;
};

const countAlumni = function (totals = {}) {
  let status = ["Alumni"];
  let total = sumStatus(status, totals);
  return total;
};

const countWithdrawn = function (totals = {}) {
  let status = ["Withdrawn"];
  let total = sumStatus(status, totals);
  return total;
};

const countAll = function (totals = {}) {
  let status = ["allStudents"];
  let total = sumStatus(status, totals);
  return total;
};

const sumStatus = function (status, totals) {
  let i = 0;
  let totalSum = 0;
  for (i; i < status.length; i++) {
    totalSum += Object.hasOwnProperty.call(totals, status[i])
      ? totals[status[i]]
      : 0;
  }
  return totalSum;
};

exports.countApplication = countApplication;
exports.countProspective = countProspective;
exports.countAccepted = countAccepted;
exports.countOnSite = countOnSite;
exports.countAlumni = countAlumni;
exports.countWithdrawn = countWithdrawn;
exports.countAll = countAll;

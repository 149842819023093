/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "core-js";
import { createRouter, createWebHistory } from "vue-router";
import apolloProvider from "../../graphql.apollo";
import VueCookies, { useCookies } from "vue3-cookies";
import { CONSTANT_KEYS, TAB_TITLES } from "./constants";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import * as Sentry from "@sentry/vue";
import formService from "@/services/form";
import withUUID from "vue-uuid";

/**
 * Global components
 *
 * This makes e.g. StudentApplication available in our components as StudentApplication, instead of
 * having to import it each time. Only use this for widely used components; otherwise import
 * them explicitly where needed.
 */
import StudentApplication from "./components/StudentApplication";
import ApplicationSidebar from "./components/ApplicationSidebar";
import FormioForm from "./components/FormioForm";
import BaseHeader from "./components/Header/BaseHeader";
import SignIn from "./components/SignIn";
import UniversityApproval from "./components/UniversityApproval";
import MyApplications from "./components/MyApplications";
import RecommendationParent from "./components/recommendation/RecommendationParent";
import StepTracker from "./components/multistep/StepTracker";
import StepComplete from "./components/multistep/StepComplete";
import StepCurrent from "./components/multistep/StepCurrent";
import StepPending from "./components/multistep/StepPending";
import StepDivider from "./components/multistep/StepDivider";
import ApplicationReceived from "./components/ApplicationReceived";
import TravelPlans from "./components/travels/travel-plans/travel-plans";
import PaymentRequestSummary from "./components/PaymentRequest/Summary";
import PaymentRequestSuccess from "./components/PaymentRequest/Success";
import DesktopSideBar from "./components/Sidebar/DesktopSideBar";
import FareFinderView from "./components/FareFinder/FareFinderView";

import { trailingSlash } from "./mixins/helpers";
import store from "./store";
import routes from "./routes";

require("./bootstrap");
require("./tooltips");
import { createApp } from "vue";
import emitter from "tiny-emitter/instance";

//Styles
import "@mdi/font/css/materialdesignicons.css";

const APP_NAME = "APIConnect";
const app = withUUID(createApp({}));

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn:
      "https://80c43b62221d49f19395b7fb711e5859@o409860.ingest.sentry.io/5283188",
    logErrors: true,
  });
}

const router = createRouter({
  routes,
  history: createWebHistory(),
});

/**
 * Register an event bus to communicate between components without going thru parent or the store
 */
export const eventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
app.component("DesktopSideBar", DesktopSideBar);
app.component("StudentApplication", StudentApplication);
app.component("ApplicationSidebar", ApplicationSidebar);
app.component("FormioForm", FormioForm);
app.component("BaseHeader", BaseHeader);
app.component("SignInForm", SignIn);
app.component("UniversityApproval", UniversityApproval);
app.component("MyApplications", MyApplications);
app.component("RecommendationComponent", RecommendationParent);
app.component("StepTracker", StepTracker);
app.component("StepComplete", StepComplete);
app.component("StepCurrent", StepCurrent);
app.component("StepPending", StepPending);
app.component("StepDivider", StepDivider);
app.component("ApplicationReceived", ApplicationReceived);
app.component("TravelPlans", TravelPlans);
app.component("PaymentRequestSummary", PaymentRequestSummary);
app.component("PaymentRequestSuccess", PaymentRequestSuccess);
app.component("FareFinderView", FareFinderView);

/**
 * Global mixins
 *
 * This makes e.g. trailingSlash available in our components as this.trailingSlash(), instead of
 * having to import it each time. Only use this for widely used helpers; otherwise import
 * them explicitly where needed, e.g. as we do with getNested().
 */
app.mixin({
  methods: {
    trailingSlash,
  },
});

/**
 * Plugins
 *
 * This makes e.g. router available in our components as this.$router, instead of
 * having to import it each time.
 */
app.use(apolloProvider);
app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
app.use(store);
app.use(router);

app.component("VSelect", vSelect);
app.component("VDraggable", draggable);

app.config.compilerOptions.whitespace = "preserve";

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const { cookies } = useCookies();

if (cookies.get("student_portal_remove_local")) {
  store.dispatch("removeFormioLocalStorage");
}

// Add event to make form.io sections collapsible with enter
document.addEventListener("keydown", (e) => {
  if (e.key === "Enter" && e.target.classList.contains("collapsible-title")) {
    e.target.click();
  }
});

const authorized = function (to) {
  const requiredPermissions = to?.meta?.permissions ?? [];
  if (requiredPermissions.length < 1) {
    return true;
  }
  const userPermissions = store?.getters?.getPermissions ?? [];
  return requiredPermissions.some((permission) =>
    userPermissions.includes(permission)
  );
};

const getTabTitle = (to) => {
  try {
    return TAB_TITLES[to?.params?.form];
  } catch (_) {
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  if (!authorized(to)) {
    next({ name: "landing-page" });
  }

  const customTitle = getTabTitle(to);
  if (customTitle) Object.assign(to.meta, { title: customTitle });

  //Redirect if session expired
  if (!to.path.includes("/sign-in") && store.getters.getSessionExpired) {
    const redirect = store.getters.getRedirectPath;
    store.commit("setRedirectPath", "");
    store.commit("setSessionExpired", false);
    localStorage.removeItem("expiredApplicationId");

    if (redirect && !to.path.includes(redirect)) {
      router.push(redirect);
    }
  }

  let invalidFormioToken = cookies.get("invalid_formio_token")
    ? !!JSON.parse(cookies.get("invalid_formio_token"))
    : false;

  // If formio token was invalid on backend trigger student logout
  if (invalidFormioToken) {
    sessionExpired(to.params.applicationId);
    return false;
  }

  const token = localStorage.getItem("formioToken") ?? "";

  //Set current user data
  if (
    token &&
    !store?.getters?.isLoggedIn &&
    !to.path.includes("/landing-page")
  ) {
    await store.dispatch("unifiedLogin");
    if (store.state.currentUser.permissions.includes("student-management")) {
      let user = await formService.currentFormServiceUser();
      await store.dispatch(
        "university/updateUniversity",
        user.data.university.value
      );
      store.commit("university/setUserDetails", {
        email: user.data.email,
        name: user.data.firstname + " " + user.data.lastname,
        university: user.data.university.label,
        universityId: user.data.university.value,
        loggedIn: true,
        dynamicUniversity: user.data.dynamicUniversity,
      });
    }
  }

  //Set Employe if a new tab is open
  let hasStudentViewPermission = store?.state?.currentUser?.permissions.includes(
    "api-employee-student-view"
  );

  let authSessionExpired = cookies.get("authSessionExpired")
    ? !!JSON.parse(cookies.get("authSessionExpired"))
    : false;

  if (authSessionExpired && hasStudentViewPermission) {
    store.dispatch("exitImpersonation");
  }

  // Prevents employees to leave student and management pages without a token
  if (
    hasStudentViewPermission &&
    !to.path.includes("/landing-page") &&
    !to.path.includes("/students") &&
    !to.path.includes("/student-management") &&
    (!token || token === "undefined")
  ) {
    router.push("/students");
  }

  if (
    Object.hasOwnProperty.call(to, "params") &&
    Object.hasOwnProperty.call(to["params"], "applicationId") &&
    !to.path.includes("/payment-request")
  ) {
    let toApplicationId = to.params.applicationId;
    let fromApplicationId = "";

    if (Object.hasOwnProperty.call(from, "params"))
      fromApplicationId = from.params.applicationId ?? "";

    if (
      from.path !== "/applications" &&
      fromApplicationId !== toApplicationId
    ) {
      await store.dispatch("getUiVersion", toApplicationId);
      next();
    } else {
      next();
    }
  } else if (to.path.includes("/applications/new")) {
    if (from.path !== "/applications") {
      store.commit("setUiVersion", CONSTANT_KEYS.UI_VERSION);
    }
    next();
  } else {
    store.commit("setUiVersion", "");
    next();
  }
});

//Validate if token still valid

router.afterEach((to) => {
  //Some of the URLs are not available on vue routes file and they cannot use exemptFromLogin pro

  // Scroll to top
  window.scrollTo(0, 0);
  if (documentTitleGuard(to)) document.title = to.meta.title + " | " + APP_NAME;

  if (!localStorage.getItem("formioToken")) {
    store.commit("setFormioToken");
  }

  // Set application ID in redirect if present
  if (
    Object.hasOwnProperty.call(to, "params") &&
    Object.hasOwnProperty.call(to["params"], "applicationId") &&
    to["params"]["applicationId"]
  ) {
    store.commit("setCurrentApplicationId", to["params"]["applicationId"]);

    //Validate enrollment status
    if (!store.state.studentApplications.length) {
      if (!store.state.formioToken) {
        store.commit("setFormioToken");
      }
      store.dispatch("getEnrollmentData");
    }
  }
});

function sessionExpired(applicationId) {
  let hasStudentViewPermission = store?.state?.currentUser?.permissions.includes(
    "api-employee-student-view"
  );

  if (hasStudentViewPermission) {
    store.dispatch("exitImpersonation");
  } else {
    const redirectPath = window.location.pathname;
    if (!store.getters.getSessionExpired) {
      if (applicationId) {
        localStorage.setItem("expiredApplicationId", applicationId);
      }
      store.dispatch("logout", {
        sessionExpired: true,
        redirectPath: redirectPath,
        reload: true,
      });
    }
  }
}

function documentTitleGuard(navigationMeta) {
  if (!Object.hasOwnProperty.call(navigationMeta, "meta")) return false;
  if (!Object.hasOwnProperty.call(navigationMeta["meta"], "title"))
    return false;
  if (!navigationMeta["meta"]["title"]) return false;
  return true;
}

app.mount("#app");

if (window.Cypress) {
  // only available during E2E tests
  window.app = app;
}

<template>
  <aside
    v-if="program.program"
    class="mb-12 dashboard__sidebar sm:mb-0 sm:col-span-5 lg:col-span-4"
  >
    <section class="p-6 bg-blue-100 rounded">
      <header class="text-lg font-semibold">
        <div class="flex justify-between items-center">
          <h2>Program Summary</h2>
          <p
            v-if="false"
            class="text-teal-500 font-semibold text-xs cursor-pointer underline hidden"
            @click="toggleProgramModal(changeProgramModal)"
          >
            Change Program
          </p>
        </div>
      </header>
      <DateCountdown
        v-if="showDeadlineComponent"
        :application-deadline="program.application_deadline"
        :application-extension-deadline="program.application_extension_deadline"
        :is-large-icon="true"
      />
      <div class="mt-8">
        <div class="mt-6">
          <h3
            class="text-xs font-semibold tracking-widest text-gray-600 uppercase"
          >
            Program Name
          </h3>
          <p class="mt-2 text-sm" data-cy="sidebar-program-name-label">
            {{ program["program"].name }}
          </p>
        </div>
        <div v-if="!isVirtualProgram" class="mt-6">
          <h3
            class="text-xs font-semibold tracking-widest text-gray-600 uppercase"
          >
            Host City &amp; Country
          </h3>
          <p class="mt-2 text-sm">
            {{ program["site"].city }}, {{ program["site"].country }}
          </p>
        </div>
        <div class="mt-6">
          <h3
            class="text-xs font-semibold tracking-widest text-gray-600 uppercase"
          >
            Dates
          </h3>
          <program-dates
            :id="selectedProgramId"
            :dates="buildStringDateRange(program.start_date, program.end_date)"
            :start-is-final="program.start_date_is_final > 0"
            :end-is-final="program.end_date_is_final > 0"
            tag="p"
            class="mt-2 text-sm"
          />
        </div>
        <div v-if="!isVirtualProgram" class="mt-6">
          <h3
            class="text-xs font-semibold tracking-widest text-gray-600 uppercase"
          >
            Visa Requirement
          </h3>
          <span class="flex items-baseline justify-between">
            <p class="mt-2 text-sm">
              {{ program.visa_required ? "Yes" : "No" }}
            </p>
            <a
              v-if="program.visa_required"
              class="api-link"
              target="_blank"
              style="font-size: 14px; font-weight: bold;"
              href="https://apiabroad.com/student-visas/"
            >
              View Visa Information
            </a>
          </span>
        </div>
        <div v-if="showPrice" class="mt-6" data-cy="programPrice">
          <h3
            class="text-xs font-semibold tracking-widest text-gray-600 uppercase"
          >
            Price
          </h3>
          <p class="mt-2 text-sm">${{ price }}</p>
        </div>
      </div>
    </section>
    <InfoModal v-if="infoModal" @close="infoModal = false" />
    <ModalComponent
      :open="changeProgramModal"
      :title="'Program Change'"
      @closeModal="toggleProgramModal(changeProgramModal)"
    >
      <template #modal-content>
        <ChangeProgramForm
          :applications="studentApplications"
          :user-id="getFormioUserId"
        />
      </template>
    </ModalComponent>
  </aside>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProgramDates from "./ProgramDates";
import VisaInfoModal from "./modals/VisaInfoModal";
import DateCountdown from "./DateCountdown";
import { formatDate } from "../mixins/helpers";
import modalComponent from "./modals/Modal";
import changeProgramForm from "./modals/ProgramChange";
import billingRules from "../mixins/billingRules";
import { UMASS_OPE8, UMASS_PRICING } from "@/umassConstants.js";
import {
  SALVE_REGINA_OPE8,
  SALVE_REGINA_PRICING,
} from "@/salveReginaConstants.js";
export default {
  components: {
    "program-dates": ProgramDates,
    InfoModal: VisaInfoModal,
    DateCountdown: DateCountdown,
    ModalComponent: modalComponent,
    ChangeProgramForm: changeProgramForm,
  },
  mixins: [billingRules],
  props: {
    formsList: {
      type: Array,
      default: function () {
        return new Array();
      },
    },
  },
  data() {
    return {
      infoModal: false,
      changeProgramModal: false,
    };
  },
  computed: {
    ...mapGetters(["getFormioUserId"]),
    ...mapState([
      "program",
      "selectedProgramId",
      "studentApplications",
      "currentApplicationId",
      "uiVersion",
      "userData",
    ]),
    showDeadlineComponent() {
      return this.uiVersion !== "v2";
    },
    isUmassStudent() {
      return UMASS_OPE8.includes(this.userData.data.schoolname.value);
    },
    isSalveReginaStudent() {
      return SALVE_REGINA_OPE8.includes(this.userData.data.schoolname.value);
    },
    price() {
      let price = this.program.price;
      if (this.isUmassStudent) {
        let customPrice = UMASS_PRICING.find(
          (item) => item.session === this.selectedProgramId
        );
        price = customPrice ? customPrice.price : price;
      }
      if (this.isSalveReginaStudent) {
        let reginaPrice = SALVE_REGINA_PRICING.find(
          (item) => item.session === this.selectedProgramId
        );
        price = reginaPrice ? reginaPrice.program_fee : price;
      }

      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    currentSubmissions() {
      return this.studentApplications.find(
        (application) => application["id"] === this.currentApplicationId
      );
    },
    paymentFormId() {
      let paymentForm = this.formsList.find(
        (form) =>
          form["name"] === "Pay Deposit" || form["name"] === "Institution Pays"
      );
      return paymentForm !== undefined ? paymentForm.id : "";
    },
    paidCurrentApplication() {
      return (
        this.currentSubmissions !== undefined &&
        this.currentSubmissions["submissions"].find(
          (submission) => submission["form"] === this.paymentFormId
        ) !== undefined
      );
    },
    isVirtualProgram() {
      return this.program.site.country.toLowerCase() === "virtual";
    },
    showPrice() {
      if (this.isUmassStudent || this.isSalveReginaStudent) {
        return Boolean(this.price && this.price != "0");
      } else if (
        this.price <= 0 ||
        this.institutionPaysApplicationFee ||
        this.institutionPaysAcademicFee ||
        this.institutionPaysConfirmationPayment ||
        this.institutionPaysHousingFee
      ) {
        return false;
      } else if (this.isVirtualProgram) {
        return !!this.program.price;
      } else {
        return true;
      }
    },
  },
  mounted() {},
  methods: {
    buildStringDateRange(from, to) {
      let formattedFrom = formatDate(from);
      let formattedTo = formatDate(to);
      return formattedFrom + " - " + formattedTo;
    },
    toggleModal() {
      this.infoModal = !this.infoModal;
    },
    toggleProgramModal(status) {
      if (this.$route.params.slug === "program-selection") {
        location.reload(); // refresh the page
      } else {
        this.changeProgramModal = !status; // open modal
      }
    },
  },
};
</script>

<template>
  <div>
    <Modal
      :title="title"
      :open="open"
      :show-logo="false"
      @closeModal="closeModal"
    >
      <template #modal-content>
        <component
          :is="currentTab"
          @closeModal="closeModal"
          @form="finalStep"
          @next="nextStep"
        ></component>
        <p v-if="Object.keys(finalForm).length">{{ finalForm }}</p>
      </template>
    </Modal>
    <div class="mt-6 text-center text-gray-600">
      <button
        type="button"
        variant="primary"
        variant-type="block"
        :class="`py-2 px-6 mr-5 inline-flex items-center rounded-md border border-[#007f80] bg-white text-sm font-medium text-[#007f80] shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus: focus:ring-offset-2 ${style}`"
        @click="openModal"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script type="text/javascript">
import Modal from "./Modal.vue";
import CreateHousing from "./CreateHousing.vue";
import HousingForm from "./HousingForm.vue";

export default {
  name: "HousingsModals",
  components: {
    Modal,
    CreateHousing,
    HousingForm,
  },
  props: {
    style: {
      type: String,
      default: "",
    },
    isDefaultOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: this.isDefaultOpen,
      title: "Creating Housing",
      finalForm: {},
      currentTab: "CreateHousing",
      tabs: ["CreateHousing", "HousingForm"],
    };
  },
  methods: {
    openModal() {
      this.open = true;
      history.pushState(null, null, "/programs/housing/create-new");
    },
    nextStep(title) {
      this.title = title;
      this.currentTab = this.tabs[1];
    },
    finalStep(message) {
      this.finalForm = {
        type: this.title,
        ...message,
      };
      this.currentTab = this.tabs[0];
      this.open = false;
    },
    closeModal() {
      this.open = false;
      this.currentTab = this.tabs[0];
      history.pushState(null, null, "/programs/housing/");
    },
  },
};
</script>

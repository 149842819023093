<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'gradeReport'"
        :title="'Grade Report'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <form
            id="grade-report-form"
            class="mb-6"
            action=""
            aria-label="Grade report"
            @submit.prevent="submitForm()"
          >
            <p class="mb-6">
              All applicants who are currently enrolled in high school or who
              have completed high school but have not yet enrolled in a
              university are required to submit a high school grade report. The
              grade report does not have to be the original sealed copy, but it
              must be an official copy and should be the most recent version
              available. The original grade report can be uploaded below.
            </p>
            <FileUpload
              name="grade-upload"
              :formio-files="formioFiles"
              :multiple-files="true"
              :file-types="['*']"
              @updateProgress="updateProgress"
              @updateFiles="updateFiles"
            />
            <ButtonWithSpinner
              ref="gradeSubmit"
              type="submit"
              variant="primary"
              variant-type="block"
              :disabled="v$.$invalid"
            >
              <span>
                {{ buttonLabel }}
                <svg
                  v-if="successSubmission && !v$.$invalid"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="inline feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
            </ButtonWithSpinner>
            <div v-if="submitError" class="error text-error-900 mt-2">
              {{ submitError }}
            </div>
            <div
              v-if="successSubmission && !v$.$invalid"
              class="text-success-900"
            >
              Submission Complete, thank you!
            </div>
          </form>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>

import { required } from "@vuelidate/validators";
import FormPanel from "../SharedComponents/panel.vue";
import FileUpload from "../SharedComponents/FileUpload.vue";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import formIoApi from "../../../mixins/formIoApi.js";
import forms from "../../../mixins/forms.js";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import useVuelidate from '@vuelidate/core';

const validateFileUpload = (value) => value === false;

export default {
  name: "GradeReport",
  components: {
    FormPanel,
    FileUpload,
    ButtonWithSpinner,
    PageStyle,
  },
  mixins: [formIoApi, forms],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      value: {
        files: [],
      },
      buttonLabel: "Submit",
      uploadOnProgress: false,
      uploadErrors: false,
      submissionId: "",
      formURL: "studentapplicationstage2step1",
      formioFiles: [],
      submitError: "",
      successSubmission: false,
    };
  },
  validations: {
    value: {
      files: { required },
    },
    uploadOnProgress: { validateFileUpload },
    uploadErrors: { validateFileUpload },
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "Grade Report"
    );

    if (valid) {
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    submitForm() {
      this.v$.$touch();
      this.submitError = "";
      this.successSubmission = false;

      if (!this.v$.$invalid) {
        this.$refs.gradeSubmit.startLoading();
        if (this.submissionId) {
          this.updateSubmission();
        } else {
          this.createSubmission();
        }
      }
    },
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value.files = files;
      this.uploadErrors = error;
      this.successSubmission = false;
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                if (
                  Object.hasOwnProperty.call(response, "grade-report-upload")
                ) {
                  this.formioFiles = response["grade-report-upload"]
                    ? response["grade-report-upload"]
                    : [];
                }
              }
            );
          }
        }
      );
    },
    createSubmission() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          "grade-report-upload": this.value.files,
          gradeReportSubmitted: true,
        },
      };
      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.gradeSubmit.stopLoading();
        });
    },
    updateSubmission() {
      const jsonData = [
        {
          op: "add",
          path: "/data/grade-report-upload",
          value: this.value.files,
        },
        {
          op: "add",
          path: "/data/gradeReportSubmitted",
          value: true,
        },
      ];

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.gradeSubmit.stopLoading();
        });
    },
  },
};
</script>

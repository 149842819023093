<template>
  <div id="potential-classes">
    <h2 class="px-4 text-xl font-semibold sm:px-0 hidden">Potential Classes</h2>
    <div
      v-if="potentialClasses.length === 0"
      class="flex flex-col items-center justify-center px-4 pt-4 pb-12 mt-3 text-center bg-white border-t border-b border-gray-300 sm:border sm:rounded"
    >
      <img class="-mb-4" src="/images/no-search-result.svg" alt="" />
      <h3 class="mb-4 text-lg font-semibold md:text-xl md:mb-6">
        No classes found
      </h3>
      <p class="max-w-md.text-sm md:text-lg">
        We&rsquo;re sorry that there are no classes found at this time. Please
        try
        <a href="https://apiabroad.com/class-finder/" target="_blank"
          >https://apiabroad.com/class-finder/</a
        >
        to find potential classes for this student.
      </p>
    </div>
    <div v-else class="grid gap-4">
      <div
        v-for="potentialClass in potentialClasses"
        :key="potentialClass.id"
        class="grid gap-3 px-4 py-4 mt-3 bg-white border-t border-b border-gray-300 sm:border sm:rounded"
      >
        <h3 class="text-lg">{{ potentialClass.title }}</h3>

        <div clas="grid grid-cols-2 gap-4">
          <div v-if="site">
            <p class="text-sm text-gray-500">{{ site }}</p>
          </div>
          <div v-if="potentialClass.terms.length">
            <p class="text-sm text-gray-500">
              {{ joinItems(potentialClass.terms) }}
            </p>
          </div>
        </div>
        <div v-if="potentialClass.description">
          <p>{{ potentialClass.description }}</p>
        </div>
        <table>
          <tbody>
            <tr v-if="potentialClass.language_of_instruction">
              <th class="w-1/2" scope="row">
                Language of Instruction
              </th>
              <td class="w-1/2">
                {{ potentialClass.language_of_instruction }}
              </td>
            </tr>
            <tr v-if="potentialClass.source_system_class_id">
              <th class="w-1/2" scope="row">
                Host University Class Code
              </th>
              <td class="w-1/2">{{ potentialClass.source_system_class_id }}</td>
            </tr>
            <tr
              v-if="
                potentialClass.api_url_syllabus ||
                potentialClass.host_institution_url_syllabus
              "
            >
              <th class="w-1/2" scope="row">
                Syllabus
              </th>
              <td class="w-1/2">
                <a
                  :href="
                    getUrlSyllabus(
                      potentialClass.api_url_syllabus,
                      potentialClass.host_institution_url_syllabus
                    )
                  "
                  target="_blank"
                >
                  View Syllabus
                </a>
              </td>
            </tr>
            <tr v-if="potentialClass.programs.length">
              <th class="w-1/2" scope="row">
                Available Programs
              </th>
              <td class="w-1/2">
                {{ joinItems(potentialClass.programs) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PotentialClasses",
  props: {
    site: {
      type: String,
      default: "",
    },
    programSalesforceId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      potentialClasses: [],
    };
  },
  watch: {
    programSalesforceId: {
      handler: function (val) {
        if (val) this.getClasses(val);
      },
      immediate: true,
    },
  },
  methods: {
    async getClasses(programSalesforceId) {
      this.potentialClasses = [];
      const url =
        this.trailingSlash(process.env.MIX_LEARNING_SERVICE_API_ENDPOINT) +
        "v2/classes/related-to-program/" +
        programSalesforceId;
      let response = await fetch(url);

      if (response.status === 200) {
        let json = await response.json();
        this.potentialClasses = json.data.filter(
          (classItem) => classItem.status === "Active"
        );
      }
    },
    joinItems(items) {
      if (items) {
        return items
          .map(function (item) {
            return item.name;
          })
          .join(", ");
      }
    },
    getUrlSyllabus(api, hostInstitution) {
      return api ?? hostInstitution;
    },
  },
};
</script>

<style scoped>
table {
  @apply w-full table-fixed;
}
table th {
  @apply py-2 pr-2;
  @apply text-xs tracking-widest text-left text-gray-500 uppercase align-top;
}
table td {
  @apply py-2 pl-2;
  @apply text-sm;
}
</style>

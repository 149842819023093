<template>
  <div>
    <div class="flex justify-end">
      <a
        id="templateCsv"
        href="/files/Bulk_Import_Template.csv"
        target="_blank"
        class="flex align-middle m-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-download text-teal-900 font-semibold"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
        <span class="ml-2 text-teal-900 font-semibold">CSV Template</span>
      </a>
    </div>
    <file-upload-component
      :file-types="fileType"
      :custom-button-label="buttonLabel"
      :width-button="widthButton"
      :max-file-size="2"
      @updateFiles="handleUpdateFiles"
      @updateProgress="handleUpdateProgress"
    ></file-upload-component>
    <div class="flex mt-24">
      <button
        id="cancel-btn"
        ref="cancelBtn"
        data-cy="cancel-btn"
        type="button"
        variant="secondary-outlined"
        variant-type="normal"
        :custom-class="'w-107px'"
        class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-white border-teal-500 text-teal-500 hover:bg-teal-100 hover:text-white hover:border-teal-100"
        @click="$emit('cancel-btn')"
      >
        <span>Cancel</span>
      </button>
      <div class="w-full ml-4">
        <button-with-spinner
          id="validate-btn"
          ref="validateBtn"
          data-cy="validate-btn"
          :disabled="buttonDisabled"
          class="flex w-full justify-center rounded font-semibold border-2 border-transparent px-4 min-h-10 hover:no-underline items-center bg-yellow-500 text-gray-700 hover:bg-yellow-900 hover:text-gray-700 focus:bg-yellow-900"
          @click.prevent="handleValidateCsvButton"
        >
          <span> Validate CSV File </span>
        </button-with-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadComponent from "../forms/SharedComponents/FileUpload";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner";
export default {
  name: "BulkEnrollmentUploadScreen",
  components: {
    "file-upload-component": FileUploadComponent,
    "button-with-spinner": ButtonWithSpinner,
  },
  emits: ["validate-csv", "cancel-btn"],
  data() {
    return {
      fileType: [".csv"],
      buttonLabel: "Upload CSV file",
      customFileBoxLabel: "Teste",
      widthButton: "w-3/5",
      files: false,
      buttonDisabled: true,
      uploadErrors: false,
    };
  },
  methods: {
    handleUpdateFiles(files, errors) {
      this.files = files;
      if (errors) {
        this.$refs.validateBtn.stopLoading();
        this.uploadErrors = errors;
      }
    },
    handleUpdateProgress(stillRunning) {
      if (!stillRunning) {
        this.buttonDisabled = false;
      }
    },
    handleValidateCsvButton() {
      this.$refs.validateBtn.startLoading();
      /* Once multipleFile props isn't set in FileUploadComponent, it returns only 01 file */
      this.$emit("validate-csv", this.files[0]);
    },
    stopLoadingBtn() {
      this.$refs.validateBtn.stopLoading();
    },
    // expects true or false
    disableValidateBtn(toggle) {
      this.buttonDisabled = toggle;
    },
  },
};
</script>

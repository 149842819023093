<template>
  <Modal
    :open="openModal"
    :show-close-button="false"
    :close-on-outside-click="false"
    @closeModal="toggleModal"
  >
    <template #modal-content>
      <div>
        <p class="text-gray-800 mb-6">
          The adventure of a lifetime awaits! Please review and complete your
          information to continue enrolling for {{ program.program.name }}.
        </p>

        <step-tracker
          :forms="steps"
          :application-step-tracker="false"
          :active-step="currentStep"
          class="px-6"
        />

        <div class="mt-16">
          <component
            :is="currentComponent"
            :application-id="applicationId"
            :current-application-data="currentApplication"
            :application-submissions="applicationSubmissions"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { eventBus } from "@/app";
import { mapState } from "vuex";
import Modal from "@/components/modals/Modal.vue";
import PersonalProfile from "@/components/forms/ProfileReview/PersonalProfile/PersonalProfileForm.vue";
import AcademicProfile from "@/components/forms/ProfileReview/AcademicProfile/AcademicProfileForm.vue";
import Addresses from "@/components/forms/ProfileReview/Addresses/AddressesForm.vue";
import forms from "@/mixins/forms";

export default {
  name: "ProfileReviewModal",
  components: { Modal, PersonalProfile, AcademicProfile, Addresses },
  mixins: [forms],
  data() {
    return {
      openModal: false,
      currentStep: 0,
    };
  },
  computed: {
    ...mapState(["program", "studentApplications"]),
    steps() {
      return [
        {
          name: "Personal Profile",
          component: "PersonalProfile",
        },
        {
          name: "Academic Profile",
          component: "AcademicProfile",
        },
        {
          name: this.paymentTitle,
          component: "Addresses",
        },
      ];
    },
    currentComponent() {
      return this.steps[this.currentStep].component;
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
    currentApplication() {
      const applicationSubmissions = this.studentApplications.find(
        (application) => application.id === this.applicationId
      );
      return applicationSubmissions !== undefined ? applicationSubmissions : {};
    },
    paymentTitle() {
      return this.currentApplication.bulkEnrollmentStatus === "Accepted" ||
        this.institutionPaysApplicationFee
        ? "Institution Pays"
        : "Pay Deposit";
    },
    applicationSubmissions() {
      return this.currentApplication?.submissions ?? [];
    },
  },
  created() {
    eventBus.$on("changeStep", (val) => {
      this.changeStep(val);
    });
    this.setCurrentStep(this.currentApplication);
    this.openProfileModal(this.currentApplication);
  },
  methods: {
    toggleModal() {
      this.openModal = !this.openModal;
    },
    setCurrentStep(application) {
      let hasAcademicInfo = application.submissions.some(
        (submission) =>
          submission.form === this.academicId[this.currentEnvironment]
      );
      let hasInstitutionPaysInfo = application.submissions.some(
        (submission) =>
          submission.form === this.institutionPayments[this.currentEnvironment]
      );
      this.currentStep = hasAcademicInfo ? 2 : hasInstitutionPaysInfo ? 3 : 0;
    },
    openProfileModal(application) {
      this.openModal = Boolean(
        application.bulkEnrollmentId &&
          !this.formIsComplete(this.applicationId, "v2")
      );
    },
    async changeStep() {
      await this.$store.dispatch("getFormioSubmissions");
      await this.$store.dispatch("getStudentApplications");
      this.currentStep++;
      if (this.currentStep >= this.steps.length) this.openModal = false;
    },
  },
};
</script>

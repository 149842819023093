<template>
  <form-panel
    :id="'billing-address'"
    :title="'Billing Address'"
    panel-content-class="grid grid-cols-1 gap-4 pb-6 mb-6 border-b border-gray-200 billing-form md:grid-cols-2 sm:gap-6"
  >
    <template #content>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>First name</span>
        </span>
        <input
          v-model="value.firstName"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="billingAddressFirstName"
          :class="{ 'bg-error-100': v$.value.firstName.$error }"
        />
        <div
          v-if="v$.value.firstName.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.firstName.$error }"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.firstName.maxLength.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.firstName.$error }"
        >
          Please use a maximum of
          {{ v$.value.firstName.maxLength.$params.max }} characters for the
          first name.
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Last name</span>
        </span>
        <input
          v-model="value.lastName"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="billingAddressLastName"
          :class="{ 'bg-error-100': v$.value.lastName.$error }"
        />
        <div
          v-if="v$.value.lastName.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.lastName.$error }"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.lastName.maxLength.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.lastName.$error }"
        >
          Please use a maximum of
          {{ v$.value.lastName.maxLength.$params.max }} characters for the last
          name.
        </div>
      </label>
      <label class="block text-sm md:col-span-2">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Address</span>
        </span>
        <input
          v-model="value.address"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="billingAddress"
          :class="{ 'bg-error-100': v$.value.address.$error }"
        />
        <div
          v-if="v$.value.address.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.address.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>City</span>
        </span>
        <input
          v-model="value.city"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="billingAddressCity"
          :class="{ 'bg-error-100': v$.value.city.$error }"
        />
        <div
          v-if="v$.value.city.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.city.$error }"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.city.maxLength.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.city.$error }"
        >
          Please use a maximum of
          {{ v$.value.city.maxLength.$params.max }} characters for the city.
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>State/Province</span>
        </span>
        <input
          v-model="value.state"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="billingAddressState"
          :class="{ 'bg-error-100': v$.value.state.$error }"
        />
        <div
          v-if="v$.value.state.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.state.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Country</span>
        </span>
        <VSelect
          v-model="value.country"
          :value="value.country"
          :reduce="(country) => country.value"
          :options="countries"
          :get-option-label="(option) => option.label"
          aria-label="Country"
          :class="{ 'bg-error-100': v$.value.country.$error }"
          data-cy="billingAddressCountry"
          @search="fetchCountries"
        ></VSelect>
        <div
          v-if="v$.value.country.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.country.$error }"
        >
          Field is required
        </div>
      </label>
      <label class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Post Code</span>
        </span>
        <input
          v-model="value.postCode"
          type="text"
          class="form-input block w-full min-h-10"
          data-cy="billingAddressPostCode"
          :class="{ 'bg-error-100': v$.value.postCode.$error }"
        />
        <div
          v-if="v$.value.postCode.required.$invalid"
          class="error text-error-900"
          :class="{ hidden: !v$.value.postCode.$error }"
        >
          Field is required
        </div>
      </label>
    </template>
  </form-panel>
</template>

<script>
import formPanel from "../SharedComponents/panel";
import countryListApi from "../../../mixins/countryListApi";

import { maxLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "BillingAddress",
  components: { formPanel },
  mixins: [countryListApi],
  props: {
    billingAddress: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:billing-address"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        country: "United States of America",
        postCode: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:billing-address", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      firstName: {
        required,
        maxLength: maxLength(32),
      },
      lastName: {
        required,
        maxLength: maxLength(64),
      },
      address: {
        required,
      },
      city: {
        required,
        maxLength: maxLength(32),
      },
      state: {
        required,
      },
      country: {
        required,
      },
      postCode: {
        required,
      },
    },
  },
};
</script>

import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

let initialState = () => {
  return {
    formioToken: "",
    currentUser: null,
    enrollmentToken: "",
    userData: {},
    isImpersonating: false,
    studentApplications: [],
    studentFormioSubmissions: [],
    selectedProgramId: "",
    program: {},
    isInternship: false,
    universityName: "",
    universityCity: "",
    universityImageUrl: "",
    universityWebsiteUrl: "",
    programSelected: false,
    billingRules: {},
    term: "",
    payments: [],
    enrollmentApplications: [],
    sessionExpired: false,
    redirectPath: "",
    previousApplication: {
      previousApplicationId: "",
      session: "",
      paidFee: false,
      applicationFee: 0,
      transactionId: "",
      finished: false,
      submissionId: "",
    },
    currentApplicationId: "",
    applicationPaid: false,
    customer_id: "",
    uiVersion: "",
    completedSections: {
      onboarding1: false,
      payment: false,
      onboarding2: false,
      otherPayments: false,
    },
    onboardingStep1Forms: {},
    onboardingStep2Forms: {},
    currentGpa: 0,
    directProgramData: {},
    appliedAgreement: {},
    institutionPaysApplicationFee: false,
    institutionPaysConfirmationPayment: false,
    institutionPaysAcademicFee: false,
    institutionPaysHousingFee: false,
  };
};

let mutations = {
  reset(state, payload = null) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });

    state.userData = payload?.userData || null;
    state.currentUser = payload?.currentUser || null;
    state.isImpersonating = payload?.isImpersonating || false;
  },
  setFormioToken(state) {
    state.formioToken = null;
    if (localStorage.getItem("formioToken")) {
      state.formioToken = localStorage.getItem("formioToken");
    } else if (cookies.get("formio_api_token")) {
      state.formioToken = cookies.get("formio_api_token");
      localStorage.setItem("formioToken", state.formioToken);
    }
  },
  setEnrollmentToken(state, token) {
    state.enrollmentToken = token;
  },
  setFormioUser(state, formioUser = null) {
    if (formioUser) {
      state.userData = formioUser;
    } else {
      state.userData = JSON.parse(localStorage.getItem("formioUser"));
    }
  },
  setStudent(state, student) {
    state.student = student;
  },
  setStudentApplications(state, applications) {
    state.studentApplications = applications;
  },
  setCurrentApplicationSubmissions(state, submissions) {
    state.currentApplicationSubmissions = submissions;
  },
  setStudentFormioSubmissions(state, submissions) {
    state.studentFormioSubmissions = submissions;
  },
  setOnboardingFormioSubmissions(state, submissions) {
    state.onBoardingFormioSubmissions = submissions;
  },
  setSelectedProgramId(state, id) {
    state.selectedProgramId = id;
  },
  setProgram(state, program) {
    state.program = program;
  },
  setIsInternship(state, isInternship) {
    state.isInternship = isInternship;
  },
  setUniversityName(state, universityName) {
    state.universityName = universityName;
  },
  setUniversityCity(state, universityCity) {
    state.universityCity = universityCity;
  },
  setUniversityImageUrl(state, universityImageUrl) {
    state.universityImageUrl = universityImageUrl;
  },
  setUniversityWebsiteUrl(state, universityWebsiteUrl) {
    state.universityWebsiteUrl = universityWebsiteUrl;
  },
  setProgramSelected(state, programSelected) {
    state.programSelected = programSelected;
  },
  setBillingRules(state, billingRules) {
    state.billingRules = billingRules;
  },
  setPayments(state, billingData) {
    state.payments = billingData;
  },
  setCustomerId(state, customer_id) {
    state.customer_id = customer_id;
  },
  setTerm(state, term) {
    state.term = term;
  },
  setEnrollmentApplicationData(state, applications) {
    // Applications also known as progresses
    state.enrollmentApplications = applications;
  },
  clearProgram(state) {
    state.program = {};
  },
  setSessionExpired(state, value) {
    state.sessionExpired = value;
  },
  setRedirectPath(state, value) {
    state.redirectPath = value;
    localStorage.setItem("redirectPath", value);
  },
  setPreviousApplicationIdByField(state, fieldData) {
    switch (fieldData.handle) {
      case "previousApplicationId":
        state["previousApplication"]["previousApplicationId"] = fieldData.value;
        break;
      case "session":
        state["previousApplication"]["session"] = fieldData.value;
        break;
      case "paidFee":
        state["previousApplication"]["paidFee"] = fieldData.value;
        break;
      case "applicationFee":
        state["previousApplication"]["applicationFee"] = fieldData.value;
        break;
      case "transactionId":
        state["previousApplication"]["transactionId"] = fieldData.value;
        break;
      case "finished":
        state["previousApplication"]["finished"] = fieldData.value;
        break;
      case "submissionId":
        state["previousApplication"]["submissionId"] = fieldData.value;
        break;
      default:
        console.warn("The handle proportioned to the state does not exists");
    }
  },
  setUiVersion(state, version) {
    state.uiVersion = version;
  },
  setCompletedSections(state, data) {
    state.completedSections[data.field] = data.value;
  },
  setOnboarding2Forms(state, forms) {
    state.onboardingStep2Forms = forms;
  },
  resetPreviousApplicationState(state) {
    state["previousApplication"]["previousApplicationId"] = "";
    state["previousApplication"]["session"] = "";
    state["previousApplication"]["paidFee"] = false;
    state["previousApplication"]["applicationFee"] = 0;
    state["previousApplication"]["transactionId"] = "";
    state["previousApplication"]["finished"] = false;
    state["previousApplication"]["submissionId"] = "";
  },
  setCurrentApplicationId(state, applicationId) {
    state["currentApplicationId"] = applicationId;
  },
  updateChangeNotPending(state, applicationId) {
    state["studentApplications"].find((app) => {
      if (app["id"] === applicationId) {
        app["programChange"]["changeIsPending"] = false;
        return app;
      }
    });
  },
  setApplicationPaid(state, hasPaid) {
    state.applicationPaid = hasPaid;
  },
  setOnboardingForms(state, forms) {
    state.onboardingStep1Forms = JSON.parse(JSON.stringify(forms));
  },
  setAdvisorApprovalData(state, payload) {
    // payload = {field: "<String>, value: <Mixed>, applicationId: <String>}
    let application = state["studentApplications"].findIndex(
      (app) => app["id"] === payload["applicationId"]
    );
    if (application !== -1) {
      state["studentApplications"][application]["advisorApproval"][
        payload.field
      ] = payload.value;
    }
  },
  setCurrentGpa(state, payload) {
    state.currentGpa = payload;
  },
  setDirectProgramData(state, programData) {
    state.directProgramData = programData;
  },
  clearDirectProgram(state) {
    state.directProgramData = {};
  },
  setAppliedAgreement(state, payload) {
    state.appliedAgreement = Object.assign({}, payload);
  },
  setInstitutionPaysValue(state, payload) {
    // payload = {field: <String>, value: <Boolean>}
    if (
      payload !== undefined &&
      payload !== null &&
      Object.hasOwnProperty.call(payload, "field") &&
      Object.hasOwnProperty.call(payload, "value") &&
      typeof payload.field === "string" &&
      typeof payload.value === "boolean"
    ) {
      if (payload.field === "applicationFee") {
        state.institutionPaysApplicationFee = payload.value;
      }
      if (payload.field === "confirmationPayment") {
        state.institutionPaysConfirmationPayment = payload.value;
      }
      if (payload.field === "academicFee") {
        state.institutionPaysAcademicFee = payload.value;
      }
      if (payload.field === "housingFee") {
        state.institutionPaysHousingFee = payload.value;
      }
    }
  },
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  setFeatureFlags(state, featureFlags) {
    state.featureFlags = featureFlags;
  },
  setItemsToPay(_, itemsToPay) {
    localStorage.setItem("toPay", JSON.stringify(itemsToPay));
  },
  cleanPaymentsToPay() {
    localStorage.removeItem("toPay");
  },
  setIsImpersonating(state, booleanValue) {
    state.isImpersonating = booleanValue;
  },
  setAddressInfo(state, addresInfo) {
    state.addressInfo = addresInfo;
  },
};
export default mutations;

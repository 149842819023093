import { mapState } from "vuex";
import { eventBus } from "../app";
import formService from "../services/form";

export default {
  data() {
    return {
      currentStep: 0,
      newApplicationId: "",
      secondaryApplicationId: "",
      newOrValid: true,
      resumed: false,
      ready: false,
      isLoading: true,
      applicationSubmissions: [],
    };
  },
  computed: {
    ...mapState(["formioToken", "programSelected", "applicationPaid"]),
    tokenValid() {
      let valid = false;
      if (typeof this.formioToken === "string" && this.formioToken) {
        valid = true;
      }
      return valid;
    },
    hasLocalStorage() {
      return typeof Storage !== "undefined";
    },
    getApplicationId() {
      //when its a new application the ID is available in the data property as newApplicationId
      if (this.newApplicationId) {
        return this.newApplicationId;
        //when resuming an application the Id is available as a prop applicationId
      } else if (this.applicationId) {
        return this.applicationId;
      } else {
        return null;
      }
    },
    inProgress() {
      return !!this.applicationId;
    },
    validApplicationId() {
      if (!this.getFarthestSubmission()) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    if (this.tokenValid) {
      this.setToken();
    }
  },
  async created() {
    await this.fetchApplicationSubmissions();
    eventBus.$on("changeStep", () => {
      this.changeStep();
    });
    eventBus.$on("showSpinner", (value) => {
      this.isLoading = value;
    });

    this.$store.commit("setFormioToken");
    this.$store.dispatch("userSetup");
    this.createComponent();
  },
  methods: {
    async fetchApplicationSubmissions() {
      if (this.getApplicationId) {
        return formService
          .getSubmissionReport({
            applicationIds: [this.getApplicationId],
          })
          .then((submissions) => {
            this.applicationSubmissions = submissions;
            return submissions;
          });
      } else {
        return [];
      }
    },
    setToken() {
      if (this.hasLocalStorage) {
        localStorage.setItem("formioToken", this.formioToken);
      }
    },
    createComponent() {
      // set state value if application has been paid
      this.setPaidStatus();
      //Validate component if V2

      if (this.uiVersion === "v2") this.validateCurrentStep(this.currentStep);

      // Get program data for in-progress application
      if (this.inProgress && this.$store.state.selectedProgramId) {
        this.selectProgram(this.$store.state.selectedProgramId).then(() => {
          if (
            this.slug !== "program-selection" &&
            this.slug !== "program-confirmation" &&
            !this.programSelected
          ) {
            this.loadProgramSelection();
          }
          this.ready = true;
          // If the application_id in the url is mucked up, or they don't actually have any submissions, redirect to create a new one
          if (!this.validApplicationId) {
            this.newOrValid = false;
            this.$router.push({ path: "/applications/new" });
          } else {
            // If things are valid but no slug is present, figure out the slug and go there
            if (!this.slug) {
              let form = this.getFarthestAlongIncompleteForm();

              if (!form) {
                form = this.forms[this.forms.length - 1];
              }

              // Need to use slug to set current step, so when form is submitted, it moves to the next step correctly
              let slug = form.slug;
              this.currentStep = this.forms.findIndex((form) => {
                return form.slug === slug;
              });

              this.$router.replace({
                path: "/applications/" + this.applicationId + "/" + form.slug,
              });
            } else {
              // Once we do have a slug, set current step (which our form component depends on)
              this.currentStep = this.forms.findIndex((form) => {
                return form.slug === this.$route.params.slug;
              });
            }
          }
        });
      } else {
        this.$store.dispatch("getFormioSubmissions").then(() => {
          if (this.applicationId && this.validApplicationId) {
            this.$store.commit("setProgramSelected", true);
            this.$store
              .dispatch("setProgramDataByApplicationId", {
                applicationId: this.applicationId,
                apolloClient: this.$apollo,
              })
              .then(() => {
                this.ready = true;
                this.currentStep = this.forms.findIndex((form) => {
                  return form.slug === this.$route.params.slug;
                });
              });
          } else {
            //load program selection form if no program selected
            if (
              this.slug !== "program-selection" &&
              this.slug !== "program-confirmation" &&
              !this.programSelected
            ) {
              this.loadProgramSelection();
            }
            this.ready = true;

            this.currentStep = this.forms.findIndex((form) => {
              return form.slug === this.$route.params.slug;
            });
          }
        });
      }
    },
    setPaidStatus() {
      //set a store property indicating if application has a payment submission
      if (this.applicationSubmissions.length === 0) {
        this.$store.commit("setApplicationPaid", false);
      } else {
        this.applicationSubmissions.forEach((submission) => {
          if (
            submission.form === this.forms[this.paymentFormIndex].id &&
            submission.state === "submitted"
          ) {
            this.$store.commit("setApplicationPaid", true);
          }
        });
      }
    },
    async selectProgram(programId) {
      if (programId) {
        this.$store.commit("setProgramSelected", true);
        this.$store.commit("setSelectedProgramId", programId);
        await this.$store.dispatch("getProgramData", this.$apollo);
      } else {
        this.$store.commit("setSelectedProgramId", "");
        this.$store.commit("setProgram", {});
        this.$store.commit("setProgramSelected", false);
        this.$store.commit("setIsInternship", false);
        this.$store.commit("setUniversityName", "");
        this.$store.commit("setUniversityCity", "");
        this.$store.commit("setUniversityImageUrl", "");
        this.$store.commit("setUniversityWebsiteUrl", "");
        this.$store.commit("setSelectedProgramId", "");
      }
    },
    loadProgramSelection() {
      this.currentStep = 0;
      this.$router.push({ path: "/applications/new" });
    },
    /**
     * Returns farthest along submission. May be either draft or complete
     */
    getFarthestSubmission() {
      if (this.applicationSubmissions.length === 0) {
        return false;
      }

      let order = {};
      let i = 0;
      this.forms.forEach((form) => {
        order[i] = form.id;
        i++;
      });

      // copy submission to prevent mutation of state shared by other components
      let copySubmissions = JSON.parse(
        JSON.stringify(this.applicationSubmissions)
      );
      copySubmissions.forEach((submission) => {
        let key = Object.keys(order).find(
          (key) => order[key] === submission.form
        );
        submission.key = parseInt(key);
      });

      return copySubmissions.reduce((max, submission) => {
        return max.key > submission.key ? max : submission;
      });
    },
    /**
     *
     * @returns {{name: string, id: string, url: string, slug: string}}
     */
    getFarthestAlongIncompleteForm() {
      let farthestSubmission = this.getFarthestSubmission();
      if (farthestSubmission.state === "draft") {
        return this.forms[farthestSubmission.key];
      } else if (this.forms.length <= farthestSubmission.key + 1) {
        return this.forms[farthestSubmission.key];
      } else {
        return this.forms[farthestSubmission.key + 1];
      }
    },
    goToStep(step) {
      this.currentStep = step;
      if (this.inProgress) {
        this.$router.push({
          path:
            "/applications/" +
            this.applicationId +
            "/" +
            this.forms[this.currentStep]["slug"],
        });
      } else {
        this.$router.push({
          path: "/applications/new/" + this.forms[this.currentStep]["slug"],
        });
      }
    },
    /**
     * Move to the next application step when using our own vue forms.
     */
    async changeStep() {
      await this.fetchApplicationSubmissions();
      this.$store.dispatch("getStudentApplications").then(() => {
        const previousStep = this.currentStep;
        //set application paid true only if we are in the payment step
        if (this.currentStep === this.paymentFormIndex) {
          this.setPaidStatus();
        }

        let form = this.getFarthestAlongIncompleteForm();

        if (!form) {
          form = this.forms[this.forms.length - 1];
        }

        // Need to use slug to set current step, so when form is submitted, it moves to the next step correctly
        let slug = form.slug;
        this.currentStep = this.forms.findIndex((form) => {
          return form.slug === slug;
        });

        if (previousStep === 0) this.$store.commit("clearDirectProgram");
        this.$router.push({
          path: "/applications/" + this.getApplicationId + "/" + form.slug,
        });
      });
    },
    setNewApplicationId(id) {
      this.newApplicationId = id;
      this.$store.commit("setCurrentApplicationId", id);
      console.info(
        id === "" ? "Application ID Unset" : "Application ID set successfully"
      );
    },
    setNewSecondaryApplicationId(id) {
      this.secondaryApplicationId = id;
      console.info(
        id === ""
          ? "Secondary Application ID Unset"
          : "Secondary Application ID set successfully"
      );
    },
  },
};

<template>
  <!-- remove this -->
  <div
    :data-cy="cypressId"
    class="flex items-start py-4 border-b border-gray-200 md:items-center text-gray-650 sm:px-4 sm:py-2"
    :class="{ 'ml-12': !balancePending }"
  >
    <BaseCheckBox
      v-if="balancePending"
      :checked="selected"
      :data-cy="cypressId + '-selected'"
      container-class="ml-4"
      @input="onFieldChange('selected', $event, true)"
    />

    <div class="flex-1 md:gap-x-6 md:grid-cols-16 md:grid md:items-center">
      <div
        class="text-base md:text-sm font-semibold text-gray-600 md:col-span-4 lg:col-span-3"
      >
        {{ payment.name }}
      </div>

      <div
        v-if="!payment.balance"
        class="mt-1 text-sm md:col-span-3 md:mt-0 lg:col-span-3"
      >
        <SkeletonLoader />
      </div>

      <Balance v-else :balance="balance" />

      <SkeletonLoader v-if="!payment.balance" />
      <PaymentMessage
        v-else
        :payment="payment"
        :class="
          hasDatabasePayment
            ? 'md:col-span-6 lg:col-span-7'
            : 'md:col-span-10 lg:col-span-11'
        "
      />
      <div
        v-if="hasDatabasePayment"
        class="mt-2 md:mt-0 md:flex md:justify-end md:col-span-4"
      >
        <p
          v-if="hasDatabasePayment"
          class="text-sm md:text-base text-gray-500 italic text-right mr-8"
        >
          Processing
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { toCamelCase, toFixed } from "../../mixins/helpers";
import Balance from "./Balance";
import PaymentMessage from "./PaymentMessage";
import SkeletonLoader from "../helpers/SkeletonLoader";
import { eventBus } from "@/app";
import BaseCheckBox from "@/components/forms/SharedComponents/BaseCheckBox";
import formInputsMixin from "@/mixins/formInputs.js";

export default {
  components: {
    Balance,
    PaymentMessage,
    SkeletonLoader,
    BaseCheckBox,
  },
  mixins: [formInputsMixin],
  props: {
    payment: {
      type: Object,
      default() {
        return {};
      },
    },
    displayToDo: {
      type: Boolean,
      default: false,
    },
    remainingBalance: {
      type: Number,
      default: 0,
    },
    showRemainingBalance: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handlePayment"],
  data() {
    return {
      selected: false,
      hasDatabasePayment: false,
    };
  },
  computed: {
    cypressId() {
      return toCamelCase(this.payment.name);
    },
    iconSrc() {
      let url = process.env.MIX_CDN_ENDPOINT;
      let icon = "icon_payment-balance.svg";

      if (this.payment.name === "Application Deposit") {
        icon = "icon_deposit.svg";
      } else if (this.payment.name === "Confirmation Payment") {
        icon = "icon_payment-confirm.svg";
      }
      return url + "/images/" + icon;
    },
    balancePending() {
      return Boolean(
        typeof this.balance !== "undefined" &&
          parseFloat(this.balance) > 0 &&
          !this.hasDatabasePayment
      );
    },
    balance() {
      let balance = this.payment.balance;
      if (this.showRemainingBalance) {
        balance = Number(toFixed(this.remainingBalance / 100)).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 2,
          }
        );
      }
      return balance;
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
    isApplicationDeposit() {
      const validPayment = Boolean(
        this.payment && Object.keys(this.payment).length > 0
      );
      const name = validPayment ? this.payment.name : "";
      const status = validPayment ? this.payment.status : "";
      return Boolean(name === "Application Deposit" && status === "Open");
    },
  },
  watch: {
    selected: {
      handler: function (val) {
        //Add/Remove Payment on parent
        this.$emit("handlePayment", {
          selected: val,
          payment: this.payment,
        });
      },
    },
    payment: {
      handler: function () {
        if (this.isApplicationDeposit) {
          this.setDatabasePayment();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    eventBus.$on("selectPayment", (value) => {
      this.selectPayment(value);
    });
  },
  methods: {
    selectPayment(value) {
      if (this.balancePending) this.selected = value;
    },
    async setDatabasePayment() {
      let hasPayment = false;
      let options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response = await fetch(
        "/api/payments/" + this.applicationId,
        options
      );
      hasPayment = response.status === 200;
      this.hasDatabasePayment = hasPayment;
    },
  },
};
</script>

<style></style>

<template>
  <div class="space-y-6">
    <template v-if="loading">
      <countdown
        ref="counter"
        :message="message"
        @redirecting="(msg) => (message = msg)"
      />
    </template>
    <template v-else>
      <h4 class="mb-2 font-semibold text-gray-700">
        {{ title }}
      </h4>
      <slot name="courseDescription">
        <p>
          <template v-if="hasAcademicPdf">
            We have a guide to help you
            <a
              class="text-teal-500 text-semibold"
              :href="program.academic_details_pdf"
              target="_blank"
            >
              here.
            </a>
            Once you review, please fill in the form below with courses that are
            are offered within the program you selected.
          </template>
          <template v-else-if="hasProgramURL">
            Please fill in the form below with courses that are offered within
            the program you selected. Visit the
            <a
              class="text-teal-500 text-semibold"
              :href="program.program.url"
              target="_blank"
            >
              API program page
            </a>
            to find the most up-to-date course offerings and schedules.
          </template>
          <template v-else>
            Please fill in the form below with courses that are offered within
            the program you selected. Visit the
            <a
              class="text-teal-500 text-semibold"
              href="https://apiabroad.com"
              target="_blank"
            >
              API website
            </a>
            to find the most up-to-date course offerings and schedules
          </template>
          There are some host institutions that do not use Course Code and/or
          Section. If the course code and/or section are not listed in the
          course information provided, please leave those fields blank.
        </p>
      </slot>
      <slot name="courseDesign">
        <div class="grid grid-cols-1 gap-6 md:gap-10">
          <div class="lg:grid lg:grid-cols-1 lg:gap-y-6">
            <CoursesCustomizable
              ref="courses"
              :languages="languagesOfInstruction"
              :limit="limit"
              :form-data="submissionData"
              @input="getLatestSubmission($event, 'courseFormInfo')"
            />
            <h4 class="mb-2 font-semibold text-gray-700">
              Alternative Course Selection
            </h4>
            <p>
              Please fill in the form below with alternative courses in the
              event that your top choices are full. Remember to only select
              courses that are offered within the program you selected.
            </p>
            <CoursesCustomizable
              :key="'coursesAlt'"
              ref="coursesAlternative"
              :languages="languagesOfInstruction"
              :path-name="'alternativeCourseSelection'"
              :limit="limit"
              :form-data="submissionData"
              @input="getLatestSubmission($event, 'courseFormInfoAlt')"
            />
          </div>
        </div>
      </slot>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ButtonSpin
          ref="seco"
          :disabled="buttonStats.requestOngoing || completedSubmission"
          :variant="'secondary'"
          :type="'button'"
          @click.prevent="submitForm(true)"
        >
          {{ buttonStats.secondary }}
        </ButtonSpin>
        <ButtonSpin
          ref="prim"
          :disabled="buttonStats.requestOngoing || !formChanged"
          :variant="'primary'"
          :type="'button'"
          @click.prevent="submitForm()"
        >
          {{ buttonStats.primary }}
        </ButtonSpin>
      </div>
      <div
        v-if="buttonStats.finished"
        class="text-center text-success-900 col-span-2"
      >
        Submission Complete, thank you!
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { INSTRUCTION_LANGUAGES } from "../../../constants";
import CoursesCustomizable from "./CourseCustomizable.vue";
import formioApi from "../../../mixins/formIoApi";
import formValidation from "../../../mixins/formValidation";
import formMixing from "../../../mixins/forms";
import countdown from "../SharedComponents/CountDown.vue";
import ButtonSpin from "../SharedComponents/ButtonWithSpinner.vue";

export default {
  name: "CourseComponent",
  components: {
    CoursesCustomizable,
    countdown,
    ButtonSpin,
  },
  mixins: [formioApi, formMixing, formValidation],
  props: {
    title: {
      type: String,
      default: "Course Selection",
    },
    limit: {
      type: Number,
      default: 6,
    },
    pathName: {
      type: String,
      default: "courseSelection",
    },
    submissionId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      languagesOfInstruction: INSTRUCTION_LANGUAGES,
      loading: true,
      message: "",
      courseFormInfo: {},
      changes: 0,
      courseFormInfoAlt: {},
      formChanged: false,
      buttonStats: {
        primary: "Submit",
        secondary: "Save Progress",
        requestOngoing: false,
        finished: false,
      },
      sectionsToValidate: ["coursesAlternative", "courses"],
      completedSubmission: false,
    };
  },
  computed: {
    ...mapState(["uiVersion"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    disablePrimary() {
      return this.handleDataAs === "update" ? this.changes <= 2 : false;
    },
    handleDataAs() {
      return this.submission_id === "" ? "new" : "update";
    },
    hasAcademicPdf() {
      if (!Object.hasOwnProperty.call(this.program, "academic_details_pdf"))
        return false;
      if (!this.program.academic_details_pdf) return false;
      return true;
    },
    hasProgramURL() {
      if (!Object.hasOwnProperty.call(this.program, "program")) return false;
      if (typeof this.program.program !== "object") return false;
      if (this.program.program === null) return false;
      if (Array.isArray(this.program.program)) return false;
      if (!Object.hasOwnProperty.call(this.program.program, "url"))
        return false;
      if (!this.program.program.url) return false;
      return true;
    },
    formSubmissionURL() {
      return this.uiVersion === "v2"
        ? "courseform"
        : "studentapplicationstage2step2";
    },
  },
  async created() {
    let canView = await this.canViewForm();
    if (canView) {
      let submissionId = await this.getSubmissionExistence();
      if (submissionId) {
        this.submission_id = submissionId;
        let submissionData = await this.getSubmissionData(submissionId);
        if (submissionData) this.setCourseData(submissionData);
      }
      this.loading = false;
      return;
    }
    this.$refs.counter.startCountDown();
  },
  methods: {
    async canViewForm() {
      this.message = "Checking if form is aplicable to your program";
      const valid = await this.isValidApplicationAndIsOnRules(
        this.applicationId,
        "Course Selection"
      );
      return valid.valid;
    },
    getSubmissionExistence() {
      this.message = "Gathering form submission, please wait.";
      return this.formioSubmissionExists(
        this.formSubmissionURL,
        this.applicationId
      );
    },
    getSubmissionData(id) {
      this.message = "Getting your form submission";
      return this.getFormioSubmission(this.formSubmissionURL, id);
    },
    setCourseData(data) {
      this.submissionData = data;
      this.completedSubmission = data.completedCourseSelection ?? false;
    },
    getLatestSubmission(newData, objectKey) {
      this[objectKey] = newData;
      this.formChanged = true;
      this.buttonStats.finished = false;
      this.changes++;
    },
    async submitForm(isDraft = false) {
      if (!isDraft) {
        try {
          await this.validate();
        } catch (e) {
          console.log(e);
        }
        if (this.hasErrors) return true;
      }

      this.buttonStats.finished = false;
      // Courses, preparing data
      let formData = this.handleDataAs === "new" ? {} : [];
      let brandNewCourses = this.courseFormInfo.courses.map((course) => {
        return this.formatCourseData(course);
      });
      let brandNewAltCourses = this.courseFormInfoAlt.courses.map((course) => {
        return this.formatCourseData(course);
      });
      if (this.handleDataAs === "new") {
        formData.data = {
          [this.pathName]: brandNewCourses,
          alternativeCourseSelection: brandNewAltCourses,
          completedCourseSelection: isDraft ? false : true,
          application_id: this.applicationId,
        };
      } else {
        formData.push({
          op: "add",
          path: "/data/" + this.pathName,
          value: brandNewCourses,
        });
        formData.push({
          op: "add",
          path: "/data/alternativeCourseSelection",
          value: brandNewAltCourses,
        });
        formData.push({
          op: "add",
          path: "/data/completedCourseSelection",
          value: isDraft ? false : true,
        });
      }
      // Sending form
      this.buttonSpinnersActions(true);
      try {
        let submissionStatus = await this.submitToFormIo(
          this.formSubmissionURL,
          formData,
          this.handleDataAs === "update" ? "PATCH" : "post",
          this.submission_id
        );
        if (submissionStatus) {
          this.submission_id = submissionStatus;
          this.buttonStats.finished = true;
          this.formChanged = false;
          this.completedSubmission = isDraft ? false : true;
        }
      } catch (e) {
        // False positive
        if (e instanceof Error && Object.hasOwnProperty.call(e, "response")) {
          let error = await e.response.json();
          if (
            Object.hasOwnProperty.call(error, "errors") &&
            Array.isArray(error.errors) &&
            error.errors.find((err) => err === "Resource not found")
          ) {
            this.buttonStats.finished = true;
            this.formChanged = false;
          }
        } else {
          console.error(e);
        }
      } finally {
        this.buttonSpinnersActions();
      }
    },
    buttonSpinnersActions(activate = false) {
      this.buttonStats.requestOngoing = activate;
      let mustReloadSaveProgressButton =
        !this.completedSubmission && this.isDraft ? true : false;
      if (activate) {
        if (mustReloadSaveProgressButton) {
          this.$refs.seco.startLoading();
        }
        this.$refs.prim.startLoading();
        return;
      }
      if (mustReloadSaveProgressButton) {
        this.$refs.seco.stopLoading();
      }
      this.$refs.prim.stopLoading();
    },
    formatCourseData(course) {
      let courseData = Object.assign({}, course); // Avoids clone issues
      return {
        courseTitle: courseData.title,
        languageOfInstruction: courseData.languageInstruction,
        requiredForMajor: courseData.majorRequired,
        courseCode: courseData.code,
        courseSection: courseData.section,
        credits: courseData.credits,
      };
    },
    formatState(isDraft = false) {
      return {
        op: "replace",
        path: "/state",
        value: isDraft ? "draft" : "submitted",
      };
    },
  },
};
</script>

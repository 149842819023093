<template>
  <div class="mb-10">
    <h4 class="mb-4 text-lg font-semibold text-gray-700">{{ title }}</h4>
    <div class="grid grid-cols-1 gap-y-6 gap-x-4 md:grid-cols-2">
      <label :for="getName + 'PassportNumber'" class="block text-sm">
        <span class="inline-flex justify-between mb-2 text-gray-600">
          <span class="font-semibold mr-1">Passport Number</span>
          <Tooltip
            tooltip="The number seen in the top right corner of the first page."
          />
        </span>
        <input
          :id="getName + 'PassportNumber'"
          v-model.trim="v$.value.passportNumber.$model"
          type="text"
          placeholder="ex. 123789456"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.passportNumber.$error }"
        />
        <div
          v-if="
            v$.value.passportNumber.required.$invalid &&
            v$.value.passportNumber.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="
            v$.value.passportNumber.passportRegex.$invalid &&
            v$.value.passportNumber.$error
          "
          class="error text-error-900"
        >
          Invalid passport number
        </div>
      </label>
      <label :for="getName + 'IssuingAuthority'" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Issuing Authority</span>
        </span>
        <input
          :id="getName + 'IssuingAuthority'"
          v-model.trim="v$.value.issuingAuthority.$model"
          type="text"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.issuingAuthority.$error }"
        />
        <div
          v-if="
            v$.value.issuingAuthority.required.$invalid &&
            v$.value.issuingAuthority.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.issuingAuthority.minLength.$invalid"
          class="error text-error-900"
        >
          Issuing Authority must have at least
          {{ v$.value.issuingAuthority.minLength.$params.min }} characters.
        </div>
        <div
          v-if="v$.value.issuingAuthority.maxLength.$invalid"
          class="error text-error-900"
        >
          Issuing Authority must have no more than
          {{ v$.value.issuingAuthority.maxLength.$params.max }} characters.
        </div>
      </label>
      <Datepicker
        label-name="Date of Issue"
        :date="v$.value.dateIssue.$model"
        :required="true"
        :min-year="1950"
        :input-prefix="getName + 'Issue'"
        @updateDate="updateDateIssue($event)"
      />
      <Datepicker
        label-name="Date of Expiration"
        :date="v$.value.dateExpiration.$model"
        :required="true"
        :min-year="1950"
        :custom-errors="expirationDateErrors"
        :input-prefix="getName + 'Expiration'"
        @updateDate="updateDateExpiration($event)"
      />
      <Datepicker
        label-name="Date of Birth"
        :date="v$.value.dateBirth.$model"
        :required="true"
        :min-year="1950"
        :input-prefix="getName + 'Birth'"
        @updateDate="updateDateBirth($event)"
      />
      <fieldset>
        <legend class="mb-4 text-sm font-semibold text-gray-600">
          Sex on passport
        </legend>
        <div class="grid grid-cols-2">
          <label
            v-for="sex in SEXES"
            :key="sex.value"
            class="flex mb-4 md:mb-0 md:flex-auto"
            :for="getName + sex.value"
          >
            <input
              :id="getName + sex.value"
              v-model.trim="v$.value.sex.$model"
              type="radio"
              :name="getName + '-sex'"
              :value="sex.value"
              class="mt-1 text-blue-700 form-radio"
              :class="{ 'bg-error-100': v$.value.sex.$error }"
            />
            <span class="ml-2">{{ sex.label }}</span>
          </label>
          <div
            v-if="v$.value.sex.required.$invalid && v$.value.sex.$error"
            class="error text-error-900 col-span-3 text-sm"
          >
            Field is required
          </div>
        </div>
      </fieldset>
    </div>
    <div class="mt-10">
      <p class="mb-6">
        If your gender expression does not match the "sex" indicated on your
        passport, please alert API, so that we can provide you with important
        recommendations regarding the immigration procedures.
      </p>

      <p class="mb-4">
        In addition to the information above, a copy of your passport is also
        required. The passport copy should look like the example below:
      </p>
      <img class="w-32 max-w-6xl h-auto mx-auto" src="/images/passport.png" />
      <p>Upload instructions:</p>
      <ol class="pl-6 mb-6 list-decimal">
        <li>
          Submit only the page in your passport that shows your photo and
          signature.
        </li>
        <li>Be sure you have signed your passport.</li>
        <li>
          Be sure the copy is in color and clear (name and passport number must
          be legible, photo must be clear).
        </li>
        <li>
          If you are applying for a visa, submit a copy of your passport to us
          before you submit it to the consulate with your visa application.
        </li>
      </ol>
      <FileUpload
        :name="getName + '-upload'"
        :formio-files="formioData.files"
        :multiple-files="true"
        :file-types="['.jpeg', '.jpg', '.png']"
        @updateProgress="updateProgress"
        @updateFiles="updateFiles"
      />
      <div
        v-if="v$.value.files.required.$invalid && v$.value.files.$error"
        class="error text-error-900 col-span-3 text-sm"
      >
        Field is required
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import { SEXES } from "../../../constants";
import Datepicker from "../SharedComponents/Datepicker.vue";
import FileUpload from "../SharedComponents/FileUpload.vue";
import Tooltip from "../SharedComponents/Tooltip.vue";
import { mapState } from "vuex";
import { eventBus } from "../../../app";
import useVuelidate from "@vuelidate/core";

const validateFileUpload = (value) => value === false;
const passportRegex = helpers.regex(/^[a-zA-Z0-9]{6,10}$/);

function validExpirationDate(value) {
  let expirationDate = new Date(value);
  let dateWithinSixMonths = new Date();
  dateWithinSixMonths.setMonth(dateWithinSixMonths.getMonth() + 6);
  let programDateWithinSixMonths = new Date(
    this.program.end_date.replace(/-/g, "/")
  );
  programDateWithinSixMonths.setMonth(
    programDateWithinSixMonths.getMonth() + 6
  );

  return (
    expirationDate > dateWithinSixMonths &&
    expirationDate > programDateWithinSixMonths
  );
}

export default {
  name: "Passaport",
  components: {
    Datepicker,
    FileUpload,
    Tooltip,
  },
  props: {
    passportInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: "",
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["update:passport"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        passportNumber: "",
        issuingAuthority: "",
        dateIssue: "",
        dateExpiration: "",
        dateBirth: "",
        sex: "",
        files: [],
      },
      uploadOnProgress: false,
      uploadErrors: false,
      expirationDateErrors: [],
    };
  },
  computed: {
    ...mapState(["program"]),
    getName() {
      return this.title.replace(/\s/g, "").toLowerCase();
    },
  },
  validations: {
    value: {
      passportNumber: {
        required,
        passportRegex,
      },
      issuingAuthority: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(64),
      },
      dateIssue: {
        required,
      },
      dateExpiration: {
        required,
        validExpirationDate,
      },
      dateBirth: {
        required,
      },
      sex: {
        required,
      },
      files: {
        required,
      },
    },
    uploadOnProgress: {
      validateFileUpload,
    },
    uploadErrors: {
      validateFileUpload,
    },
  },
  watch: {
    "value.dateExpiration": {
      handler: function () {
        const error =
          "The date on your passport has expired or will expire while on program. To participant in a program abroad you will need a valid passport. Please begin the process to renew your passport as soon as possible";

        if (
          !this.v$.value.dateExpiration.validExpirationDate &&
          !this.expirationDateErrors.includes(error)
        ) {
          this.expirationDateErrors.push(error);
        }
        if (this.v$.value.dateExpiration.validExpirationDate) {
          this.expirationDateErrors = [];
        }
      },
    },
    formioData: {
      handler: function (newValue) {
        this.setFormioData(newValue);
      },
      deep: true,
      immediate: true,
    },
    value: {
      handler: function () {
        this.$emit("update:passport", this.value);
        eventBus.$emit("changeSuccessSubmission");
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.SEXES = SEXES;
  },
  methods: {
    updateDateIssue(date) {
      this.v$.value.dateIssue.$model = date;
    },
    updateDateExpiration(date) {
      this.v$.value.dateExpiration.$model = date;
    },
    updateDateBirth(date) {
      this.v$.value.dateBirth.$model = date;
    },
    updateProgress(val) {
      this.uploadOnProgress = val;
    },
    updateFiles(files, error) {
      this.value.files = files;
      this.uploadErrors = error;
    },
    setFormioData(formio) {
      if (Object.prototype.hasOwnProperty.call(formio, "passportNumber")) {
        this.value.passportNumber = formio.passportNumber;
        this.value.issuingAuthority = formio.issuingAuthority;
        this.value.dateIssue = formio.dateIssue;
        this.value.dateExpiration = formio.dateExpiration;
        this.value.dateBirth = formio.dateBirth;
        this.value.sex = formio.sex;
      }
    },
  },
};
</script>

<template>
  <div class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:gap-6">
    <label for="firstName" class="block text-sm">
      <span class="font-semibold text-gray-600 flex justify-between mb-2">
        <span>Legal first name</span>
      </span>
      <input
        id="firstName"
        v-model.trim="v$.value.firstName.$model"
        type="text"
        data-cy="first-name"
        class="form-input block w-full min-h-10"
        :class="{ 'bg-error-100': v$.value.firstName.$error }"
      />
      <div
        v-if="v$.value.firstName.required.$invalid && v$.value.firstName.$error"
        class="error text-error-900"
      >
        Field is required
      </div>
      <div
        v-if="v$.value.firstName.maxLength.$invalid"
        class="error text-error-900"
      >
        First name must have no more than
        {{ v$.value.firstName.maxLength.$params.max }} characters.
      </div>
      <div
        v-if="v$.value.firstName.nameRegex.$invalid"
        class="error text-error-900"
      >
        Field does not match the pattern
      </div>
      <span class="text-gray-600 text-xs col-span-3">
        Please enter your legal name so important documents are not delayed.
      </span>
    </label>
    <label for="lastName" class="block text-sm">
      <span class="font-semibold text-gray-600 flex justify-between mb-2">
        <span>Legal last name</span>
      </span>
      <input
        id="lastName"
        v-model.trim="v$.value.lastName.$model"
        type="text"
        data-cy="last-name"
        class="form-input block w-full min-h-10"
        :class="{ 'bg-error-100': v$.value.lastName.$error }"
      />
      <div
        v-if="v$.value.lastName.required.$invalid && v$.value.lastName.$error"
        class="error text-error-900"
      >
        Field is required
      </div>
      <div
        v-if="v$.value.lastName.maxLength.$invalid"
        class="error text-error-900"
      >
        Last name must have no more than
        {{ v$.value.lastName.maxLength.$params.max }} characters.
      </div>
      <div
        v-if="v$.value.lastName.nameRegex.$invalid"
        class="error text-error-900"
      >
        Field does not match the pattern
      </div>
    </label>
    <label for="email" class="block text-sm md:col-span-2">
      <span class="font-semibold text-gray-600 flex justify-between mb-2">
        <span>Email</span>
      </span>

      <template v-if="enabledEmail">
        <input
          id="email"
          v-model.trim="v$.value.email.$model"
          type="text"
          data-cy="email"
          class="form-input block w-full min-h-10 lowercase"
          :class="{ 'bg-error-100': v$.value.email.$error }"
        />
        <div
          v-if="v$.value.email.required.$invalid && v$.value.email.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="
            v$.value.email.email.$invalid ||
            v$.value.email.validEmailAddress.$invalid
          "
          class="error text-error-900"
        >
          Invalid email address. Please try another email address.
        </div>
        <div
          v-if="v$.value.email.maxLength.$invalid"
          class="error text-error-900"
        >
          Email must have no more than
          {{ v$.value.email.maxLength.$params.max }} characters.
        </div>
      </template>
      <p v-else class="block w-full min-h-10 lowercase text-base">
        {{ v$.value.email.$model }}
      </p>
    </label>
    <label for="phone" class="block text-sm">
      <span class="inline-flex justify-between mb-2 text-gray-600">
        <span class="font-semibold mr-1">Phone</span>
        <Tooltip
          tooltip="The best phone number to contact you. Your mobile number is usually best."
        />
      </span>
      <label for="phone" class="block text-sm">
        <MazPhoneNumberInput
          id="phone"
          ref="phoneInput"
          :model-value="v$.value.phone.$model"
          :color="'info'"
          :fetch-country="false"
          :default-country-code="v$.value.phoneCode.$model ? null : 'US'"
          :no-use-browser-locale="v$.value.phoneCode.$model ? true : false"
          :clearable="true"
          :preferred-countries="['US']"
          :no-example="true"
          :no-search="true"
          data-cy="phone"
          type="tel"
          @update="updatePhone($event)"
        />
        <div
          v-if="v$.value.phone.required.$invalid && v$.value.phone.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="
            v$.value.phone.validPhoneNumber.$invalid && v$.value.phone.$error
          "
          class="error text-error-900"
        >
          Invalid phone number. Please try another phone number.
        </div>
      </label>
    </label>
    <Datepicker
      label-name="Birthday"
      :date="v$.value.birthday.$model"
      :required="true"
      :min-year="1950"
      @updateDate="updateDate($event)"
    />
    <label for="citizenship" class="block text-sm">
      <span class="text-gray-600 inline-flex justify-between mb-2">
        <span class="font-semibold mr-1">Citizenship</span>
        <Tooltip
          tooltip="Choose your primary citizenship. If you have dual US citizenship, select United States."
        />
      </span>
      <VSelect
        id="citizenship"
        v-model="v$.value.citizenship.$model"
        :value="v$.value.citizenship.$model"
        :reduce="(country) => country.value"
        :options="countries"
        :get-option-label="(option) => option.label"
        data-cy="citizenship"
        aria-label="Country"
        :class="{ 'bg-error-100': v$.value.citizenship.$error }"
        @search="fetchCountries"
      ></VSelect>
      <div
        v-if="
          v$.value.citizenship.required.$invalid && v$.value.citizenship.$error
        "
        class="error text-error-900"
      >
        Field is required
      </div>
    </label>
    <label for="gender" class="block text-sm">
      <span class="font-semibold text-gray-600 flex justify-between mb-2">
        <span>Gender</span>
      </span>
      <VSelect
        id="gender"
        v-model.trim="v$.value.gender.$model"
        :value="v$.value.gender.$model"
        :options="GENDERS"
        :get-option-label="(option) => option.label"
        :reduce="(gender) => gender.value"
        data-cy="gender"
        aria-label="Gender"
        :class="{ 'bg-error-100': v$.value.gender.$error }"
      />
      <div
        v-if="v$.value.gender.required.$invalid && v$.value.gender.$error"
        class="error text-error-900"
      >
        Field is required
      </div>
    </label>
    <label for="dualCitizenship" class="inline-flex mt-2">
      <input
        id="dualCitizenship"
        v-model="v$.value.dualCitizenship.$model"
        type="checkbox"
        data-cy="dual-citizenship"
        class="mt-1 text-blue-700 form-checkbox"
      />
      <span class="text-gray-600 inline-flex justify-between mb-2">
        <span class="ml-2 mr-1">I have dual citizenship</span>
        <Tooltip
          tooltip="You will need to upload a passport for each country of citizenship."
        />
      </span>
    </label>
    <template v-if="showStudentType">
      <fieldset class="md:col-span-2">
        <legend class="font-semibold text-gray-600">I'm a</legend>
        <div class="mt-2 md:flex">
          <label
            v-for="studentType in STUDENT_TYPES"
            :key="studentType.value"
            class="flex mb-4 md:mb-0 md:flex-auto"
            :for="studentType.value"
          >
            <input
              :id="studentType.value"
              v-model.trim="v$.value.studentType.$model"
              type="radio"
              name="student-type"
              :value="studentType.value"
              data-cy="student-type"
              class="mt-1 text-blue-700 form-radio"
              :class="{ 'bg-error-100': v$.value.studentType.$error }"
            />
            <span class="ml-2">{{ studentType.label }}</span>
          </label>
        </div>
        <div
          v-if="
            v$.value.studentType.required.$invalid &&
            v$.value.studentType.$error
          "
          class="error text-error-900 text-sm"
        >
          Field is required
        </div>
      </fieldset>
      <template
        v-if="v$.value.studentType.$model === 'college' && !handleAsNonEnrolled"
      >
        <label for="schoolName" class="block text-sm">
          <span class="text-gray-600 inline-flex justify-between mb-2">
            <span class="font-semibold mr-1">School name</span>
            <Tooltip
              tooltip="If your school does not appear when you search, choose other."
            />
          </span>
          <VSelect
            id="schoolName"
            v-model="v$.value.university.$model"
            :value="v$.value.university.$model"
            :options="universities"
            :get-option-label="(option) => option.label"
            aria-label="School Name"
            data-cy="school-name"
            :class="{ 'bg-error-100': v$.value.university.$error }"
            @search="searchUniversity"
          ></VSelect>
          <div
            v-if="
              v$.value.university.required.$invalid &&
              v$.value.university.$error
            "
            class="error text-error-900"
          >
            Field is required
          </div>
        </label>
        <label for="major" class="block text-sm">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Major</span>
          </span>
          <VSelect
            id="major"
            v-model="v$.value.major.$model"
            :value="v$.value.major.$model"
            :options="MAJORS"
            :get-option-label="(option) => option.label"
            :reduce="(major) => major.value"
            aria-label="Majors"
            data-cy="major"
            :class="{ 'bg-error-100': v$.value.major.$error }"
            :filter="searchMajor"
          ></VSelect>
        </label>
      </template>
      <template
        v-if="
          value.studentType === 'highschool' || value.studentType === 'gapyear'
        "
      >
        <label for="parentName" class="block text-sm">
          <span class="text-gray-600 inline-flex justify-between mb-2">
            <span class="font-semibold mr-1">Parent Name</span>
            <Tooltip
              tooltip="Who should we contact with program information."
            />
          </span>
          <input
            id="parentName"
            v-model.trim="v$.value.parentName.$model"
            type="text"
            data-cy="parent-name"
            class="form-input block w-full min-h-10"
            :class="{ 'bg-error-100': v$.value.parentName.$error }"
          />
          <div
            v-if="
              v$.value.parentName.required.$invalid &&
              v$.value.parentName.$error
            "
            class="error text-error-900"
          >
            Field is required
          </div>
        </label>
        <label for="parentEmail" class="block text-sm">
          <span class="font-semibold text-gray-600 flex justify-between mb-2">
            <span>Parent Email</span>
          </span>
          <input
            id="parentEmail"
            v-model.trim="v$.value.parentEmail.$model"
            type="text"
            data-cy="parent-email"
            class="form-input block w-full min-h-10"
            :class="{ 'bg-error-100': v$.value.parentEmail.$error }"
          />
          <div
            v-if="
              v$.value.parentEmail.required.$invalid &&
              v$.value.parentEmail.$error
            "
            class="error text-error-900"
          >
            Field is required
          </div>
          <div
            v-if="v$.value.parentEmail.email.$invalid"
            class="error text-error-900"
          >
            Email must be a valid email
          </div>
        </label>
      </template>
    </template>

    <slot name="other-content" />
  </div>
</template>

<script>
import {
  email,
  helpers,
  maxLength,
  required,
  requiredIf,
} from "@vuelidate/validators";
import { validEmailAddress, validPhoneNumber } from "@/mixins/customValidators";
import { GENDERS } from "@/constants";
import countryListApi from "@/mixins/countryListApi";
import universitiesListApi from "@/mixins/universitiesListApi";
import majorsList from "@/mixins/majorsList";
import Datepicker from "@/components/forms/SharedComponents/Datepicker.vue";
import Tooltip from "@/components/forms/SharedComponents/Tooltip.vue";
import nestedModelComponent from "@/mixins/nestedModelComponent";

import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import "maz-ui/css/main.css";
import useVuelidate from "@vuelidate/core";

const STUDENT_TYPES = [
  {
    value: "college",
    label: "College student",
  },
  {
    value: "highschool",
    label: "High school student",
  },
  {
    value: "gapyear",
    label: "Gap year student",
  },
];

/*
  A-Za-z matches the characters A-Za-z literally (case sensitive)
  A matches the character A with index 6510 (4116 or 1018) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  Z matches the character Z with index 9010 (5A16 or 1328) literally (case sensitive)
  a matches the character a with index 9710 (6116 or 1418) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  z matches the character z with index 12210 (7A16 or 1728) literally (case sensitive)
  \u00C0 matches the character À with index C016 (19210 or 3008) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  \u00D6 matches the character Ö with index D616 (21410 or 3268) literally (case sensitive)
  \u00D8 matches the character Ø with index D816 (21610 or 3308) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  \u00F6 matches the character ö with index F616 (24610 or 3668) literally (case sensitive)
  \u00F8 matches the character ø with index F816 (24810 or 3708) literally (case sensitive)
  - matches the character - with index 4510 (2D16 or 558) literally (case sensitive)
  \u00ff matches the character ÿ with index FF16 (25510 or 3778) literally (case sensitive)
*/

const nameRegex = helpers.regex(
  /^[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00ff ]+$/
);
const requiredIfParentInfo = requiredIf(function () {
  return (
    this.value.studentType === "highschool" ||
    this.value.studentType === "gapyear"
  );
});

export default {
  name: "PersonalProfile",
  components: {
    Datepicker,
    Tooltip,
    MazPhoneNumberInput,
  },
  mixins: [
    countryListApi,
    universitiesListApi,
    majorsList,
    nestedModelComponent,
  ],
  props: {
    showStudentType: {
      type: Boolean,
      default: true,
    },
    handleAsNonEnrolled: {
      type: Boolean,
      default: false,
    },
    enabledEmail: {
      type: Boolean,
      default: true,
    },
    formioData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        firstName: "",
        lastName: "",
        email: "",
        isValidPhoneNumber: true,
        phoneCode: "",
        phone: "",
        birthday: "",
        citizenship: "United States of America",
        gender: "",
        dualCitizenship: false,
        studentType: "",
        university: null,
        major: "",
        parentName: "",
        parentEmail: "",
      },
    };
  },
  validations: {
    value: {
      firstName: {
        required,
        nameRegex,
        maxLength: maxLength(32),
      },
      lastName: {
        required,
        nameRegex,
        maxLength: maxLength(32),
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
        validEmailAddress,
      },
      phoneCode: {},
      isValidPhoneNumber: {},
      phone: {
        required,
        validPhoneNumber,
      },
      birthday: { required },
      citizenship: { required },
      gender: { required },
      dualCitizenship: {},
      studentType: {
        required: requiredIf(function () {
          return this.showStudentType;
        }),
      },
      university: {
        required: requiredIf(function () {
          return this.value.studentType === "college";
        }),
      },
      major: {},
      parentName: {
        required: requiredIfParentInfo,
      },
      parentEmail: {
        required: requiredIfParentInfo,
        email,
      },
    },
  },
  watch: {
    "value.studentType": {
      handler: function (newval) {
        if (newval === "highschool" || newval === "gapyear") {
          this.value.major = "";
          this.value.university = null;
        }
        if (newval === "college") {
          this.value.parentName = "";
          this.value.parentEmail = "";
        }
      },
    },
    formioData: {
      handler: function (val) {
        const excludedFields = ["countryCode", "phoneNumber", "phone"];
        for (const prop in val) {
          if (prop === "ihavedualcitizenship")
            this.value.dualCitizenship = val[prop];
          else if (prop === "student_type") this.value.studentType = val[prop];
          else if (prop === "schoolname") this.value.university = val[prop];
          else if (prop === "firstname") this.value.firstName = val[prop];
          else if (prop === "lastname") this.value.lastName = val[prop];
          else if (!excludedFields.includes(prop)) this.value[prop] = val[prop];
        }
        //Set phone data
        this.value.phoneCode = val.countryCode ?? "";
        if (!val.phone && !val.phoneNumber) {
          this.value.phone = "";
        } else {
          this.value.phone = `+${val.phone}` ?? val.phoneNumber;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.STUDENT_TYPES = this.handleAsNonEnrolled
      ? STUDENT_TYPES.filter((type) => type.value !== "college")
      : STUDENT_TYPES;
    this.GENDERS = GENDERS;
  },
  methods: {
    updateDate(date) {
      this.v$.value.birthday.$model = date;
    },
    updatePhone(data) {
      this.value.isValidPhoneNumber = data.isValid;
      if (data.isValid) {
        this.value.phone = data.nationalNumber;
        this.value.phoneCode = data.countryCallingCode;
      }
    },
  },
};
</script>

<style>
.vs__selected {
  margin: 0;
  position: absolute;
}
</style>

<template>
  <div>
    <div class="fixed inset-0 z-0 hidden bg-blue-700 opacity-50 sm:block" />
    <div
      class="fixed inset-0 z-10 py-6 overflow-auto bg-white sm:bg-transparent sm:p-6 sm:p-12"
    >
      <div
        id="fixed-date-modal"
        class="max-w-4xl px-6 mx-auto bg-white sm:px-16 md:px-32 sm:py-20 sm:rounded-lg sm:shadow"
        role="dialog"
        aria-modal="true"
        aria-labelledby="fixed-date-modal-label"
      >
        <div class="flex flex-col items-center mb-10">
          <div class="flex justify-end w-full sm:hidden">
            <button
              class="block h-6 w-6 flex items-center justify-center"
              @click="$emit('close')"
            >
              <span class="sr-only">Close</span>
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <img
            class="h-20"
            src="../../../images/internship-fixed-date.svg"
            alt="API Abroad"
          />
          <h2
            id="fixed-date-modal-label"
            class="mt-6 text-xl leading-tight text-center sm:text-2xl md:text-3xl"
          >
            8-Week Fixed Date Internship Program
          </h2>
        </div>

        <div class="text-gray-600 space-y-6">
          <p>
            <strong>Credit through University of Massachusetts Amherst</strong>
            | Participants may choose to enroll with API’s accredited school of
            record (University of Massachusetts Amherst) for an additional fee
            of $750. Participants who select this option will be connected via
            email with an internship coordinator from University of
            Massachusetts Amherst and will be responsible to complete several
            written reflections and/or related assignments over the course of
            the internship experience. (For a syllabus, please contact your API
            Program Coordinator.) Upon completion of the program, participants
            will receive an official transcript from University of Massachusetts
            Amherst which will reflect the credit earned in the form of a letter
            grade (256 hours, 6 credits for the 8-week full-time session OR 160
            hours, 3 credits for the 8-week part-time session). The official
            transcript will be sent directly to the participant’s home
            university.*
          </p>
          <p>
            <strong>Credit through Home Institution</strong> | Participants may
            petition your home university for academic credit for work completed
            during your internship. Participants who select this option will NOT
            receive an official transcript from API upon completion of the
            program, and will NOT receive credit through API’s school of record.
            Participants must confirm with their home university how credit is
            awarded for international internships and make sure to complete all
            necessary paperwork and assignments required to obtain credit.
          </p>
          <p>
            * Participants who indicate that they wish to receive credit through
            University of Massachusetts Amherst may not be able to opt out of
            this arrangement after submitting this form. Please be sure you
            understand all credit options before moving forward, and contact
            your API Program Coordinator if you have any questions.
          </p>
        </div>

        <div class="mt-12">
          <button
            class="block w-full text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900"
            @click="$emit('close')"
          >
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  emits: ["close"],
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

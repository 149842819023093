<template>
  <div v-if="showCard" class="flex flex-col">
    <router-link
      :to="{
        name: 'enrollment/code-of-conduct',
        params: { applicationId: $route.params.applicationId },
      }"
      class="flex flex-row justify-start items-center mb-2"
    >
      <CheckMarkIcon :completed="completed" />

      <p
        class="text-sm font-semibold ml-4"
        :class="completed ? 'text-gray-650' : 'text-cyan-700'"
      >
        Code of Conduct
      </p>
    </router-link>
  </div>
</template>

<script>
import codeOfConductRules from "../../mixins/codeOfConductRules.js";
import CheckMarkIcon from "../forms/SharedComponents/CheckMarkIcon.vue";
import { eventBus } from "../../app";
import { POST_FORM_RULES } from "../../constants.js";

export default {
  name: "CodeOfConduct",
  components: {
    CheckMarkIcon,
  },
  mixins: [codeOfConductRules],
  data() {
    return {
      completed: false,
      showCard: false,
    };
  },
  watch: {
    program: {
      handler: function (val) {
        this.reloadRules(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setCardProps();
  },
  methods: {
    reloadRules(programs) {
      this.setAllForm(programs);
      this.setCardProps();
    },
    setCardProps() {
      this.getAllSubmission();
      const notCompleted = Object.values(this.codeRules).filter(
        (rule) => rule.exist && !rule.submission.length
      );

      this.showCard = this.validRules.length > 0;
      this.completed = notCompleted.length === 0;

      if (this.showCard) {
        eventBus.$emit("addPreDepartureForm", {
          label: "Code of Conduct",
          ruleName: POST_FORM_RULES["Code Of Conduct"],
          routeName: "enrollment/code-of-conduct",
          submitted: this.completed,
        });
      }
    },
  },
};
</script>

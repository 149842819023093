import { mapState } from "vuex";
import { buildStringDateRange } from "../mixins/helpers";
import { readCookie } from "@/mixins/helpers";
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies();
export default {
  data() {
    return {
      paymentDone: false,
      chargeErrors: [],
    };
  },
  computed: {
    ...mapState(["program"]),
    orderDescription() {
      return (
        this.program.program.name +
        " (" +
        buildStringDateRange(this.program.start_date, this.program.end_date) +
        ")"
      );
    },
  },
  methods: {
    async checkTransactionExistsForApplicationId() {
      let vm = this;
      let options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      return await fetch("/api/payments/" + vm.currentApplicationId, options)
        .then((response) => {
          if (response.status === 200) {
            return true;
          } else if (response.status === 404) {
            return false;
          } else {
            throw new Error("Invalid response");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async tryCardPayment() {
      this.paymentDone = await this.checkTransactionExistsForApplicationId();
      if (!this.paymentDone) {
        let vm = this;
        let authData = {};
        authData.clientKey = process.env.MIX_AUTHORIZENET_CLIENT_KEY;
        authData.apiLoginID = process.env.MIX_AUTHORIZENET_API_LOGIN_ID;

        let cardInformation = {
          cardNumber: this.cardInformation.number,
          month: this.cardInformation.month,
          year: this.cardInformation.year,
          cardCode: this.cardInformation.cvCode,
        };

        let secureData = {};
        secureData.authData = authData;
        secureData.cardData = cardInformation;

        if (!this.paymentDone) {
          Accept.dispatchData(secureData, async function (response) {
            try {
              const validationResponse = await vm.responseHandler(response);
              await vm.processPayment(validationResponse.opaqueData);
            } catch (e) {
              vm.setErrorMessage(e);
              console.warn(e);
            }
          });
        } else {
          alert(
            "Oops! There is an error but we have the payment you submitted for this application. Please enter the following promotion code to continue: pymntpg20"
          );
          this.$refs.paymentSubmitButton.stopLoading();
        }
      } else {
        alert(
          "Oops! There is an error but we have the payment you submitted for this application. Please enter the following promotion code to continue: pymntpg20"
        );
        this.$refs.paymentSubmitButton.stopLoading();
      }
    },
    processPayment(opaqueData) {
      let vm = this;
      return new Promise(function (resolve, reject) {
        let options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-XSRF-Token": readCookie("XSRF-TOKEN"),
          },
          body: JSON.stringify({
            dataDescriptor: opaqueData.dataDescriptor,
            dataValue: opaqueData.dataValue,
            firstname: vm.billingAddress.firstName,
            lastname: vm.billingAddress.lastName,
            email: vm.$store.state.userData.data.email,
            address: vm.billingAddress.address,
            city: vm.billingAddress.city,
            state: vm.billingAddress.state,
            country: vm.billingAddress.country,
            postcode: vm.billingAddress.postCode,
            currentAddress: vm.currentAddress.address,
            currentCity: vm.currentAddress.city,
            currentState: vm.currentAddress.state,
            currentCountry: vm.currentAddress.country,
            currentPostcode: vm.currentAddress.postCode,
            permanentAddress: vm.permanentAddress.address,
            permanentCity: vm.permanentAddress.city,
            permanentState: vm.permanentAddress.state,
            permanentCountry: vm.permanentAddress.country,
            permanentPostcode: vm.permanentAddress.postCode,
            orderDescription: vm.orderDescription,
            appId: vm.currentApplicationId,
            amount: vm.totalFee,
            ownerId: cookies.get("formio_owner_id"),
          }),
        };

        fetch("/api/charge", options)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Charge failed");
            }
            return response.json();
          })
          .then((data) => {
            if (typeof data.errors !== "undefined") {
              reject(data.errors);
            } else {
              //save transaction ID to data property for later storage in form.io
              vm.transactionId = data.transId;
              vm.paymentDone = true;
              resolve("Success");
            }
          })
          .catch((error) => {
            reject("Something went wrong while trying to pay: " + error);
          });
      });
    },
    setErrorMessage: function (errors) {
      // We got an array of errors back
      if (Array.isArray(errors) && errors.length) {
        this.processErrorArray(errors);
      } else if (
        // We got an object, with an error property, which is an array of errors
        Object.prototype.hasOwnProperty.call(errors, "error") &&
        Array.isArray(errors.error)
      ) {
        this.processErrorArray(errors.error);
      }
    },
    processErrorArray(errors) {
      errors.forEach((err) => {
        if (Object.prototype.hasOwnProperty.call(err, "errorText")) {
          this.chargeErrors.push(err.errorText);
        }
        if (Object.prototype.hasOwnProperty.call(err, "message")) {
          this.chargeErrors.push(err.message);
        }
      });
    },
    responseHandler(response) {
      let errors = [];
      return new Promise(function (resolve, reject) {
        if (response.messages.resultCode === "Error") {
          response.messages.message.forEach(function (message) {
            errors.push({
              message: message.code + ": " + message.text,
              path: ["submit"],
            });
            reject(errors);
          });
        }
        resolve(response);
      });
    },
  },
};

import axios from "axios";
import store from "../store";

const apiClient = axios.create({
  baseURL: "/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

apiClient.interceptors.response.use(
  // success callback;
  // we're just telling axios to handle successful requests normally
  (response) => {
    return response;
  },

  // error callback;
  // here we are defining centralized error handling for all backend requests
  (error) => {
    if ([401, 419].includes(error?.response?.status)) {
      store.dispatch("logout", {
        sessionExpired: true,
        reload: true,
      });
    }

    return Promise.reject(error);
  }
);

export default apiClient;

<template>
  <section>
    <h2 v-if="applicationStepForm" class="text-xl md:text-3xl">
      Academic Profile
    </h2>
    <form
      id="academic-profile"
      action=""
      aria-label="Academic Profile Form"
      class="grid grid-cols-1 gap-4 pb-6 mb-6 border-gray-200 billing-form md:grid-cols-2 sm:gap-6"
      :class="{ 'mt-8': applicationStepForm }"
      @submit.prevent="submitForm()"
    >
      <label
        v-if="studentType === 'college'"
        for="schoolName"
        class="block text-sm md:col-span-2"
      >
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>School Name</span>
        </span>
        <VSelect
          id="schoolName"
          v-model="v$.value.university.$model"
          :value="v$.value.university.$model"
          :options="universities"
          :get-option-label="(option) => option.label"
          aria-label="School Name"
          :class="{ 'bg-error-100': v$.value.university.$error }"
          @search="searchUniversity"
        />
        <div
          v-if="
            v$.value.university.required.$invalid && v$.value.university.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label
        v-if="studentType !== 'highschool'"
        for="primaryMajor"
        class="block text-sm md:col-span-2"
      >
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Primary Major</span>
        </span>
        <VSelect
          id="primaryMajor"
          v-model="v$.value.primaryMajor.$model"
          :value="v$.value.primaryMajor.$model"
          :options="MAJORS"
          :get-option-label="(option) => option.label"
          :reduce="(major) => major.value"
          aria-label="Primary Major"
          :class="{ 'bg-error-100': v$.value.primaryMajor.$error }"
          :filter="searchMajor"
        />
        <div
          v-if="
            v$.value.primaryMajor.required.$invalid &&
            v$.value.primaryMajor.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label
        v-if="studentType !== 'highschool'"
        for="secondaryMajor"
        class="block text-sm md:col-span-2"
      >
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Secondary Major</span>
        </span>
        <VSelect
          id="secondaryMajor"
          v-model="v$.value.secondaryMajor.$model"
          :value="v$.value.secondaryMajor.$model"
          :options="MAJORS"
          :get-option-label="(option) => option.label"
          :reduce="(major) => major.value"
          aria-label="Secondary Major"
          :filter="searchMajor"
        />
      </label>

      <label
        v-if="studentType !== 'highschool'"
        for="minor"
        class="block text-sm md:col-span-2"
      >
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Minor</span>
        </span>
        <VSelect
          id="minor"
          v-model="v$.value.minor.$model"
          :value="v$.value.minor.$model"
          :options="MAJORS"
          :get-option-label="(option) => option.label"
          :reduce="(major) => major.value"
          aria-label="Minor"
          :filter="searchMajor"
        />
      </label>

      <label for="gpa" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Cumulative GPA</span>
        </span>
        <input
          id="gpa"
          v-model="v$.value.gpa.$model"
          type="text"
          placeholder="Enter GPA (e.g., 3.20)"
          class="form-input block w-full min-h-10"
          :class="{ 'bg-error-100': v$.value.gpa.$error }"
          @blur="addDecimals()"
        />
        <div
          v-if="v$.value.gpa.required.$invalid && v$.value.gpa.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
        <div
          v-if="v$.value.gpa.decimal.$invalid && v$.value.gpa.$error"
          class="error text-error-900"
        >
          Only decimal values allowed.
        </div>
        <div
          v-if="v$.value.gpa.decimalRegex.$invalid && v$.value.gpa.$error"
          class="error text-error-900"
        >
          Only 2 decimals are allowed.
        </div>
        <div
          v-if="v$.value.gpa.maxValue.$invalid && v$.value.gpa.$error"
          class="error text-error-900"
        >
          Cumulative GPA cannot be greater than
          {{ v$.value.gpa.maxValue.$params.max }}.
        </div>
        <div
          v-if="v$.value.gpa.minValue.$invalid && v$.value.gpa.$error"
          class="error text-error-900"
        >
          Cumulative GPA cannot be less than
          {{ v$.value.gpa.minValue.$params.min }}.
        </div>
      </label>

      <label for="yearSchool" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Year in School</span>
        </span>
        <VSelect
          id="yearSchool"
          v-model="v$.value.yearSchool.$model"
          :value="v$.value.yearSchool.$model"
          :reduce="(year) => year.value"
          :options="ACADEMIC_YEARS"
          :get-option-label="(option) => option.label"
          aria-label="Year in School"
          :class="{ 'bg-error-100': v$.value.yearSchool.$error }"
        />
        <div
          v-if="
            v$.value.yearSchool.required.$invalid && v$.value.yearSchool.$error
          "
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <label for="language" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Language Proficiency</span>
        </span>
        <VSelect
          id="language"
          v-model="v$.value.language.$model"
          :value="v$.value.language.$model"
          :reduce="(language) => language.value"
          :options="LANGUAGES"
          :get-option-label="(option) => option.label"
          aria-label="Language Proficiency"
          :class="{ 'bg-error-100': v$.value.language.$error }"
        />
        <div
          v-if="v$.value.language.required.$invalid && v$.value.language.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>

      <fieldset v-if="isInternship" class="md:col-span-2">
        <legend class="text-sm text-gray-600">
          <span class="font-semibold">
            In order of preference, list your top 3 areas of professional
            development out of the categories below.
          </span>
          <Tooltip
            tooltip="All API programs have a focus on professional development. This information is helpful to identify at the start of your program. "
          />
        </legend>
        <div class="mt-2 space-y-4">
          <template v-for="n in 3" :key="'labelArea' + n">
            <label :for="'area' + n" class="block text-sm">
              <span
                class="font-semibold text-gray-600 flex justify-between sr-only"
              >
                <span>Area {{ n }}</span>
              </span>
            </label>
            <VSelect
              :id="'area' + n"
              v-model="v$.value['area' + n].$model"
              :value="v$.value['area' + n].$model"
              :options="areas"
              :get-option-label="(option) => option.label"
              :placeholder="
                'Start typing to search (' +
                (n === 1
                  ? 'e.g., agriculture, architect, biomechanics, geobiology, etc.)'
                  : 'optional)')
              "
              :aria-label="'Area ' + n"
              :data-hide-options="!searchKey && !areas.length"
              @search="searchAreas"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes"></span>
              </template>
            </VSelect>
          </template>
        </div>
        <div
          v-if="v$.value.validateArea.$invalid && v$.value.$error"
          class="error text-error-900 text-sm"
        >
          At least one area is required
        </div>
        <div
          v-if="v$.value.validateDuplicates.$invalid"
          class="error text-error-900 text-sm"
        >
          Areas can not be duplicate
        </div>
      </fieldset>

      <template v-if="applicationStepForm">
        <fieldset class="md:col-span-2">
          <legend class="font-semibold text-gray-600">
            Do you currently or have you ever had any disciplinary sanctions at
            your home university or high school if not yet enrolled in college?
          </legend>
          <div class="mt-2 md:flex">
            <label
              v-for="option in OPTIONS"
              :key="option.value"
              class="flex mb-4 md:mb-0 md:flex-auto"
              :for="option.value + 'Disciplinary'"
            >
              <input
                :id="option.value + 'Disciplinary'"
                v-model.trim="v$.value.disciplinarySanctions.$model"
                type="radio"
                name="disciplinary-sanctions"
                :value="option.value"
                class="mt-1 text-blue-700 form-radio"
                :class="{
                  'bg-error-100': v$.value.disciplinarySanctions.$error,
                }"
              />
              <span class="ml-2">{{ option.label }}</span>
            </label>
          </div>
          <div
            v-if="
              v$.value.disciplinarySanctions.required.$invalid &&
              v$.value.disciplinarySanctions.$error
            "
            class="error text-error-900 text-sm"
          >
            Field is required
          </div>
        </fieldset>

        <fieldset class="md:col-span-2">
          <legend class="font-semibold text-gray-600">
            Are you on Academic Probation?
          </legend>
          <div class="mt-2 md:flex">
            <label
              v-for="option in OPTIONS"
              :key="option.value"
              class="flex mb-4 md:mb-0 md:flex-auto"
              :for="option.value + 'AcademicProbation'"
            >
              <input
                :id="option.value + 'AcademicProbation'"
                v-model.trim="v$.value.academicProbation.$model"
                type="radio"
                name="academic-probation"
                :value="option.value"
                class="mt-1 text-blue-700 form-radio"
                :class="{
                  'bg-error-100': v$.value.academicProbation.$error,
                }"
              />
              <span class="ml-2">{{ option.label }}</span>
            </label>
          </div>
          <div
            v-if="
              v$.value.academicProbation.required.$invalid &&
              v$.value.academicProbation.$error
            "
            class="error text-error-900 text-sm"
          >
            Field is required
          </div>
        </fieldset>

        <fieldset v-if="studentType === 'college'" class="md:col-span-2">
          <legend class="font-semibold text-gray-600">
            Have you already completed an application for this program through
            your home university study abroad office?
          </legend>
          <div class="mt-2 md:flex">
            <label
              v-for="option in OPTIONS"
              :key="option.value"
              class="flex mb-4 md:mb-0 md:flex-auto"
              :for="option.value + 'ApplicationCompled'"
            >
              <input
                :id="option.value + 'ApplicationCompled'"
                v-model.trim="v$.value.applicationCompled.$model"
                type="radio"
                name="application-compled"
                :value="option.value"
                class="mt-1 text-blue-700 form-radio"
                :class="{
                  'bg-error-100': v$.value.applicationCompled.$error,
                }"
              />
              <span class="ml-2">{{ option.label }}</span>
            </label>
          </div>
          <div
            v-if="
              v$.value.applicationCompled.required.$invalid &&
              v$.value.applicationCompled.$error
            "
            class="error text-error-900 text-sm"
          >
            Field is required
          </div>
        </fieldset>
      </template>

      <div class="md:col-span-2" :class="{ 'mt-5': !applicationStepForm }">
        <ButtonWithSpinner
          ref="academicSubmit"
          type="submit"
          variant="primary"
          variant-type="block"
          data-cy="academicProfileSubmit"
          :disabled="v$.$invalid"
        >
          <span>
            Continue Application
            <svg
              v-if="successSubmission && !v$.$invalid"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="inline feather feather-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </span>
        </ButtonWithSpinner>
        <div v-if="submitError" class="error text-error-900 mt-2">
          {{ submitError }}
        </div>
        <div v-if="successSubmission && !v$.$invalid" class="text-success-900">
          Submission Complete, thank you!
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import universitiesListApi from "../../../mixins/universitiesListApi";
import majorsList from "../../../mixins/majorsList";
import { ACADEMIC_YEARS, LANGUAGES, AREAS } from "../../../constants";
import {
  requiredIf,
  required,
  decimal,
  minValue,
  maxValue,
  helpers,
} from "@vuelidate/validators";
import formIoApi from "../../../mixins/formIoApi.js";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import forms from "../../../mixins/forms.js";
import { eventBus } from "../../../app";
import Tooltip from "../SharedComponents/Tooltip.vue";
import useVuelidate from "@vuelidate/core";

const options = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
const decimalRegex = helpers.regex(/^\d(\.\d{1,2})?$/);
const requiredIfCollege = requiredIf(function () {
  return this.studentType === "college";
});
const requiredIfapplicationStep = requiredIf(function () {
  return this.applicationStepForm;
});
const validateArea = (value, vm) => {
  let area1 = vm.areaLabel1;
  let area2 = vm.areaLabel2;
  let area3 = vm.areaLabel3;

  if (vm.isInternship) return area1 || area2 || area3;
  else return true;
};
const validateDuplicates = (value, vm) => {
  let values = [vm.areaLabel1, vm.areaLabel2, vm.areaLabel3].filter(
    (item) => item
  );
  let isDuplicate = values.some((item, index) => {
    return values.indexOf(item) != index;
  });
  return !isDuplicate;
};

export default {
  name: "AcademicProfile",
  components: { ButtonWithSpinner, Tooltip },
  mixins: [universitiesListApi, majorsList, formIoApi, forms],
  props: {
    applicationId: {
      type: String,
      default: "",
    },
    applicationStepForm: {
      type: Boolean,
      default: true,
    },
    applicationSubmissions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        university: null,
        primaryMajor: "",
        secondaryMajor: "",
        minor: "",
        gpa: "",
        yearSchool: "",
        language: "",
        disciplinarySanctions: "",
        academicProbation: "",
        applicationCompled: "",
        area1: null,
        area2: null,
        area3: null,
      },
      formURL: "studentapplicationstage2step1",
      submissionId: "",
      successSubmission: false,
      submitError: "",
      areas: [],
      searchKey: "",
    };
  },
  validations: {
    value: {
      university: {
        required: requiredIfCollege,
      },
      primaryMajor: {
        required: requiredIf(function () {
          return this.studentType !== "highschool";
        }),
      },
      secondaryMajor: {},
      minor: {},
      gpa: {
        required,
        decimal,
        decimalRegex,
        minValue: minValue(1),
        maxValue: maxValue(5),
      },
      yearSchool: { required },
      language: { required },
      disciplinarySanctions: {
        required: requiredIfapplicationStep,
      },
      academicProbation: {
        required: requiredIfapplicationStep,
      },
      applicationCompled: {
        required: requiredIf(function () {
          return this.studentType === "college" && this.applicationStepForm;
        }),
      },
      area1: {},
      area2: {},
      area3: {},
      validateArea,
      validateDuplicates,
    },
  },
  computed: {
    studentType() {
      return Object.prototype.hasOwnProperty.call(this.userData, "data")
        ? this.userData.data.student_type
        : "";
    },
    areaLabel1() {
      return this.value.area1 ? this.value.area1.label : "";
    },
    areaLabel2() {
      return this.value.area2 ? this.value.area2.label : "";
    },
    areaLabel3() {
      return this.value.area3 ? this.value.area3.label : "";
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.successSubmission = false;
      },
      deep: true,
    },
  },
  async created() {
    eventBus.$emit("showSpinner", true);
    this.setConstants();
    const valid = await this.validApplication();

    if (!Object.prototype.hasOwnProperty.call(this.userData, "data"))
      await this.$store.dispatch("cacheFormioUser");

    if (valid) {
      this.prepopulateForm();
      this.getFormioData();
      eventBus.$emit("showSpinner", false);
    } else {
      console.error(
        `Invalid application: no submissions found for application ID ${this.applicationId}`
      );
      window.location.href = "/applications";
    }
  },
  methods: {
    async validApplication() {
      return this.applicationSubmissions.length > 0;
    },
    setConstants() {
      const sortAcademicYears = ACADEMIC_YEARS.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      const sortLanguages = LANGUAGES.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      this.ACADEMIC_YEARS = sortAcademicYears;
      this.LANGUAGES = sortLanguages;
      this.OPTIONS = options;
    },
    prepopulateForm() {
      this.value.university =
        this.studentType === "college"
          ? this.userData.data.schoolname
          : { label: "Not Enrolled", value: "Not Enrolled" };

      this.value.primaryMajor = this.userData.data.major;
    },
    addDecimals() {
      if (this.value.gpa) {
        const dec = this.value.gpa.split(".")[1];

        if (dec === undefined || dec.length < 2)
          this.value.gpa = parseFloat(this.value.gpa).toFixed(2);
      }
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.setFormioData(response);
              }
            );
          }
        }
      );
    },
    setFormioData(data) {
      if (this.studentType === "college") {
        this.value.university =
          data["school-name"] && data["school-name"].value
            ? data["school-name"]
            : this.value.university;
      }
      this.value.primaryMajor =
        data.major && data.major.name
          ? data.major.name
          : this.value.primaryMajor;

      this.value.secondaryMajor =
        data.secondMajor && data.secondMajor.name ? data.secondMajor.name : "";
      this.value.minor = data.minor && data.minor.name ? data.minor.name : "";
      this.value.gpa = data.gpa ? data.gpa.toFixed(2) : "";
      this.value.yearSchool = this.getYearSchool(data["academic-level"]);
      this.value.language = data.languageProficiency ?? "";
      this.value.disciplinarySanctions = data.disciplinarySanctions ?? "";
      this.value.academicProbation = data.academicProbation ?? "";
      this.value.applicationCompled = data.applicationCompled ?? "";
      this.value.area1 = data.area1 ?? null;
      this.value.area2 = data.area2 ?? null;
      this.value.area3 = data.area3 ?? null;
    },
    getYearSchool(value) {
      let newValue = value ?? "";

      if (newValue) {
        const hasValue = this.ACADEMIC_YEARS.some(
          (item) => item.value === value
        );

        if (!hasValue) {
          const oldItem = this.ACADEMIC_YEARS.find(
            (item) => item.oldValue === value
          );
          if (oldItem !== undefined) newValue = oldItem.value;
        }
      }

      return newValue;
    },
    submitForm() {
      this.v$.$touch();
      this.submitError = "";
      this.successSubmission = false;

      if (!this.v$.$invalid) {
        this.$refs.academicSubmit.startLoading();
        if (this.submissionId) {
          this.updateAcademicProfile();
        } else {
          this.createAcademicProfile();
        }
      }
    },
    addObject(field, value) {
      return {
        op: "add",
        path: "/data/" + field,
        value: value,
      };
    },
    getMajorObj(major) {
      return major ? { name: major } : {};
    },
    updateAcademicProfile() {
      const jsonData = [
        this.addObject("school-name", this.value.university),
        this.addObject("major", this.getMajorObj(this.value.primaryMajor)),
        this.addObject(
          "secondMajor",
          this.getMajorObj(this.value.secondaryMajor)
        ),
        this.addObject("minor", this.getMajorObj(this.value.minor)),
        this.addObject("gpa", parseFloat(this.value.gpa)),
        this.addObject("academic-level", this.value.yearSchool),
        this.addObject("languageProficiency", this.value.language),
        this.addObject(
          "disciplinarySanctions",
          this.value.disciplinarySanctions
        ),
        this.addObject("academicProbation", this.value.academicProbation),
        this.addObject("applicationCompled", this.value.applicationCompled),
        this.addObject("area1", this.value.area1),
        this.addObject("area2", this.value.area2),
        this.addObject("area3", this.value.area3),
        this.addObject("academicInformationSubmitted", true),
      ];

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.successSubmission = true;
          //Move to next step
          eventBus.$emit("changeStep");
        })
        .catch((error) => {
          this.sendSubmissionError = error;
        })
        .finally(() => {
          this.$refs.academicSubmit.stopLoading();
        });
    },
    createAcademicProfile() {
      const formioObject = {
        data: {
          application_id: this.applicationId,
          "school-name": this.value.university,
          major: this.getMajorObj(this.value.primaryMajor),
          secondMajor: this.getMajorObj(this.value.secondaryMajor),
          minor: this.getMajorObj(this.value.minor),
          gpa: parseFloat(this.value.gpa),
          "academic-level": this.value.yearSchool,
          languageProficiency: this.value.language,
          disciplinarySanctions: this.value.disciplinarySanctions,
          academicProbation: this.value.academicProbation,
          applicationCompled: this.value.applicationCompled,
          area1: this.value.area1,
          area2: this.value.area2,
          area3: this.value.area3,
          academicInformationSubmitted: true,
        },
      };

      this.submitToFormIo(this.formURL, formioObject)
        .then((response) => {
          this.submissionId = response;
          this.successSubmission = true;
          //Move to next step
          eventBus.$emit("changeStep");
        })
        .catch((error) => {
          this.submitError = error;
        })
        .finally(() => {
          this.$refs.academicSubmit.stopLoading();
        });
    },
    searchAreas(search, loading) {
      loading(true);
      let filterAreas = [];
      this.searchKey = search;

      if (search) {
        filterAreas = AREAS.filter((area) =>
          area.label.toLowerCase().includes(search.toLowerCase())
        );
        filterAreas.sort((a, b) => a.label.localeCompare(b.label));
      }

      this.areas = filterAreas;
      loading(false);
    },
  },
};
</script>

<style>
.vs__search::placeholder {
  opacity: 0.5;
}

[data-hide-options="true"] ul[role="listbox"] {
  display: none !important;
}
</style>

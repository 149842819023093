let state = {
  formioToken: "",
  currentUser: null,
  enrollmentToken: "",
  userData: {},
  isImpersonating: false,
  studentApplications: [],
  studentFormioSubmissions: [],
  currentApplicationSubmissions: {},
  onBoardingFormioSubmissions: [],
  selectedProgramId: "",
  program: {},
  isInternship: false,
  universityName: "",
  universityCity: "",
  universityImageUrl: "",
  universityWebsiteUrl: "",
  programSelected: false,
  billingRules: {},
  payments: [],
  term: "",
  enrollmentApplications: [],
  sessionExpired: false,
  redirectPath: "",
  previousApplication: {
    previousApplicationId: "",
    session: "",
    paidFee: false,
    applicationFee: 0,
    transactionId: "",
    finished: false,
    submissionId: "",
  },
  currentApplicationId: "",
  applicationPaid: false,
  customer_id: "",
  uiVersion: "",
  completedSections: {
    onboarding1: false,
    payment: false, //confirmation paymet
    onboarding2: false,
    otherPayments: false,
  },
  onboardingStep1Forms: {},
  onboardingStep2Forms: {},
  currentGpa: 0,
  directProgramData: {},
  appliedAgreement: {},
  institutionPaysApplicationFee: false,
  institutionPaysConfirmationPayment: false,
  institutionPaysAcademicFee: false,
  institutionPaysHousingFee: false,
  featureFlags: {},
  addressInfo: {},
};
export default state;

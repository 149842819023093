<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <Passports
        :formio-data="formio"
        :show-add-button="showAddButton"
        :render-form="renderForm"
        :rule-type="ruleType"
        @sendPassportData="submit"
      />

      <ButtonWithSpinner
        ref="passportSubmit"
        data-cy="passportSubmitButton"
        type="submit"
        variant="primary"
        variant-type="block"
        @click="validateForm"
      >
        <span>
          Submit
          <svg
            v-if="successSubmission && !hasErrors"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </ButtonWithSpinner>
      <div
        v-if="shouldShowSubmitError && submitErrorCode === 400"
        class="error text-error-900 mt-2"
      >
        There has been a problem with submitting this form. Please refresh the
        page and try again. If the problem continues, please contact
        <a
          class="text-teal-500 font-semibold focus:underline focus:text-teal-900 hover:text-teal-900"
          href="mailto:productsupport@apiabroad.com"
        >
          productsupport@apiabroad.com</a
        >
        for help.
      </div>
      <div
        v-if="shouldShowSubmitError && submitErrorCode !== 400"
        class="error text-error-900 mt-2"
      >
        {submitError}
      </div>
      <div v-if="successSubmission && !hasErrors" class="text-success-900">
        Submission successful. Please watch your email for any necessary
        corrections from your Program Coordinator.
      </div>
      <p v-if="hasErrors" class="text-error-900">
        Please complete the required fields correctly.
      </p>
    </template>
  </PageStyle>
</template>

<script>
import Passports from "./Passports.vue";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import forms from "../../../mixins/forms.js";
import { mapState } from "vuex";
import ButtonWithSpinner from "../SharedComponents/ButtonWithSpinner.vue";
import { eventBus } from "../../../app";
import formIoApi from "../../../mixins/formIoApi.js";
import SentryHelp from "../../../mixins/sentryHelper";

export default {
  name: "PassportsForm",
  components: {
    Passports,
    PageStyle,
    ButtonWithSpinner,
  },
  mixins: [forms, formIoApi, SentryHelp],
  data() {
    return {
      formio: {
        firstPassport: null,
        secondPassport: null,
      },
      ruleType: "",
      hasErrors: false,
      shouldShowSubmitError: false,
      submitError: "",
      submitErrorCode: 0,
      successSubmission: false,
      submissionId: "",
      first: {},
      second: {},
      renderForm: false,
      noPassport: false,
      formURL: "passport",
    };
  },
  computed: {
    ...mapState(["program"]),
    applicationId() {
      return this.$route.params.applicationId;
    },
    showAddButton() {
      return this.ruleType === "post_acceptance_form_rules";
    },
  },
  async created() {
    eventBus.$on("changeSuccessSubmission", () => {
      this.changeSuccessSubmission();
    });

    const valid = await this.isValidApplicationAndIsOnRules(
      this.applicationId,
      "Passport Info"
    );
    this.ruleType = valid.ruleType;

    if (valid.valid) {
      this.renderForm = true;
      this.getFormioData();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    validateForm() {
      this.shouldShowSubmitError = false;
      this.successSubmission = false;
      this.hasErrors = false;
      this.$refs.passportSubmit.startLoading();

      eventBus.$emit("getPassportData");
    },
    submit(data) {
      this.hasErrors = data.hasErrors;
      this.first = data.firstPassport;
      this.second = data.secondPassport;
      this.noPassport = data.noPassport;

      if (!data.hasErrors) {
        const method = this.submissionId ? "PUT" : "POST";

        this.submitToFormIo(
          this.formURL,
          this.getJsonData(),
          method,
          this.submissionId
        )
          .then((response) => {
            this.submissionId = response;
            this.successSubmission = true;
          })
          .catch((error) => this.handleSubmitError(error))
          .finally(() => {
            this.$refs.passportSubmit.stopLoading();
          });
      } else {
        this.$refs.passportSubmit.stopLoading();
      }
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;
          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.formio.noPassport = response.noPassportBox ?? false;
                this.formio.firstPassport = {
                  passportNumber: response["passport-number-01"] ?? "",
                  issuingAuthority: response["issuing-authority-01"] ?? "",
                  dateIssue: response["issue-date-01"] ?? "",
                  dateExpiration: response["date-of-expiration-01"] ?? "",
                  dateBirth: response["date-of-birth-01"] ?? "",
                  sex: response["gender-01"] ?? "",
                  files: response["passport-image-01"]
                    ? response["passport-image-01"]
                    : [],
                };

                let secondPassport = {
                  passportNumber: response["passport-number-02"] ?? "",
                  issuingAuthority: response["issuing-authority-02"] ?? "",
                  dateIssue: response["issue-date-02"] ?? "",
                  dateExpiration: response["date-of-expiration-02"] ?? "",
                  dateBirth: response["date-of-birth-02"] ?? "",
                  sex: response["gender-02"] ?? "",
                  files: response["passport-image-02"]
                    ? response["passport-image-02"]
                    : [],
                };

                let validSecondPassport = this.checkProperties(secondPassport);
                if (validSecondPassport)
                  this.formio.secondPassport = secondPassport;
              }
            );
          }
        }
      );
    },
    getJsonData() {
      return {
        data: {
          application_id: this.applicationId,
          "passport-number-01": this.first.passportNumber ?? "",
          "issuing-authority-01": this.first.issuingAuthority ?? "",
          "issue-date-01": this.first.dateIssue ?? "",
          "date-of-expiration-01": this.first.dateExpiration ?? "",
          "date-of-birth-01": this.first.dateBirth ?? "",
          "gender-01": this.first.sex ?? "",
          "passport-image-01": this.first.files ?? [],
          "passport-number-02": this.second.passportNumber ?? "",
          "issuing-authority-02": this.second.issuingAuthority ?? "",
          "issue-date-02": this.second.dateIssue ?? "",
          "date-of-expiration-02": this.second.dateExpiration ?? "",
          "date-of-birth-02": this.second.dateBirth ?? "",
          "gender-02": this.second.sex ?? "",
          "passport-image-02": this.second.files ?? [],
          passportSubmitted: true,
          noPassportBox: this.noPassport,
        },
      };
    },
    handleSubmitError(error) {
      this.throwSentryError("Passports Form Request", { error: error });
      this.shouldShowSubmitError = true;
      this.submitErrorCode = error.response.status;
      this.submitError = error.message;
    },
    checkProperties(obj) {
      for (var key in obj) {
        if (Array.isArray(obj[key])) {
          if (obj[key].length) return true;
        } else {
          if (obj[key]) return true;
        }
      }
      return false;
    },
    changeSuccessSubmission() {
      if (this.successSubmission) this.successSubmission = false;
    },
  },
};
</script>

<template>
  <fieldset>
    <legend>
      Do you have any medical conditions, past or present, that may require
      routine medical treatment while you are abroad (e.g., diabetes, colitis,
      etc.)? We can ensure that the type of care you need is available in the
      location you are traveling to, inform you about available health care
      providers in advance of your departure, and assist you with logistics.
    </legend>
    <RadioButton
      name="routine-medical"
      aria-controls="routine-medical"
      :options="['yes', 'no']"
      :selected-option="selectedOption"
      :validation-errors="v$.value.selectedRadio"
      @input="onFieldChange('selectedRadio', $event)"
    />

    <div
      v-if="value.selectedRadio === 'yes'"
      id="routine-medical"
      :aria-expanded="value.selectedRadio === 'yes'"
    >
      <div class="grid grid-cols-1 gap-y-8 ml-6">
        <ApiTextArea
          name="routine-medical-textarea"
          label="If yes, please list."
          :max-length="500"
          :typed-text="typedText"
          :validation-errors="v$.value.textarea"
          @textUpdate="onFieldChange('textarea', $event)"
        />
      </div>
    </div>
  </fieldset>
</template>

<script>
import formInputs from "../../mixins/formInputs";
import RadioButton from "../forms/SharedComponents/RadioButton";
import ApiTextArea from "../forms/SharedComponents/ApiTextArea";
import { required, requiredIf, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  name: "RoutineMedical",
  components: { RadioButton, ApiTextArea },
  mixins: [formInputs],
  props: {
    routine: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:routine-medical"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        selectedRadio: "",
        textarea: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        this.$emit("update:routine-medical", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      selectedRadio: {
        required,
      },
      textarea: {
        required: requiredIf(function () {
          return this.value.selectedRadio === "yes";
        }),
        maxLength: maxLength(500),
      },
    },
  },
};
</script>

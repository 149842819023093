<template>
  <div id="program-selection-container" data-cy="program-selection-container">
    <div class="mt-5 text-gray-800">
      <p data-cy="download-instructions">
        Please
        <a
          data-cy="download-inline-link"
          href="/files/Bulk_Import_Template.csv"
          class="text-teal-900 font-semibold"
        >
          <span>
            download the CSV template
          </span></a
        >. Before you upload your files, make sure your files are
        <a
          data-cy="zendesk-instructions-link"
          href="https://support.apiabroad.com/hc/en-us/articles/5873495082647"
          target="_blank"
          class="text-teal-900 font-semibold"
        >
          <span>
            ready to be imported
          </span></a
        >.
      </p>
      <div class="mt-5 flex justify-end">
        <a
          data-cy="download-link"
          href="/files/Bulk_Import_Template.csv"
          class="text-teal-900 font-semibold"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-download text-teal-900 font-semibold inline"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          <span>
            CSV Template
          </span>
        </a>
      </div>
    </div>
    <div>
      <label for="home-university" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Home University</span>
        </span>
        <VSelect
          id="home-university"
          v-model="v$.homeUniversity.$model"
          aria-label="Home University"
          :value="v$.homeUniversity.$model"
          :options="universities"
          :get-option-label="(option) => option.label"
          :clearable="false"
          class="filters university-select text-sm"
          placeholder="Select Home Institution Name"
          @search="searchUniversity"
        ></VSelect>
        <div
          v-if="v$.homeUniversity.required.$invalid && v$.homeUniversity.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>
    </div>
    <div class="mt-10">
      <label for="program-session" class="block text-sm">
        <span class="font-semibold text-gray-600 flex justify-between mb-2">
          <span>Program Session</span>
        </span>
        <VSelect
          id="program-session"
          v-model="v$.programSession.$model"
          aria-label="Program Session"
          :value="v$.programSession.$model"
          :options="sessions"
          label="program"
          :get-option-label="(option) => option.program"
          :clearable="false"
          class="filters university-select text-sm"
          placeholder="Select Program Session"
        ></VSelect>
        <div
          v-if="v$.programSession.required.$invalid && v$.programSession.$error"
          class="error text-error-900"
        >
          Field is required
        </div>
      </label>
    </div>
    <div class="mt-32 flex justify-center align-center">
      <ButtonWithSpinner
        id="cancel-btn"
        ref="cancelBtn"
        data-cy="cancel-btn"
        type="button"
        variant="secondary-outlined"
        variant-type="normal"
        :custom-class="'mr-5 w-107px'"
        :disabled="false"
        @click="$emit('cancel-btn')"
      >
        <span>Cancel </span>
      </ButtonWithSpinner>
      <ButtonWithSpinner
        id="next-btn"
        ref="nextBtn"
        data-cy="next-btn"
        type="button"
        variant="secondary"
        variant-type="normal"
        :custom-class="'w-107px'"
        :disabled="!allowSubmit"
        @click.prevent="submit()"
      >
        <span>Next</span>
      </ButtonWithSpinner>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";

import { required } from "@vuelidate/validators";
import formValidation from "../../mixins/formValidation";
import universitiesListApi from "../../mixins/universitiesListApi";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner.vue";

export default {
  name: "ProgramSelectionBulkEnrollment",
  components: { ButtonWithSpinner },
  mixins: [universitiesListApi, formValidation],
  emits: ["next-btn", "cancel-btn"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      homeUniversity: {},
      programSession: {},
      programSessionData: {},
      sessions: [],
    };
  },
  computed: {
    allowSubmit() {
      return !_.isEmpty(this.homeUniversity) && this.programSession;
    },
  },
  watch: {
    homeUniversity: {
      handler: function () {
        this.getProgramSessions();
      },
      deep: true,
    },
  },
  methods: {
    async submit() {
      //calling the startLoading method of the button, which enables the button loading state
      this.$refs.nextBtn.startLoading();
      try {
        await this.validateSingleForm();
        await this.fetchProgramSessionData();
        const payload = {
          homeUniversity: this.homeUniversity,
          programSessionData: this.programSessionData,
        };

        //emit event to parent
        this.$emit("next-btn", payload);
      } catch (error) {
        console.log(error);
      } finally {
        this.$refs.nextBtn.stopLoading();
      }
    },
    async getProgramSessions() {
      this.sessions = [];
      this.programSession = "";
      let url = this.trailingSlash(
        process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT
      );
      url += "institution/programs/" + this.homeUniversity.value;
      await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.sessions = Object.values(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchProgramSessionData() {
      const urlBase = this.trailingSlash(
        process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT
      );
      const url = `${urlBase}session/${this.programSession.salesforce_id}`;
      return fetch(url)
        .then((response) => response.json())
        .then(
          (data) =>
            (this.programSessionData = {
              semester: data.session,
              site: {
                id: data.program.site.id,
                city: data.program.site.city,
                country: data.program.site.country,
              },
              program_session_salesforce_id: data.salesforce_id,
              experience_type:
                data.program.type_id === 0 ? "virtual" : "abroad",
            })
        )
        .catch((error) => console.log(error));
    },
  },
  validations: {
    programSession: {
      required,
    },
    homeUniversity: {
      required,
    },
  },
};
</script>

<style></style>

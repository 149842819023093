import * as Sentry from "@sentry/vue";
export default {
  methods: {
    sendNotifications(notificationType, programId) {
      let isFirstSubmition = this.$route.path.indexOf("/submission/") === -1;
      if (notificationType && isFirstSubmition) {
        let postData = {
          event: notificationType,
          program_session_id: programId,
        };

        fetch(
          this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
            "advisor/notification",
          {
            method: "post",
            body: JSON.stringify(postData),
            headers: {
              "Content-type": "application/json",
              "x-jwt-token": localStorage.getItem("formioToken"),
            },
            mode: "cors",
          }
        ).catch((e) => {
          console.warn(e);
          Sentry.captureException(new Error("Advisor Notification"), {
            tags: {
              error: e,
            },
          });
        });
      }
    },
  },
};

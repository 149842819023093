<template>
  <div>
    <Modal
      :open="open"
      :show-logo="false"
      title="Request Payment"
      :show-close-button="true"
      @closeModal="open = false"
    >
      <template v-if="value.items.length" #modal-content>
        <!-- Items to pay -->
        <ItemsToPay :items="value.items" @updateItems="updateItems" />
        <!-- Personal information form -->
        <div class="grid md:grid-cols-2 md:gap-6 lg:grid-cols-2">
          <h2 class="md:col-span-2 lg:col-span-2 font-semibold">
            I would like to send request to
          </h2>
          <label class="flex-col items-start mt-5">
            <span class="mr-2 text-gray-600 font-semibold">First Name</span>
            <input
              v-model.trim="v$.value.firstName.$model"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{
                'bg-error-100': v$.value.firstName.$error,
              }"
            />
            <div
              v-if="
                v$.value.firstName.required.$invalid &&
                v$.value.firstName.$error
              "
              class="error text-error-900 text-sm inline-block"
            >
              Field is required
            </div>
            <div
              v-if="
                v$.value.firstName.nameRegex.$invalid &&
                v$.value.firstName.$error
              "
              class="error text-error-900 text-sm inline-block"
            >
              Field does not match the pattern
            </div>
          </label>
          <label class="flex-col items-start mt-5">
            <span class="mr-2 text-gray-600 font-semibold">Last Name</span>
            <input
              v-model.trim="v$.value.lastName.$model"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{
                'bg-error-100': v$.value.lastName.$error,
              }"
            />
            <div
              v-if="
                v$.value.lastName.required.$invalid && v$.value.lastName.$error
              "
              class="error text-error-900 text-sm inline-block"
            >
              Field is required
            </div>
            <div
              v-if="
                v$.value.lastName.nameRegex.$invalid && v$.value.lastName.$error
              "
              class="error text-error-900 text-sm inline-block"
            >
              Field does not match the pattern
            </div>
          </label>
          <label class="flex-col items-start mt-5">
            <span class="mr-2 text-gray-600 font-semibold">Email Address</span>
            <input
              v-model.trim="v$.value.email.$model"
              type="text"
              class="form-input block w-full min-h-10"
              :class="{
                'bg-error-100': v$.value.email.$error,
              }"
            />
            <div
              v-if="v$.value.email.required.$invalid && v$.value.email.$error"
              class="error text-error-900 text-sm inline-block"
            >
              Field is required
            </div>
            <div
              v-if="v$.value.email.email.$invalid && v$.value.email.$error"
              class="error text-error-900 text-sm inline-block"
            >
              Email invalid
            </div>
          </label>
        </div>
        <div class="w-full mt-6">
          <button-with-spinner
            ref="sendRequestBtn"
            data-cy="sendRequest-btn"
            :disabled="v$.$invalid"
            class="flex w-full justify-center rounded font-semibold border-2 border-transparent px-4 min-h-10 hover:no-underline items-center bg-yellow-500 text-gray-700 hover:bg-yellow-900 hover:text-gray-700 focus:bg-yellow-900"
            @click.prevent="submitForm"
          >
            <span> Send Request </span>
          </button-with-spinner>
          <div v-if="successfullySent && !v$.$invalid" class="text-success-900">
            Thank you, your request has been submitted.
          </div>
          <div v-if="requestError" class="error text-error-900 mt-2">
            {{ requestError }}
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  required,
  maxLength,
  decimal,
  email,
  helpers,
} from "@vuelidate/validators";
import { mapState, mapGetters } from "vuex";
import {
  isDecimal,
  isHigherThenCurrentBalance,
  isZeroOrBlank,
} from "../../mixins/helpers";
import Modal from "../modals/Modal.vue";
import ButtonWithSpinner from "../forms/SharedComponents/ButtonWithSpinner.vue";
import axios from "axios";
import ItemsToPay from "../paymentCards/ItemsToPay.vue";
import { normalizeDateForLaravel, dollarsToCents } from "../../mixins/helpers";
import useVuelidate from "@vuelidate/core";

const nameRegex = helpers.regex(/^[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00ff ]+$/);

export default {
  name: "RequestPayment",
  components: {
    Modal,
    ItemsToPay,
    "button-with-spinner": ButtonWithSpinner,
  },

  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  emits: ["reset"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      open: false,
      value: {
        firstName: "",
        lastName: "",
        email: "",
        items: [],
      },
      disabledBtn: true,
      successfullySent: false,
      requestError: false,
    };
  },
  computed: {
    ...mapGetters(["currentApplication"]),
    ...mapState([
      "userData",
      "payments",
      "selectedProgramId",
      "formioToken",
      "userData",
      "program",
      "customer_id",
    ]),
    requirerCurrentApplicationId() {
      return this.$route.params.applicationId;
    },
  },
  validations: {
    value: {
      firstName: {
        required,
        nameRegex,
      },
      lastName: {
        required,
        nameRegex,
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
      items: {
        $each: helpers.forEach({
          balance: {
            required,
            decimal,
            isDecimal,
            isZeroOrBlank,
            isHigherThenCurrentBalance,
            maxLength: maxLength(10),
          },
        }),
      },
    },
  },
  watch: {
    items: {
      handler: function (val) {
        this.value.items = val
          ? val.map((item) => Object.assign({ rootAmount: item.balance }, item))
          : this.payments;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    normalizeItems(items) {
      return items.map((item) => {
        delete item.paid_by;
        delete item.status;
        delete item.payment_date;
        return {
          ...item,
          balance: dollarsToCents(item.balance),
          due_date: normalizeDateForLaravel(item.due_date),
          payment_date: normalizeDateForLaravel(item.due_date),
        };
      });
    },
    async submitForm() {
      this.$refs.sendRequestBtn.startLoading();
      let payload = {
        requirer: {
          first_name: this.userData.data.firstname,
          last_name: this.userData.data.lastname,
          email: this.userData.data.email,
        },
        program: {
          end_date: this.program.end_date,
          start_date: this.program.start_date,
          name: this.program.program.name,
          salesforce_id: this.program.program.salesforce_id,
        },
        first_name: this.v$.value.firstName.$model,
        last_name: this.v$.value.lastName.$model,
        email: this.v$.value.email.$model,
        items: this.normalizeItems(this.v$.value.items.$model),
        account_number: this.customer_id,
        application_id: this.requirerCurrentApplicationId,
      };
      await axios
        .post(`/api/send-payment-request`, payload)
        .then((response) => response.data)
        .then((data) => {
          this.successfullySent = data.code === 200 ? true : false;
          setTimeout(() => {
            this.open = false;
            this.$emit("reset");
          }, 2000);
        })
        .catch((e) => {
          this.requestError = e;
        });
    },
    openRequestPayment() {
      this.open = true;
    },
    updateItems(items) {
      this.value.items = items;
    },
  },
};
</script>

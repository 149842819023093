import state from "./state";

let getters = {
  isLoggedIn(state) {
    return !!state.currentUser;
  },
  getPermissions(state) {
    return state?.currentUser?.permissions || [];
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
  currentApplication: (state) => (appId) => {
    let app = state.enrollmentApplications.find(
      (application) =>
        Object.hasOwnProperty.call(application, "application_id") &&
        application.application_id === appId
    );
    app = app === undefined ? [] : app;
    return app; // Gets matching application from the enrollment service
  },
  getFormioUserId: (state) => {
    return Object.hasOwnProperty.call(state.userData, "_id")
      ? state.userData["_id"]
      : "";
  },
  getStudentToken: (state) => {
    return state.formioToken;
  },
  getEnrollmentToken(state) {
    return state.enrollmentToken;
  },
  getCurrentApplicationId: (state) => {
    return state.currentApplicationId;
  },
  getProgramData: (state) => {
    return state.program;
  },
  getCurrentStudentApplication: (state) => {
    let app = state.studentApplications.find(
      (application) =>
        Object.hasOwnProperty.call(application, "id") &&
        application["id"] &&
        application["id"] === state.currentApplicationId
    );
    return app === undefined ? null : app; // Gets matching application from form.io
  },
  getStudentType: (state) => {
    if (!Object.keys(state.userData).length) return "";
    if (!Object.hasOwnProperty.call(state.userData, "data")) return "";
    if (!Object.hasOwnProperty.call(state.userData.data, "student_type"))
      return "";
    return state.userData.data.student_type;
  },
  canSkipStep2: (state) => {
    // Edge case for applications without post_acceptance_form_rules
    // If theres no rules, the section does not need to be completed
    let skipSection = true;
    if (Object.hasOwnProperty.call(state.onboardingStep2Forms, "forms")) {
      let completed = state.onboardingStep2Forms.forms.filter(
        (form) => form.submitted
      );
      skipSection =
        completed.length === state.onboardingStep2Forms.forms.length;
    }
    return skipSection;
  },
  getSessionExpired: (state) => {
    return state.sessionExpired;
  },
  getRedirectPath: (state) => {
    let redirectPath = localStorage.getItem("redirectPath")
      ? localStorage.getItem("redirectPath")
      : "";

    if (state.redirectPath) return state.redirectPath;
    else return redirectPath;
  },
};
export default getters;

<template>
  <div class="mt-3">
    <div v-for="(item, index) in value.items" :key="index" ref="items">
      <div
        class="flex justify-between border rounded-md border-gray-400 p-3 md:p-3 lg:p-3"
      >
        <h2 class="text-base text-gray-700 font-semibold md:ml-2 lg:ml-2">
          {{ item.name }}
        </h2>
        <h2 class="flex items-center">
          <span class="hidden md:block lg:block md:mr-3">Balance:</span>
          <span class="font-semibold mr-2 text-gray-700"
            >${{ value.items[index].rootAmount }} USD</span
          >
        </h2>
      </div>
      <div class="grid grid-cols-4 gap-4 m-6">
        <p class="col-span-4 font-semibold">
          {{ copyLabelPaymentItem }}
        </p>
        <label class="flex items-center col-span-4 md:col-span-2">
          <input
            :id="`currentAmount${index}`"
            type="radio"
            :checked="true"
            :name="`radioBtn${index}`"
            :v-model="value.items[index].balance"
            class="form-radio"
            @change="changeAmount(index, $event.target.value, true)"
          /><span class="ml-2 text-base"
            >Current Balance (${{ value.items[index].rootAmount }})</span
          >
        </label>
        <div
          class="col-span-4 md:col-span-2 gap-2 flex md:justify-end flex-col xs:flex-row"
        >
          <label class="flex items-center md:ml-4 lg:ml-4">
            <input
              :id="`otherAmount${index}`"
              type="radio"
              :name="`radioBtn${index}`"
              :v-model="value.items[index].balance"
              class="form-radio"
              @change="changeAmount(index, 0)"
            />
            <span class="ml-2 mr-3 text-base">Other Amount</span>
          </label>
          <label class="flex items-center maxWidth">
            <CurrencyInput
              :id="`other-amount-${index}`"
              :ref="`newValue${index}`"
              :aria-labelled-by="`otherAmount${index}`"
              custom-classes="form-input block min-h-10"
              :validation-errors="
                v$.value.items.$each.$response.$errors[index].balance
              "
              :max-length="10"
              @input="changeAmount(index, $event)"
            />
          </label>
        </div>
        <div class="col-span-4 flex md:justify-end">
          <div
            v-if="
              !v$.value.items.$each.$response.$data[index].balance.decimal &&
              v$.value.items.$each.$response.$data[index].balance.$error
            "
            class="error text-error-900"
          >
            Decimal value invalid.
          </div>
          <div
            v-if="
              !v$.value.items.$each.$response.$data[index].balance
                .isZeroOrBlank &&
              v$.value.items.$each.$response.$data[index].balance.$errors
            "
            class="error text-error-900"
          >
            Other amount can't be 0 or blank.
          </div>
          <div
            v-if="
              !v$.value.items.$each.$response.$data[index].balance.isDecimal &&
              v$.value.items.$each.$response.$data[index].balance.$errors
            "
            class="error text-error-900"
          >
            Only 2 decimals are allowed.
          </div>
          <div
            v-if="
              !v$.value.items.$each.$response.$data[index].balance
                .isHigherThenCurrentBalance
            "
            class="error text-error-900"
          >
            The new amount can’t be higher than the current balance.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import CurrencyInput from "@/components/forms/SharedComponents/CurrencyInput";
import { required, maxLength, decimal, helpers } from "@vuelidate/validators";
import {
  isDecimal,
  isHigherThenCurrentBalance,
  isZeroOrBlank,
} from "../../mixins/helpers";
export default {
  name: "ItemsToPay",
  components: { CurrencyInput },
  props: {
    paymentContext: {
      type: String,
      default: "PaymentRequest",
    },
    items: {
      type: Array,
      default: () => [],
    },
    isPaymentPage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["blockBtn", "updateItems"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        items: [],
      },
    };
  },
  validations() {
    return {
      value: {
        items: {
          $each: helpers.forEach({
            balance: {
              required,
              decimal,
              isDecimal,
              isZeroOrBlank,
              isHigherThenCurrentBalance,
              maxLength: maxLength(10),
            },
          }),
        },
      },
    };
  },
  computed: {
    copyLabelPaymentItem() {
      switch (this.paymentContext) {
        case "PaymentView":
          return "I would like to pay";
        default:
          return "The person receiving this request should pay";
      }
    },
  },
  watch: {
    items: {
      handler: function (val) {
        this.value.items = val
          ? val.map((item) => Object.assign({ rootAmount: item.balance }, item))
          : this.payments;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeAmount(key, newAmount, setDefaultAmount = false) {
      let currentAmountRadioButton = document.querySelector(
        `#currentAmount${key}`
      );
      let otherAmountRadioButton = document.querySelector(`#otherAmount${key}`);
      if (otherAmountRadioButton.checked) {
        this.value.items[key].balance = newAmount;
        if (!this.value.items[key].balance.isZeroOrBlank) {
          this.v$.value.items.$touch();
        }
      }
      if (!setDefaultAmount) {
        otherAmountRadioButton.checked = "true";
        this.value.items[key].balance = newAmount;
      } else {
        this.$refs[`newValue${key}`][0].value = "";
        currentAmountRadioButton.checked = "true";
        this.value.items[key].balance = this.items[key].rootAmount;
      }

      if (!otherAmountRadioButton.checked) {
        this.$refs[`newValue${key}`][0].reset();
      }
      this.updateItems();
    },
    checkAmount(key) {
      let newAmount = this.$refs[`newValue${key}`][0].value;
      let currentAmountRadioButton = document.querySelector(
        `#currentAmount${key}`
      );
      let otherAmountRadioButton = document.querySelector(`#otherAmount${key}`);
      if (otherAmountRadioButton.checked) {
        this.value.items[key].balance = newAmount;
        if (!this.value.items[key].balance.isZeroOrBlank) {
          this.v$.$touch();
        }
      }
      if (
        this.value?.items[key]?.otherAmount ||
        otherAmountRadioButton.checked
      ) {
        otherAmountRadioButton.checked = "true";
        this.$refs[`newValue${key}`][0].value = this.value.items[key].balance;
      } else {
        this.$refs[`newValue${key}`][0].value = "";
        currentAmountRadioButton.checked = "true";
        this.value.items[key].balance = this.items[key].rootAmount;
      }
    },
    updateItems() {
      this.$emit("updateItems", this.value.items);
    },
  },
};
</script>
<style scoped>
.maxWidth {
  max-width: 10rem;
}
</style>

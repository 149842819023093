import apiClient from "./API";

export default {
  async getCurrentUser() {
    return (await apiClient.get("/user")).data;
  },
  async updateUserUniversity(payload) {
    return (await apiClient.put(`/user/update-university`, payload)).data;
  },
};

export default {
  data() {
    return {
      billingAddress: {},
      currentAddress: {},
      permanentAddress: {},
      currentAddressToPermanentAddress: {},
      billingAddressToCurrent: {},
    };
  },
  computed: {},
  methods: {
    setCurrentAddressAsBilling(value) {
      switch (value) {
        case true:
          this.billingAddressToCurrent = {
            address: this.billingAddress.address,
            city: this.billingAddress.city,
            state: this.billingAddress.state,
            country: this.billingAddress.country,
            postCode: this.billingAddress.postCode,
          };
          break;

        default:
          this.billingAddressToCurrent = {};
          break;
      }
    },
    setPermanentAddressAsCurrentAddress(value) {
      switch (value) {
        case true:
          this.currentAddressToPermanentAddress = {
            address: this.currentAddress.address,
            city: this.currentAddress.city,
            state: this.currentAddress.state,
            country: this.currentAddress.country,
            postCode: this.currentAddress.postCode,
          };
          break;

        default:
          this.currentAddressToPermanentAddress = {};
          break;
      }
    },
  },
};

<template>
  <FormPanel
    :id="'hostFamily'"
    :title="'Host Family'"
    :disabled-panel="disabledPanel"
    :initially-expanded="!disabledPanel"
    class="mt-6"
    panel-content-class="text-gray-600 border-gray-200"
  >
    <template #content>
      <p>
        You selected Host Family as your preferred housing accommodations, so we
        ask that you complete a few more questions specific to your host family
        selection:
      </p>
      <ol class="pl-4 mt-6 list-decimal space-y-4">
        <li v-for="question in QUESTIONS" :key="question.model">
          <fieldset>
            <legend>{{ question.question }}</legend>
            <div class="mt-2 space-y-2">
              <label
                v-for="option in question.options"
                :key="question.model + option.value"
                class="flex"
                :for="question.model + option.value"
              >
                <input
                  :id="question.model + option.value"
                  v-model="v$.value[question.model].$model"
                  type="radio"
                  :name="question.model"
                  :value="option.value"
                  class="mt-1 text-blue-700 form-radio"
                  :class="{ 'bg-error-100': v$.value[question.model].$error }"
                />
                <span class="ml-2">{{ option.label }}</span>
              </label>
            </div>
            <div
              v-if="
                v$.value[question.model].required.$invalid &&
                v$.value[question.model].$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </fieldset>
        </li>
      </ol>
    </template>
  </FormPanel>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import housingForm from "../../../mixins/housingForm.js";

const QUESTIONS = [
  {
    question:
      "Do you have any strong feelings one way or the other about living with a host family that has children?",
    model: "familyHasChildren",
    options: [
      {
        label: "I would like to live with a host family that has children",
        value: "hasChildren",
      },
      {
        label: "I do not wish to live with a host family that has children",
        value: "noChildren",
      },
      {
        label: "I have no strong feelings either way",
        value: "eitherWay",
      },
    ],
  },
  {
    question:
      "Living with a host family is the best way to improve your language skills. Would you prefer to be with a family who doesn't speak English at all?",
    model: "noEnglish",
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    question:
      "In your living space, do you prefer to be integrated into family life or left more to yourself?",
    model: "integratedIntoFamily",
    options: [
      {
        label: "Yes, I prefer to be integrated into family life",
        value: "yes",
      },
      {
        label: "No, I prefer to be left more to myself",
        value: "no",
      },
    ],
  },
  {
    question:
      "Do you have any strong feelings one way or the other about living with a host family that has pets?",
    model: "noPets",
    options: [
      {
        label: "Yes, I would prefer not to live with any pets",
        value: "yes",
      },
      {
        label: "No, I don’t mind living with pets",
        value: "no",
      },
    ],
  },
  {
    question:
      "If you follow a gluten-free, vegetarian, or vegan diet, we can arrange for host families to prepare meals according to your diet.  Please note that this may come with an additional fee in certain locations. This helps to offset the cost of special foods and will ensure that the family can prepare quality meals according to your dietary preferences.",
    model: "mealArrangements",
    options: [
      {
        label:
          "I would like to request dietary accommodations and I understand that this may come with an additional fee. My Program Coordinator will be in touch with more information.",
        value: "yes",
      },
      {
        label: "I do not require any meal arrangements.",
        value: "no",
      },
    ],
  },
];

export default {
  name: "HostFamily",
  mixins: [housingForm],
  props: {
    hostFamily: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  emits: ["update:host-family"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        familyHasChildren: "",
        noEnglish: "",
        integratedIntoFamily: "",
        noPets: "",
        mealArrangements: "",
      },
    };
  },
  watch: {
    value: {
      handler: function () {
        if (this.successSubmission) this.$emit("changeSuccessSubmission");
        this.$emit("update:host-family", this.value);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {
    value: {
      familyHasChildren: { required },
      noEnglish: { required },
      integratedIntoFamily: { required },
      noPets: { required },
      mealArrangements: { required },
    },
  },
  created() {
    this.QUESTIONS = QUESTIONS;
  },
};
</script>

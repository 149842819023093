import axios from "axios";
import { dollarsToCents } from "@/mixins/helpers";

export default {
  data() {
    return {
      isPendingPayment: false,
      pendingPayments: {},
      paymentsBalance: {},
    };
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId; //"1660260554708a051g000002xCoCAAU5";
    },
  },
  methods: {
    async getPendingPayments() {
      try {
        const response = await axios.get(
          "/api/pending-payments/" + this.applicationId
        );
        const formatpendingPayments = this.formatData(response.data.data ?? []);
        this.pendingPayments = await this.removeFailedCharges(
          formatpendingPayments
        );
        this.isPendingPayment = !!Object.keys(this.pendingPayments).length;
      } catch (e) {
        this.pendingPayments = {};
      }
    },
    async removeFailedCharges(pendingPayments) {
      try {
        const response = await axios.get(
          "/api/failed-charges/" + this.applicationId
        );

        let filteredPendingPayments = pendingPayments;

        if (response?.data?.data) {
          for (const charge of response.data.data) {
            for (const item of charge.line_items) {
              const name = item.name.toLowerCase();
              if (
                !name.includes("convenience fee") &&
                !name.includes("ach fee")
              ) {
                //Remove pending payments item base on balance and line id
                let lineItem = filteredPendingPayments[item.line_id];
                const index = lineItem.items.findIndex((object) => {
                  return object.amount === item.amount;
                });

                if (index > -1) {
                  lineItem.items.splice(index, 1);
                  lineItem.total -= Number(item.amount);
                  lineItem.remainingBalance += Number(item.amount);

                  //Remove line id object if total is 0, this will mean that items will be empty
                  if (lineItem.total <= 0)
                    delete filteredPendingPayments[item.line_id];
                }
              }
            }
          }
        }

        return filteredPendingPayments;
      } catch (e) {
        return pendingPayments;
      }
    },
    formatData(data) {
      data.sort((a, b) => b.status.localeCompare(a.status));
      let succeededItems = [];
      let payments = {};

      for (const paymentIntent of data) {
        for (const item of paymentIntent.line_items) {
          const name = item.name.toLowerCase();
          if (!name.includes("convenience fee") && !name.includes("ach fee")) {
            //If the item has pending balance on the enrollment service
            //we should add it to pending payments
            const enrollmentItem = this.paymentsBalance[item.line_id] ?? {};
            if (enrollmentItem.balance > 0) {
              //Only add payment intents with processing status
              //If a payment intent exist with a succeeded status and other one with processing the item should not be add
              if (
                paymentIntent.status === "processing" &&
                !succeededItems.includes(paymentIntent.id)
              ) {
                payments[item.line_id] ??= {
                  name: item.name,
                  items: [],
                  total: 0,
                  remainingBalance: 0,
                };
                payments[item.line_id].items.push({
                  ...item,
                  id: paymentIntent.id,
                  created: paymentIntent.created,
                });
                payments[item.line_id].total += Number(item.amount);
                payments[item.line_id].remainingBalance =
                  enrollmentItem.balance - payments[item.line_id].total;
              } else if (paymentIntent.status === "succeeded") {
                //Add success item to array
                succeededItems.push(paymentIntent.id);
              }
            }
          }
        }
      }
      return payments;
    },
    setPaymentsBalance(payments) {
      this.paymentsBalance = payments.reduce((accumulator, value) => {
        const balance = parseFloat(value.balance ?? 0);
        return {
          ...accumulator,
          [value.line_item_id]: {
            balance: dollarsToCents(balance),
            pendingBalance: balance > 0,
          },
        };
      }, {});
    },
  },
};

<template>
  <div>
    <div class="mt-6 font-sans mt-7">
      <span class="font-medium text-sm leading-normal font-sans">
        Which of these best describes the type of housing?
      </span>
    </div>
    <div class="flex flex-col font-sans mt-5">
      <a
        v-for="item in items"
        :key="item.name"
        class="flex flex-row items-center mt-6 cursor-pointer hover:bg-gray-200 rounded-md"
        @click="next(item.name)"
      >
        <div class="">
          <img class="w-16 h-16" loading="lazy" :src="item.icon" />
        </div>
        <div class="flex ml-4 flex-2 flex-col text-sm">
          <h2 class="font-bold">{{ item.name }}</h2>
          <span class="mt-2">{{ item.description }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import ApartmentIcon from "@/assets/icons/apartment.svg";
import DormIcon from "@/assets/icons/dorm.svg";
import HotelIcon from "@/assets/icons/hotel.svg";
import LocalHostsIcon from "@/assets/icons/local-hosts.svg";
import ShorttermRentalsIcon from "@/assets/icons/shortterm-rentals.svg";

export default {
  name: "CreateHousing",
  props: {
    default: () => Array(),
  },
  emits: ["next"],
  data: () => ({
    items: [
      {
        name: "Apartment",
        description:
          "asdasdasdasdasd ASDASDALKFAOSKDOASKDafs sdfolkop s asda asdasda asdasda sda",
        icon: ApartmentIcon,
      },
      {
        name: "Dorm",
        description:
          "asdasdasdasdasd ASDASDALKFAOSKDOASKDafs sdfolkop s asda asdasda asdasda sda",
        icon: DormIcon,
      },
      {
        name: "Hotel",
        description:
          "asdasdasdasdadsa ASDASDALKFAOSKDOASKDafs sdfolkop s asda asdasda asdasda sda",
        icon: HotelIcon,
      },
      {
        name: "Local Hosts",
        description:
          "asdasdasdasdasdas ASDASDALKFAOSKDOASKDafs sdfolkop s asda asdasda asdasda sda",
        icon: LocalHostsIcon,
      },
      {
        name: "Short-Term Rentals",
        description:
          "asdasdasdasd ASDASDALKFAOSKDOASKDafs sdfolkop s asda asdasda asdasda sda",
        icon: ShorttermRentalsIcon,
      },
    ],
  }),
  methods: {
    next(housingType) {
      this.$emit("next", "Create " + housingType);
    },
  },
};
</script>

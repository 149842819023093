<template>
  <div class="simple-data-table">
    <!-- Header -->
    <span
      v-for="column in columns"
      :key="column.id"
      class="text-xs font-semibold text-gray-700 bg-white flex items-center pl-3"
    >
      {{ column.title }}
    </span>
    <!-- Items -->
    <template v-for="item in items">
      <template v-for="(column, key) in columns" :key="key">
        <span
          v-if="!column.customRender"
          class="text-sm text-gray-700 bg-white flex items-center pl-3 border-t-2 border-blue-100 break-all"
          >{{ item[column.id] }}</span
        >
        <span
          v-else
          class="text-sm text-gray-700 bg-white flex items-center pl-3 border-t-2 border-blue-100"
          ><button
            class="inline-block text-center rounded font-semibold border-2 border-transparent px-4 min-h-10 text-white bg-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white"
            @click="handleClick(item)"
          >
            Student View
          </button></span
        ></template
      >
    </template>
  </div>
</template>
<script>
export default {
  props: {
    columns: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    items: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    // eslint-disable-next-line vue/require-default-prop
    onClicked: {
      type: Function,
    },
  },
  methods: {
    handleClick(item) {
      this.onClicked(item);
    },
  },
};
</script>
<style>
.simple-data-table {
  display: grid;
  grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) 1fr 2fr 2fr 2fr;
  grid-template-rows: 40px;
  grid-auto-rows: 90px;
}

.simple-data-table span:nth-child(6n) {
  justify-content: center;
}
</style>

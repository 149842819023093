<template>
  <component
    :is="tag"
    :type="type"
    :disabled="buttonIsDisabled"
    :class="[btnClass, colorVariants, customClass, buttonHeight]"
    :variant="variant"
    :variant-type="variantType"
    :href="to"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "ButtonWithSpinner",
  props: {
    tag: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    variantType: {
      type: String,
      default: "normal",
    },
    type: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    buttonHeight: {
      type: String,
      default: "min-h-10",
    },
  },
  compatConfig: {
    MODE: 3,
  },
  data() {
    return {
      loading: false,
      buttonIsDisabled: this.disabled,
    };
  },
  computed: {
    btnClass() {
      return {
        "base-spinner": this.loading === true,
        "cursor-not-allowed": this.buttonIsDisabled,
        "inline-block text-center rounded font-semibold border-2 border-transparent px-4": true,
      };
    },
    // eslint-disable-next-line vue/return-in-computed-property
    colorVariants() {
      switch (this.variant) {
        case "secondary":
          switch (this.variantType) {
            case "normal":
              switch (this.buttonIsDisabled) {
                case true:
                  return "opacity-33 cursor-not-allowed text-white bg-teal-500";
                default:
                  break;
              }
              return "text-white bg-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white";
            case "block":
              if (this.buttonIsDisabled) {
                return "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-teal-500";
              } else {
                return "block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white";
              }
            default:
              break;
          }
          break;
        case "secondary-outlined":
          switch (this.variantType) {
            case "normal":
              switch (this.buttonIsDisabled) {
                case true:
                  return "opacity-33 cursor-not-allowed text-teal-900";
                default:
                  break;
              }
              return "text-teal-500 border-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white hover:border-teal-100";
            case "block":
              if (this.buttonIsDisabled) {
                return "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-teal-500";
              } else {
                return "block w-full text-center rounded font-semibold border-2 border-transparent px-4 text-white bg-teal-500 hover:bg-teal-100 hover:text-white focus:bg-teal-100 focus:text-white";
              }
            default:
              break;
          }
          break;
        case "primary":
          switch (this.variantType) {
            case "normal":
              if (this.buttonIsDisabled) {
                return "opacity-33 cursor-not-allowed inline-block text-center rounded font-semibold border-2 border-transparent px-4 bg-yellow-500 text-gray-700";
              } else {
                return "inline-block text-center rounded font-semibold border-2 border-transparent px-4 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900";
              }

            case "block":
              if (this.buttonIsDisabled) {
                return "opacity-33 cursor-not-allowed block w-full text-center rounded font-semibold border-2 border-transparent px-4 bg-yellow-500 text-gray-700";
              } else {
                return "block w-full text-center rounded font-semibold border-2 border-transparent px-4 bg-yellow-500 text-gray-700 hover:bg-yellow-900 focus:bg-yellow-900";
              }

            default:
              break;
          }
          break;
        default:
          break;
      }
    },
  },
  watch: {
    disabled: function (newVal) {
      this.buttonIsDisabled = newVal;
    },
  },
  methods: {
    startLoading() {
      this.loading = true;
      this.buttonIsDisabled = true;
    },
    stopLoading() {
      this.loading = false;
      this.buttonIsDisabled = false;
    },
  },
};
</script>
<style scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.base-spinner {
  position: relative;
  overflow: hidden;
}

.base-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  background-color: inherit;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  top: 0;
  left: 0;
}

.base-spinner:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.45);
  border-top-color: inherit;
  animation: spinner 0.6s linear infinite;
  z-index: 2;
}
</style>

<template>
  <div>
    <template v-if="loading">
      <CountDown
        ref="counter"
        :message="message"
        @redirecting="(msg) => (message = msg)"
      />
    </template>
    <template v-else>
      <div class="mb-6 space-y-4">
        <p>
          To help us better understand the type of internship experience that
          you are seeking and your motivations for participating in this
          program, please thoroughly answer the questions below. Please consider
          answers carefully and make sure to use appropriate grammar and
          spelling, as this information could be forwarded along to potential
          employers.
        </p>
        <TextApi
          name="supportSkills"
          label="1. What skills or experience do you have to support interning in the areas of interest you selected?"
          label-span-custom-class="inline font-semibold text-gray-600 justify-between mb-2 canary-classname canary-2"
          :typed-text="formioData.supportSkills"
          :validation-errors="v$.value.supportSkills"
          @textUpdate="setTextArea('supportSkills', $event)"
        />
        <label class="block text-sm">
          <span
            class="inline font-semibold text-gray-600 justify-between mb-2 canary-classname canary-2"
          >
            2. Rank the following areas by order of importance in your program.
            Click to drag and drop the options.
          </span>
          <div class="flex mt-4 mb-2 text-sm text-left">
            <div class="flex-initial w-12 py-2 font-semibold md:w-16">
              Rank
            </div>
            <div class="flex-1 py-2 font-semibold">
              Area of Importance
            </div>
          </div>
          <div class="flex">
            <div class="flex-initial w-8 md:w-12">
              <div class="flex items-center h-10 mb-3 font-semibold">1</div>
              <div class="flex items-center h-10 mb-3 font-semibold">2</div>
              <div class="flex items-center h-10 mb-3 font-semibold">3</div>
              <div class="flex items-center h-10 mb-3 font-semibold">4</div>
              <div class="flex items-center h-10 mb-3 font-semibold">5</div>
            </div>
            <div class="flex-1">
              <VDraggable
                v-model="value.order"
                item-key="id"
                @start="dragging = true"
                @end="dragging = false"
              >
                <template #item="{ element }">
                  <div
                    class="flex items-center justify-between w-full h-10 px-4 mb-3 bg-white rounded-full border-dashed border"
                  >
                    {{ element.name }}
                  </div>
                </template>
              </VDraggable>
            </div>
          </div>
        </label>
        <TextApi
          name="backgroundPrepare"
          label="3. Explain how your academic background and/or previous experience
            has prepared you for an internship in your field of interest."
          label-span-custom-class="inline font-semibold text-gray-600 justify-between mb-2 canary-classname canary-2"
          :typed-text="formioData.backgroundPrepare"
          :validation-errors="v$.value.backgroundPrepare"
          :max-length="v$.value.backgroundPrepare.maxLength.$params.max"
          @textUpdate="setTextArea('backgroundPrepare', $event)"
        />
        <TextApi
          :max-length="v$.value.applySkills.maxLength.$params.max"
          name="applySkills"
          label="4. Describe the skills that you would like to apply and/or develop
            in each area of interest."
          label-span-custom-class="inline font-semibold text-gray-600 justify-between mb-2 canary-classname canary-2"
          :typed-text="formioData.applySkills"
          :validation-errors="v$.value.applySkills"
          @textUpdate="setTextArea('applySkills', $event)"
        />
        <TextApi
          :max-length="v$.value.challenges.maxLength.$params.max"
          name="challenges"
          label="5. What do you think the biggest challenges will be as an intern
            abroad (e.g., physical limitations, inexperience living in the host
            country, language barriers)?"
          label-span-custom-class="inline font-semibold text-gray-600 justify-between mb-2 canary-classname canary-2"
          :typed-text="formioData.challenges"
          :validation-errors="v$.value.challenges"
          @textUpdate="setTextArea('challenges', $event)"
        />
      </div>
      <div
        v-if="componentUsage === 'form'"
        class="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <ButtonSpin
          ref="seco"
          :disabled="buttonStats.requestOngoing"
          :variant="'secondary'"
          :type="'button'"
          @click.prevent="submitForm(true)"
        >
          {{ buttonStats.secondary }}
        </ButtonSpin>
        <ButtonSpin
          ref="prim"
          :disabled="buttonStats.requestOngoing || v$.$invalid"
          :variant="'primary'"
          :type="'button'"
          @click.prevent="submitForm()"
        >
          {{ buttonStats.primary }}
        </ButtonSpin>
      </div>
      <div
        v-if="buttonStats.finished && !v$.$invalid"
        class="text-center text-success-900 col-span-2"
      >
        Submission Complete, thank you!
      </div>
    </template>
  </div>
</template>

<script>
import { RANKS_OF_IMPORTANCE } from "../../../constants";
import { required, maxLength } from "@vuelidate/validators";

import formsMix from "../../../mixins/forms";
import formioApiMix from "../../../mixins/formIoApi";
import nestedMixin from "../../../mixins/nestedModelComponent";
import CountDown from "../SharedComponents/CountDown.vue";
import TextApi from "../SharedComponents/ApiTextArea.vue";
import ButtonSpin from "../SharedComponents/ButtonWithSpinner.vue";
import useVuelidate from "@vuelidate/core";
const longText =
  "panel32Whatskillsorexperiencedoyouhavetosupportinterningintheseareas";
const longText2 =
  "panel34Explainhowyouracademicbackgroundandorpreviousexperiencehaspreparedyouforaninternshipinyourfieldofinterest";
const longText3 =
  "panel35Describetheskillsthatyouwouldliketoapplyandordevelopineachareaofinterest";
const longText4 =
  "panel36WhatdoyouthinkthebiggestchallengeswillbeasaninternabroadeGPhysicallimitationsinexperiencelivinginthehostcountrylanguagebarriers";
import installComponentShims from "@/draggableShim.js";

export default {
  name: "InternshipInterestsForm",
  components: {
    CountDown,
    TextApi,
    ButtonSpin,
  },
  mixins: [nestedMixin, formsMix, formioApiMix],
  props: {
    componentUsage: {
      type: String,
      default: "form", // form | component
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: true,
      message: "",
      type: "new",
      buttonStats: {
        primary: "Save",
        secondary: "Save Progress",
        requestOngoing: false,
        finished: false,
      },
      fieldNames: {
        rank1: "rank1",
        rank2: "rank2",
        rank3: "rank3",
        rank4: "rank4",
        rank5: "rank5",
        text1: longText,
        text2: longText2,
        text3: longText3,
        text4: longText4,
        appId: "application_id",
        completed: "completedInterests",
      },
      value: {
        supportSkills: "",
        backgroundPrepare: "",
        order: RANKS_OF_IMPORTANCE,
        applySkills: "",
        challenges: "",
        applicationId: "",
      },
      formioData: {
        supportSkills: "",
        backgroundPrepare: "",
        applySkills: "",
        challenges: "",
      },
    };
  },
  validations: {
    value: {
      backgroundPrepare: {
        required,
        maxLength: maxLength(2000),
      },
      supportSkills: {
        required,
        maxLength: maxLength(2000),
      },
      order: {},
      applySkills: {
        required,
        maxLength: maxLength(2000),
      },
      challenges: {
        required,
        maxLength: maxLength(2000),
      },
      applicationId: {
        required,
      },
    },
  },
  computed: {
    applicationId() {
      return this.$route.params.applicationId;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.buttonStats.finished = false;
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    //make draggable component work with the compatibility build.
    installComponentShims();

    // Can view?
    this.value.applicationId = this.applicationId;
    let can = await this.canView();
    if (can) {
      // Has submission?
      let data = await this.checkSubmission();
      if (data) {
        // Load Submission
        this.type = "update";
        let submission = await this.getSubmission(data);
        if (submission) {
          this.prepareFetchedSubmission(submission);
        }
      }
      this.loading = false;
    }
    if (!can) this.$refs.counter.startCountDown();
  },
  methods: {
    async submitForm(isDraft = false) {
      this.buttonsAction();
      let formBody = this.prepareSubmission(isDraft);
      let submission = await this.submitToFormIo(
        "hostuniversityinternship",
        formBody,
        this.type === "new" ? "POST" : "PATCH",
        this.submission_id
      );
      if (submission) {
        this.submission_id = submission;
        this.buttonStats.finished = true;
        this.type = "update";
      }
      this.buttonsAction(false);
    },
    buttonsAction(activate = true) {
      this.buttonStats.requestOngoing = activate;
      if (activate) {
        this.$refs.seco.startLoading();
        this.$refs.prim.startLoading();
        return;
      }
      this.$refs.seco.stopLoading();
      this.$refs.prim.stopLoading();
    },
    canView() {
      this.message = "Validating form, please wait";
      return this.isValidApplication(
        this.applicationId,
        "Internship Interests"
      );
    },
    checkSubmission() {
      this.message = "Getting form submission, please wait";
      return this.formioSubmissionExists(
        "hostuniversityinternship",
        this.applicationId
      );
    },
    getSubmission(id) {
      return this.getFormioSubmission("hostuniversityinternship", id);
    },
    prepareFetchedSubmission(data) {
      this.submissionData = data;
      if (Object.hasOwnProperty.call(data, this.fieldNames.text1))
        this.formioData.supportSkills = data[this.fieldNames.text1];
      if (Object.hasOwnProperty.call(data, this.fieldNames.text2))
        this.formioData.backgroundPrepare = data[this.fieldNames.text2];
      if (Object.hasOwnProperty.call(data, this.fieldNames.text3))
        this.formioData.applySkills = data[this.fieldNames.text3];
      if (Object.hasOwnProperty.call(data, this.fieldNames.text4))
        this.formioData.challenges = data[this.fieldNames.text4];
      let ranks = [];
      let i = 0;
      while (i < 5) {
        let rankName = "rank" + (i + 1);
        if (Object.hasOwnProperty.call(data, rankName)) {
          ranks.push(data[rankName]);
        }
        i++;
      }

      this.value.order.sort((area1, area2) => {
        return ranks.indexOf(area1.value) - ranks.indexOf(area2.value);
      });
    },
    prepareSubmission(draft) {
      let formObject = null;
      if (this.type === "new") {
        formObject = {
          data: {
            [this.fieldNames.rank1]: this.value.order[0].value,
            [this.fieldNames.rank2]: this.value.order[1].value,
            [this.fieldNames.rank3]: this.value.order[2].value,
            [this.fieldNames.rank4]: this.value.order[3].value,
            [this.fieldNames.rank5]: this.value.order[4].value,
            [this.fieldNames.text1]: this.value.supportSkills,
            [this.fieldNames.text2]: this.value.backgroundPrepare,
            [this.fieldNames.text3]: this.value.applySkills,
            [this.fieldNames.text4]: this.value.challenges,
            [this.fieldNames.appId]: this.applicationId,
            [this.fieldNames.completed]: draft ? false : true,
          },
        };
      } else {
        formObject = [
          {
            path: "/data/" + this.fieldNames.rank1,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.rank1
            )
              ? "replace"
              : "add",
            value: this.value.order[0].value,
          },
          {
            path: "/data/" + this.fieldNames.rank2,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.rank2
            )
              ? "replace"
              : "add",
            value: this.value.order[1].value,
          },
          {
            path: "/data/" + this.fieldNames.rank3,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.rank3
            )
              ? "replace"
              : "add",
            value: this.value.order[2].value,
          },
          {
            path: "/data/" + this.fieldNames.rank4,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.rank4
            )
              ? "replace"
              : "add",
            value: this.value.order[3].value,
          },
          {
            path: "/data/" + this.fieldNames.rank5,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.rank5
            )
              ? "replace"
              : "add",
            value: this.value.order[4].value,
          },
          {
            path: "/data/" + this.fieldNames.text1,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.text1
            )
              ? "replace"
              : "add",
            value: this.value.supportSkills,
          },
          {
            path: "/data/" + this.fieldNames.text2,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.text2
            )
              ? "replace"
              : "add",
            value: this.value.backgroundPrepare,
          },
          {
            path: "/data/" + this.fieldNames.text3,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.text3
            )
              ? "replace"
              : "add",
            value: this.value.applySkills,
          },
          {
            path: "/data/" + this.fieldNames.text4,
            op: Object.hasOwnProperty.call(
              this.submissionData,
              this.fieldNames.text4
            )
              ? "replace"
              : "add",
            value: this.value.challenges,
          },
          {
            path: "/data/" + this.fieldNames.completed,
            op: "add",
            value: draft ? false : true,
          },
          {
            path: "/state",
            op: "add",
            value: "submitted",
          },
        ];
      }
      return formObject;
    },
    setTextArea(key, val) {
      this.value[key] = val;
      this.v$.value[key].$touch();
    },
  },
};
</script>

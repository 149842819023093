<template>
  <div>
    <div class="mt-6">
      <span class="font-bold text-sm leading-normal font-sans">
        Please provide the name and address to get started. Don't worry, you can
        change them later if necessary.
      </span>
    </div>
    <form action="">
      <div class="mt-8 flex flex-col text-sm mt-4">
        <label class="block text-sm font-bold text-gray-700" for="fname"
          ><span class="text-[#f00]">* </span>Name</label
        >
        <input
          v-model="name"
          class="mt-1 block w-full h-8 px-2 rounded-md ring-[1px] ring-[#d1d5db] shadow-sm focus:border-[#007f80] focus:ring-[#007f80] sm:text-sm"
          type="text"
        />
        <div class="w-full">
          <div v-if="v$.name.$error" class="error text-error-900">
            {{ customErrorCopy }}
          </div>
        </div>
        <label class="mt-8 block text-sm font-bold text-gray-700" for="fname"
          ><span class="text-[#f00]">* </span>Address - Street Address</label
        >
        <AddressComponent
          custom-class="mt-1 block w-full h-8 px-2 rounded-md ring-[1px] ring-[#d1d5db] shadow-sm focus:border-[#007f80] focus:ring-[#007f80] sm:text-sm"
          @updateAddressInfo="setAddressInfo"
        />
      </div>
      <div class="flex mt-14 justify-center">
        <button
          class="py-2 px-6 mr-5 inline-flex items-center rounded-md border border-[#007f80] bg-white text-sm font-medium text-[#007f80] shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus: focus:ring-offset-2"
          @click="$emit('closeModal')"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="inline-flex items-center rounded-md border hover:border-gray-300 bg-[#007f80] px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-white hover:text-[#007f80] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click.prevent="submit"
        >
          Save & Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddressComponent from "@/components/forms/AddressInputComponent/AddressComponent.vue";

export default {
  name: "HousingForm",
  components: {
    AddressComponent,
  },
  props: {
    customErrorCopy: {
      type: String,
      default: "This field is required.",
    },
  },
  emits: ["closeModal", "form"],
  data() {
    return {
      v$: useValidate(),
      name: null,
      address: null,
    };
  },
  methods: {
    setAddressInfo(msg) {
      this.address = msg;
    },
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("form", {
          name: this.name,
          address: this.address,
        });
      }
    },
  },
  validations() {
    return {
      name: { required },
      address: { required },
    };
  },
};
</script>

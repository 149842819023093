<template>
  <div
    v-if="isSideBarEnabled && fareFinderEnabled"
    class="hidden md:block h-full flex w-[80px] bg-[#1f264c] top-0 left-0 flex-col space-y-1 p-1"
  >
    <router-link
      v-for="item in items"
      v-slot="{ navigate, isActive }"
      :key="item.id"
      :to="item.path"
    >
      <NavItem
        class="text-center p-2 rounded text-xs relative flex flex-col items-center justify-center"
        :is-desktop="true"
        :show-chevron="item.nestedItems !== undefined"
        :feature-flag-on="item.featureFlagOn"
        :name="item.name"
        :icons="item.icons"
        :path="item.path"
        :nested-items="item.nestedItems"
        :is-active="isActive"
        @click="navigate"
      />
    </router-link>
  </div>
  <div v-else />
</template>

<script>
import NavItem from "./NavItem";
import { mapState } from "vuex";

export default {
  name: "DesktopSideBar",
  components: { NavItem },
  computed: {
    ...mapState(["featureFlags"]),
    isSideBarEnabled() {
      return this.featureFlags["sidebar-enabled"];
    },
    fareFinderEnabled() {
      return this.featureFlags["fare-finder"];
    },
    items() {
      return [
        {
          id: 1,
          name: "My Applications",
          featureFlagOn: true,
          path: "/applications",
          icons: {
            default: "/images/icon-my-applications-default.svg",
            active: "/images/icon-my-applications-active.svg",
          },
        },
        {
          id: 2,
          name: "Fare Finder",
          featureFlagOn: this.fareFinderEnabled,
          path: "/fare-finder",
          icons: {
            default: "/images/icon-fare-finder-default.svg",
            active: "/images/icon-fare-finder-active.svg",
          },
        },
        {
          id: 3,
          name: "Experience Search",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-experiencesearch-default.svg",
            active: "/images/icon-experiencesearch-active.svg",
          },
        },
        {
          id: 4,
          name: "Program Manager",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-programmgmt-default.svg",
            active: "/images/icon-programmgmt-active.svg",
          },
        },
        {
          id: 5,
          name: "Curriculum Integration",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-curriculum-default.svg",
            active: "/images/icon-curriculum-active.svg",
          },
          nestedItems: [
            { id: 1, name: "Dashboard", path: "blabla1" },
            { id: 2, name: "Approve Courses", path: "blabla2" },
          ],
        },
        {
          id: 6,
          name: "Badges",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-badges-default.svg",
            active: "/images/icon-badges-active.svg",
          },
        },
        {
          id: 7,
          name: "Student Mgmt",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-studentmgmt-default.svg",
            active: "/images/icon-studentmgmt-active.svg",
          },
          nestedItems: [{ id: 1, name: "Test", path: "blabla2" }],
        },
        {
          id: 8,
          name: "Learning Experience",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-learningexperience-default.svg",
            active: "/images/icon-learningexperience-active.svg",
          },
        },
        {
          id: 9,
          name: "Funnel Analytics & Capacity Planning",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-funnel-default.svg",
            active: "/images/icon-funnel-active.svg",
          },
        },
        {
          id: 10,
          name: "API Research",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-apiresearch-default.svg",
            active: "/images/icon-apiresearch-active.svg",
          },
        },
        {
          id: 11,
          name: "Scholarship",
          featureFlagOn: false,
          path: "#",
          icons: {
            default: "/images/icon-scholarship-default.svg",
            active: "/images/icon-scholarship-active.svg",
          },
        },
      ];
    },
  },
};
</script>

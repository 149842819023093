<template>
  <PageStyle>
    <template #pageHeader>
      <router-link
        :to="{
          name: 'applications/landing',
          params: { applicationId: applicationId },
        }"
        class="inline-flex items-center text-sm text-gray-700 hover:text-gray-700 hover:underline"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left w-4 h-4"
        >
          <polyline points="15 18 9 12 15 6" />
        </svg>
        <span class="ml-1">Back</span>
      </router-link>
    </template>
    <template #pageContent>
      <FormPanel
        :id="'universityApproval'"
        :title="'UNIVERSITY APPROVAL REQUEST'"
        class="mt-6"
        panel-content-class="text-gray-600 border-gray-200"
      >
        <template #content>
          <div class="mb-6 space-y-6">
            <p v-if="approvalMessages" class="text-teal-100">
              {{ approvalMessages }}
            </p>
            <p v-if="hasAdvisors && !bulkEnrolledAccepted">
              Below, please submit a request to your institution’s study abroad
              advisor so they can approve the program you have selected. This
              approval is required by API and your institution. <br />
              Our records indicate that the following advisors from your
              institution are eligible to approve your participation in an API
              program. Please select your advisor from the list below and we
              will send them a digital University Approval Request via email.
              Similar to a letter of recommendation, we will need a response
              from the person who receives this request in order for this task
              to be considered complete.
            </p>
            <p v-else-if="!hasAdvisors && !bulkEnrolledAccepted">
              Below, please submit a request to your institution’s study abroad
              advisor so they can approve the program you have selected. This
              approval is required by API and your institution.
              <br />
              To submit, please type your advisor's name and email below and we
              will send them a digital University Approval Request via email.
              Similar to a letter of recommendation, we will need a response
              from the person who receives this request in order for this task
              to be considered complete.
            </p>
            <p v-else>
              Your university has approved you to go on this program.
            </p>
          </div>
          <div v-if="!bulkEnrolledAccepted" class="space-y-1">
            <label
              v-for="advisor in advisors"
              :key="advisor.value"
              class="flex"
              :for="advisor.email"
            >
              <input
                :id="advisor.email"
                v-model.trim="v$.value.advisor.$model"
                type="radio"
                :value="advisor.value"
                name="approval-advisors"
                class="mt-1 text-blue-700 form-radio"
                :disabled="advisorApprovedApplication"
                :class="{ 'bg-error-100': v$.value.advisor.$error }"
              />
              <span class="ml-2">{{ advisor.label }}</span>
            </label>
            <div
              v-if="
                v$.value.advisor.required.$invalid && v$.value.advisor.$error
              "
              class="error text-error-900 text-sm"
            >
              Field is required
            </div>
          </div>
          <EmailSender
            v-if="!bulkEnrolledAccepted"
            ref="OtherInformation"
            :email-information="value.otherInformation"
            :show-inputs="value.advisor === 'other'"
            :disable-button="
              v$.$invalid || hasErrors || advisorApprovedApplication
            "
            :button-label="buttonLabel"
            :email-data="emailSenderData"
            :email-u-r-l="emailSenderURL"
            :application-id="applicationId"
            :selected-name="formioData.name"
            :selected-email="formioData.email"
            :success-submission="successSubmission && !v$.$invalid"
            :name-max-length="128"
            :email-max-length="128"
            @emailSent="emailSent"
            @validateForm="validateForm"
            @submitForm="submitForm"
            @update:email-sender="value.otherInformation = $event"
          >
            <template v-if="sendSubmissionError" #errors>
              <p>{{ sendSubmissionError }}</p>
            </template>
          </EmailSender>
          <div
            v-if="advisorApprovedApplication && !bulkEnrolledAccepted"
            class="text-center mt-2"
          >
            An Advisor Approval has already been received on your behalf from
            {{ advisorApprovedData }}
          </div>
        </template>
      </FormPanel>
    </template>
  </PageStyle>
</template>

<script>
import FormPanel from "../SharedComponents/panel.vue";
import EmailSender from "../SharedComponents/EmailSender.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import formValidation from "../../../mixins/formValidation.js";
import formIoApi from "../../../mixins/formIoApi.js";
import { required } from "@vuelidate/validators";
import forms from "../../../mixins/forms.js";
import PageStyle from "../SharedComponents/Layout/PageDefault.vue";
import sentryHelper from "../../../mixins/sentryHelper";
import formService from "@/services/form";
import useVuelidate from "@vuelidate/core";

export default {
  name: "UniversityApprovalForm",
  components: {
    FormPanel,
    EmailSender,
    PageStyle,
  },
  mixins: [forms, formIoApi, formValidation, sentryHelper],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      value: {
        advisor: "",
        otherInformation: {
          name: "",
          email: "",
        },
      },
      sectionsToValidate: ["OtherInformation"],
      approvalMessages: "",
      advisors: [],
      buttonLabel: "Request Approval",
      submissionId: "",
      formURL: "studentapplicationstage2step1",
      sentApproval: false,
      sendSubmissionError: "",
      formioData: {
        name: "",
        email: "",
      },
      successSubmission: false,
    };
  },
  validations: {
    value: {
      advisor: { required },
    },
  },
  computed: {
    ...mapGetters(["getCurrentStudentApplication"]),
    ...mapState(["formioToken", "program"]),
    bulkEnrolledAccepted() {
      return this.getCurrentStudentApplication?.bulkEnrollmentId &&
        this.advisorApprovedApplication
        ? true
        : false;
    },
    advisorApprovedData() {
      let info = this.advisorApprovedApplication
        ? this.getCurrentStudentApplication.advisorApproval.data
        : "";
      return this.advisorApprovedApplication
        ? info.advisorname + " " + info.advisoremail
        : "";
    },
    advisorApprovedApplication() {
      let approved = false;
      if (
        !Object.hasOwnProperty.call(
          this.getCurrentStudentApplication,
          "advisorApproval"
        )
      )
        return approved;
      if (
        !Object.hasOwnProperty.call(
          this.getCurrentStudentApplication["advisorApproval"],
          "formSubmitted"
        )
      )
        return approved;
      if (
        this.getCurrentStudentApplication["advisorApproval"][
          "formSubmitted"
        ] === true
      )
        approved = true;
      return approved;
    },
    hasAdvisors() {
      return this.advisors.length > 0;
    },
    applicationId() {
      return this.$route.params.applicationId;
    },
    emailSenderURL() {
      return (
        this.trailingSlash(process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT) +
        "approval"
      );
    },
    emailSenderData() {
      let name = this.value.otherInformation.name;
      let email = this.value.otherInformation.email;

      if (this.value.advisor !== "other") {
        let splitAdvisorData = this.value.advisor.split("|");
        name = splitAdvisorData[0] + " " + splitAdvisorData[1];
        email = splitAdvisorData[2];
      }

      let body = new FormData();
      body.append("email", email);
      body.append("name", name);
      body.append("application_id", this.applicationId);
      body.append("term", this.program.session ?? "");
      body.append("start_date", this.program.start_date ?? "");
      body.append("end_date", this.program.end_date ?? "");

      return body;
    },
  },
  async created() {
    const valid = await this.isValidApplication(
      this.applicationId,
      "University Approval"
    );
    if (valid) {
      this.getAdvisors();
      this.getFormioData();
      this.checkIfApproved();
    } else {
      window.location.href = "/applications";
    }
  },
  methods: {
    ...mapMutations(["setAdvisorApprovalData"]),
    getAdvisors() {
      formService
        .listSubmissions("advisor", {
          "data.university.value": this.userData?.data?.schoolname?.value,
          limit: 200,
        })
        .then((advisors) => {
          this.advisors = advisors
            .filter(
              (advisor) =>
                !advisor.data.apiAbroadAdmin &&
                !advisor.data.hideFromApprovalList
            )
            .map((advisor) => {
              return {
                value:
                  advisor.data.firstname +
                  "|" +
                  advisor.data.lastname +
                  "|" +
                  advisor.data.email,
                label:
                  advisor.data.firstname +
                  " " +
                  advisor.data.lastname +
                  ": " +
                  advisor.data.email,
                email: advisor.data.email,
              };
            });
          if (this.advisors.length === 0) {
            this.value.advisor = "other";
          }
        });
    },
    getFormioData() {
      this.formioSubmissionExists(this.formURL, this.applicationId).then(
        (submissionId) => {
          this.submissionId = submissionId;

          if (this.submissionId) {
            this.getFormioSubmission(this.formURL, this.submissionId).then(
              (response) => {
                this.setFormioData(response);
              }
            );
          }
        }
      );
    },
    setFormioData(data) {
      // Dont set advisor data if no submission was made to u.approval
      if (!Object.hasOwnProperty.call(data, "universityApprovalSubmitted"))
        return;
      this.value.advisor = data.selectAdvisor ? data.selectAdvisor : "";
      this.formioData.name = data.otherAdvisorsAdvisorName
        ? data.otherAdvisorsAdvisorName
        : "";
      this.formioData.email = data["advisor-email"]
        ? data["advisor-email"]
        : "";

      this.sentApproval = !!data.sentApproval;

      if (this.value.advisor) {
        this.buttonLabel = "Resend";
        this.setApprovalMessages();
      }
    },
    setApprovalMessages() {
      const application = Object.assign({}, this.getCurrentStudentApplication);
      let message = this.sentApproval
        ? "An email request has been sent to your advisor."
        : "";

      if (application) {
        message = application.advisorApproval.formSubmitted
          ? "We received the University Approval form from your advisor on " +
            application.advisorApproval.formSubmittedDate +
            "."
          : message;
      }

      this.approvalMessages = message;
    },
    async validateForm() {
      this.sendSubmissionError = "";
      this.successSubmission = false;
      const valid = await this.isValidApplication(
        this.applicationId,
        "University Approval"
      );

      if (valid) {
        this.v$.$touch();
        await this.validate();
        this.$refs.OtherInformation.submit();
      } else {
        this.sendSubmissionError =
          "Cannot submit the form, the application id provided does not match our records.";
        const sentryTags = {
          applicationId: this.applicationId,
          studentEmail: this.userData.data.email,
        };
        this.throwSentryError(
          "University Approval: Application ID does not match an ID in the enrollment service",
          sentryTags
        );
      }
    },
    emailSent() {
      this.sentApproval = true;
    },
    submitForm() {
      if (this.sentApproval) {
        if (this.submissionId) {
          this.updateApproval();
        } else {
          this.submitApproval();
        }
      } else {
        this.$refs.OtherInformation.$refs.advisorButton.stopLoading();
      }
    },
    updateApproval() {
      const jsonData = [
        {
          op: "add",
          path: "/data/selectAdvisor",
          value: this.value.advisor,
        },
        {
          op: "add",
          path: "/data/otherAdvisorsAdvisorName",
          value: this.value.otherInformation.name,
        },
        {
          op: "add",
          path: "/data/advisor-email",
          value: this.value.otherInformation.email,
        },
        {
          op: "add",
          path: "/data/sentApproval",
          value: this.sentApproval,
        },
        {
          op: "add",
          path: "/data/universityApprovalSubmitted",
          value: true,
        },
      ];

      this.submitToFormIo(this.formURL, jsonData, "PATCH", this.submissionId)
        .then(() => {
          this.buttonLabel = "Resend";
          this.successSubmission = true;
        })
        .catch((error) => {
          this.sendSubmissionError = error;
        })
        .finally(() => {
          this.$refs.OtherInformation.$refs.advisorButton.stopLoading();
        });
    },
    submitApproval() {
      this.submitToFormIo(this.formURL, this.getFormioObject())
        .then((response) => {
          this.submissionId = response;
          this.buttonLabel = "Resend";
          this.successSubmission = true;
        })
        .catch((error) => {
          this.sendSubmissionError = error;
        })
        .finally(() => {
          this.$refs.OtherInformation.$refs.advisorButton.stopLoading();
        });
    },
    getFormioObject() {
      return {
        data: {
          application_id: this.applicationId,
          selectAdvisor: this.value.advisor,
          otherAdvisorsAdvisorName: this.value.otherInformation.name,
          "advisor-email": this.value.otherInformation.email,
          sentApproval: this.sentApproval,
          universityApprovalSubmitted: true,
        },
      };
    },
    async checkIfApproved() {
      let submissionId = await this.formioSubmissionExists(
        "advisorapproval",
        this.applicationId
      );
      if (submissionId) {
        let submission = await this.getFormioSubmission(
          "advisorapproval",
          submissionId
        );
        this.setAdvisorApprovalData({
          field: "formSubmitted",
          value: true,
          applicationId: this.applicationId,
        });

        if (submission) {
          this.setAdvisorApprovalData({
            field: "data",
            value: submission,
            applicationId: this.applicationId,
          });
        }
      } else {
        this.setAdvisorApprovalData({
          field: "formSubmitted",
          value: false,
          applicationId: this.applicationId,
        });
        this.setAdvisorApprovalData({
          field: "data",
          value: {},
          applicationId: this.applicationId,
        });
      }
    },
  },
};
</script>

import { uuid } from "vue-uuid";
import { readCookie } from "@/mixins/helpers";

export default {
  computed: {
    albumNameKey() {
      const album =
        {
          production: "",
          local: "staging",
        }[process.env.MIX_APP_ENV] ?? process.env.MIX_APP_ENV;
      return album === "" ? "" : encodeURIComponent(album) + "/";
    },
  },
  methods: {
    async s3Upload(file, newName) {
      const fileKey = this.albumNameKey + newName;

      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileKey", fileKey);

      return fetch("/api/s3-upload", {
        method: "POST",
        headers: {
          "X-XSRF-Token": readCookie("XSRF-TOKEN"),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((s3) => {
          if (s3.result.Location) {
            return {
              Location: s3.result.Location,
              Bucket: s3.result.Bucket,
              key: fileKey,
            };
          } else {
            throw new Error("Upload connection failed");
          }
        });
    },
    createUUIDName(name) {
      const lastIndex = name.lastIndexOf(".");
      const fileExtension = name.substring(lastIndex);

      return uuid.v4() + fileExtension;
    },
    fileName(name) {
      let nameParts = name.split(".");
      const extension = nameParts.pop();

      const maxFileNameLength = 79 - extension.length;
      const fileName = nameParts.join(".").substring(0, maxFileNameLength);

      return [fileName, extension].join(".");
    },
  },
};

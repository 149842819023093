import { getNested, readCookie, trailingSlash } from "../mixins/helpers";
import userService from "@/services/user";
import formService from "@/services/form";

import gql from "graphql-tag";
import apiClient from "../services/API";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

let programApiUrlBase = trailingSlash(
  process.env.MIX_PROGRAM_SERVICE_API_ENDPOINT
);
const enrollmentApiUrlBase = trailingSlash(
  process.env.MIX_ENROLLMENT_SERVICE_ENDPOINT
);
const currentEnvironment = determineEnvironment();
const programChangeIds = {
  staging: "605d19f9008126bbd63758a1",
  production: "605dfb7d70debe7013810a76",
  development: "601d8adca37fc749bf1921de",
}; // Program Changes form Ids

const paymentIds = {
  production: ["5f4686082f068b84c96f8db2", "5e4d93df6c740bfc00b07525"],
  staging: ["5f4684a8c9230479b6179900", "5f0e1603870c11607ed8e141"],
  development: ["5f4553626640264a813a5fb1", "5ee7a436e9c4fd073f07bc47"],
};

const programSelectionIds = {
  production: "5e4d6b7993920a7732410c8d",
  staging: "5f0e1603870c115f00d8e146",
  development: "5ee7a436e9c4fd694207bc46",
};

const topLevelDomain = window.location.hostname.split(".").slice(-2).join(".");

let actions = {
  async unifiedLogin({ commit }) {
    const user = await userService.getCurrentUser();
    commit("setCurrentUser", user);
  },
  prepareFormio({ commit }) {
    commit("setFormioToken");
  },
  async getEnrollmentData({ commit, state }) {
    // 1. Setting up fetch
    let endpoint = enrollmentApiUrlBase + "progress";
    let headers = new Headers();
    headers.append("x-jwt-token", state.formioToken);
    // 2. Fetch Call for enrollment service
    let fetchCall = await fetch(endpoint, { headers: headers });
    if (fetchCall.status === 200) {
      let info = await fetchCall.json();
      if (Array.isArray(info)) {
        // 3. Set enrollment applications
        commit("setEnrollmentApplicationData", info);
      }
    }
  },
  removeFormioLocalStorage() {
    localStorage.removeItem("formioUser");
    localStorage.removeItem("formioToken");
    cookies.remove(
      "student_portal_remove_local",
      "/",
      window.location.hostname.split(".").slice(-2).join(".")
    );
  },
  getFormioSubmissions({ commit }) {
    const formPaths = [
      "programselection",
      "paymentform",
      "institutionpayment",
      "studentapplicationstage2step1",
      "studentapplicationstage2step2",
      "hostuniversityinternship",
      "housing",
      "programfeespaymentoptions",
      "applicationreceived",
      "programchanges",
    ];

    return formService
      .getSubmissionReport({ formPaths })
      .then((submissions) => {
        commit("setStudentFormioSubmissions", submissions);
      });
  },
  async getStudentApplications({ commit, state }) {
    let applications = (await apiClient.get("/applications")).data;

    applications.forEach((application, index, allApplications) => {
      //Search enrollment application to save the status
      let enrollmentStatus = state.enrollmentApplications.find(
        (app) => app.application_id === application.id
      );
      application.enrollmentStatus = enrollmentStatus
        ? enrollmentStatus.enrollment_status
        : "";

      // next check if they have pending program changes
      let submissionProgramChange = application.submissions.find(
        (submission) => {
          return submission.form === programChangeIds[currentEnvironment];
        }
      );
      if (submissionProgramChange !== undefined) {
        allApplications[index]["programChange"]["requestedChange"] = true;
        allApplications[index]["programChange"]["changeIsPending"] =
          !Object.hasOwnProperty.call(
            submissionProgramChange["data"],
            "changeFinished"
          ) && !submissionProgramChange["data"]["changeFinished"];
        allApplications[index]["programChange"][
          "data"
        ] = submissionProgramChange;

        if (
          Object.hasOwnProperty.call(
            submissionProgramChange["data"],
            "newApplicationId"
          )
        ) {
          allApplications[index]["programChange"]["isNewProgram"] =
            allApplications[index]["id"] ===
            submissionProgramChange.data.newApplicationId;

          allApplications[index]["programChange"][
            "isNewProgramSelected"
          ] = !!submissionProgramChange.data.newApplicationId;
        }
      }

      //check if the application was paid
      application.applicationPaid = application.submissions.some(
        (submission) => {
          return paymentIds[currentEnvironment].includes(submission.form);
        }
      );

      //Set UI version
      let submissionProgramSelection = application.submissions.find(
        (submission) => {
          return submission.form === programSelectionIds[currentEnvironment];
        }
      );
      if (submissionProgramSelection !== undefined) {
        if (
          process.env.MIX_UI_VERSION_FLAG &&
          process.env.MIX_UI_VERSION_FLAG.toLowerCase() === "true"
        ) {
          application.uiVersion =
            submissionProgramSelection.data.uiVersion ?? "v1";
        }

        //Set bulk Enrollment data
        application.bulkEnrollmentId =
          submissionProgramSelection.data.bulkEnrollmentId ?? null;
        application.bulkEnrollmentStatus =
          submissionProgramSelection.data.enrollmentStatus ?? "";
      }
    });

    commit("setStudentApplications", applications);
  },
  getCurrentApplicationSubmissions({ commit, state }) {
    commit("setCurrentApplicationSubmissions", {});
    if (state.currentApplicationId !== "") {
      formService
        .getSubmissionReport({
          applicationIds: [state.currentApplicationId],
        })
        .then((submissions) => {
          commit(
            "setCurrentApplicationSubmissions",
            submissions.reduce((report, submission) => {
              // organize submissions into an object indexed by form path
              const formPath = submission.formPath;
              if (!Object.hasOwn(report, formPath)) {
                report[formPath] = [];
              }
              report[formPath].push(submission);
              return report;
            }, {})
          );
        });
    }
  },
  async getProgramData({ commit, state }, apolloClient) {
    await apolloClient
      .query({
        query: gql`
          query programSessionBySalesforceId($salesforce_id: String!) {
            programSessionBySalesforceId(salesforce_id: $salesforce_id) {
              program_participation_criteria
              program_status
              statement_of_purpose
              visa_required
              salesforce_id
              program {
                name
                internship
                salesforce_id
                customized
                url
                sponsoring_institution_ope8_id
                university {
                  salesforce_id
                  name
                  image
                  url
                }
              }
              form_rules
              post_acceptance_form_rules
              housing_rules
              application_deadline
              application_extension_deadline
              enrollment_deadline
              program_manager
              program_manager_email
              program_manager_image
              minimum_gpa
              deadline
              price
              application_fee
              site {
                salesforce_id
                city
                country
              }
              term
              session
              start_date
              start_date_is_final
              end_date
              end_date_is_final
              academic_details_pdf
              supplemental_form_one
              supplemental_form_two
              supplemental_form_three
              supplemental_form_four
              supplemental_form_instructions
              supplemental_link_one
              supplemental_link_two
              supplemental_link_three
              supplemental_link_four
              cancellation_policy_type
              link_to_custom_cancelation_policy
            }
          }
        `,
        variables: {
          salesforce_id: state.selectedProgramId,
        },
      })
      .then((response) => {
        commit("setProgram", response.data.programSessionBySalesforceId);
        commit("setProgramSelected", true);
        commit(
          "setIsInternship",
          response.data.programSessionBySalesforceId.program.internship
        );
        commit(
          "setUniversityName",
          response.data.programSessionBySalesforceId.program.university
            ? response.data.programSessionBySalesforceId.program.university.name
            : null
        );
        commit(
          "setUniversityCity",
          response.data.programSessionBySalesforceId.program.university
            ? response.data.programSessionBySalesforceId.site.city
            : null
        );
        commit(
          "setUniversityImageUrl",
          response.data.programSessionBySalesforceId.program.university
            ? response.data.programSessionBySalesforceId.program.university
                .image
            : null
        );
        commit(
          "setUniversityWebsiteUrl",
          response.data.programSessionBySalesforceId.program.university
            ? response.data.programSessionBySalesforceId.program.university.url
            : null
        );
        commit("setSelectedProgramId", state.selectedProgramId);
        commit("setTerm", response.data.programSessionBySalesforceId.term);
      });
  },
  getBillingRules({ commit, state }) {
    //bail if no schoolname value
    if (
      typeof state.userData === "undefined" ||
      typeof state.userData.data === "undefined" ||
      typeof state.userData.data.schoolname === "undefined" ||
      typeof state.userData.data.schoolname.value === "undefined"
    ) {
      commit("setBillingRules", {});
      return;
    }

    const universityId = getNested(state.userData.data.schoolname.value);
    fetch(enrollmentApiUrlBase + "university/billing/" + universityId)
      .then((response) => {
        if (!response.ok) {
          commit("setBillingRules", {});
          throw new Error("No billing rules found");
        }
        return response.json();
      })
      .then((data) => {
        commit("setBillingRules", data);
      })
      .catch((error) => console.log(error));
  },
  cacheFormioUser({ commit, state }) {
    // Fetch user if we don't already have user data OR if it's not in localStorage, b/c other places rely on that
    if (!state.userData || Object.keys(state.userData).length === 0) {
      return formService.currentFormServiceUser().then((formioUser) => {
        localStorage.setItem("formioUser", JSON.stringify(formioUser));
        commit("setFormioUser", formioUser);
      });
    }
  },
  async userSetup({ dispatch }) {
    await dispatch("cacheFormioUser");
    dispatch("getBillingRules");
  },
  async getPayments({ commit, state }, applicationId) {
    let response = await fetch(enrollmentApiUrlBase + "student", {
      headers: {
        "Content-type": "application/json",
        "x-jwt-token": state.formioToken,
      },
    });

    let status = await response.status;
    if (status !== 200) {
      return false;
    }

    let data = await response.json();

    //set customer ID/experience ID
    if (data.customer_id) {
      commit("setCustomerId", data.customer_id);
    } else {
      console.log("customer_id does not exist!");
    }

    data.progress.forEach((progress) => {
      if (
        progress.application_id === applicationId &&
        typeof progress.payments !== "undefined"
      ) {
        let payments = [];
        progress.payments.forEach((payment) => {
          payments.push({
            id: payment.id,
            name: payment.name,
            balance: payment.balance,
            due_date: payment.due_date,
            payment_date: payment.payment_date,
            paid_by: payment.paid_by,
            status: payment.status,
            invoice: payment.invoice,
            sku: payment.product_sku,
            line_item_id: payment.opportunity,
            paid: payment.paid,
            salesforce_id: payment.salesforce_id,
          });
        });
        commit("setPayments", payments);
      }
    });
  },
  async setProgramDataByApplicationId(
    { commit, dispatch, state },
    { applicationId, apolloClient }
  ) {
    const selectedProgramId = await fetchProgramSessionIdByApplicationId(
      applicationId,
      state
    );
    if (selectedProgramId === null) {
      console.log("Error setting program data");
      return;
    }
    commit("setSelectedProgramId", selectedProgramId);
    return dispatch("getProgramData", apolloClient);
  },
  initialSetUp({ commit, dispatch }) {
    commit("setFormioToken");
    commit("setSelectedProgramId", "");
    dispatch("userSetup");
  },
  signIn({ state, dispatch, commit }, { apolloClient, submission }) {
    //formioUser key not created by formio on firefox or safari
    if (localStorage.getItem("formioUser") === null) {
      localStorage.setItem("formioUser", JSON.stringify(submission));
    }
    cookies.set(
      "formio_api_token",
      localStorage.formioToken,
      "1Y",
      "/",
      topLevelDomain
    );
    cookies.set("formio_owner_id", submission._id, "1Y", "/", topLevelDomain);

    //create a cooke with the users name to display on header dropdown
    cookies.set(
      "formio_user_name",
      submission.data.firstname,
      "1Y",
      "/",
      topLevelDomain
    );

    const applicationId = localStorage.getItem("expiredApplicationId");
    //If the redirect url use a selected program set the data
    if (state.sessionExpired && applicationId) {
      dispatch("setProgramDataByApplicationId", {
        applicationId: applicationId,
        apolloClient: apolloClient,
      });
    }

    //Clear redirect variables
    localStorage.removeItem("expiredApplicationId");
    commit("setRedirectPath", "");
    commit("setSessionExpired", false);
  },
  async enrollmentAuth({ commit }) {
    localStorage.removeItem("enrollment_auth");
    let response = await fetch("/enrollment-service/authenticate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-XSRF-Token": readCookie("XSRF-TOKEN"),
      },
    });
    let data = await response.json();
    let token = data.token;
    localStorage.setItem("enrollment_auth", token);
    commit("setEnrollmentToken", token);
  },
  logout({ commit }, { sessionExpired = false, redirectPath = "", reload }) {
    let resetPayload = {
      featureFlags: {},
    };

    commit("university/logout");
    commit("reset", resetPayload);
    commit("setSessionExpired", sessionExpired);
    commit("setEnrollmentToken", "");
    commit("setCurrentUser", null);

    if (typeof Storage !== "undefined") {
      localStorage.removeItem("formioToken");
      localStorage.removeItem("formioUser");
      localStorage.removeItem("enrollment_auth");
    }

    cookies.remove("formio_api_token", "/", topLevelDomain);
    cookies.remove("formio_owner_id", "/", topLevelDomain);
    cookies.remove("validated_student", "/", topLevelDomain);
    cookies.remove("formio_user_name", "/", topLevelDomain);
    cookies.remove("authSessionExpired", "/", topLevelDomain);
    cookies.remove("invalid_formio_token", "/", topLevelDomain);
    cookies.set("apiabroad_remove_local", "ok", "1Y", "/", topLevelDomain);

    if (reload) {
      if (sessionExpired) commit("setRedirectPath", redirectPath);
      window.location.href = "/logout/okta";
    }
  },
  async exitImpersonation({ commit, state }) {
    const topLevelDomain = window.location.hostname
      .split(".")
      .slice(-2)
      .join(".");

    if (typeof Storage !== "undefined") {
      localStorage.removeItem("formioToken");
      localStorage.removeItem("formioUser");
      commit("setFormioToken");
    }
    let resetPayload = {
      currentUser: state.currentUser,
      userData: {},
      formioToken: state.currentUser?.advisorToken ?? null,
    };

    cookies.remove("invalid_formio_token", "/", topLevelDomain);

    commit("reset", resetPayload);
    cookies.remove("authSessionExpired", "/", topLevelDomain);
    commit("setIsImpersonating", false);

    await window.axios.post("/impersonate/leave");
    window.location.href = "/students";
  },
  async getUiVersion({ commit, state }, applicationId) {
    let version = "v1";
    if (
      process.env.MIX_UI_VERSION_FLAG &&
      process.env.MIX_UI_VERSION_FLAG.toLowerCase() === "true" &&
      state.currentUser
    ) {
      await formService
        .listSubmissions("programselection", {
          "data.application_id": applicationId,
          sort: "-created",
          limit: 1,
        })
        .then((submissions) => {
          if (submissions.length) {
            version = submissions[0]?.data?.uiVersion ?? version;
          }
        });
    }
    commit("setUiVersion", version);
  },
  async directProgram({ commit }, programSession) {
    //clear the state custom program data
    commit("setDirectProgramData", {});
    //get program data
    await fetch(programApiUrlBase + "session/" + programSession)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Program not found");
        }
      })
      .then((json) => {
        //set state data if custom program
        const obj = {
          salesforce_id: programSession,
          programName: json.program.name,
          customized: json.program.customized,
        };
        commit("setDirectProgramData", obj);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getCustomerId({ commit, state }) {
    let response = await fetch(enrollmentApiUrlBase + "student", {
      headers: {
        "Content-type": "application/json",
        "x-jwt-token": state.formioToken,
      },
    });

    let status = await response.status;
    if (status !== 200) {
      return false;
    }

    let data = await response.json();

    //set customer ID/experience ID
    if (data.customer_id) {
      commit("setCustomerId", data.customer_id);
    } else {
      console.log("customer_id does not exist!");
    }
  },
};
export default actions;

const fetchProgramSessionIdByApplicationId = (applicationId) => {
  return formService
    .listSubmissions("programselection", {
      select: "data.programSession",
      "data.application_id": applicationId,
    })
    .then((submissions) => {
      return submissions[0]?.data?.programSession;
    })
    .catch(() => {
      console.log("Error fetching program session");
      return null;
    });
};

function determineEnvironment() {
  const env = process.env.MIX_APP_ENV;
  return env === "local" ? "staging" : env;
}

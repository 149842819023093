import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import getters from "./getters";
import university from "./modules/university";
import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex'


export default createStore({
  strict: true,
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: { university },
});
